import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import { API_BASE_URL } from '../env-consts';

export const axiosClient = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});
export default class ApiClient {
  private baseUrl: string;

  constructor(baseUrl = '') {
    this.baseUrl = baseUrl;
  }

  async get<ResponseType = any>(
    url: string,
    conf: AxiosRequestConfig = {}
  ): Promise<AxiosResponse<ResponseType>> {
    // console.log("API Client: baseUrl", this.baseUrl)
    try {
      const response = await axiosClient.get(this.baseUrl + url, conf);
      // console.log(`request: ${url} success: `, response)
      return await Promise.resolve(response);
    } catch (error) {
      // console.log(`request: ${url} what error: `, error)
      return await Promise.reject(error);
    }
  }

  async delete<ResponseType = any>(
    url: string,
    conf: AxiosRequestConfig = {}
  ): Promise<AxiosResponse<ResponseType>> {
    try {
      const response = await axiosClient.delete(this.baseUrl + url, conf);
      return await Promise.resolve(response);
    } catch (error) {
      return await Promise.reject(error);
    }
  }

  async post<ResponseType = any>(
    url: string,
    data = {},
    conf: AxiosRequestConfig = {}
  ): Promise<AxiosResponse<ResponseType>> {
    try {
      const response = await axiosClient.post(this.baseUrl + url, data, conf);
      return await Promise.resolve(response);
    } catch (error) {
      return await Promise.reject(error);
    }
  }

  async put<ResponseType = any>(
    url: string,
    data = {},
    conf: AxiosRequestConfig = {}
  ): Promise<AxiosResponse<ResponseType>> {
    try {
      const response = await axiosClient.put(this.baseUrl + url, data, conf);
      return await Promise.resolve(response);
    } catch (error) {
      return await Promise.reject(error);
    }
  }

  async patch<ResponseType = any>(
    url: string,
    data = {},
    conf: AxiosRequestConfig = {}
  ): Promise<AxiosResponse<ResponseType>> {
    try {
      const response = await axiosClient.patch(this.baseUrl + url, data, conf);
      return await Promise.resolve(response);
    } catch (error) {
      return await Promise.reject(error);
    }
  }
}
