import { API_BASE_URL } from '../env-consts';
import {
  CompanyUserResponse,
  CreateCompanyUserPayload,
  UpdateCompanyUserPayload,
} from '../shared/types/companies';
import { PaginatedResult } from '../shared/types/paginatedResult';
import { UserResponse } from '../shared/types/users';
import ApiClient from './ApiClient';

const client = new ApiClient(`${API_BASE_URL}/companies`);

export const CompanyUserClient = {
  async inviteUser(companyId: string, payload: CreateCompanyUserPayload) {
    return client.post(`/${companyId}/user`, payload).then(() => {});
  },

  async getAll(companyId: string, page: number, query?: string) {
    return client
      .get<PaginatedResult<CompanyUserResponse>>(`/${companyId}/user`, {
        params: {
          page,
          query,
        },
      })
      .then((res) => res.data);
  },

  // TODO: Review Ronan(Me) marked it as a dev endpoint
  async acceptInvite(companyId: string, userId: string) {
    return client
      .post<UserResponse>(`/companies/accept-invite/${companyId}/${userId}`)
      .then(({ data }) => data);
  },

  async resendInvite(companyId: string, userId: string) {
    return client.post(`/${companyId}/user/${userId}`).then(() => {});
  },

  async deleteUser(companyId: string, payload: CreateCompanyUserPayload) {
    return client.post(`/${companyId}/user`, payload).then(() => {});
  },

  /**
   * Currently this only updates the user role.
   */
  async updateUser(
    companyId: string,
    userId: string,
    payload: UpdateCompanyUserPayload
  ) {
    return client
      .patch<void>(`/${companyId}/user/${userId}`, payload)
      .then(() => {});
  },
};
