import axios from 'axios';
import React from 'react';
import {
  ActivityIndicator,
  Linking,
  Pressable,
  Text,
  View,
} from 'react-native';
import { axiosClient } from '../../api';

import tw from '../../config/tailwind';
import { isWeb } from '../../constants';
import { API_BASE_URL, WP_SITE_URL } from '../../env-consts';
import { FavouriteType } from '../../shared/types/favourites';
import { WpResource } from '../../shared/types/wordpress';
import { decodeEntities } from '../../utils/decodeEntities';
import FavButton from '../favourites/FavButton';
import Col from '../shared/Col';
import ContentWrapper from '../shared/ContentWrapper';
import FullWidthButton from '../shared/FullWidthButton';
import Row from '../shared/Row';
import Spacer from '../shared/Spacer';
import DoubleChevronRightSVG from '../svg/DoubleChevronRightSVG';
import DownloadSVG from '../svg/DownloadSVG';

interface ResourceListingProps {
  resource: WpResource | undefined;
  skeleton?: boolean;
}

const ResourceListing: React.FC<ResourceListingProps> = ({
  resource,
  skeleton,
}) => {
  if (skeleton || !resource) {
    return (
      <ContentWrapper
        style={tw`flex-1 border-background-600 border justify-center items-center`}
      >
        <ActivityIndicator />
      </ContentWrapper>
    );
  }

  const downloadUrl = resource?.acf.pinnacle_portal_only
    ? `${API_BASE_URL}/resources/${resource.id}` //resource.acf.custom_download_url
    : WP_SITE_URL + `?p=${resource?.acf.download_file}&download`;

  const downloadClicked = (e: {
    preventDefault(): void;
    stopPropagation(): void;
  }) => {
    if (resource?.acf.pinnacle_portal_only) {
      e.preventDefault();
      e.stopPropagation();

      axios({
        url: `${API_BASE_URL}/resources/${resource.id}`,
        method: 'GET',
        responseType: 'blob',
        headers: {
          Authorization: axiosClient.defaults.headers.common['Authorization'],
        },
      }).then((response) => {
        const href = URL.createObjectURL(response.data);

        const link = document.createElement('a');
        link.href = href;
        link.setAttribute(
          'download',
          resource.acf.custom_download_url.substring(
            resource.acf.custom_download_url.lastIndexOf('/') + 1
          )
        );
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
    }
  };

  return (
    <ContentWrapper style={tw`flex-1 border-background-600 border pt-2`}>
      <Row style={tw`mb-5`}>
        <Col style='w-auto flex flex-row items-center'>
          {isWeb ? (
            <a
              href={downloadUrl}
              target='_blank'
              download
              style={tw`text-inherit no-underline`}
              onClick={downloadClicked}
            >
              <DownloadSVG style={tw`text-green`} />
            </a>
          ) : (
            <DownloadSVG style={tw`text-green`} />
          )}
        </Col>
        <Spacer />
        <FavButton entityId={resource.id} type={FavouriteType.Resource} />
      </Row>
      <Row style={tw`flex-1`}>
        <Col style={tw`w-full`}>
          <Text
            style={tw`text-sm text-green font-bold font-ubuntu-bold pb-1 uppercase`}
          >
            {resource.assignedCategoryNames.length
              ? resource.assignedCategoryNames.join(', ')
              : ' '}
          </Text>
          <Text style={tw`font-bold font-ubuntu-bold`}>
            {decodeEntities(resource?.title.rendered)}
          </Text>
          <Spacer />
          {isWeb ? (
            <a
              href={downloadUrl}
              target='_blank'
              download
              style={tw`text-inherit no-underline mr-6 max-w-50`}
            >
              <FullWidthButton
                variant='transparent'
                buttonStyle={tw.style(`pl-0 pr-4 py-2 mt-4`)}
                underline
                onPress={downloadClicked}
              >
                <View style={tw`flex-row`}>
                  <DoubleChevronRightSVG />
                  <Text style={tw`text-base`}>Download</Text>
                </View>
              </FullWidthButton>
            </a>
          ) : (
            <FullWidthButton
              variant='transparent'
              buttonStyle={tw.style(`pl-0 pr-4 py-2 mr-6 mt-4 max-w-50`)}
              underline
              onPress={() => Linking.openURL(downloadUrl)}
            >
              <View style={tw`flex-row`}>
                <DoubleChevronRightSVG />
                <Text style={tw`text-base`}>Download</Text>
              </View>
            </FullWidthButton>
          )}
        </Col>
      </Row>
    </ContentWrapper>
  );
};

export default ResourceListing;
