import { Formik, isFunction } from 'formik';
import { PhoneNumberUtil } from 'google-libphonenumber';
import React, { useEffect, useState } from 'react';
import { ActivityIndicator, Pressable, ScrollView, View } from 'react-native';
import * as Yup from 'yup';
import { ContactClient } from '../../../api';
import tw from '../../../config/tailwind';
import { phoneRegExp } from '../../../constants';
import { useToasts } from '../../../hooks/useToasts';
import {
  ContactResponse,
  CreateContactPayload,
  UpdateContactPayload,
} from '../../../shared/types/contacts';
import { PaginatedResult } from '../../../shared/types/paginatedResult';
import { formatToE164 } from '../../../utils/transformToE164';
import ContactCard from '../../contacts/ContactCard';
import Pagination from '../../Pagination';
import SearchBar from '../../SearchBar';
import Button from '../../shared/Button';
import Col from '../../shared/Col';
import DefaultModal from '../../shared/DefaultModal';
import FilePicker from '../../shared/FilePicker';
import FullWidthButton from '../../shared/FullWidthButton';
import InputText from '../../shared/InputText';
import InputValidationMessage from '../../shared/InputValidationMessage';
import P from '../../shared/P';
import Row from '../../shared/Row';
import Text from '../../shared/Text';
import PlusSVG from '../../svg/PlusSVG';

const phoneUtil: PhoneNumberUtil =
  require('google-libphonenumber').PhoneNumberUtil.getInstance();

interface AssignContactModalProps {
  setVisible: (v: boolean) => void;
  assignedContactIds: string[];
  companyId: string;
  onUpdated: () => void;
}

const AssignContactModal: React.FC<AssignContactModalProps> = ({
  setVisible,
  assignedContactIds: currentAssignedContactIds,
  onUpdated,
  companyId,
}) => {
  const { addToast, addUnhandledErrorToast } = useToasts();
  const [contacts, setContacts] = useState<PaginatedResult<ContactResponse>>();
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState('');
  const [refresh, setRefresh] = useState(0);
  const [assignedContactIds, setAssignedContactIds] = useState<string[]>(
    currentAssignedContactIds
  );

  useEffect(() => {
    let isActive = true;
    ContactClient.findAll(query, page, undefined, 6).then(
      (res) => isActive && setContacts(res)
    );
    return () => {
      isActive = false;
    };
  }, [refresh]);

  const assignContact = async (id: string) => {
    try {
      await ContactClient.assign({
        companyId,
        contactId: id,
      });
      setAssignedContactIds([...assignedContactIds, id]);
      addToast({
        title: 'Contact assigned',
        description: 'Contact was assigned to the company successfully',
        type: 'success',
      });
      onUpdated();
    } catch (error) {
      addUnhandledErrorToast(error);
    }
  };

  return (
    <DefaultModal
      setVisible={setVisible}
      visible
      title='Assign a contact'
      modalWidth='lg'
    >
      <View>
        {!contacts ? (
          <ActivityIndicator />
        ) : (
          <>
            <SearchBar
              onStoppedTyping={(v) => {
                setPage(1);
                setQuery(v);
                setRefresh((x) => x + 1);
              }}
              textInputProps={{ value: query, placeholder: 'Search contacts' }}
            />

            {refresh > 0 && !contacts.items.length && (
              <Text>No contacts found.</Text>
            )}

            <Row style={tw`flex flex-wrap -mx-1.5`}>
              {contacts.items.map(
                ({ id, name, mobile, role, telephone, email, image }, i) => (
                  <Col style={tw`w-full sm:w-1/2 md:w-1/3 mb-3 px-1.5`}>
                    <View style={tw`border border-background`}>
                      <ContactCard
                        key={i}
                        name={name}
                        role={role}
                        mob={mobile}
                        tel={telephone}
                        email={email}
                        imageUrl={image?.publicUrl}
                        action={
                          <View
                            style={tw`min-h-[50px] self-center justify-center`}
                          >
                            {assignedContactIds.includes(id) ? (
                              <Text
                                style={tw`text-green font-bold font-ubuntu-bold mb-0`}
                              >
                                Assigned
                              </Text>
                            ) : (
                              <FullWidthButton
                                onPress={() => assignContact(id)}
                                variant='transparent'
                                buttonStyle={tw`py-2 pr-4 mb-0`}
                                underline
                              >
                                Assign
                              </FullWidthButton>
                            )}
                          </View>
                        }
                      />
                    </View>
                  </Col>
                )
              )}
            </Row>

            <Pagination
              paginatedResult={contacts}
              onChangePage={(v) => {
                setPage(v);
                setRefresh((x) => x + 1);
              }}
            />
          </>
        )}
      </View>
    </DefaultModal>
  );
};

export default AssignContactModal;
