import { FC } from 'react';
import { Pressable, View } from 'react-native';
import { ClassInput } from 'twrnc/dist/esm/types';

import tw from '../../config/tailwind';
import PencilSVG from '../svg/PencilSVG';
import Text from './Text';

type Props = {
  onEditClick: () => void;
  style?: ClassInput;
};

const EditableText: FC<Props> = ({ children, onEditClick, style }) => {
  return (
    <View
      style={tw.style(`flex-1 flex flex-row items-center max-w-[14rem]`, style)}
    >
      <Text style={tw`font-bold font-ubuntu-bold max-w-[14rem] mr-4`}>
        {children}
      </Text>
      <Pressable style={tw`ml-auto`} onPress={onEditClick}>
        <PencilSVG style={tw`text-green`} />
      </Pressable>
    </View>
  );
};

export default EditableText;
