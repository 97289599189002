import { FC } from 'react';
import { View } from 'react-native';

import tw from '../../config/tailwind';
import DoubleChevronRightSVG from '../svg/DoubleChevronRightSVG';
import Button from './Button';
import FullWidthButton from './FullWidthButton';
import Text from './Text';

type Props = {
  children: string;
  onPress: () => void;
};

const BackButton: FC<Props> = ({ children, onPress }) => {
  return (
    <View style={tw`w-48 mb-6`}>
      <FullWidthButton
        variant='transparent'
        buttonStyle={tw`py-2 pr-3 w-48`}
        underline
        onPress={onPress}
      >
        <View style={tw`flex flex-row items-center`}>
          <DoubleChevronRightSVG style={tw`mr-1`} />
          <Text>{children}</Text>
        </View>
      </FullWidthButton>
    </View>
  );
};

export default BackButton;
