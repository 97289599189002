import { useEffect, useState } from "react";
import DefaultModal from "../../../components/shared/DefaultModal";
import { Rebate, RebateApplicationNewPayload } from "../../../shared/types/rebates";
import * as Yup from 'yup';
import { Formik } from "formik";
import { View } from "react-native";
import tw from "../../../config/tailwind";
import FilePicker from "../../../components/shared/FilePicker";
import { GlobalSettingsClient } from "../../../api/GlobalSettingsClient";
import { GlobalSettingsIds } from "../../../shared/types/globalSettings";
import { RebateTemplate } from "../../../components/admin/AdminGlobalSettingsPage";
import DownloadSVG from "../../../components/svg/DownloadSVG";
import Button from "../../../components/shared/Button";
import { displayQuarter } from "./RebateFunction";
import LoadingScreen from "../../../components/shared/LoadingScreen";
import { useAppContext } from "../../../hooks/useAppContext";
import { RebateClient } from "../../../api/RebateClient";
import { useToasts } from "../../../hooks/useToasts";
import RebateSupportingEvidencesUploader from "./RebateSupportingEvidencesUploader";
import H3 from "../../../components/shared/H3";


interface Props {
  rebate: Rebate | null;
	visble: boolean;
	setVisible: (v: boolean) => void;
	refresh: () => void;
};

const RebateReapplyModal:React.FC<Props> = ({rebate, visble, setVisible, refresh}) =>{
	const { setGlobalLoading } = useAppContext();
	const { addToast, addUnhandledErrorToast } = useToasts();

	const initialValues: RebateApplicationNewPayload = {
		rebateId: rebate?.id ?? '',
		evidence: null,
    supportingEvidences: []
	}

	const [rebateTemplate, setRebateTemplate] = useState<
    RebateTemplate | undefined
  >(undefined);

	const schema: Yup.SchemaOf<typeof initialValues> = Yup.object().shape({
		rebateId: Yup.string().required(),
    evidence: Yup.mixed().required('Please upload a valid excel file'),
    supportingEvidences: Yup.array()
  });

	const loadSettings = async () => {
    const res = await GlobalSettingsClient.getAll();
    const tempRebateTemplate = JSON.parse(
      res.find((x) => x.id === GlobalSettingsIds.rebateTemplate)?.value || '{}'
    ) as RebateTemplate;
    setRebateTemplate(tempRebateTemplate);
  };

  useEffect(() => {
    loadSettings();
  }, []);

	const onSubmit = async (payload: RebateApplicationNewPayload) => {
		// set loading
    setGlobalLoading && setGlobalLoading(true);

    // post request
    const res = await RebateClient.createApplication(payload);

    // remove loading
    setGlobalLoading && setGlobalLoading(false);

    // catch and handle erro response
    if (res.status !== 201) {
      addToast({
        title: 'Failed to create new rebate.',
        description: res.message,
        type: 'error',
      });
      return;
    }

    // update success toast
    addToast({
      title: 'New Rebate created',
      description: res.message,
      type: 'success',
    });

    refresh && refresh();

    setVisible(false);
	}

  return (
    <>
      <DefaultModal
        setVisible={(v: boolean) => setVisible(v)}
				visible={visble}
				title={'New Application for rebate'}
      >
				<Formik
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={onSubmit}
          validationSchema={schema}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            values,
            errors,
            isSubmitting,
          }) => (
            <>
              <View style={tw`font-sans`}>
                <p>Company: {rebate?.company.name}</p>
								<p>Quarter: {displayQuarter(rebate?.rebateDate)}</p>
              </View>
              <View style={tw`font-sans`}>
                <div>
                  <H3>Evidence Spreadsheet</H3>
                  <FilePicker
                    type={[
                      `application/vnd.ms-excel`,
                      `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet`,
                    ]}
                    setValue={(v) => setFieldValue('evidence', v)}
                    label='Select new evidence to upload'
                    hasError={!!errors.evidence}
                  />
                  {errors.evidence ? (
                    <p style={tw`text-red`}>{errors.evidence}</p>
                  ) : null}
                </div>

                <RebateSupportingEvidencesUploader
                  files={values.supportingEvidences}
                  addFiles={(f) => {
                    if(!f){return};
                    const files = [...values.supportingEvidences].concat([f])
                    setFieldValue('supportingEvidences', files);
                  }}
                  removeFiles={(idx) => {
                    setFieldValue('supportingEvidences', values.supportingEvidences.filter((f, i) => i !== idx))
                  }}
                />

                <div style={tw`w-full flex flex-rows justify-between my-5`}>
                  {rebateTemplate ? (
                    <a
                      style={tw`font-sans mr-auto bg-[#eee] p-4 flex flex-rows justify-center align-center items-center text-dark no-underline`}
                      href={rebateTemplate?.url}
                      download
                    >
                      <DownloadSVG style={tw`mr-2`} />
                      Download evidence template
                    </a>
                  ) : null}
                  <Button onPress={handleSubmit} disabled={isSubmitting}>
                    Submit
                  </Button>
                </div>
              </View>
            </>
          )}
        </Formik>
			</DefaultModal>
			<LoadingScreen />
    </>
  )
};

export default RebateReapplyModal