import { API_BASE_URL } from '../env-consts';
import {
  CompanyResponse,
  CreateCompanyPayload,
  UpdateCompanyPayload,
} from '../shared/types/companies';
import { ContactResponse } from '../shared/types/contacts';
import { PaginatedResult } from '../shared/types/paginatedResult';
import ApiClient from './ApiClient';

const client = new ApiClient(`${API_BASE_URL}/companies`);

export const CompanyClient = {
  async getById(id: string) {
    return client.get<CompanyResponse>(`/${id}/`).then(({ data }) => data);
  },

  async getAll(page: number, query?: string) {
    return client
      .get<PaginatedResult<CompanyResponse>>(`/`, {
        params: {
          page,
          query,
        },
      })
      .then(({ data }) => data);
  },

  async create(payload: CreateCompanyPayload) {
    return client.post<CompanyResponse>(`/`, payload).then(({ data }) => data);
  },

  async update(companyId: string, payload: UpdateCompanyPayload) {
    return client
      .patch<CompanyResponse>(`/${companyId}`, payload)
      .then(({ data }) => data);
  },

  async delete(companyId: string) {
    return client.delete(`/${companyId}`);
  },

  async getCompanyContacts(companyId: string) {
    return client
      .get<ContactResponse[]>(`/${companyId}/contacts`)
      .then((res) => res.data);
  },
};
