import { CompanyUserRole } from './companies';
import { ContactResponse } from './contacts';
import { SnagSmallResponse } from './snags';
import { TaskSmallResponse } from './tasks';
import { WpApplication, WpDistributor, WpProduct, WpSector } from './wordpress';

export enum ProjectRole {
  User = 'User',
  Owner = 'Owner',
}

export interface ProjectResponse {
  id: string;
  name: string;
  location: string;
  distributors: WpDistributor[];
  contacts: ContactResponse[];
  application?: WpApplication;
  sector?: WpSector;
  caseStudyOptOut: boolean;
  caseStudyFlag: boolean;
  status: ProjectStatus;
  createdAt: string;
  completedAt: string;
  customApplication: string | null;
}

export interface ProjectCompanyUserResponse {
  userId: string;
  companyId: string;
  projectId: string;
  projectRole: ProjectRole;
  companyRole: CompanyUserRole;
  user: {
    firstName: string;
    lastName: string;
    email: string;
    businessName: string;
  };
}

export interface ProjectRoomResponse {
  id: string;
  name: string;
  projectId: string;
  relatedProducts?: WpProduct[];
}

export interface ProjectProductResponse {
  id: string;
  name: string;
  roomId: string;
  roomName: string;
  product: WpProduct;
}

export interface CreateProjectRoomPayload {
  name: string;
  relatedProductIds?: number[];
}

export interface UpdateProjectRoomPayload {
  name: string;
  productsAdded: number[];
  productsRemoved: number[];
}

export interface CompleteProjectPayload {
  completionDate: Date;
  caseStudyOptIn: boolean;
}

export interface CompleteProjectErrorResponse {
  incompleteSnags: SnagSmallResponse[];
  incompleteTasks: TaskSmallResponse[];
}

export interface CreateProjectPayload {
  name: string;
  location: string;
  distributors: number[];
  contacts?: string[];
  applicationId: number;
  sectorId: number;
  caseStudyOptOut: boolean;
  companyId: string;
  customApplication?: string;
}

export type UpdateProjectPayload = Partial<CreateProjectPayload> & {
  caseStudyFlag?: boolean;
};

export interface ProjectStatsResponse {
  tasks: {
    complete: number;
    incomplete: number;
  };
  snags: {
    complete: number;
    incomplete: number;
  };
}

export enum ProjectStatus {
  Completed = 'completed',
  Live = 'live',
}
