import { IPaginationMeta } from 'nestjs-typeorm-paginate';
import Button from '../../../components/shared/Button';
import tw from '../../../config/tailwind';

interface Props {
  meta?: IPaginationMeta | null;
  setPage: (page: number) => void;
}

const globalButtonStyle: string = `p-2 min-h-[0px] bg-transparent`;
const globalVariant: string = 'transparent';

const RebatePaginationControl: React.FC<Props> = ({ meta, setPage }) => {
  return meta ? (
    <div
      style={tw`font-sans my-2 w-full flex justify-between items-center align-center`}
    >
      {
        meta.totalItems ?
        <div>{`Showing ${(meta.currentPage - 1) * meta.itemsPerPage + 1}-${
          (meta.currentPage - 1) * meta.itemsPerPage + meta.itemCount
        } of ${meta.totalItems}`}</div>
        : <div></div>
      }
      {meta.totalPages && meta.totalPages > 1 ? (
        <>
          <div style={tw`flex flex-row`}>
            <Button
              style={globalButtonStyle}
              variant={globalVariant}
              disabled={meta.currentPage === 1}
              onPress={() => setPage(1)}
            >{`<<`}</Button>
            <Button
              style={globalButtonStyle}
              variant={globalVariant}
              disabled={meta.currentPage === 1}
              onPress={() => setPage(meta.currentPage - 1 || 1)}
            >{`<`}</Button>
            {meta.totalPages
              ? [...Array(meta.totalPages)].map((x, i) => (
                  <Button
                    style={globalButtonStyle}
                    variant={
                      i + 1 === meta.currentPage ? 'default' : globalVariant
                    }
                    key={`pagination-item-${i + 1}`}
                    disabled={i + 1 === meta.currentPage}
                    onPress={() => setPage(i + 1)}
                  >
                    {i + 1}
                  </Button>
                ))
              : null}
            <Button
              variant={globalVariant}
              style={globalButtonStyle}
              disabled={meta.currentPage === meta.totalPages}
              onPress={() => setPage(meta.currentPage + 1)}
            >{`>`}</Button>
            <Button
              variant={globalVariant}
              style={globalButtonStyle}
              disabled={meta.currentPage === meta.totalPages}
              onPress={() => setPage(meta.totalPages || 1)}
            >{`>>`}</Button>
          </div>
        </>
      ) : null}
    </div>
  ) : null;
};
export default RebatePaginationControl;
