import {
  createContext,
  FC,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Animated, View } from 'react-native';

import Toast from '../components/toasts/Toast';
import tw from '../config/tailwind';

export type ToastNotification = {
  title: string;
  description: string;
  type: 'success' | 'error';
  id?: number;
};

type ToastContextType = {
  addToast: (toast: ToastNotification) => void;
  addUnhandledErrorToast: (exception: Error | any) => void;
  setShowModalUnderlay: (show: boolean) => void;
};

export const ToastContext = createContext<ToastContextType>({
  addToast: () => {},
  addUnhandledErrorToast: () => {},
  setShowModalUnderlay: () => {},
});

type Props = {
  children: ReactNode;
};

const ToastContextProvider: FC<Props> = ({ children }) => {
  const [toasts, setToasts] = useState<ToastNotification[]>([]);
  const [showModalUnderlay, setShowModalUnderlay] = useState(false);

  const backdropOpacity = useRef(new Animated.Value(0)).current;
  const toastIdentifier = useRef<number>(0);

  const addToast = (toast: ToastNotification) => {
    toast.id = toastIdentifier.current++;
    setToasts((oldToasts) => [...oldToasts, toast]);
  };

  const addUnhandledErrorToast = (e: Error | any) => {
    console.error(e);

    addToast({
      title: 'Something went wrong',
      description: 'An unhandled error occured, please try again later.',
      type: 'error',
    });
  };

  const removeToast = (toast: ToastNotification) => {
    setToasts((oldToasts) => oldToasts.filter((t) => t.id !== toast.id));
  };

  // useEffect(() => {
  //   Animated.timing(backdropOpacity, {
  //     toValue: showModalUnderlay ? 1 : 0,
  //     duration: 250,
  //     useNativeDriver: true,
  //   }).start();
  // }, [showModalUnderlay]);

  return (
    <ToastContext.Provider
      value={{
        addToast,
        addUnhandledErrorToast,
        setShowModalUnderlay,
      }}
    >
      {children}

      {/* TODO: stop doing this, more detail in DefaultModal.tsx */}
      <Animated.View
        pointerEvents='none'
        style={[
          tw`bg-black bg-opacity-40 w-full h-full absolute`,
          { opacity: backdropOpacity },
        ]}
      />
      {toasts.length > 0 && (
        <View
          style={tw`w-full max-w-md absolute right-0 bottom-0 px-8 py-4 flex overflow-hidden`}
        >
          {toasts.map((toast, i) => (
            <Toast
              toast={toast}
              removeToast={removeToast}
              key={toast.id}
              forceHide={i < toasts.length - 3}
            />
          ))}
        </View>
      )}
    </ToastContext.Provider>
  );
};

export default ToastContextProvider;
