import React from 'react';
import { TouchableOpacity, View } from 'react-native';

import tw from '../config/tailwind';
import { PaginatedResult } from '../shared/types/paginatedResult';
import Row from './shared/Row';
import Spacer from './shared/Spacer';
import Text from './shared/Text';
import DoubleChevronLeftSVG from './svg/DoubleChevronLeftSVG';
import DoubleChevronRightSVG from './svg/DoubleChevronRightSVG';

interface PaginationProps {
  paginatedResult: PaginatedResult<any>;
  onChangePage: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  onChangePage,
  paginatedResult: { totalPages, totalItems, currentPage, items, perPage },
}) => {
  const firstPageItemIndex = (currentPage - 1) * perPage;
  const lastPageItemIndex = firstPageItemIndex + items.length;
  const showActions = totalPages > 1;
  const canGoBack = firstPageItemIndex >= perPage;
  const canGoForward = lastPageItemIndex < totalItems;

  return (
    <Row style={tw`mx-0 items-center`}>
      <Spacer />

      <View>
        <Text>
          {items.length > 0 ? firstPageItemIndex + 1 : 0} - {lastPageItemIndex}{' '}
          of {totalItems}
        </Text>
      </View>

      {showActions && (
        <View style={tw`flex flex-row flex-nowrap ml-4`}>
          <TouchableOpacity
            disabled={!canGoBack}
            onPress={() => onChangePage(currentPage - 1)}
          >
            <View
              style={tw.style(
                `flex flex-row items-center pr-3 py-3`,
                !canGoBack && tw`opacity-25`
              )}
            >
              <DoubleChevronLeftSVG height={12} width={12} style={tw`-mb-px`} />
              <Text>Previous</Text>
            </View>
          </TouchableOpacity>

          <TouchableOpacity
            disabled={!canGoForward}
            onPress={() => onChangePage(currentPage + 1)}
          >
            <View
              style={tw.style(
                `flex flex-row items-center py-3`,
                !canGoForward && 'opacity-25'
              )}
            >
              <Text>Next</Text>
              <DoubleChevronRightSVG
                height={12}
                width={12}
                style={tw`-mb-px`}
              />
            </View>
          </TouchableOpacity>
        </View>
      )}
    </Row>
  );
};

export default Pagination;
