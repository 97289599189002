import { FC } from 'react';
import {
  KeyboardTypeOptions,
  NativeSyntheticEvent,
  TextInput,
  TextInputFocusEventData,
} from 'react-native';

import tw from '../../config/tailwind';
import InputLabel from './InputLabel';

type Props = {
  value: string;
  placeholder?: string;
  setValue?: (v: string) => void;
  onBlur?: (e: NativeSyntheticEvent<TextInputFocusEventData>) => void;
  hasError?: boolean;
  label?: string;
  required?: boolean;
  labelSize?: 'sm' | 'md';
  multiline?: boolean;
  numberOfLines?: number;
  readonly?: boolean;
  keyboardType?: KeyboardTypeOptions;
};

const InputText: FC<Props> = ({
  value,
  setValue,
  onBlur,
  placeholder,
  hasError,
  label,
  required,
  multiline,
  numberOfLines = 4,
  labelSize,
  readonly,
  keyboardType = 'default',
}) => {
  return (
    <>
      {label && (
        <InputLabel label={label} required={required} labelSize={labelSize} />
      )}
      <TextInput
        style={tw.style(
          `px-4 py-3 mb-4 border-2 font-sans ${
            hasError ? 'border-red' : 'border-blue'
          } bg-white`,
          { minHeight: 50 }
        )}
        value={value}
        onChangeText={setValue}
        onBlur={onBlur}
        placeholder={placeholder}
        placeholderTextColor={tw.color(`dark opacity-50`)}
        multiline={multiline}
        numberOfLines={multiline ? numberOfLines : undefined}
        editable={!readonly}
        focusable
        keyboardType={keyboardType}
      />
    </>
  );
};

export default InputText;
