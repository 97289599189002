import dayjs from 'dayjs';
import { serialize } from 'object-to-formdata';
import { API_BASE_URL } from '../env-consts';
import {
  Rebate,
  RebateApplicationNewPayload,
  RebateApplicationUpdatePayload,
  RebateNewPayload,
  RebateNewResponse,
} from '../shared/types/rebates';
import { RebateResultFilter } from '../views/AuthenticatedStack/Rebates/RebatesScreen';
import ApiClient from './ApiClient';

const client = new ApiClient(`${API_BASE_URL}/rebates`);
const formDataOptions = {
  headers: {
    'Content-Type': 'multipart/form-data',
    enctype: 'multipart/form-data',
  },
};
export const RebateClient = {
  async getTemplateFile(
    filename: string
  ){
    return await client.get(`/templateFile?filename=${filename}`);
  },
  async getRebateFile(
    companyId: string, filename: string
  ){
    return await client.get(`/file?companyId=${companyId}&filename=${filename}`);
  },
  async create(payload: RebateNewPayload) {
    const { rebateDate, evidence, supportingEvidences, consent, ...cleanPayload } = payload;
    const massaged = {
      ...cleanPayload,
      rebateDate: dayjs(rebateDate).format('YYYY-MM-DD'),
      consent: 'true',
    };
    const formData = new FormData();
    for(const [key, value] of Object.entries(massaged)){
      if(value){
        formData.append(key, value);
      }
    }
    if(evidence){
      formData.append('supportingEvidences', evidence);
    }
    supportingEvidences.forEach(x => {
      formData.append('supportingEvidences', x);
    })
    const res: RebateNewResponse = await client
      .post('', formData, formDataOptions)
      .then((res) => {
        return { status: 201, message: 'New rebate created successfully.' };
      })
      .catch((error) => {
        if (error.response) {
          return {
            status: error.response.status,
            message: error.response.message,
          };
        } else {
          return {
            status: 500,
            message: 'An error has occurred. Please try again later.',
          };
        }
      });
    return res;
  },

  async getAll(companyId: string | null = null) {
    return await client.get(`${companyId ? `?companyId=${companyId}` : ``}`);
  },

  async getById(id: string) {
    return await client
      .get(`?id=${id}`)
      .then((response) => {
        return response.data as Rebate;
      })
      .catch((response) => {
        return null;
      });
  },

  async getAllPaginated(
    page: number = 1,
    filter: RebateResultFilter | null = null
  ) {
    // console.log('getAllPaginated > filter', filter);
    let filterToQuery = '';
    if (filter) {
      let filterQuery = {};
      if (filter.company) {
        Object.assign(filterQuery, { companyId: filter.company.id });
      }
      if (filter.applicant) {
        Object.assign(filterQuery, { applicantId: filter.applicant.id });
      }
      if (filter.reviewer) {
        Object.assign(filterQuery, { reviewerId: filter.reviewer.id });
      }
      let newParams = new URLSearchParams(filterQuery);
      filter.quarters.forEach((x) => {
        newParams.append('date', dayjs(x.value).format('YYYY-MM-DD'));
      });
      //console.log('before convert to string', newParams, newParams.toString());
      filterToQuery = newParams.toString();
    }
    const url = `/paginated?page=${page}&${filterToQuery}`;
    return await client.get(url);
  },

  async createApplication(payload: RebateApplicationNewPayload) {
    const {evidence, supportingEvidences, rebateId} = payload;
    const formData = new FormData();
    formData.append('rebateId', rebateId);
    if(evidence){
      formData.append('supportingEvidences', evidence);
    }
    supportingEvidences.forEach(x => {
      formData.append('supportingEvidences', x);
    })
    const res: RebateNewResponse = await client
      .post('/application', formData, formDataOptions)
      .then((res) => {
        return {
          status: 201,
          message: 'New rebate application created successfully.',
        };
      })
      .catch((error) => {
        if (error.response) {
          return {
            status: error.response.status,
            message: error.response.message,
          };
        } else {
          return {
            status: 500,
            message: 'An error has occurred. Please try again later.',
          };
        }
      });
    return res;
  },

  async updateApplication(payload: RebateApplicationUpdatePayload) {
    const { id, ...data } = payload;
    return client.patch(`/application/${id}`, data);
  },

  async updateTemplate(file: File) {
    const data = new FormData();
    data.set('file', file, file.name);
    const res = await client.post('/template', data, formDataOptions);
    console.log('from rebate api', res);
    return res.data;
  },
};
