import { useState } from 'react';
import DefaultModal from '../../shared/DefaultModal';
import FilePicker from '../../shared/FilePicker';
import InputValidationMessage from '../../shared/InputValidationMessage';
import tw from '../../../config/tailwind';
import Button from '../../shared/Button';
import {ScaleLoader} from 'react-spinners';
import { RebateClient } from '../../../api/RebateClient';
import { useToasts } from '../../../hooks/useToasts';

interface Props {
  visible: boolean;
  setVisible: (v: boolean) => void;
  callback?: () => void;
}

const RebateTemplateUploadModal: React.FC<Props> = ({
  visible,
  setVisible,
  callback
}) => {
  const [file, setFile] = useState<File | null>(null);
  const [fileError, setFileError] = useState('');
  const [uploading, setUploading] = useState(false);
  const { addToast, addUnhandledErrorToast } = useToasts();

  const handleSubmit = async () => {
    if (!file) {
      setFileError('Please provide an valid excel file.');
      return;
    }
    setUploading(true);

    // for testing
    // setTimeout(() => setUploading(false), 3000);

    const res = await RebateClient.updateTemplate(file);
    console.log(res);

    addToast({
      title: 'Success!',
      description: `${res.message}`,
      type: 'success',
    });
    if(callback){ callback() };
    setUploading(false);
    setVisible(false);
  };

  return (
    <DefaultModal
      visible={visible}
      setVisible={setVisible}
      title={'Upload New Rebate Template'}
    >
      {uploading ? (
        <div style={tw`font-sans flex flex-rows align-center items-center justify-center`}>
          <ScaleLoader height={26} width={3} style={tw`mr-6`} color={`#3CDBC0`} /><p>Uploading, please wait</p>
        </div>
      ) : (
        <>
          <div style={tw`font-sans`}>
            <p>Please Upload an excel file (xls / xlsx)</p>
            <FilePicker
              type={[
                `application/vnd.ms-excel`,
                `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet`
              ]}
              label='Select File'
              setValue={(f) => {
                f && setFile(f);
                setFileError('');
              }}
              hasError={!!fileError}
            />
            {fileError && (
              <InputValidationMessage>{fileError}</InputValidationMessage>
            )}
          </div>
          <div>
            <Button disabled={!file} onPress={() => handleSubmit()}>
              Upload
            </Button>
          </div>
        </>
      )}
    </DefaultModal>
  );
};

export default RebateTemplateUploadModal;
function addToast(arg0: { title: string; description: string; type: string; }) {
  throw new Error('Function not implemented.');
}

