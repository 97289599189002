import { useFocusEffect, useNavigation } from '@react-navigation/native';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Animated,
  Pressable,
  ScrollView,
  useWindowDimensions,
  View,
} from 'react-native';

import tw from '../../config/tailwind';
import { AuthContext } from '../../context/AuthContextProvider';
import { useAppContext } from '../../hooks/useAppContext';
import { useContainer } from '../../hooks/useContainer';
import A from '../shared/A';
import FullWidthButton from '../shared/FullWidthButton';
import Text from '../shared/Text';
import DoubleChevronRightSVG from '../svg/DoubleChevronRightSVG';
import PinnacleLogoSvg from '../svg/PinnacleLogoSVG';
import Navigation from './Navigation';
const SlaveryReportPDF = require('../../assets/pdf/Zentia-Anti-Slavery-Statement-22-12-15.pdf');

interface SidebarProps {}

const Sidebar: React.FC<SidebarProps> = ({}) => {
  const { menuActive, setMenuActive } = useAppContext();
  const [margin] = useContainer();
  const { logout } = useContext(AuthContext);
  const navigation = useNavigation();
  const [menuWidth, setMenuWidth] = useState(0);

  const window = useWindowDimensions();
  const [isMobileMenu, setIsMobileMenu] = useState(window.width <= 1024);

  const toggleMenu = useRef(new Animated.Value(menuActive ? 1 : 0)).current;
  useEffect(() => {
    Animated.timing(toggleMenu, {
      toValue: menuActive ? 1 : 0,
      duration: 350,
      useNativeDriver: true,
    }).start();
  }, [menuActive]);

  useEffect(() => {
    setMenuActive(false);
  }, []);

  useFocusEffect(
    useCallback(() => {
      setMenuActive(false);
    }, [])
  );

  useEffect(() => {
    if (window.width <= 1024 !== isMobileMenu) {
      setIsMobileMenu(window.width <= 1024);
    }
  }, [window.width]);

  return (
    <>
      {isMobileMenu && (
        <Pressable
          onPress={() => menuActive && setMenuActive(false)}
          style={[tw`absolute inset-0 z-20 `]}
          disabled={!menuActive}
          pointerEvents={!menuActive ? 'none' : undefined}
        >
          <Animated.View
            style={[
              tw`absolute inset-0 bg-black z-20 `,
              {
                opacity: toggleMenu.interpolate({
                  inputRange: [0, 1],
                  outputRange: [0, 0.3],
                }),
              },
            ]}
          />
        </Pressable>
      )}
      <Animated.View
        onLayout={(e) => setMenuWidth(e.nativeEvent.layout.width)}
        style={[
          tw.style(
            `py-8 pr-8 bg-white overflow-hidden shadow-xl z-30 h-screen max-h-screen lg:flex absolute lg:relative`
          ),
          {
            paddingLeft: margin,
            left: toggleMenu.interpolate({
              inputRange: [0, 1],
              outputRange: [-menuWidth, 0],
            }),
          },
          tw`lg:left-0`,
        ]}
      >
        <View style={tw`w-70 xl:w-76 h-full max-h-screen`}>
          <PinnacleLogoSvg style={tw`mb-16`} />

          <ScrollView
            style={tw.style(`flex-1 -mr-8 pr-8`, {
              marginLeft: '-' + margin,
              paddingLeft: margin,
            })}
          >
            <Navigation />

            <FullWidthButton
              variant='transparent'
              onPress={async () => {
                await logout();
                navigation.navigate('unauthenticatedStackNavigator', {
                  screen: 'login',
                });
              }}
              buttonStyle={tw`py-3 ml-3 max-w-64 mt-6`}
              underline
            >
              <View style={tw`flex-row`}>
                <DoubleChevronRightSVG style={tw`mr-2`} />
                <Text style={tw`font-ubuntu-bold font-bold`}>Log out</Text>
              </View>
            </FullWidthButton>
          </ScrollView>

          <View style={tw`mt-4`}>
            <Text style={tw`text-xs`}>
              © 2022 Zentia Limited and its licensors.{' \n'}
              <A
                style={tw`text-blue text-xs`}
                url='https://www.zentia.com/en-gb/terms-conditions'
              >
                Terms and Conditions
              </A>
              .{' '}
              <A
                style={tw`text-blue text-xs`}
                url='https://www.zentia.com/en-gb/website-privacy-policy'
              >
                Privacy Policy
              </A>
              .{'\n'}
              <a
                style={tw`text-blue text-xs`}
                href={SlaveryReportPDF} target="_blank"
              >
                Modern Slavery Report
              </a>
              .{' '}
              <A
                style={tw`text-blue text-xs`}
                url='https://www.zentia.com/en-gb/wp-content/uploads/sites/18/2022/05/Aurelius-Group-UK-Tax-Strategy.pdf'
              >
                Group UK tax strategy
              </A>
              .
            </Text>
          </View>
        </View>
      </Animated.View>
    </>
  );
};

export default Sidebar;
