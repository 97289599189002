import React, { useEffect, useRef, useState } from 'react';
import { Animated, Easing, Text } from 'react-native';

import tw from '../../config/tailwind';
import { ToastNotification } from '../../context/ToastContextProvider';

interface InfoBoxProps {
  toast: ToastNotification;
  removeToast: (toast: ToastNotification) => void;
  forceHide?: boolean;
}

const Toast: React.FC<InfoBoxProps> = ({ toast, removeToast, forceHide }) => {
  const fadeAnim = useRef(new Animated.Value(0)).current;
  const [shouldRemove, setShouldRemove] = useState(false);

  const fadeIn = () => {
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 250,
      useNativeDriver: true,
    }).start();
  };

  const fadeOut = () => {
    Animated.timing(fadeAnim, {
      toValue: 0,
      duration: 250,
      useNativeDriver: true,
    }).start(() => setShouldRemove(true));
  };

  useEffect(() => {
    if (!forceHide) {
      fadeIn();
      const timeout = setTimeout(() => {
        fadeOut();
      }, 5500);

      return () => clearTimeout(timeout);
    } else {
      fadeOut();
    }
  }, [forceHide]);

  useEffect(() => {
    if (shouldRemove) {
      removeToast(toast);
    }
  }, [shouldRemove]);

  return (
    <Animated.View
      style={[
        tw.style(
          `bg-white px-6 py-5 w-full shadow-md border-l-2 my-2 border-${
            toast.type === 'error' ? 'red' : 'green'
          }`
        ),
        {
          opacity: fadeAnim,
          transform: [
            {
              translateX: fadeAnim.interpolate({
                inputRange: [0, 1],
                outputRange: [100, 0],
                easing: Easing.quad,
              }),
            },
          ],
        },
      ]}
    >
      <Text style={tw`font-bold uppercase mb-1 text-dark font-ubuntu-bold`}>
        {toast.title}
      </Text>
      <Text>{toast.description}</Text>
    </Animated.View>
  );
};

export default Toast;
