import React, { useState } from 'react';

import Tabs from '../../components/navigation/Tabs';
import H1 from '../../components/shared/H1';
import P from '../../components/shared/P';
import BookTraining from '../../components/training/BookTraining';
import LogTrainingSession from '../../components/training/LogTrainingSession';
import RequestARig from '../../components/training/RequestARig';
import TrainingCertificateList from '../../components/training/TrainingCertificateList';
import { useAuthContext } from '../../hooks/useAuthContext';
import DashboardLayout from '../../layout/DashboardLayout';
import { UserRole } from '../../shared/types/users';

const tabs = [
  'Training records',
  'Book training',
  'Request a rig',
  'Log a session',
] as const;
type Tab = typeof tabs[number];

const initialTab = tabs[0];

interface TrainingScreenProps {}

const TrainingScreen: React.FC<TrainingScreenProps> = ({}) => {
  const [activeTab, setActiveTab] = useState<Tab>(initialTab);
  const { user, activeCompany } = useAuthContext();

  // const companyRole = user?.companyUsers.find(
  //   (x) => x.companyId === activeCompany?.id
  // )?.companyRole;

  const onTabChange = (tab: string) => {
    setActiveTab(tab as Tab);
  };

  return (
    <DashboardLayout>
      <H1 xl>Pinnacle Supervisor Training</H1>
      <P>
        View your Pinnacle by Zentia training certificates and book Pinnacle
        Supervisor training at our factory in Team Valley. Want to book the
        training rig for a session at your business? Complete your Pinnacle
        Supervisor training to unlock access to the rig.
      </P>

      <Tabs
        tabs={tabs.filter((tab, i) => i < 3 || user?.role === UserRole.Admin)}
        activeTab={activeTab}
        emitActiveTab={onTabChange}
      />

      {activeTab === 'Training records' && <TrainingCertificateList />}
      {activeTab === 'Book training' && <BookTraining />}
      {activeTab === 'Request a rig' && <RequestARig />}
      {activeTab === 'Log a session' && <LogTrainingSession />}
    </DashboardLayout>
  );
};

export default TrainingScreen;
