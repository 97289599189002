import { format, parse } from 'date-fns';
import { FC } from 'react';
import {
  createElement,
  NativeSyntheticEvent,
  Platform,
  TextInputFocusEventData,
} from 'react-native';
import { ClassInput } from 'twrnc/dist/esm/types';

import tw from '../../config/tailwind';
import removeTime from '../../utils/removeTime';
import InputLabel from './InputLabel';
import Text from './Text';

type Props = {
  value?: Date;
  placeholder?: string;
  setValue: (date: Date) => void;
  onBlur?: (e: NativeSyntheticEvent<TextInputFocusEventData>) => void;
  hasError?: boolean;
  label?: string;
  labelSize?: 'sm' | 'md';
  style?: ClassInput;
  max?: Date;
  min?: Date;
};

const DatePicker: FC<Props> = ({
  value,
  placeholder,
  setValue,
  hasError,
  label,
  labelSize,
  onBlur,
  style,
  max,
  min,
}) => {
  if (Platform.OS === 'web') {
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue(removeTime(parse(e.target.value, 'yyyy-MM-dd', new Date())));
    };

    return (
      <>
        {label && <InputLabel label={label} required labelSize={labelSize} />}
        {createElement('input', {
          type: 'date',
          value: value ? format(value, 'yyyy-MM-dd') : '',
          onInput: onChange,
          onBlur,
          style: tw.style(
            `px-4 py-3 mb-4 border-blue border-2 font-sans  ${
              hasError ? 'border-red' : 'border-blue'
            } bg-white`,
            { minHeight: '50px' },
            style
          ),
          placeholder,
          max: max ? format(max, 'yyyy-MM-dd') : undefined,
          min: min ? format(min, 'yyyy-MM-dd') : undefined,
        })}
      </>
    );
  } else {
    // TODO: if we want native support, implement a native date picker
    return <Text>Date pickers have not been implemented</Text>;
  }
};

export default DatePicker;
