import {
  GoogleMap,
  Marker, useJsApiLoader
} from '@react-google-maps/api';
import React, { useEffect } from 'react';

import useBreakpoint from 'use-breakpoint';
import tw from '../../config/tailwind';
import { GOOGLE_MAPS_API_KEY } from '../../env-consts';
import { CustomMarkerProps } from './Map';
import MapWebLegends from './MapWebLegends';

interface MapProps {
  children: React.ReactNode;
  // markers: (MarkerProps & { id: string | number })[];
  markers: CustomMarkerProps[];
  onMarkerPress: (id: string | number) => void;
  height?: number;
}

const BREAKPOINTS = { sm: 0, md: 768, lg: 1024, xl: 1280, '2xl': 1536 }

const MapWeb: React.FC<MapProps> = ({
  markers,
  children,
  onMarkerPress,
  height = 700,
}) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_MAPS_API_KEY || '',
  });

  const [map, setMap] = React.useState<google.maps.Map | null>(null);

  const { breakpoint, maxWidth, minWidth } = useBreakpoint(BREAKPOINTS, 'lg');

  useEffect(() => {
    if (!map) return;

    const bounds = new window.google.maps.LatLngBounds();
    for (let i = 0; i < markers.length; i++) {
      if (markers[i].visible) bounds.extend(markers[i].position);
    }
    map.fitBounds(bounds);
    setMap(map);
  }, [markers]);

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds();
    for (let i = 0; i < markers.length; i++) {
      bounds.extend(markers[i].position);
    }
    map.fitBounds(bounds);
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);
  return isLoaded ? (
    <GoogleMap
      options={{
        fullscreenControl: false,
        streetViewControl: false,
        zoomControl: true,
      }}
      mapContainerStyle={tw.style(`flex`, {
        height,
      })}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      <MapWebLegends position={breakpoint === 'md' || breakpoint == 'sm' ? 'BOTTOM_LEFT' : 'TOP_RIGHT'} ></MapWebLegends>
      {markers.filter(m => m).map((markerProps, i) => (
        <Marker
          onClick={() => {
            if(markerProps.id)
            onMarkerPress(markerProps.id);
          }}
          key={i}
          {...markerProps}
          icon={
            markerProps?.markerType === 'distributor' ? {
              url: 'https://www.zentia.com/en-gb/wp-content/themes/mw-zentia/assets/dist/images/icons/pinpoint.svg',
              size: new window.google.maps.Size(62, 72),
              anchor: new window.google.maps.Point(31, 56),
            } : {
              url: '/svg/map-contact-marker.svg',
              size: new window.google.maps.Size(32, 42),
              scaledSize: new window.google.maps.Size(32, 42),
              anchor: new window.google.maps.Point(31, 56),
            }
          }
        />
      ))}
      {children}
      {/* Child components, such as markers, info windows, etc. */}
      <></>
    </GoogleMap>
  ) : (
    <></>
  );
};

export default React.memo(MapWeb);
