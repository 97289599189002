import {
  NavigationProp,
  RouteProp,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import { format } from 'date-fns';
import { FC, useEffect, useState } from 'react';
import { View } from 'react-native';

import TaskCommentList from '../../../components/project/TaskCommentList';
import TaskImageList from '../../../components/project/TaskImageList';
import TaskCompletionModal from '../../../components/project/modals/TaskCompletionModal';
import BackButton from '../../../components/shared/BackButton';
import Button from '../../../components/shared/Button';
import H1 from '../../../components/shared/H1';
import H2 from '../../../components/shared/H2';
import P from '../../../components/shared/P';
import tw from '../../../config/tailwind';
import DashboardLayout from '../../../layout/DashboardLayout';
import { ProjectStackNavigatorParamList } from '../../../routes/types';
import { ProjectResponse } from '../../../shared/types/projects';
import { TaskResponse } from '../../../shared/types/tasks';
import { ProjectClient, TaskClient } from '../../../api';

const ProjectTaskScreen: FC = () => {
  const [project, setProject] = useState<ProjectResponse>();
  const [task, setTask] = useState<TaskResponse>();
  const [refresh, setRefresh] = useState(0);
  const [showCompletionModal, setShowCompletionModal] =
    useState<boolean>(false);

  const navigation =
    useNavigation<NavigationProp<ProjectStackNavigatorParamList>>();
  const route = useRoute<RouteProp<ProjectStackNavigatorParamList, 'task'>>();

  useEffect(() => {
    ProjectClient.getById(route.params.projectId).then((projectResponse) =>
      setProject(projectResponse)
    );
  }, [route.params.projectId]);

  useEffect(() => {
    TaskClient.getById(route.params.projectId, route.params.taskId).then(
      (taskResponse) => setTask(taskResponse)
    );
  }, [route.params.taskId, refresh]);

  return (
    <DashboardLayout>
      <BackButton
        onPress={() =>
          navigation.navigate('project', { id: route.params.projectId })
        }
      >
        Back to project
      </BackButton>

      <P style={tw`font-ubuntu-bold font-bold mb-0 mt-4`}>{project?.name}</P>
      <H1 xl>{task?.name}</H1>
      <View style={tw.style(`p-6 bg-white mx-0 mb-4`)}>
        <H2>Task description</H2>
        <P>{task?.description}</P>
        {task && !task?.completedAt && (
          <Button
            style={tw`ml-auto`}
            onPress={() => setShowCompletionModal(true)}
          >
            Complete task
          </Button>
        )}

        {task && task.completedAt && (
          <P>
            Completed at {format(task.completedAt, 'dd/MM/yyyy')} by{' '}
            {task.completedBy.firstName} {task.completedBy.lastName}
          </P>
        )}
      </View>

      {task && (
        <>
          <View style={tw.style(`p-6 bg-white mx-0 mb-4`)}>
            <H2>Comments</H2>
            <TaskCommentList task={task} type='task' />
          </View>
          <View style={tw.style(`p-6 bg-white mx-0 mb-4`)}>
            <H2>Images</H2>
            <TaskImageList
              task={task}
              onImageAdded={() => setRefresh((x) => x + 1)}
            />
          </View>
          <TaskCompletionModal
            visible={showCompletionModal}
            setVisible={setShowCompletionModal}
            task={task}
            setTask={setTask}
          />
        </>
      )}
    </DashboardLayout>
  );
};

export default ProjectTaskScreen;
