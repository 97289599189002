import React, { useState } from 'react';
import {
  Image,
  ImageBackground,
  Linking,
  Pressable,
  useWindowDimensions,
  View,
} from 'react-native';

import tw from '../../config/tailwind';
import { SnagImageResponse, SnagResponse } from '../../shared/types/snags';
import { TaskImageResponse, TaskResponse } from '../../shared/types/tasks';
import DefaultModal from '../shared/DefaultModal';
import DownloadAction from '../shared/DownloadAction';
import FullWidthButton from '../shared/FullWidthButton';
import Text from '../shared/Text';
import AddImageModal from './modals/AddImageModal';

interface TaskImageListProps {
  task: TaskResponse | SnagResponse;
  isSnag?: boolean;
  onImageAdded: () => void;
}
const TaskImageList: React.FC<TaskImageListProps> = ({
  task,
  isSnag,
  onImageAdded,
}) => {
  const [showImageModal, setShowImageModal] = useState(false);
  const [activeImage, setActiveImage] = useState<
    TaskImageResponse | SnagImageResponse
  >();
  useWindowDimensions(); // trigger redraw when breakpoints change

  return (
    <View>
      <View style={tw`flex flex-row flex-wrap`}>
        {(
          (task as TaskResponse).taskImages || (task as SnagResponse).snagImages
        )
          ?.sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime())
          .map((image, i) => (
            <View
              style={tw`aspect-square w-full lg:w-1/2 xl:w-1/3 p-4`}
              key={i}
            >
              <Pressable
                style={tw`h-full shadow-md`}
                onPress={() => setActiveImage(image)}
              >
                <ImageBackground
                  style={tw`absolute left-0 top-0 w-full h-full`}
                  source={{ uri: image.publicUrl }}
                  resizeMode='cover'
                />
                <View
                  style={tw`bg-white px-4 py-3 mt-auto flex flex-row items-center justify-between`}
                >
                  <Text>{image.filename}</Text>

                  <Pressable onPress={() => Linking.openURL(image.publicUrl)}>
                    <DownloadAction />
                  </Pressable>
                </View>
              </Pressable>
            </View>
          ))}
      </View>

      <FullWidthButton
        variant='transparent'
        buttonStyle={tw`py-2 pr-4 w-48`}
        underline
        onPress={() => setShowImageModal(true)}
      >
        + Upload image
      </FullWidthButton>

      <AddImageModal
        onImageAdded={onImageAdded}
        setVisible={setShowImageModal}
        visible={showImageModal}
        task={task}
        isSnag={isSnag}
      />

      <DefaultModal
        visible={!!activeImage}
        setVisible={(visible) =>
          setActiveImage(visible ? activeImage : undefined)
        }
        title={activeImage?.filename}
      >
        <Image
          source={{ uri: activeImage?.publicUrl }}
          style={tw`w-full h-[60vh] my-2.5`}
          resizeMode='contain'
        />
      </DefaultModal>
    </View>
  );
};

export default TaskImageList;
