import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import * as Yup from 'yup';
import { CompanyUserClient, TrainingClient } from '../../api';

import tw from '../../config/tailwind';
import { TRAINING_SESSIONS } from '../../constants';
import { useAuthContext } from '../../hooks/useAuthContext';
import { useToasts } from '../../hooks/useToasts';
import { CompanyUserResponse } from '../../shared/types/companies';
import Button from '../shared/Button';
import Col from '../shared/Col';
import ContentWrapper from '../shared/ContentWrapper';
import DatePicker from '../shared/DatePicker';
import FilePicker from '../shared/FilePicker';
import H2 from '../shared/H2';
import H3 from '../shared/H3';
import InputText from '../shared/InputText';
import InputValidationMessage from '../shared/InputValidationMessage';
import Row from '../shared/Row';
import Select from '../shared/Select';

interface LogTrainingSessionProps {}

type FormValues = {
  attendee: string;
  completedOn: Date;
  userId: string;
};

const LogTrainingSession: React.FC<LogTrainingSessionProps> = ({}) => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [companyUsers, setCompanyUsers] = useState<CompanyUserResponse[]>([]);
  const { activeCompany } = useAuthContext();
  const { addUnhandledErrorToast, addToast } = useToasts();
  const [certificate, setCertificate] = useState<File>();

  useEffect(() => {
    if (activeCompany) {
      // TODO: handle potential for paginated responses, currently
      // relying on the fact that it's just going to be a 1 page response
      CompanyUserClient.getAll(activeCompany.id, 1)
        .then((users) => setCompanyUsers(users.items))
        .catch((e) => addUnhandledErrorToast(e));
    }
  }, [activeCompany?.id]);

  const initialValues: FormValues = {
    attendee: '',
    completedOn: new Date(),
    userId: '',
  };

  const schema: Yup.SchemaOf<typeof initialValues> = Yup.object().shape({
    attendee: Yup.string().required().label('Attendee'),
    completedOn: Yup.date().required().label('Completed on'),
    userId: Yup.string().required().label('User'),
  });

  const onSubmit = async (formValues: typeof initialValues) => {
    try {
      const user = companyUsers.find((x) => x.userId === formValues.userId);
      if(!user?.companyId){
        return
      }
      await TrainingClient.createRecord(
        {
          title: [TRAINING_SESSIONS[0].name, formValues.attendee].join(' - '),
          completedOn: formValues.completedOn,
          userId: formValues.userId,
          companyId: user?.companyId
        },
        certificate
      );

      addToast({
        title: 'Training session added',
        description: `We've saved this training session for ${user?.user?.firstName} ${user?.user?.lastName}.`,
        type: 'success',
      });

      setFormSubmitted(true);
    } catch (e) {
      addUnhandledErrorToast(e);
    }
  };

  if (formSubmitted) {
    return (
      <ContentWrapper>
        <H3>Session saved</H3>
        <Button onPress={() => setFormSubmitted(false)}>Add another</Button>
      </ContentWrapper>
    );
  }

  return (
    <ContentWrapper>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={schema}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          values,
          errors,
        }) => (
          <View>
            <H2>Log training session</H2>

            <Row style='mb-5 w-10/12'>
              <Col>
                <DatePicker
                  label='Completion Date'
                  labelSize='md'
                  value={values.completedOn}
                  setValue={(date) => setFieldValue('completedOn', date)}
                  onBlur={handleBlur('completedOn')}
                  placeholder='Completion Date'
                  hasError={!!errors.completedOn}
                />
                {errors.completedOn && (
                  <InputValidationMessage>
                    {typeof errors.completedOn === 'string'
                      ? errors.completedOn
                      : ''}
                  </InputValidationMessage>
                )}
              </Col>
              <Col>
                <Select
                  label='User'
                  labelSize='md'
                  onValueChange={handleChange('userId')}
                  selectedValue={values.userId || undefined}
                  options={companyUsers.map((user) => ({
                    label: `${user.user?.firstName} ${user.user?.lastName} (${user.user?.email})`,
                    value: user.userId,
                  }))}
                  searchable
                />
                {errors.userId && (
                  <InputValidationMessage>
                    {errors.userId}
                  </InputValidationMessage>
                )}
              </Col>
              <Col>
                <InputText
                  label='Attendee'
                  value={values.attendee}
                  setValue={handleChange('attendee')}
                  onBlur={handleBlur('attendee')}
                  placeholder='Attendee Name'
                  hasError={!!errors.attendee}
                  required
                  labelSize='md'
                />
                {errors.attendee && (
                  <InputValidationMessage>
                    {errors.attendee}
                  </InputValidationMessage>
                )}
              </Col>

              <Col style={tw`w-full`}>
                <FilePicker
                  type='*/*'
                  label='Select certificate'
                  setValue={setCertificate}
                />
              </Col>
            </Row>

            <Button onPress={handleSubmit} style={tw`mb-4`}>
              Save
            </Button>
          </View>
        )}
      </Formik>
    </ContentWrapper>
  );
};

export default LogTrainingSession;
