import { MarkerProps } from '@react-google-maps/api';
import { isNaN } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { ActivityIndicator, Pressable, View } from 'react-native';

import { LatLng } from '../../@types/geolocation';
import SearchBar from '../../components/SearchBar';
import FavButton from '../../components/favourites/FavButton';
import Map, { CustomMarkerProps } from '../../components/maps/Map';
import DistributorListing from '../../components/project/distributor/DistributorListing';
import Button from '../../components/shared/Button';
import Col from '../../components/shared/Col';
import H1 from '../../components/shared/H1';
import InputLabel from '../../components/shared/InputLabel';
import InputValidationMessage from '../../components/shared/InputValidationMessage';
import P from '../../components/shared/P';
import Row from '../../components/shared/Row';
import Select from '../../components/shared/Select';
import CrossSVG from '../../components/svg/CrossSVG';
import GeolocateSVG from '../../components/svg/GeolocateSVG';
import tw from '../../config/tailwind';
import { GEOLOCATE_SEARCH_LABEL, GlobalLabels } from '../../constants';
import DashboardLayout from '../../layout/DashboardLayout';
import { FavouriteType } from '../../shared/types/favourites';
import { WpDistributor } from '../../shared/types/wordpress';
import {
  filterDistributors,
  filterMapContact,
} from '../../utils/filterDistributors';
import { getPermission, getUserLocation } from '../../utils/geolocation';
import { ContactClient, LoqateClient, WpClient } from '../../api';
import { ContactResponse } from '../../shared/types/contacts';
import ContactCard from '../../components/contacts/ContactCard';
import { useAuthContext } from '../../hooks/useAuthContext';
import { UserRole } from '../../shared/types/users';

interface DistributorScreenProps {}

const distanceOptions = [10, 25, 50, 100, 250, 500];

const DistributorScreen: React.FC<DistributorScreenProps> = ({}) => {
  const [distributors, setDistributors] = useState<WpDistributor[]>([]);
  const [contacts, setContacts] = useState<ContactResponse[]>([]);
  const [markers, setMarkers] = useState<CustomMarkerProps[]>([]);
  const [loading, setLoading] = useState(true);
  const [activeDistributorId, setActiveDistributorId] = useState<number>();
  const [activeContact, setActiveContact] = useState<ContactResponse>();

  const [addressInput, setAddressInput] = useState('');
  const [addressChanged, setAddressChanged] = useState(false);

  const [errors, setErrors] = useState<Record<string, string>>({});

  const [geoLocatingUser, setGeoLocatingUser] = useState(false);
  const [location, setLocation] = useState<LatLng>();
  const [distance, setDistance] = useState<number>(10);

  const { user } = useAuthContext();

  const activeDistributor = useMemo(() => {
    if (activeDistributorId === undefined) {
      return undefined;
    }
    return distributors.find((dis) => dis.id === activeDistributorId);
  }, [activeDistributorId]);

  // init markers
  useEffect(() => {
    let isActive = true;
    if (loading) {
      WpClient.getAllDistributors().then(async (res) => {
        if (isActive) {
          setDistributors(res);
          let tempContacts: ContactResponse[] = [];
          // if (user?.role !== UserRole.Admin) {
          if(false){
            setContacts([]);
          } else {
            const contactsres = await ContactClient.findAll(
              '',
              1,
              undefined,
              1000
            );
            setContacts(contactsres.items);
            tempContacts = [...contactsres.items];
          }

          setMarkers([
            //...markers,
            ...res
              .filter((d) => d.acf.latitude && d.acf.longitude)
              .map((distributor) => ({
                id: distributor.id,
                markerType: 'distributor',
                position: {
                  lat: parseFloat(distributor.acf.latitude || '0'),
                  lng: parseFloat(distributor.acf.longitude || '0'),
                },
                visible: true,
              })),
            ...tempContacts
              .filter((c) => c.latitude && c.longitude)
              .map((contact) => ({
                id: contact.id,
                markerType: 'contact',
                position: {
                  lat: contact.latitude,
                  lng: contact.longitude,
                },
                visible: true,
                icon: {
                  url: 'https://www.zentia.com/en-gb/wp-content/uploads/sites/18/2022/08/Pinterest_x1.png',
                  // size: new window.google.maps.Size(62, 72),
                  // anchor: new window.google.maps.Point(31, 56),
                },
              })),
          ]);
          setLoading(false);
        }
      });
    }
    return () => {
      isActive = false;
    };
  }, []);

  // reset Markers
  const resetMarkers = () => {
    setMarkers(
      markers.map((marker) => ({
        ...marker,
        visible: true,
      }))
    );
    setAddressChanged(false);
  };

  // filter markers
  const filterMarkers = async (oldLocation?: LatLng) => {
    const errors: Record<string, string> = {};

    if (!addressInput && addressChanged) {
      return resetMarkers();
    }

    if (!addressInput) {
      errors.address = 'Please enter a location or postcode';
    }
    if (!distance) {
      errors.distance = 'Please select a distance';
    }

    setErrors(errors);
    if (Object.keys(errors).length > 0) {
      return;
    }

    let location = oldLocation;
    if (addressChanged && addressInput !== GEOLOCATE_SEARCH_LABEL) {
      location = await LoqateClient.geolocateAddress(addressInput).then(
        (res) => {
          const result = res.Items?.[0];
          if (result) {
            return {
              lat: result.Latitude,
              long: result.Longitude,
            };
          }
          return undefined;
        }
      );
    }

    if (!location) {
      console.log('no location');
      return resetMarkers();
    }

    const matchedResults = await filterDistributors({
      distributors,
      name: '',
      location,
      distanceInMiles: distance || 0,
    });
    const matchedContacts = await filterMapContact({
      contacts,
      name: '',
      location,
      distanceInMiles: distance || 0,
    });

    // console.log('markers data', matchedResults, matchedContacts);

    setMarkers([
      ...markers.map((marker) => ({
        ...marker,
        visible: false,
      })),
      ...matchedResults
        .filter((d) => d.acf.latitude && d.acf.longitude)
        .map((distributor) => ({
          id: distributor.id,
          markerType: 'distributor',
          position: {
            lat: parseFloat(distributor.acf.latitude || '0'),
            lng: parseFloat(distributor.acf.longitude || '0'),
          },
          visible: true,
        })),
      ...matchedContacts
        .filter((c) => c.latitude && c.longitude)
        .map((contact) => ({
          id: contact.id,
          markerType: 'contact',
          position: {
            lat: contact.latitude,
            lng: contact.longitude,
          },
          visible: true,
        })),
    ]);
    setLocation(location);
    setAddressChanged(false);
  };

  const geolocateUser = async () => {
    if (addressInput === GEOLOCATE_SEARCH_LABEL || geoLocatingUser) return;

    try {
      setGeoLocatingUser(true);
      const location = await getUserLocation();

      if (!location) {
        const hasPermission = await getPermission();
        if (!hasPermission) {
          setErrors({
            ...errors,
            address: 'Geolocation failed: Permission denied',
          });
        } else {
          throw new Error();
        }
        return;
      }

      setLocation(location);
      setAddressInput(GEOLOCATE_SEARCH_LABEL);
      setErrors((errors) => {
        const { address, ...remainingErrors } = errors;
        return remainingErrors;
      });
    } catch (e) {
      setErrors({ ...errors, address: 'Geolocation failed' });
    } finally {
      setGeoLocatingUser(false);
    }
  };

  if (loading) return <DashboardLayout children={undefined} />;

  return (
    <DashboardLayout>
      <View style={tw`mb-5`}>
        <H1>{GlobalLabels.FindContact}</H1>
        <P>
          Looking for Zentia products near your latest projects or place of
          work? Search by postcode or town to find your nearest distributor or
          contact.
        </P>
      </View>
      <InputLabel label={GlobalLabels.SearchForContact} />
      <Row style={tw`mb-3 -mx-1.5 align-center`}>
        <Col
          style={tw.style(
            `relative w-full md:w-auto lg:max-w-[400px] mb-3 md:mb-0 px-1.5 justify-center`
          )}
        >
          <SearchBar
            textInputProps={{
              placeholder: 'Enter a postcode or location',
              value: addressInput,
            }}
            onStoppedTyping={(v) => {
              if (v !== addressInput) {
                if (addressInput === GEOLOCATE_SEARCH_LABEL) {
                  setLocation(undefined);
                }
                setAddressInput(v);
                setAddressChanged(true);
                setErrors((errors) => {
                  const { address, ...remainingErrors } = errors;
                  return remainingErrors;
                });
              }
            }}
            onChangeText={(v, setValue) => {
              const label = GEOLOCATE_SEARCH_LABEL;
              if (addressInput.toLowerCase() === label.toLowerCase()) {
                setValue(v.length > label.length ? label : '');
              } else {
                setValue(v);
              }
            }}
            style={tw.style(
              `lg:w-full bg-white mb-0 sm:min-w-[275px]`,
              errors.address && 'border-red'
            )}
          />

          {Object.keys(errors).length > 0 && (
            <InputValidationMessage style={tw`mt-0 mb-0`}>
              {errors.address || ' '}
            </InputValidationMessage>
          )}
        </Col>
        <Col style={tw`justify-center w-full md:w-auto px-1.5`}>
          <Select
            placeholderOption='Select distance'
            selectedValue={distance}
            onValueChange={(v) => {
              setDistance(v);
              setErrors((errors) => {
                const { distance, ...remainingErrors } = errors;
                return remainingErrors;
              });
            }}
            options={distanceOptions.map((opt) => ({
              label: `${opt} miles`,
              value: opt,
            }))}
            style={tw.style(`mb-0`, errors.distance && 'border-red')}
          />
          {Object.keys(errors).length > 0 && (
            <InputValidationMessage style={tw`mt-0 mb-0`}>
              {errors.distance || ' '}
            </InputValidationMessage>
          )}
        </Col>
        <Col
          style={tw`flex flex-row mt-3 md:mt-0 w-full md:w-auto px-1.5 align-center items-center`}
        >
          <Pressable
            onPress={geolocateUser}
            style={tw.style(
              `flex items-center justify-center w-12 mr-3 bg-green text-blue h-[50px] w-[50px]`,
              geoLocatingUser && `text-green bg-blue`
            )}
          >
            {!geoLocatingUser ? (
              <GeolocateSVG width={24} height={24} />
            ) : (
              <ActivityIndicator size={24} color='white' />
            )}
          </Pressable>

          <Button
            onPress={() => filterMarkers(location)}
            style={'py-1.5 mb-0 min-h-[50px] w-auto flex-1 px-[24px]'}
          >
            Search
          </Button>
        </Col>
      </Row>

      <View style={tw.style(`relative`)}>
        <Map
          markers={markers}
          onMarkerPress={(id) => {
            const disId = parseInt(id.toString());
            if (/^\d+$/.test(id.toString()) && !isNaN(id) && Number(disId)) {
              setActiveDistributorId(disId);
              setActiveContact(undefined);
            } else {
              const contact = contacts.find((x) => x.id === id);
              setActiveDistributorId(undefined);
              if (contact) {
                setActiveContact(contact);
              }
            }
          }}
        />
        {(activeDistributor || activeContact) && (
          <View
            key={activeDistributorId}
            style={tw.style(
              `absolute top-3 left-3 mr-3 right-0 md:right-none md:min-w-1/2 lg:min-w-1/3 max-w-[400px]`
            )}
          >
            <Pressable
              onPress={() => {
                setActiveDistributorId(undefined);
                setActiveContact(undefined);
              }}
              style={tw.style(
                `absolute top-0 right-0 z-10 h-8 w-8 flex justify-center items-center`,
                { cursor: 'pointer' }
              )}
            >
              <CrossSVG height={28} width={28} style={tw`pt-1 pr-1`} />
            </Pressable>
            {activeDistributor ? (
              <DistributorListing
                item={activeDistributor}
                actions={[
                  <FavButton
                    entityId={activeDistributorId!}
                    type={FavouriteType.Distributor}
                  />,
                ]}
              />
            ) : (
              <ContactCard
                {...activeContact}
                style={tw`border border-dark-300`}
                action={
                  <FavButton
                    entityId={activeContact?.id!}
                    type={FavouriteType.Contact}
                  />
                }
              />
            )}
          </View>
        )}
      </View>
    </DashboardLayout>
  );
};

export default DistributorScreen;
