import { cache } from '../config/cache';
import { API_BASE_URL } from '../env-consts';
import { PaginatedResult } from '../shared/types/paginatedResult';
import {
  WpApplication,
  WpDistributor,
  WpNews,
  WpNewsCategory,
  WpProductSearchResult,
  WpResource,
  WPResourceCategory,
  WpSector,
} from '../shared/types/wordpress';
import requestWithTimeout from '../utils/requestWithTimeout';
import ApiClient from './ApiClient';

const client = new ApiClient(`${API_BASE_URL}`);

export const WpClient = {
  async getCategories(): Promise<WpNewsCategory[]> {
    const key = 'wpClientCategories';
    let categories = await cache.get<WpNewsCategory[]>(key);
    if (!categories || !categories.length) {
      categories = await requestWithTimeout(() =>
        client
          .get<WpNewsCategory[]>('/news/categories')
          .then((res) => res.data.filter((cat) => cat.count > 0))
      ).catch(() => []);

      cache.set(key, categories);
    }
    return categories || [];
  },

  async searchProducts(
    page: number,
    query?: string
  ): Promise<PaginatedResult<WpProductSearchResult>> {
    const key = `wpClientProductsSearch_p${page}`;
    let products = !query
      ? await cache.get<PaginatedResult<WpProductSearchResult>>(key)
      : undefined;

    if (!products || !products.items.length) {
      products = await client
        .get<PaginatedResult<WpProductSearchResult>>('/wp/product-search', {
          params: { page, searchQuery: query },
        })
        .then((res) => res.data);

      !query && cache.set(key, products);
    }

    if (!products) {
      throw new Error('Could not search products');
    }

    return products;
  },

  async getNews(page: number, category?: number, descending = true) {
    return requestWithTimeout(() =>
      client
        .get<PaginatedResult<WpNews>>(`/news`, {
          params: {
            page,
            category,
            descending,
          },
        })
        .then(({ data }) => {
          return data
        })
        .catch(error => console.log(error))
    );
  },

  async searchDistributorsByName(
    query: string,
    page: number,
    perPage?: number
  ) {
    return requestWithTimeout(() =>
      client
        .get<PaginatedResult<WpDistributor>>('/wp/distributors', {
          params: {
            search: query,
            page,
            perPage,
          },
        })
        .then((res) => res.data)
    );
  },

  async getAllDistributors(): Promise<WpDistributor[]> {
    return requestWithTimeout(() =>
      client.get<WpDistributor[]>('/wp/distributors').then((res) => res.data)
    );
  },

  async fetchNewsPost(slug: string) {
    return requestWithTimeout(() =>
      client
        .get<WpNews>(`/news/fromslug`, { params: { slug } })
        .then(async (res) => res.data)
    );
  },

  async getResources(page: number, descending = true, categoryId?: number) {
    return requestWithTimeout<PaginatedResult<WpResource>>(() =>
      client
        .get(`/resources`, {
          params: {
            page,
            descending,
            categoryId,
          },
        })
        .then(({ data }) => data)
    );
  },

  async getResourceApplications() {
    return requestWithTimeout(() =>
      client
        .get<WPResourceCategory[]>('/resource-categories')
        .then((res) => res.data)
    );
  },

  async getApplications() {
    return requestWithTimeout(() =>
      client.get<WpApplication[]>('/wp/applications').then((res) => res.data)
    );
  },

  async getApplicationSectors() {
    return requestWithTimeout(() =>
      client.get<WpSector[]>('/wp/sectors').then((res) => res.data)
    );
  },
};
