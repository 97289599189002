import React from 'react';
import { View, ActivityIndicator } from 'react-native';
import AdminCompanyUsersTable from '../../../components/admin/AdminCompanyUsersTable';
import AdminContactsTable from '../../../components/admin/AdminContactsTable';
import EditCompanyModal from '../../../components/admin/modals/EditCompanyModal';
import A from '../../../components/shared/A';
import BackButton from '../../../components/shared/BackButton';
import ContentWrapper from '../../../components/shared/ContentWrapper';
import H1 from '../../../components/shared/H1';
import tw from '../../../config/tailwind';
import DashboardLayout from '../../../layout/DashboardLayout';

interface AdminContactsScreenProps {}

const AdminContactsScreen: React.FC<AdminContactsScreenProps> = ({}) => {
  return (
    <DashboardLayout>
      <H1 xl>Zentia Contacts</H1>
      <ContentWrapper>
        <AdminContactsTable />
      </ContentWrapper>
    </DashboardLayout>
  );
};

export default AdminContactsScreen;
