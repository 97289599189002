import InvoiceListTable from "../../components/invoices/InvoiceListTable";
import H1 from "../../components/shared/H1";
import DashboardLayout from "../../layout/DashboardLayout";

interface Props {

}
const GlobalInvoicesScreen: React.FC<Props> = ({ }) => {

    return (
        <DashboardLayout>
            <H1 xl>Invoices</H1>
            <InvoiceListTable/>
        </DashboardLayout>
    )
}

export default GlobalInvoicesScreen;