import { FC, ReactNode } from 'react';
import { ClassInput } from 'twrnc/dist/esm/types';

import tw from '../../config/tailwind';
import Text from './Text';

type Props = {
  children: ReactNode;
  xl?: boolean;
  style?: ClassInput;
};

const H1: FC<Props> = ({ children, xl, style }) => {
  return (
    <Text
      style={tw.style(
        `${xl ? 'text-3xl' : 'text-2xl'} font-ubuntu-bold text-blue mb-6`,
        style
      )}
    >
      {children}
    </Text>
  );
};

export default H1;
