import { ResizeMode, Video } from 'expo-av';
import React, { useEffect, useRef, useState } from 'react';
import { Pressable, View, Animated } from 'react-native';
import { useHover } from 'react-native-web-hooks';

import tw from '../config/tailwind';
import FeedbackForm from './feedback/FeedbackForm';
import Tabs from './navigation/Tabs';
import A from './shared/A';
import DefaultModal from './shared/DefaultModal';
import H3 from './shared/H3';
import P from './shared/P';
import QuestionSVG from './svg/QuestionSVG';

interface SupportOverlayProps {}

const SupportOverlay: React.FC<SupportOverlayProps> = ({}) => {
  const [showModal, setShowModal] = useState(false);

  const ref = useRef(null);
  const isHovered = useHover(ref);
  const hoverAnim = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    Animated.timing(hoverAnim, {
      toValue: isHovered ? 1 : 0,
      duration: 200,
      useNativeDriver: true,
    }).start();
  }, [isHovered]);

  const tabs = ['Guide', 'Support', 'Feedback'] as const;
  type Tab = typeof tabs[number];
  const [activeTab, setActiveTab] = useState<Tab>(tabs[0]);

  return (
    <>
      <Pressable ref={ref} onPress={() => setShowModal(!showModal)}>
        <Animated.View
          style={[
            tw.style(
              `absolute z-10 bg-green bottom-4 right-4 rounded-full w-[40px] h-[40px] justify-center items-center`,
              { boxShadow: '0 0 3px 1px rgba(0, 0, 0, 20%)' }
            ),
            {
              backgroundColor: hoverAnim.interpolate({
                inputRange: [0, 1],
                outputRange: [tw.color('green') || '', tw.color('white') || ''],
              }),
            },
          ]}
        >
          <Animated.Text
            style={{
              color: hoverAnim.interpolate({
                inputRange: [0, 1],
                outputRange: [tw.color('white') || '', tw.color('green') || ''],
              }),
            }}
          >
            <QuestionSVG height={24} width={24} />
          </Animated.Text>
        </Animated.View>
      </Pressable>

      <DefaultModal
        title={`Support & Feedback`}
        visible={showModal}
        setVisible={() => {
          setShowModal(false);
        }}
      >
        <Tabs
          activeTab={activeTab}
          tabs={[...tabs]}
          emitActiveTab={(tabName: string) => setActiveTab(tabName as Tab)}
        />

        {activeTab === 'Guide' && (
          <View style={tw`mb-4`}>
            <H3>How do I use the Pinnacle Portal?</H3>
            <Video
              style={tw`aspect-16/9 overflow-hidden mt-4`}
              source={{
                // uri: 'https://www.zentia.com/en-gb/wp-content/uploads/sites/18/2022/09/Zentia_Portal_Walkthrough.mp4',
                uri: 'https://www.zentia.com/en-gb/wp-content/uploads/sites/18/2023/07/ZENTIA_PINNACLE_PORTAL_FINAL_VIDEO_V2.mp4',
              }}
              useNativeControls
              resizeMode={ResizeMode.CONTAIN}
            />
          </View>
        )}

        {activeTab === 'Support' && (
          <View style={tw`mb-4`}>
            <H3>Having trouble and need technical assistance?</H3>
            <P>
              Contact us via our{' '}
              <A
                url='https://www.zentia.com/en-gb/contact-us/'
                style={tw`text-blue`}
              >
                contact form
              </A>{' '}
              and a member of our team will be in touch.
            </P>
          </View>
        )}

        {activeTab === 'Feedback' && (
          <View>
            <FeedbackForm />
          </View>
        )}
      </DefaultModal>
    </>
  );
};

export default SupportOverlay;
