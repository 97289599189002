import React, { useState } from 'react';
import { TouchableOpacity, View } from 'react-native';

import tw from '../../config/tailwind';
import Col from '../shared/Col';
import Row from '../shared/Row';
import Select from '../shared/Select';
import Spacer from '../shared/Spacer';
import DoubleChevronLeftSVG from '../svg/DoubleChevronLeftSVG';
import DoubleChevronRightSVG from '../svg/DoubleChevronRightSVG';
import Tab from './Tab';

interface TabsProps {
  emitActiveTab: (tabName: string) => void;
  tabs: string[];
  activeTab?: string;
}

interface TabPaginationProps {
  currentPage: number;
  maxPages: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}

const TabPagination: React.FC<TabPaginationProps> = ({
  currentPage,
  setPage,
  maxPages,
}) => {
  return (
    <View style={tw` flex-row h-full items-stretch bg-background`}>
      <TouchableOpacity
        disabled={currentPage === 0}
        onPress={() => setPage((oldPage) => Math.max(0, oldPage - 1))}
        activeOpacity={1}
      >
        <View
          style={tw`flex justify-center h-full px-2 ${
            currentPage === 0 ? 'opacity-25' : ''
          }`}
        >
          <DoubleChevronLeftSVG color='currentColor' style={tw`text-blue`} />
        </View>
      </TouchableOpacity>
      <TouchableOpacity
        disabled={currentPage === maxPages}
        onPress={() => setPage((oldPage) => Math.min(oldPage + 1, maxPages))}
        activeOpacity={1}
      >
        <View
          style={tw`flex justify-center h-full px-2 ${
            currentPage === maxPages ? 'opacity-25' : ''
          }`}
        >
          <DoubleChevronRightSVG color='currentColor' style={tw`text-blue`} />
        </View>
      </TouchableOpacity>
    </View>
  );
};

const Tabs: React.FC<TabsProps> = ({ activeTab, tabs, emitActiveTab }) => {
  const onTabChange = (tabName: string) => {
    if (activeTab !== tabName) {
      emitActiveTab(tabName);
    }
  };

  const [page, setPage] = useState(0);

  return (
    <>
      <Select
        style={tw`sm:hidden`}
        onValueChange={onTabChange}
        selectedValue={activeTab}
        options={tabs.map((tab) => ({ label: tab, value: tab }))}
      />
      <View style={tw`-mb-1 hidden sm:flex`}>
        <View style={tw`mb-8 relative overflow-hidden`}>
          <Row style={tw`flex-nowrap`}>
            <Col style={tw`w-auto flex-1 pr-0`}>
              <Row
                style={tw.style(`flex flex-row mx-0 flex-nowrap`, {
                  transform: `translateX(-${page * ((1 / 4) * 100)}%)`,
                })}
              >
                {tabs.map((tab, i) => (
                  <Tab
                    key={i}
                    tab={tab}
                    active={activeTab === tab}
                    onPress={() => onTabChange(tab)}
                  />
                ))}
                {tabs.length < 4 && (
                  <Spacer style='border-b border-blue mb-1' />
                )}
              </Row>
            </Col>

            {tabs.length > 4 && (
              <Col
                style={tw`w-auto justify-center pl-0 border-b border-blue mb-1`}
              >
                <TabPagination
                  currentPage={page}
                  setPage={setPage}
                  maxPages={tabs.length % 4}
                />
              </Col>
            )}
          </Row>
        </View>
      </View>
    </>
  );
};

export default Tabs;
