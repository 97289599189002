import React, { useState } from 'react';

import {
  FavouriteResponse,
  FavouriteType,
} from '../../shared/types/favourites';
import ProductListing from '../project/ProductListing';
import DistributorListing from '../project/distributor/DistributorListing';
import ResourceListing from '../wordpress/ResourceListing';
import FavButton from './FavButton';
import { View } from 'react-native';
import TagsModal from '../project/modals/TagsModal';
import ContactCard from '../contacts/ContactCard';

interface FavouriteListingProps {
  item: FavouriteResponse;
  onDataOutdated: () => void;
}

const FavouriteListing: React.FC<FavouriteListingProps> = ({
  item,
  onDataOutdated,
}) => {
  const [modalActive, setModalActive] = useState(false);
  console.log('render favo');
  switch (item.type) {
    case FavouriteType.Resource:
      return <ResourceListing resource={item.entity} />;
    case FavouriteType.Product:
      return (
        <View>
          <ProductListing
            product={item.entity}
            hideRemoveBtn
            showTagBtn
            tags={item.tags}
            onShowTagModal={() => setModalActive(true)}
          />
          {modalActive && (
            <TagsModal
              favourite={item}
              tags={item.tags}
              setVisible={setModalActive}
              onUpdated={onDataOutdated}
            />
          )}
        </View>
      );
    case FavouriteType.Distributor:
      return (
        <DistributorListing
          item={item.entity}
          actions={[
            <FavButton
              entityId={item.entity.id}
              type={FavouriteType.Distributor}
            />,
          ]}
        />
      );
    case FavouriteType.Contact:
      return (
        <ContactCard
          {...item.entity}
          action={
            <FavButton
              entityId={item.entity.id}
              type={FavouriteType.Distributor}
            />
          }
        />
      );
  }
};

export default FavouriteListing;
