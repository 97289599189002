import { RouteProp, useRoute } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { ActivityIndicator } from 'react-native';

import { CompanyClient } from '../../../api';
import AdminCompanyUsersTable from '../../../components/admin/AdminCompanyUsersTable';
import ContentWrapper from '../../../components/shared/ContentWrapper';
import H1 from '../../../components/shared/H1';
import { useAuthContext } from '../../../hooks/useAuthContext';
import DashboardLayout from '../../../layout/DashboardLayout';
import { CompanyAdminStackNavigatorParamList } from '../../../routes/types';
import { CompanyResponse } from '../../../shared/types/companies';

interface CompanyAdminScreenProps {}

const CompanyAdminScreen: React.FC<CompanyAdminScreenProps> = ({}) => {
  const route =
    useRoute<RouteProp<CompanyAdminStackNavigatorParamList, 'company'>>();
  const { activeCompany, user } = useAuthContext();

  const [company, setCompany] = useState<CompanyResponse>();

  useEffect(() => {
    if (activeCompany) {
      CompanyClient.getById(activeCompany.id).then((result) => {
        setCompany(result);
      });
    }
  }, [activeCompany]);

  return (
    <DashboardLayout>
      {company ? (
        <>
          <H1 xl>{company.name}</H1>

          <ContentWrapper>
            <AdminCompanyUsersTable company={company} />
          </ContentWrapper>
        </>
      ) : (
        <ActivityIndicator />
      )}
    </DashboardLayout>
  );
};

export default CompanyAdminScreen;
