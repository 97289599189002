import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';

const phoneUtil: PhoneNumberUtil =
  require('google-libphonenumber').PhoneNumberUtil.getInstance();

export function formatToE164(v: string) {
  try {
    return phoneUtil.format(phoneUtil.parse(v, 'GB'), PhoneNumberFormat.E164);
  } catch {
    return v;
  }
}
