import { useFocusEffect } from '@react-navigation/native';
import React, { FC, useEffect, useState } from 'react';
import { Text, View } from 'react-native';

import ForgotPasswordForm from '../../components/auth/ForgotPasswordForm';
import A from '../../components/shared/A';
import H1 from '../../components/shared/H1';
import P from '../../components/shared/P';
import tw from '../../config/tailwind';
import LoginLayout from '../../layout/LoginLayout';
import useLinkProps from '../../routes/types';

const ForgotPasswordScreen: FC = () => {
  const [sent, setSent] = useState(false);
  const [forgotLogin, setForgotLogin] = useState(false);

  const loginLink = useLinkProps<'unauthenticatedStackNavigator'>({
    screen: 'unauthenticatedStackNavigator',
    params: {
      screen: 'login',
    },
  });

  useFocusEffect(
    React.useCallback(() => {
      return () => {
        setForgotLogin(false);
      };
    }, [])
  );

  if (forgotLogin) {
    return (
      <LoginLayout>
        <View>
          <H1 style={tw`text-xl font-ubuntu-bold font-bold`}>
            Forgot your email login?
          </H1>
          <P>
            The email used to access your Pinnacle by Zentia account is normally
            your work email.
          </P>
          <P>
            If you need further assistance, please contact agoodwin@zentia.com.
          </P>
          <P style={tw`text-xs`}>
            Remembered your details?{' '}
            <A style={tw`text-xs`} linkProps={loginLink}>
              Log in
            </A>
          </P>
        </View>
      </LoginLayout>
    );
  }

  return (
    <LoginLayout>
      {sent ? (
        <>
          <View>
            <H1 style={tw`text-xl font-ubuntu-bold font-bold`}>
              Password sent
            </H1>
            <P>Please check your email for further instructions.</P>
          </View>
          <P style={tw`text-xs`}>
            Not received the email?{' '}
            <A style={tw`text-xs`} onPress={() => setSent(false)}>
              Retry
            </A>
          </P>
        </>
      ) : (
        <>
          <View>
            <H1 style={tw`text-xl font-ubuntu-bold font-bold`}>
              Reset your password
            </H1>
            <P>
              Enter your email address and we'll send you an email with
              instructions on how to reset your password.
            </P>
          </View>
          <ForgotPasswordForm onFormSent={() => setSent(true)} />
          <A style={tw`text-xs`} onPress={() => setForgotLogin(true)}>
            Forgot your login?
          </A>
          <P style={tw`text-xs`}>
            Remembered your password?{' '}
            <A style={tw`text-xs`} linkProps={loginLink}>
              Log in
            </A>
          </P>
        </>
      )}
    </LoginLayout>
  );
};

export default ForgotPasswordScreen;
