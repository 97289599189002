import { Formik } from 'formik';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import React, { useEffect, useState } from 'react';
import { ActivityIndicator, View } from 'react-native';
import * as Yup from 'yup';

import { ProjectClient, UserClient } from '../../api';
import { LeadClient } from '../../api/LeadClient';
import tw from '../../config/tailwind';
import { phoneRegExp, postcodeRegExp } from '../../constants';
import { useAuthContext } from '../../hooks/useAuthContext';
import { useToasts } from '../../hooks/useToasts';
import {
  CreateLeadPayload,
  LeadRegion,
  LeadResponse,
  LeadSmallResponse,
  LeadStatus,
  UpdateLeadPayload,
} from '../../shared/types/leads';
import { ProjectResponse } from '../../shared/types/projects';
import { UserResponse } from '../../shared/types/users';
import { onConflictError } from '../../utils/onConflictError';
import { formatToE164 } from '../../utils/transformToE164';
import Button from '../shared/Button';
import Col from '../shared/Col';
import H2 from '../shared/H2';
import InputText from '../shared/InputText';
import InputValidationMessage from '../shared/InputValidationMessage';
import Row from '../shared/Row';
import Select from '../shared/Select';
import LeadForm, { massagePayload } from './LeadForm';

const phoneUtil: PhoneNumberUtil =
  require('google-libphonenumber').PhoneNumberUtil.getInstance();

interface UserDetailsFormProps {
  lead: LeadResponse;
  updateLead: (user: LeadResponse) => void;
}

const EditLeadPage: React.FC<UserDetailsFormProps> = ({
  lead,
  updateLead,
}) => {
  const { addToast, addUnhandledErrorToast } = useToasts();

  const initialValues: UpdateLeadPayload = {
    name: lead.name,
    addressCity: lead.addressCity,
    addressLine1: lead.addressLine1,
    addressLine2: lead.addressLine2,
    addressPostCode: lead.addressPostCode,
    description: lead.description,
    phone: lead.phone,
    source: lead.source,
    pinnaclePartnerId: lead.pinnaclePartner?.id,
    region: lead.region,
    status: lead.status,
    projectId: lead.project?.id,
    otherSource: lead.companyId ? false : true,
    companyId: lead.companyId
  };

  const onSubmit = async (values: UpdateLeadPayload) => {
    const payload: UpdateLeadPayload = massagePayload(values);

    try {
      const updatedLead = await LeadClient.update(lead.id, payload).catch((e) => onConflictError(e, addToast));

      if (!updatedLead) return;

      addToast({
        title: 'Lead updated',
        description: `Your changes have been saved.`,
        type: 'success',
      });

      updateLead(updatedLead);
    } catch (e) {
      addUnhandledErrorToast(e);
    }
  };


  return (
    <View>
      <LeadForm
        initialValues={initialValues}
        onSubmit={(values) => onSubmit(values)}
        submitText={'Update'}
      />
    </View>
  );
};

export default EditLeadPage;