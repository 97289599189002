import React, { useState } from 'react';
import { SnagClient } from '../../../api';

import { useToasts } from '../../../hooks/useToasts';
import { SnagResponse } from '../../../shared/types/snags';
import ConfirmationModal from '../../shared/ConfirmationModal';

interface TaskCompletionModalProps {
  snag: SnagResponse;
  setSnag: (task: SnagResponse) => void;
  visible: boolean;
  setVisible: (v: boolean) => void;
}

const SnagCompletionModal: React.FC<TaskCompletionModalProps> = ({
  snag,
  setSnag,
  visible,
  setVisible,
}) => {
  const [loading, setLoading] = useState(false);
  const { addUnhandledErrorToast, addToast } = useToasts();

  const onSubmit = async (didConfirm?: boolean) => {
    if (!didConfirm) {
      setVisible(false);
      return;
    }

    try {
      setLoading(true);
      const updatedTask = await SnagClient.completeSnag(
        snag.project.id,
        snag.id
      );
      setVisible(false);
      setSnag(updatedTask);
      addToast({
        title: 'Issue completed',
        description: 'The issue was completed successfully.',
        type: 'success',
      });
      setLoading(false);
    } catch (e) {
      setLoading(false);
      addUnhandledErrorToast(e);
    }
  };

  return (
    <ConfirmationModal
      visible={visible}
      title='Complete issue'
      prompt={"Are you sure you'd like to mark this issue as complete?"}
      onPromptEnd={onSubmit}
      loading={loading}
    />
  );
};

export default SnagCompletionModal;
