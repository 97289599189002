import { Link } from '@react-navigation/native';
import { To } from '@react-navigation/native/lib/typescript/src/useLinkTo';
import React, {
  PropsWithChildren,
  ReactElement,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import { ActivityIndicator, ScrollView, View } from 'react-native';
import { ClassInput } from 'twrnc/dist/esm/types';

import tw from '../../config/tailwind';
import { PaginatedResult } from '../../shared/types/paginatedResult';
import Pagination from '../Pagination';
import SearchBar from '../SearchBar';
import InputCheckbox from './InputCheckbox';
import Text from './Text';

interface GenericTableProps<ItemType> {
  paginatedResult?: PaginatedResult<ItemType>;

  headers: {
    [Property in keyof ItemType]?: {
      title: string;
      width: string;
      format?: (
        value: ItemType[Property],
        item: ItemType
      ) => string | ReactNode;
      link?: (item: ItemType) => To;
      bold?: boolean;
    };
  };

  actions?: (item: ItemType) => ReactNode[];

  rowStyle?: (item: ItemType) => ClassInput;

  setQueryInput?: (query: string) => void;

  showDeleted?: boolean;
  setShowDeleted?: (showDeleted: boolean) => void;

  page?: number;
  setPage?: (page: number) => void;

  searchPlaceholder?: string;
}

const GenericTable = <ItemType extends unknown>({
  headers,
  actions,
  paginatedResult,
  rowStyle,
  setQueryInput,
  showDeleted,
  setShowDeleted,
  setPage,
  searchPlaceholder,
}: PropsWithChildren<GenericTableProps<ItemType>>): ReactElement | null => {
  const onQueryStoppedTyping = (query: string) => {
    setPage && setPage(1);
    setQueryInput && setQueryInput(query);
  };
  const [rowHeights, setRowHeights] = useState<number[]>([]);

  useEffect(() => {
    if (rowHeights.length > 0) {
      setRowHeights([]);
    }
  }, [paginatedResult?.currentPage]);

  const setRowHeight = (rowIndex: number, height: number) => {
    setRowHeights((heights) => {
      heights[rowIndex] = height;
      return [...heights];
    });
  };

  return (
    <View>
      <View style={tw`flex-row mb-10 items-center`}>
        {setQueryInput && (
          <SearchBar
            textInputProps={{ placeholder: searchPlaceholder }}
            onStoppedTyping={onQueryStoppedTyping}
            style={tw`mr-4 mb-0`}
          />
        )}

        {setShowDeleted && showDeleted !== undefined && (
          <InputCheckbox
            value={showDeleted}
            setValue={setShowDeleted}
            label='Include deleted'
            labelStyle={tw`text-base`}
            containerStyle={tw`mb-0`}
          />
        )}
      </View>
      <View style={tw`-mx-4 flex flex-row flex-nowrap`}>
        <ScrollView
          horizontal
          showsHorizontalScrollIndicator
          style={tw`w-full`}
          contentContainerStyle={tw`grow`}
        >
          <View style={tw`min-w-full`}>
            <GenericTableRow rowIndex={-1} rowStyle={tw`mb-4`}>
              {Object.keys(headers)
                .map((key) => headers[key as keyof ItemType])
                .map((header, i) => {
                  if (header) {
                    const { width, title } = header;
                    return (
                      <View
                        key={`header_${i}`}
                        style={tw.style(`px-4`, {
                          flex: `1 0 ${width}`,
                        })}
                      >
                        <Text
                          style={tw`font-ubuntu-bold font-bold text-dark uppercase text-sm items-center`}
                        >
                          {title}
                        </Text>
                      </View>
                    );
                  } else {
                    return <></>;
                  }
                })}
            </GenericTableRow>

            {/* Table Body */}
            <View>
              {!paginatedResult ? (
                <ActivityIndicator />
              ) : paginatedResult.items.length > 0 ? (
                paginatedResult.items.map((item, rowIndex) => (
                  <GenericTableRow
                    key={rowIndex}
                    rowIndex={rowIndex}
                    rowStyle={tw.style(`py-4.5`, rowStyle && rowStyle(item), {
                      minHeight: rowHeights[rowIndex],
                    })}
                    onSize={setRowHeight}
                  >
                    {Object.keys(headers).map((key, cellIndex) => {
                      const header = headers[key as keyof ItemType];
                      if (header) {
                        return (
                          <GenericTableCell
                            key={cellIndex}
                            width={header.width}
                          >
                            {header.link ? (
                              <Link to={header.link(item)}>
                                <Text
                                  style={tw`text-base ${
                                    header.bold ? 'font-ubuntu-medium' : ''
                                  }`}
                                >
                                  {header.format
                                    ? header.format(
                                        item[key as keyof ItemType],
                                        item
                                      )
                                    : item[key as keyof ItemType]}
                                </Text>
                              </Link>
                            ) : (
                              <Text
                                style={tw.style(
                                  `text-base ${
                                    header.bold ? 'font-ubuntu-medium' : ''
                                  }`,
                                  header.width.toString().slice(-1) !== '%'
                                    ? { maxWidth: header.width }
                                    : undefined
                                )}
                              >
                                {header.format
                                  ? header.format(
                                      item[key as keyof ItemType],
                                      item
                                    )
                                  : item[key as keyof ItemType]}
                              </Text>
                            )}
                          </GenericTableCell>
                        );
                      } else {
                        return <></>;
                      }
                    })}
                  </GenericTableRow>
                ))
              ) : (
                <Text style={tw`px-4`}>No results found</Text>
              )}
            </View>
          </View>
        </ScrollView>

        {actions && (
          <View style={{ width: '150px' }}>
            <GenericTableRow rowIndex={-1} rowStyle={tw`px-4 mb-4`}>
              <Text style={tw`text-sm`}>&nbsp;</Text>
            </GenericTableRow>
            <View>
              {paginatedResult?.items.map((item, rowIndex) => (
                <View
                  key={`row_actions_${rowIndex}`}
                  onLayout={(e) => {
                    const { height } = e.nativeEvent.layout;
                    if (
                      //   rowHeights[rowIndex] !== undefined &&
                      !rowHeights[rowIndex] ||
                      height > rowHeights[rowIndex]
                    ) {
                      setRowHeight(rowIndex, height);
                    }
                  }}
                  style={tw.style(
                    `flex flex-row py-4.5 justify-end`,
                    rowStyle && rowStyle(item),
                    {
                      minHeight: rowHeights[rowIndex],
                    }
                  )}
                >
                  <View
                    style={tw.style(
                      `flex flex-row items-center justify-end px-4`
                    )}
                  >
                    {actions(item).map((node, i) => (
                      <View key={i}>{node}</View>
                    ))}
                  </View>
                </View>
              ))}
            </View>
          </View>
        )}
      </View>

      {paginatedResult && setPage && (
        <Pagination paginatedResult={paginatedResult} onChangePage={setPage} />
      )}
    </View>
  );
};

interface GenericTableCellProps {
  width: string;
  children?: React.ReactNode;
}
const GenericTableCell: React.FC<GenericTableCellProps> = ({
  width,
  children,
}) => (
  <View
    style={tw.style(`px-4 justify-center`, {
      flex: `1 0 ${width || '150px'}`,
    })}
  >
    {children}
  </View>
);

interface GenericTableRowProps {
  rowIndex: number;
  rowStyle?: ClassInput;
  children?: React.ReactNode;
  onSize?: (rowIndex: number, height: number) => void;
}

const GenericTableRow: React.FC<GenericTableRowProps> = ({
  children,
  rowIndex,
  rowStyle,
  onSize,
}) => {
  return (
    <View
      onLayout={(e) => {
        const { height } = e.nativeEvent.layout;
        onSize && onSize(rowIndex, height);
      }}
      style={tw.style(`flex flex-row`, rowStyle)}
      key={rowIndex}
    >
      {children}
    </View>
  );
};

export default GenericTable;
