import { createNativeStackNavigator } from '@react-navigation/native-stack';
import React from 'react';

import CompanyAdminScreen from '../views/AuthenticatedStack/CompanyAdminStack/CompanyAdminScreen';
import { CompanyAdminStackNavigatorParamList } from './types';

const Stack = createNativeStackNavigator<CompanyAdminStackNavigatorParamList>();

const CompanyAdminStackNavigator: React.FC = () => {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen
        name='company'
        component={CompanyAdminScreen}
        options={{ title: 'Company' }}
      />
    </Stack.Navigator>
  );
};

export default CompanyAdminStackNavigator;
