import React, { useEffect, useState } from 'react';

import { CompanyUserClient } from '../../api';
import tw from '../../config/tailwind';
import { COMPANY_USER_ROLE_LABELS } from '../../constants';
import { useToasts } from '../../hooks/useToasts';
import {
  CompanyResponse,
  CompanyUserResponse,
  CompanyUserStatus,
} from '../../shared/types/companies';
import { PaginatedResult } from '../../shared/types/paginatedResult';
import Button from '../shared/Button';
import ConfirmationModal from '../shared/ConfirmationModal';
import GenericTable from '../shared/GenericTable';
import GenericTableAction from '../shared/GenericTableAction';
import CreateCompanyUserModal from './modals/CreateCompanyUserModal';
import EditCompanyUserModal from './modals/EditCompanyUserModal';

interface AdminUsersTableProps {
  company: CompanyResponse;
}

const AdminCompanyUsersTable: React.FC<AdminUsersTableProps> = ({
  company,
}) => {
  const { addToast, addUnhandledErrorToast } = useToasts();
  const [users, setUsers] = useState<PaginatedResult<CompanyUserResponse>>();
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState('');
  const [refresh, setRefresh] = useState(0);
  const [inviteModalVisible, setInviteModalVisible] = useState(false);
  const [editingUser, setEditingUser] = useState<CompanyUserResponse>();
  const [resendInviteUser, setResendInviteUser] =
    useState<CompanyUserResponse>();

  useEffect(() => {
    setUsers(undefined);
    CompanyUserClient.getAll(company.id, page, query).then((results) => {
      setUsers(results);
    });
  }, [page, query, refresh]);

  const onUpdateModalFinished = (user?: CompanyUserResponse) => {
    if (user && users) {
      const paginatedUser = users.items.find((x) => x.userId === user.userId);
      if (paginatedUser) {
        paginatedUser.companyRole = user.companyRole;
      }

      setUsers({
        ...users,
      });
    }

    setEditingUser(undefined);
  };

  const onResendConfirmation = async (didConfirm?: boolean) => {
    if (didConfirm === true && resendInviteUser) {
      try {
        await CompanyUserClient.resendInvite(
          company.id,
          resendInviteUser.userId
        );
        addToast({
          title: 'Company user re-invited',
          description: `We've sent another invite to ${resendInviteUser.user?.email}`,
          type: 'success',
        });
      } catch (e) {
        addUnhandledErrorToast(e);
      }
    }
    setResendInviteUser(undefined);
  };

  return (
    <>
      <CreateCompanyUserModal
        company={company}
        onUserAdded={() => setRefresh((x) => x + 1)}
        setVisible={setInviteModalVisible}
        visible={inviteModalVisible}
      />

      <Button
        style={tw`ml-auto mb-4`}
        onPress={() => setInviteModalVisible(true)}
      >
        Invite
      </Button>

      <GenericTable
        paginatedResult={users}
        searchPlaceholder='Search users'
        headers={{
          user: {
            title: 'User',
            width: '25%',
            format: (user) =>
              `${user?.firstName} ${user?.lastName} (${user?.email})`,
          },
          companyRole: {
            title: 'Role',
            width: '15%',
            format: (role) => COMPANY_USER_ROLE_LABELS[role],
          },
          status: {
            title: 'Status',
            width: '10%',
            format: (status) =>
              status.slice(0, 1).toUpperCase() + status.toLowerCase().slice(1),
          },
        }}
        actions={(user) => [
          <GenericTableAction
            label='Edit'
            onPress={() => setEditingUser(user)}
          />,
          user.status === CompanyUserStatus.Pending && (
            <GenericTableAction
              label='Re-invite'
              onPress={() => setResendInviteUser(user)}
            />
          ),
        ]}
        setPage={setPage}
        setQueryInput={setQuery}
      />

      <EditCompanyUserModal
        companyUser={editingUser}
        onFinished={onUpdateModalFinished}
      />

      <ConfirmationModal
        visible={resendInviteUser !== undefined}
        prompt={"Are you sure you'd like to resend an invite?"}
        onPromptEnd={onResendConfirmation}
      />
    </>
  );
};

export default AdminCompanyUsersTable;
