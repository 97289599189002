import { FC, ReactNode } from 'react';
import { ClassInput } from 'twrnc/dist/esm/types';

import tw from '../../config/tailwind';
import Text from './Text';

type Props = {
  children: ReactNode;
  style?: ClassInput;
};

const P: FC<Props> = ({ children, style }) => {
  return <Text style={tw.style(`text-base mb-4`, style)}>{children}</Text>;
};

export default P;
