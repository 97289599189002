import { useFocusEffect } from '@react-navigation/native';
import { extension } from 'mime-types';
import React, { useCallback, useEffect, useState } from 'react';
import { Linking, Pressable, View } from 'react-native';

import { DocumentClient } from '../../api';
import tw from '../../config/tailwind';
import { isWeb } from '../../constants';
import { useAuthContext } from '../../hooks/useAuthContext';
import { useToasts } from '../../hooks/useToasts';
import { DocumentResponse } from '../../shared/types/documents';
import { PaginatedResult } from '../../shared/types/paginatedResult';
import { ProjectResponse } from '../../shared/types/projects';
import DownloadAction from '../shared/DownloadAction';
import FullWidthButton from '../shared/FullWidthButton';
import GenericTable from '../shared/GenericTable';
import ViewAction from '../shared/ViewAction';
import DocumentModal from './modals/DocumentModal';

interface DocumentListTableProps {
  project: ProjectResponse;
}
const DocumentListTable: React.FC<DocumentListTableProps> = ({ project }) => {
  const [page, setPage] = useState(1);
  const [refresh, setRefresh] = useState(0);
  const [showNewDocumentModal, setShowNewDocumentModal] = useState(false);

  const { user, activeCompany } = useAuthContext();
  const { addUnhandledErrorToast } = useToasts();

  const [paginatedResult, setPaginatedResult] =
    useState<PaginatedResult<DocumentResponse>>();

  useEffect(() => {
    DocumentClient.getByProjectId(project.id, page)
      .then((result) => {
        setPaginatedResult(result);
      })
      .catch((e) => {
        addUnhandledErrorToast(e);
      });
  }, [page, activeCompany?.id, refresh]);

  const showModal = () => {};

  useFocusEffect(
    useCallback(() => {
      setRefresh((old) => old + 1);
    }, [])
  );

  return (
    <View style={tw`bg-white p-8 mb-6`}>
      <GenericTable
        paginatedResult={paginatedResult}
        headers={{
          title: {
            title: 'Title',
            width: '450px',
            bold: true,
          },
          mimeType: {
            title: 'Type',
            width: '132px',
            format: (mime) => extension(mime) || 'Unknown',
          },
        }}
        actions={(document) => [
          <ViewAction
            to={{
              screen: 'authenticatedStackNavigator',
              params: {
                screen: 'projectStack',
                params: {
                  screen: 'document',
                  params: {
                    projectId: project.id,
                    documentId: document.id,
                  },
                },
              },
            }}
            style={tw`mr-3`}
          />,
          isWeb ? (
            <a href={document.publicUrl} download>
              <DownloadAction />
            </a>
          ) : (
            <Pressable onPress={() => Linking.openURL(document.publicUrl)}>
              <DownloadAction />
            </Pressable>
          ),
        ]}
        setPage={setPage}
      />

      <View style={tw`flex flex-row justify-between`}>
        <FullWidthButton
          variant='transparent'
          buttonStyle={tw`py-2 pr-4 w-48`}
          underline
          onPress={() => setShowNewDocumentModal(true)}
        >
          + Add a document
        </FullWidthButton>
      </View>

      <DocumentModal
        visible={showNewDocumentModal}
        projectId={project.id}
        setVisible={setShowNewDocumentModal}
        onDocumentAdded={() => setRefresh((x) => x + 1)}
      />
    </View>
  );
};

export default DocumentListTable;
