import { LinkingOptions, NavigationContainer, ParamListBase } from '@react-navigation/native';
import { NativeStackNavigationProp, NativeStackScreenProps, createNativeStackNavigator } from '@react-navigation/native-stack';
import { FC, useContext } from 'react';

import { META_TITLE_SUFFIX } from '../constants';
import { AuthContext } from '../context/AuthContextProvider';
import ResetPasswordScreen from '../views/ResetPasswordScreen';
import InvitationScreen from '../views/UnauthenticatedStack/InvitationScreen';
import AuthenticatedStackNavigator from './AuthenticatedStackNavigator';
import UnauthenticatedStackNavigator from './UnauthenticatedStackNavigator';
import { RootStackParamList } from './types';

const Stack = createNativeStackNavigator();

const RootStackNavigator: FC<NativeStackScreenProps<RootStackParamList>> = ({navigation, route}) => {
  const { isAuthenticated, loading } = useContext(AuthContext);

  if (loading) {
    // do something better
    // we don't want react navigation to do its magic until we know which stack to put the user in
    // otherwise URL linking misbehaves.
    return <></>;
  }

  const linking: LinkingOptions<RootStackParamList> = {
    enabled: true,
    prefixes: [],
    config: {
      screens: {
        resetPassword: 'reset/:userId/:token',
        acceptInviteUser: 'accept-invite/:userId',
        acceptInviteCompany: 'accept-invite/:userId/:companyId',
        unauthenticatedStackNavigator: {
          path: '/',
          screens: {
            login: 'login',
            forgotPassword: 'forgot-password',
          },
        },
        authenticatedStackNavigator: {
          path: 'dashboard',
          screens: {
            home: '',
            projectsOverview: 'projects',
            projectStack: {
              screens: {
                createProject: 'projects/create',
                project: 'projects/:id',
                task: 'projects/:projectId/tasks/:taskId',
                room: 'projects/:projectId/rooms/:roomId',
                snag: 'projects/:projectId/snags/:roomId',
                document: 'projects/:projectId/documents/:documentId',
              },
            },
            globalAdminStack: {
              path: 'admin',
              screens: {
                admin: '/',
                contacts: '/contacts',
                user: '/users/:id',
                company: '/companies/:id',
                leads: '/leads/:id',
              },
            },
            companyAdminStack: {
              path: 'company/admin',
              screens: {
                company: '/',
              },
            },
            contacts: 'contacts',
            distributors: 'find-contacts',
            newsStack: {
              screens: {
                news: 'news',
                newsArticle: 'news/:slug',
              },
            },
            resources: 'resources',
            training: 'training',
            myAccount: 'my-account',
            favourites: 'favourites',
            rebates: 'rebates'
          },
        },
      },
    },
  };

  return (
    <NavigationContainer
      documentTitle={{
        formatter: (options, route) =>
          `${options?.title ?? route?.name} ${META_TITLE_SUFFIX}`,
      }}
      linking={linking}
    >
      <Stack.Navigator screenOptions={{ headerShown: false }}>
        {isAuthenticated ? (
          <Stack.Screen
            name='authenticatedStackNavigator'
            component={AuthenticatedStackNavigator}
          />
        ) : (
          <Stack.Screen
            name='unauthenticatedStackNavigator'
            component={UnauthenticatedStackNavigator}
          />
        )}
        <Stack.Screen
          name='acceptInviteUser'
          component={InvitationScreen}
          options={{ title: 'Accept an invitiation' }}
        />
        <Stack.Screen
          name='acceptInviteCompany'
          component={InvitationScreen}
          options={{ title: 'Accept an invitiation' }}
        />
        <Stack.Screen
          name='resetPassword'
          component={ResetPasswordScreen}
          options={{ title: 'Reset password' }}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
};

export default RootStackNavigator;
