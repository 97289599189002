import { FC, useEffect, useRef } from 'react';
import { Animated, View } from 'react-native';
import { ClassInput } from 'twrnc/dist/esm/types';

import tw from '../../config/tailwind';
import H1 from './H1';
import H3 from './H3';

type Props = {
  title: string;
  progress: number;
  style?: ClassInput;
};

const ProgressBar: FC<Props> = ({ title, progress, style }) => {
  const progressAnim = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    Animated.timing(progressAnim, {
      toValue: progress,
      duration: 250,
      useNativeDriver: false,
    }).start();
  }, [progress]);

  return (
    <View style={tw.style(style)}>
      <H3>{title}</H3>

      <View style={tw`flex flex-row items-center`}>
        <View style={tw`flex-1 bg-blue h-1`}>
          <Animated.View
            style={[
              tw.style(`h-full bg-green`),
              {
                width: progressAnim.interpolate({
                  inputRange: [0, 1],
                  outputRange: ['0%', '100%'],
                }),
              },
            ]}
          />
        </View>
        <H1 style={tw`mb-0 flex-none ml-3`}>{Math.round(progress * 100)}%</H1>
      </View>
    </View>
  );
};

export default ProgressBar;
