import React, { useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';

import { CompanyClient, ProjectClient, UserClient } from '../../../api';
import { LeadClient } from '../../../api/LeadClient';
import { phoneRegExp, postcodeRegExp } from '../../../constants';
import { useToasts } from '../../../hooks/useToasts';
import { CompanyResponse } from '../../../shared/types/companies';
import {
  CreateLeadPayload,
  LeadRegion,
  LeadResponse,
  LeadSourceType,
  LeadStatus
} from '../../../shared/types/leads';
import { ProjectResponse } from '../../../shared/types/projects';
import { UserResponse } from '../../../shared/types/users';
import DefaultModal from '../../shared/DefaultModal';
import LeadForm, { massagePayload } from '../LeadForm';

interface CreateLeadModalProps {
  visible: boolean;
  setVisible: (v: boolean) => void;
  onLeadAdded: (lead: LeadResponse) => void;
}

const CreateLeadModal: React.FC<CreateLeadModalProps> = ({
  visible,
  setVisible,
  onLeadAdded,
}) => {
  const { addToast, addUnhandledErrorToast } = useToasts();

  const initialValues: CreateLeadPayload = {
    name: '',
    addressCity: '',
    addressLine1: '',
    addressLine2: '',
    addressPostCode: '',
    description: '',
    phone: '',
    // pinnaclePartnerId: undefined,
    // source: '',
    status: LeadStatus.Open,
    otherSource: false
  };

  const onSubmit = async (values: CreateLeadPayload) => {
    const payload: CreateLeadPayload = massagePayload(values);

    try {
      const newLead = await LeadClient.create(payload);

      addToast({
        title: 'Lead created',
        description: `Your new lead was saved.`,
        type: 'success',
      });

      onLeadAdded(newLead);
      setVisible(false);
    } catch (e) {
      addUnhandledErrorToast(e);
    }
  };

  return (
    <DefaultModal
      setVisible={setVisible}
      visible={visible}
      title='Create a new lead'
    >
      <LeadForm
        initialValues={initialValues}
        onSubmit={(values) => onSubmit(values)}
        submitText={'Create'}
      />
    </DefaultModal>
  );
};

export default CreateLeadModal;
