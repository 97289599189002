import { Expose } from 'class-transformer';
import { CompanyUserResponse } from './companies';

export enum UserRole {
  Admin = 'Administrator',
  User = 'User',
}

export enum UserTier {
  ONE = 1,
  TWO = 2,
  THREE = 3,
}

export interface UserResponse {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  role: UserRole;
  telephone: string;
  address: string;
  postcode: string;
  businessName: string;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
  companyUsers: CompanyUserResponse[];
  tfaEnabled: boolean;
  tier: UserTier;
  isConsented: boolean;
}

export interface CreateUserPayload {
  email: string;
  firstName: string;
  lastName: string;
  businessName: string;
  role: UserRole;
  tier: UserTier;
}

export interface ForgotPasswordPayload {
  email: string;
}

export interface ResetPasswordPayload {
  password: string;
  confirmPassword: string;
}

export type UpdateUserPayload = Partial<{
  businessName: string;
  email: string;
  firstName: string;
  lastName: string;
  telephone: string;
  address: string;
  postcode: string;
  role: UserRole;
  tier: UserTier;
  isConsented: boolean;
}>;

export type UpdateUserPreferencesPayload = {
  sms: boolean;
  email: boolean;
  post: boolean;
};

export interface UserInviteResponse {
  firstName: string;
  lastName: string;
  email: string;
  companyName?: string;
  isNewUser: boolean;
  businessName?: string;
}

export type UserInvitePayload =
  | {
      userId: string;
      token: string;
      password: string;
      confirmPassword: string;
      gdprConsent: boolean;
    }
  | {
      userId: string;
      token: string;
      gdprConsent: boolean;
    }
  | {
      userId: string;
      companyId: string;
      token: string;
      gdprConsent: boolean;
    }
  | {
      userId: string;
      companyId: string;
      token: string;
      password?: string;
      confirmPassword?: string;
      userDetails: Omit<UpdateUserPayload, 'role'>;
      gdprConsent: boolean;
    };

export class UserPreferencesResponse {
  @Expose()
  email!: boolean;

  @Expose()
  post!: boolean;

  @Expose()
  sms!: boolean;
}

export type UserLoginStatResponse = {
  user: UserResponse;
  createdAt: Date;
};

// additional interface for consents data
export interface IConsentsCSVData{
  name: string;
  email: string;
  isConsented: boolean;
}