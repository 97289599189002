import React from 'react';
import { View } from 'react-native';
import { ClassInput } from 'twrnc/dist/esm/types';

import tw from '../../config/tailwind';

interface ContentWrapperProps {
  children: React.ReactNode;
  style?: ClassInput;
}

const ContentWrapper: React.FC<ContentWrapperProps> = ({ children, style }) => {
  return <View style={tw.style(`bg-white p-6`, style)}>{children}</View>;
};

export default ContentWrapper;
