import { Link, useFocusEffect } from '@react-navigation/native';
import { format } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';
import { Pressable, Text } from 'react-native';

import { UserClient } from '../../api';
import tw from '../../config/tailwind';
import { useToasts } from '../../hooks/useToasts';
import { PaginatedResult } from '../../shared/types/paginatedResult';
import { IConsentsCSVData, UserResponse } from '../../shared/types/users';
import Button from '../shared/Button';
import GenericTable from '../shared/GenericTable';
import GenericTableAction from '../shared/GenericTableAction';
import LI from '../shared/LI';
import ViewAction from '../shared/ViewAction';
import CreateAdminUserModal from './modals/CreateAdminUserModal';
import { FaDownload } from 'react-icons/fa';
import { ScaleLoader } from 'react-spinners';

interface AdminUsersTableProps {}

const generateCSV = (data: IConsentsCSVData[]) => {
  const header = `User Name,Email,Consented`;
  const rows = data.map(d => `${d.name},${d.email},${d.isConsented}`);
  return [header, ...rows].join('\n');
}

const AdminUsersTable: React.FC<AdminUsersTableProps> = ({}) => {
  const [users, setUsers] = useState<PaginatedResult<UserResponse>>();
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState('');
  const [refresh, setRefresh] = useState(0);
  const [showDeleted, setShowDeleted] = useState(false);
  const [inviteModalVisible, setInviteModalVisible] = useState(false);
  const [consentDownloading, setConsentDownloading] = useState(false);
  const { addToast, addUnhandledErrorToast } = useToasts();

  useEffect(() => {
    setUsers(undefined);
    UserClient.getAll(page, query, showDeleted).then((results) => {
      setUsers(results);
    });
  }, [page, query, refresh, showDeleted]);

  const recoverUser = async (user: UserResponse) => {
    setUsers(undefined);
    try {
      await UserClient.recoverUser(user.id);
      addToast({
        title: 'User restored',
        description: `You restored the user account ${user.firstName} ${user.lastName}`,
        type: 'success',
      });
    } catch (e) {
      addUnhandledErrorToast(e);
    }
    setRefresh((x) => x + 1);
  };

  useFocusEffect(
    useCallback(() => {
      setRefresh((old) => old + 1);
    }, [])
  );

  const getAllConsents = async () => {
    setConsentDownloading(true);
    const data = await UserClient.getAllConsentsData();
    // console.log(data);

    const csvContent = generateCSV(data);
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = `Zentia Users Consents - ${new Date().toISOString()}.csv`;
    a.click();

    setConsentDownloading(false);
  };

  return (
    <>
      <CreateAdminUserModal
        onUserAdded={() => setRefresh((x) => x + 1)}
        setVisible={setInviteModalVisible}
        visible={inviteModalVisible}
      />
      <div
        style={{
          ...tw`w-full flex justify-between`,
          gap: 10,
        }}
      >
        <Button style={`mb-4`} onPress={() => setInviteModalVisible(true)}>
          Add a Zentia Admin
        </Button>

        <Button
          style={`mb-4`}
          disabled={consentDownloading}
          onPress={() => {
            if (consentDownloading) {
              return;
            }
            getAllConsents();
          }}
          variant={'transparent'}
        >
          {consentDownloading ? (
            <div style={tw`flex`}>
              <ScaleLoader height={14} width={3} margin={2} color={`#3CDBC0`} />
              Downloading...
            </div>
          ) : (
            <>
              <FaDownload style={tw`mr-2`} />
              Download Consents CSV
            </>
          )}
        </Button>
      </div>

      <GenericTable
        paginatedResult={users}
        searchPlaceholder='Search users'
        headers={{
          firstName: {
            title: 'Name',
            width: '200px',
            format: (firstName, user) => `${firstName} ${user.lastName}`,
          },
          email: {
            title: 'Email',
            width: '300px',
          },
          //   createdAt: {
          //     title: 'Join date',
          //     width: '10%',
          //     format: (date) => format(new Date(date), 'dd / MM / yyyy'),
          //   },
          role: {
            title: 'Global role',
            width: '132px',
            format: (role, user) => `${role} (Tier ${user.tier})`,
          },
          companyUsers: {
            title: 'Companies',
            width: '200px',
            format: (companies) => (
              <>
                {companies.map(({ status, companyName, companyId }, i) => (
                  <LI
                    key={`conpany-${companyId}-${i}`}
                    style={tw`text-sm font-ubuntu-bold flex`}
                  >
                    {companyName} (
                    {status.slice(0, 1).toUpperCase() +
                      status.toLowerCase().slice(1)}
                    )
                  </LI>
                ))}
              </>
            ),
          },
        }}
        actions={(user) => {
          return [
            !user.deletedAt ? (
              <Link
                to={{
                  screen: 'authenticatedStackNavigator',
                  params: {
                    screen: 'globalAdminStack',
                    params: {
                      screen: 'user',
                      params: {
                        id: user.id,
                      },
                    },
                  },
                }}
              >
                <GenericTableAction label='View Details' />
              </Link>
            ) : (
              <Pressable onPress={() => recoverUser(user)}>
                <GenericTableAction label='Restore' />
              </Pressable>
            ),
          ];
        }}
        setPage={setPage}
        setQueryInput={setQuery}
        showDeleted={showDeleted}
        setShowDeleted={setShowDeleted}
        rowStyle={(user) => user.deletedAt && `opacity-50 bg-red-100`}
      />
    </>
  );
};

export default AdminUsersTable;
