import React, { useRef } from 'react';
import { View } from 'react-native';
import { useHover } from 'react-native-web-hooks';

import tw from '../../config/tailwind';
import DownloadSVG from '../svg/DownloadSVG';

interface DownloadActionProps {}

const DownloadAction: React.FC<DownloadActionProps> = ({}) => {
  const ref = useRef(null);
  const isHovered = useHover(ref);

  return (
    <View
      ref={ref}
      style={tw.style(
        `w-10 h-10 rounded-full bg-background-100 justify-center items-center`
      )}
    >
      <DownloadSVG
        width={18}
        height={10}
        style={tw.style(`text-green`, isHovered && `text-blue`)}
      />
    </View>
  );
};

export default DownloadAction;
