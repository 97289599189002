import React, { FC, ReactElement, useEffect, useRef, useState } from 'react';
import {
  ActivityIndicator,
  Animated,
  Modal,
  Pressable,
  ScrollView,
  TextInput,
  TouchableWithoutFeedback,
  useWindowDimensions,
  View,
} from 'react-native';
import { useHover } from 'react-native-web-hooks';
import { ClassInput } from 'twrnc/dist/esm/types';
import { LoqateClient } from '../../api';

import tw from '../../config/tailwind';
import {
  LoqateCaptureAddress,
  LoqateFullAddress,
} from '../../shared/types/loqate';
import DoubleChevronRightSVG from '../svg/DoubleChevronRightSVG';
import InputLabel from './InputLabel';
import Text from './Text';

interface LoqateAddressLookupProps<ValueType> {
  label?: string;
  required?: boolean;
  hasError?: boolean;
  labelSize?: 'sm' | 'md';
  style?: ClassInput;
  loading?: boolean;
  onAddressFound: (address: LoqateFullAddress) => void;
}

const LoqateAddressLookup = <ItemType extends unknown>({
  label,
  required,
  hasError,
  labelSize,
  style,
  loading,
  onAddressFound,
}: LoqateAddressLookupProps<ItemType>): ReactElement | null => {
  const [showSelectMenu, setShowSelectMenu] = useState(false);
  const [search, setSearch] = useState('');
  const chevronAnim = useRef(new Animated.Value(0)).current;
  const dropdownRef = useRef<View>(null);
  const textInputRef = useRef<TextInput>(null);
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const dimensions = useWindowDimensions();

  const [query, setQuery] = useState('');
  const [container, setContainer] = useState('');

  const [selectedOption, setSelectedOption] = useState<LoqateCaptureAddress>();

  const [options, setOptions] = useState<LoqateCaptureAddress[]>([]);

  useEffect(() => {
    if (dropdownRef.current) {
      dropdownRef.current.measureInWindow((left, top, width, height) => {
        setTop(top);
        setLeft(left);
        setWidth(width);
        setHeight(height);
      });
    }
  }, [top, left, width, dimensions, showSelectMenu]);

  useEffect(() => {
    const delay = setTimeout(() => {
      setContainer('');
      setQuery(search);
    }, 750);

    return () => clearTimeout(delay);
  }, [search]);

  useEffect(() => {
    Animated.timing(chevronAnim, {
      toValue: showSelectMenu ? 1 : 0,
      duration: 200,
      useNativeDriver: true,
    }).start();

    if (showSelectMenu) {
      setTimeout(() => {
        textInputRef.current?.focus();
      }, 100);
    }
  }, [showSelectMenu]);

  useEffect(() => {
    if (loading && showSelectMenu) {
      setShowSelectMenu(false);
    }
  }, [loading]);

  useEffect(() => {
    let isActive = true;
    if (!query) {
      setOptions([]);
      return;
    }

    console.log('On set query');

    LoqateClient.searchAddress({
      Text: query,
      Container: container || undefined,
    }).then((res) => {
      isActive && setOptions(res.Items);
    });

    return () => {
      isActive = false;
    };
  }, [query, container]);

  const onValueClick = async (value: string, index: number) => {
    const option: LoqateCaptureAddress = options[index];

    if (option.Type !== 'Address') {
      setContainer(option.Id);
    } else {
      const fullAddress = await LoqateClient.retrieveAddress(option.Id).then(
        (res) => res.Items.shift()
      );

      if (fullAddress) {
        setSelectedOption(option);
        onAddressFound(fullAddress);
        setShowSelectMenu(false);
      }
    }
  };

  const showPlaceholder = selectedOption === undefined;

  return (
    <View>
      {label && (
        <InputLabel label={label} required={required} labelSize={labelSize} />
      )}
      <View style={tw`flex-row`}>
        <Pressable
          disabled={loading}
          ref={dropdownRef}
          onPress={() => setShowSelectMenu(true)}
          style={tw.style(
            `bg-white px-4 py-3 mb-3 font-sans border-2 h-[50px] flex flex-row items-center min-w-48 flex-1`,
            hasError ? 'border-red' : 'border-blue',
            style
          )}
        >
          {!showSelectMenu && (
            <Text
              style={tw.style(`text-xs mr-4`, showPlaceholder && `text-grey`, {
                fontSize: '14px', // TODO: this matches other inputs, update to correct utility class
              })}
            >
              {selectedOption
                ? selectedOption.Description
                : 'Type to search...'}
            </Text>
          )}

          <Animated.View
            style={{
              ...tw`ml-auto`,
              transform: [
                {
                  rotate: chevronAnim.interpolate({
                    inputRange: [0, 1],
                    outputRange: ['90deg', '-90deg'],
                  }),
                },
              ],
            }}
          >
            <DoubleChevronRightSVG style={tw`text-green`} />
          </Animated.View>
        </Pressable>
        {loading && <ActivityIndicator style={tw`ml-2.5`} />}
      </View>

      <Modal
        transparent
        visible={showSelectMenu}
        onDismiss={() => setShowSelectMenu(false)}
      >
        <Pressable style={tw`flex-1`} onPress={() => setShowSelectMenu(false)}>
          <TouchableWithoutFeedback>
            <TextInput
              ref={textInputRef}
              autoFocus
              value={search}
              onChangeText={setSearch}
              style={tw.style(`absolute font-sans px-4 py-3`, {
                top,
                left,
                height,
                width,
              })}
              placeholder='Type to search...'
            />
          </TouchableWithoutFeedback>

          <ScrollView
            style={tw.style(`absolute bg-white mt-0.5 shadow-md`, {
              width,
              top: top + height,
              left,
              maxHeight: height * 5,
            })}
          >
            {options?.map((option, i) => (
              <SelectOption
                label={[option.Text, option.Description].join(', ')}
                value={option.Id}
                index={i}
                key={i}
                setValue={onValueClick}
              />
            ))}
            {search.length > 0 && options?.length === 0 && (
              <Text style={tw`text-center p-4 text-sm`}>
                No options match your search...
              </Text>
            )}
          </ScrollView>
        </Pressable>
      </Modal>
    </View>
  );
};

type SelectOptionProps = {
  label: string;
  value: any;
  index: number;
  setValue: (v: any, index: number) => void;
};

const SelectOption: FC<SelectOptionProps> = ({
  label,
  value,
  index,
  setValue,
}) => {
  const viewRef = useRef<View>(null);
  const isHovered = useHover(viewRef);

  return (
    <Pressable
      ref={viewRef}
      style={tw.style(`h-[50px] px-4 justify-center`, {
        backgroundColor: (isHovered && tw.color('bg-grey-100')) || '',
      })}
      onPress={() => setValue(value, index)}
    >
      <Text>{label}</Text>
    </Pressable>
  );
};

export default LoqateAddressLookup;
