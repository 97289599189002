import axios from 'axios';
import { Formik } from 'formik';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import React, { useEffect, useState } from 'react';
import { ActivityIndicator, View } from 'react-native';
import * as Yup from 'yup';

import { AuthClient } from '../../api';
import tw from '../../config/tailwind';
import { phoneRegExp } from '../../constants';
import { useAuthContext } from '../../hooks/useAuthContext';
import { useToasts } from '../../hooks/useToasts';
import { TwoFactorAuthResponseType } from '../../shared/types/auth';
import { onConflictError } from '../../utils/onConflictError';
import { formatToE164 } from '../../utils/transformToE164';
import Button from '../shared/Button';
import Col from '../shared/Col';
import DefaultModal from '../shared/DefaultModal';
import InputLabel from '../shared/InputLabel';
import InputText from '../shared/InputText';
import InputValidationMessage from '../shared/InputValidationMessage';
import P from '../shared/P';
import Row from '../shared/Row';
import Text from '../shared/Text';

const phoneUtil: PhoneNumberUtil =
  require('google-libphonenumber').PhoneNumberUtil.getInstance();

interface EnableTfaModalProps {
  setVisible: (v: boolean) => void;
  visible: boolean;
}

const EnableTfaModal: React.FC<EnableTfaModalProps> = ({
  setVisible,
  visible,
}) => {
  const { addToast, addUnhandledErrorToast } = useToasts();
  const { user, login } = useAuthContext();
  const [step, setStep] = useState(user!.tfaEnabled ? 2 : 1);

  const initialValues = {
    telephone: user!.telephone,
    otpCode: '',
  };

  useEffect(() => {
    if (user!.tfaEnabled) {
      AuthClient.disableTfa(); // Automatically trigger a 2FA to deactivate the 2FA
    }
  }, []);

  const schema: Yup.SchemaOf<typeof initialValues> = Yup.object().shape({
    telephone: Yup.string()
      .required()
      .matches(phoneRegExp, 'Please provide a valid phone number')
      .transform(formatToE164)
      .test('phoneFormatTest', 'Invalid format', (value) => {
        try {
          return phoneUtil.isValidNumberForRegion(
            phoneUtil.parse(value, 'GB'),
            'GB'
          );
        } catch {
          return false;
        }
      })
      .label('Telephone'),
    otpCode: Yup.string()
      .default('')
      .test('otpCodeTest', 'Please enter your 2FA code', (value) => {
        if (step !== 2) return true;
        return !!value && value.length > 0;
      })
      .test('otpCodeTest', 'Code must be 6 characters', (value) => {
        if (step !== 2) return true;
        return !!value && value.length === 6;
      }),
  });

  const onSubmit = async (values: typeof initialValues) => {
    const formValues = {
      ...values,
      telephone: formatToE164(values.telephone),
    };

    if (user!.tfaEnabled) {
      return disableTfa(formValues);
    }
    return enableTfa(formValues);
  };

  const enableTfa = async (values: typeof initialValues) => {
    try {
      const { type } = await AuthClient.enableTfa({
        telephone: values.telephone,
        otpCode: values.otpCode || undefined,
      });

      if (type === TwoFactorAuthResponseType.Verify) {
        setStep(2);
      } else {
        addToast({
          title: '2FA enabled',
          description: 'Your account is now secure',
          type: 'success',
        });
        const accessToken = await AuthClient.refreshToken();
        login(accessToken);
        setVisible(false);
      }
    } catch (e) {
      try {
        onConflictError(e, addToast);
      } catch (e) {
        if (axios.isAxiosError(e) && e.response?.status === 404) {
          addToast({
            title: 'Invalid code',
            description: 'Please try again.',
            type: 'error',
          });
        } else {
          addUnhandledErrorToast(e);
        }
      }
    }
  };

  const disableTfa = async (values: typeof initialValues) => {
    try {
      const res = await AuthClient.disableTfa(values.otpCode);

      if (res.type === TwoFactorAuthResponseType.Success) {
        addToast({
          title: '2FA disabled',
          description: 'You will no longer be prompted for a code on login.',
          type: 'success',
        });
        const accessToken = await AuthClient.refreshToken();
        login(accessToken);
        setVisible(false);
      }
    } catch (e) {
      if (axios.isAxiosError(e) && e.response?.status === 404) {
        addToast({
          title: 'Invalid code',
          description: 'Please try again.',
          type: 'error',
        });
      } else {
        addUnhandledErrorToast(e);
      }
    }
  };

  return (
    <DefaultModal
      setVisible={setVisible}
      visible={visible}
      title={
        user!.tfaEnabled
          ? 'Disable two factor authentication'
          : 'Enable two factor authentication'
      }
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={schema}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          values,
          errors,
          isSubmitting,
        }) => (
          <View style={tw`mt-4`}>
            {step === 1 && (
              <>
                <P>
                  Keep your account secure by enabling two factor
                  authentication. Once enabled you will need to enter an
                  additional security code to log into your account.
                </P>

                <P>
                  Please {user?.telephone ? 'confirm' : 'enter'} your phone
                  number below.
                </P>
                <Row>
                  <Col style={tw`w-full md:w-9/12`}>
                    <InputText
                      value={values.telephone}
                      setValue={handleChange('telephone')}
                      onBlur={handleBlur('telephone')}
                      placeholder='Telephone'
                      hasError={!!errors.telephone}
                      label='Telephone'
                      required
                    />
                    {errors.telephone && (
                      <InputValidationMessage>
                        {errors.telephone}
                      </InputValidationMessage>
                    )}
                  </Col>
                  <Col style={tw`w-full md:w-3/12`}>
                    <View style={tw`hidden md:flex`}>
                      <InputLabel label=' ' />
                    </View>

                    <Button
                      onPress={handleSubmit}
                      disabled={isSubmitting}
                      style={tw.style(`py-0 self-stretch`, { minHeight: 50 })}
                    >
                      Send code
                    </Button>
                  </Col>
                </Row>
              </>
            )}

            {step === 2 && (
              <>
                <P>
                  An SMS message has been sent to{' '}
                  <Text style={tw`font-bold`}>{values.telephone}</Text>. Please
                  enter the code below to{' '}
                  {user!.tfaEnabled ? 'disable' : 'enable'} two factor
                  authentication.
                </P>
                <Row>
                  <Col style={tw`w-full md:w-9/12`}>
                    <InputText
                      value={values.otpCode}
                      setValue={handleChange('otpCode')}
                      onBlur={handleBlur('otpCode')}
                      placeholder='e.g 123456'
                      hasError={!!errors.otpCode}
                      label='Code'
                      required
                    />
                    {errors.otpCode && (
                      <InputValidationMessage>
                        {errors.otpCode}
                      </InputValidationMessage>
                    )}
                  </Col>
                  <Col style={tw`w-full md:w-3/12`}>
                    <View style={tw`hidden md:flex`}>
                      <InputLabel label=' ' />
                    </View>
                    <Button
                      onPress={handleSubmit}
                      disabled={isSubmitting}
                      style={tw.style(`py-0 self-stretch`, { minHeight: 50 })}
                    >
                      {user!.tfaEnabled ? 'Disable' : 'Verify'}
                    </Button>
                  </Col>
                </Row>
              </>
            )}
          </View>
        )}
      </Formik>
    </DefaultModal>
  );
};

export default EnableTfaModal;
