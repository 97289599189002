import { Formik } from 'formik';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import React from 'react';
import { View } from 'react-native';
import * as Yup from 'yup';

import { UserClient } from '../../api';
import tw from '../../config/tailwind';
import { phoneRegExp, postcodeRegExp } from '../../constants';
import { useAuthContext } from '../../hooks/useAuthContext';
import { useToasts } from '../../hooks/useToasts';
import { UserResponse, UserRole, UserTier } from '../../shared/types/users';
import { onConflictError } from '../../utils/onConflictError';
import { formatToE164 } from '../../utils/transformToE164';
import Button from '../shared/Button';
import Col from '../shared/Col';
import H2 from '../shared/H2';
import InputText from '../shared/InputText';
import InputValidationMessage from '../shared/InputValidationMessage';
import Row from '../shared/Row';
import Select from '../shared/Select';
import Text from '../shared/Text';
import H3 from '../shared/H3';

const phoneUtil: PhoneNumberUtil =
  require('google-libphonenumber').PhoneNumberUtil.getInstance();

interface UserDetailsFormProps {
  user: UserResponse;
  updateUser: (user: UserResponse) => void;
}

const UserDetailsForm: React.FC<UserDetailsFormProps> = ({
  user,
  updateUser,
}) => {
  const [addressLine1, addressLine2, county] = user?.address.split(',') || [];
  const { addToast, addUnhandledErrorToast } = useToasts();
  const authContext = useAuthContext();

  const initialValues = {
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    email: user?.email || '',
    telephone: user?.telephone
      ? phoneUtil
          .formatInOriginalFormat(phoneUtil.parse(user.telephone, 'GB'))
          .replace(' ', '')
      : '',
    businessName: user?.businessName || '',
    addressLine1,
    addressLine2,
    county,
    postcode: user?.postcode || '',
    tier: user.tier,
  };

  const schema: Yup.SchemaOf<typeof initialValues> = Yup.object().shape({
    email: Yup.string().min(2).email().required().label('Email address'),
    firstName: Yup.string().required().min(2),
    lastName: Yup.string().required().min(2),
    telephone: Yup.string()
      .required()
      .matches(phoneRegExp, 'Please provide a valid phone number')
      .transform(formatToE164)
      .test('phoneFormatTest', 'Invalid format', (value) => {
        try {
          return phoneUtil.isValidNumberForRegion(
            phoneUtil.parse(value, 'GB'),
            'GB'
          );
        } catch {
          return false;
        }
      })
      .label('Telephone'),

    businessName: Yup.string().required().min(2),
    addressLine1: Yup.string().required().label('Address Line 1'),
    addressLine2: Yup.string().label('Address Line 2').default(''),
    county: Yup.string().label('County').required(),
    postcode: Yup.string()
      .label('Postcode')
      .required()
      .matches(postcodeRegExp, 'Please provide a valid postcode'),
    tier: Yup.mixed().required().oneOf(Object.values(UserTier)),
  });

  const onSubmit = async ({
    addressLine1,
    addressLine2,
    county,
    ...formValues
  }: typeof initialValues) => {
    if (!user?.id) return;

    const values = {
      ...formValues,
      telephone: formatToE164(formValues.telephone),
    };

    try {
      const address = [addressLine1, addressLine2, county].join(',');
      const updatedUser = await UserClient.updateUser(user.id, {
        ...values,
        address,
      }).catch((e) => onConflictError(e, addToast));

      if (!updatedUser) return;

      const didUpdateSelf = authContext.user?.id === user.id;
      addToast({
        title: 'User details updated',
        description: `You updated ${
          didUpdateSelf ? 'your' : `${user.firstName}'s`
        } user details.`,
        type: 'success',
      });

      updateUser(updatedUser);
    } catch (e) {
      addUnhandledErrorToast(e);
    }
  };

  return (
    <View>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={schema}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          values,
          errors,
        }) => (
          <View>
            <Row>
              <Col style={tw`w-full`}>
                <Row>
                  <Col style={tw`w-full sm:w-1/2`}>
                    <InputText
                      value={values.firstName}
                      setValue={handleChange('firstName')}
                      onBlur={handleBlur('firstName')}
                      placeholder='First Name'
                      hasError={!!errors.firstName}
                      label='First Name'
                      required
                    />
                    {errors.firstName && (
                      <InputValidationMessage>
                        {errors.firstName}
                      </InputValidationMessage>
                    )}
                  </Col>
                  <Col style={tw`w-full sm:w-1/2`}>
                    <InputText
                      value={values.lastName}
                      setValue={handleChange('lastName')}
                      onBlur={handleBlur('lastName')}
                      placeholder='Last Name'
                      hasError={!!errors.lastName}
                      label='Last Name'
                      required
                    />
                    {errors.lastName && (
                      <InputValidationMessage>
                        {errors.lastName}
                      </InputValidationMessage>
                    )}
                  </Col>
                  <Col style={tw`w-full sm:w-1/2`}>
                    <InputText
                      value={values.email}
                      setValue={handleChange('email')}
                      onBlur={handleBlur('email')}
                      placeholder='Email Address'
                      hasError={!!errors.email}
                      label='Email Address'
                      required
                    />
                    {errors.email && (
                      <InputValidationMessage>
                        {errors.email}
                      </InputValidationMessage>
                    )}
                  </Col>
                  <Col style={tw`w-full sm:w-1/2`}>
                    <InputText
                      value={values.telephone}
                      setValue={handleChange('telephone')}
                      onBlur={handleBlur('telephone')}
                      placeholder='Telephone'
                      hasError={!!errors.telephone}
                      label='Telephone'
                      required
                      readonly={user!.tfaEnabled}
                    />
                    {errors.telephone && (
                      <InputValidationMessage>
                        {errors.telephone}
                      </InputValidationMessage>
                    )}
                  </Col>
                  {authContext.user?.role === UserRole.Admin ? (
                    <>
                      <Col style={tw`w-1/2`}>
                        <Select
                          // selectedValue={UserTier[values.tier]}
                          selectedValue={values.tier as number}
                          onValueChange={(iValue, iIndex) =>
                            setFieldValue('tier', iValue)
                          }
                          label='Select a tier'
                          required
                          hasError={!!errors.tier}
                          options={Object.values(UserTier)
                            .filter((x) => isNaN(parseInt(x as string)))
                            .map((item, i) => ({
                              label: item.toString(),
                              value: i + 1,
                            }))}
                        />
                        {errors.tier && (
                          <InputValidationMessage>
                            {errors.tier}
                          </InputValidationMessage>
                        )}
                      </Col>

                      {/* show user consent status */}
                      <Col>
                        <Text>User Consented?</Text>
                        <H3 style={{
                          ...tw`p-2 ${user.isConsented ? 'text-green' : 'text-slate-400'}`,
                        }}>
                          {user.isConsented ? 'Consented' : 'Pending'}
                        </H3>
                      </Col>
                    </>
                  ) : null}

                  {/* 				  
                  <Col style={tw`w-full`}>
                    <InputPassword
                      value={values.password}
                      setValue={handleChange('password')}
                      onBlur={handleBlur('password')}
                      placeholder='Enter your password'
                      hasError={!!errors.password}
                      label='Password'
                    />
                    {errors.password && (
                      <InputValidationMessage>
                        {errors.password}
                      </InputValidationMessage>
                    )}
                  </Col> */}
                </Row>
              </Col>
            </Row>

            <H2 style={tw`mt-4`}>Business details</H2>

            <Row>
              <Col style={tw`w-full`}>
                <Row>
                  <Col style={tw`w-full sm:w-1/2`}>
                    <InputText
                      value={values.businessName}
                      setValue={handleChange('businessName')}
                      onBlur={handleBlur('businessName')}
                      placeholder='Business Name'
                      hasError={!!errors.businessName}
                      label='Business Name'
                      required
                    />
                    {errors.businessName && (
                      <InputValidationMessage>
                        {errors.businessName}
                      </InputValidationMessage>
                    )}
                  </Col>
                  <Col style={tw`w-full`}>
                    <InputText
                      value={values.addressLine1}
                      setValue={handleChange('addressLine1')}
                      onBlur={handleBlur('addressLine1')}
                      placeholder='Address Line 1'
                      hasError={!!errors.addressLine1}
                      label='Address Line 1'
                      required
                    />
                    {errors.addressLine1 && (
                      <InputValidationMessage>
                        {errors.addressLine1}
                      </InputValidationMessage>
                    )}
                  </Col>
                  <Col style={tw`w-full`}>
                    <InputText
                      value={values.addressLine2}
                      setValue={handleChange('addressLine2')}
                      onBlur={handleBlur('addressLine2')}
                      placeholder='Address Line 2'
                      hasError={!!errors.addressLine2}
                      label='Address Line 2'
                    />
                    {errors.addressLine2 && (
                      <InputValidationMessage>
                        {errors.addressLine2}
                      </InputValidationMessage>
                    )}
                  </Col>
                  <Col style={tw`w-full`}>
                    <InputText
                      value={values.county}
                      setValue={handleChange('county')}
                      onBlur={handleBlur('county')}
                      placeholder='County'
                      hasError={!!errors.county}
                      label='County'
                    />
                    {errors.county && (
                      <InputValidationMessage>
                        {errors.county}
                      </InputValidationMessage>
                    )}
                  </Col>
                  <Col style={tw`w-full sm:w-1/2`}>
                    <InputText
                      value={values.postcode}
                      setValue={handleChange('postcode')}
                      onBlur={handleBlur('postcode')}
                      placeholder='Postcode'
                      hasError={!!errors.postcode}
                      label='Postcode'
                    />
                    {errors.postcode && (
                      <InputValidationMessage>
                        {errors.postcode}
                      </InputValidationMessage>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>

            <Button onPress={handleSubmit} style={tw`mb-4`}>
              Update
            </Button>
          </View>
        )}
      </Formik>
    </View>
  );
};

export default UserDetailsForm;

// firstName
// lastName
// businessName
// email
// telephone

// address
// postcode

// companyUsers
