import { Formik } from 'formik';
import { FC } from 'react';
import { View } from 'react-native';
import * as Yup from 'yup';

import { UserClient } from '../../api';
import tw from '../../config/tailwind';
import { useToasts } from '../../hooks/useToasts';
import Button from '../shared/Button';
import InputText from '../shared/InputText';
import InputValidationMessage from '../shared/InputValidationMessage';

type ForgotPasswordValues = {
  email: string;
};

const schema: Yup.SchemaOf<ForgotPasswordValues> = Yup.object().shape({
  email: Yup.string().min(2).email().required().label('Email address'),
});

type Props = {
  onFormSent: () => void;
};

const ForgotPasswordForm: FC<Props> = ({ onFormSent }) => {
  const { addUnhandledErrorToast } = useToasts();
  const initialValues: ForgotPasswordValues = {
    email: '',
  };

  const onSubmit = async ({ email }: ForgotPasswordValues) => {
    try {
      await UserClient.forgotPassword(email);
      onFormSent();
    } catch (e) {
      addUnhandledErrorToast(e);
    }
  };

  return (
    <Formik
      //   initialValues={{ email: '', password: '' }}
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={schema}
    >
      {({
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        values,
        errors,
        isSubmitting,
      }) => (
        <View>
          <InputText
            value={values.email}
            setValue={handleChange('email')}
            onBlur={handleBlur('email')}
            placeholder='Enter your email address'
            hasError={!!errors.email}
            label='Email Address'
          />
          <InputValidationMessage>{errors.email || ''}</InputValidationMessage>

          <Button
            onPress={handleSubmit}
            style={tw`mb-4`}
            loading={isSubmitting}
          >
            Send email
          </Button>
        </View>
      )}
    </Formik>
  );
};

export default ForgotPasswordForm;
