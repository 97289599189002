import { useCallback, useEffect, useState } from 'react';
import ReactSelect from 'react-select';
import { UserClient } from '../../../api';
import { CompanyClient } from '../../../api/CompanyClient';
import Button from '../../../components/shared/Button';
import DefaultModal from '../../../components/shared/DefaultModal';
import InputLabel from '../../../components/shared/InputLabel';
import Select from '../../../components/shared/Select';
import tw from '../../../config/tailwind';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { CompanyResponse } from '../../../shared/types/companies';
import { Rebate } from '../../../shared/types/rebates';
import { UserResponse, UserRole } from '../../../shared/types/users';
import { getFullQuarters } from './RebateFunction';
import { defaultFilter, RebateResultFilter } from './RebatesScreen';

interface Props {
  visible: boolean;
  setVisible: (v: boolean) => void;
  filter: RebateResultFilter;
  setFilter: (f: RebateResultFilter) => void;
  rebates: Rebate[];
  setPage: (p: number) => void;
}

const RebateFilterModal: React.FC<Props> = ({
  visible,
  setVisible,
  filter,
  setFilter,
  rebates,
  setPage
}) => {
  const { user } = useAuthContext();
  const isPartners = user?.role === UserRole.User;

  const [companies, setCompanies] = useState<CompanyResponse[]>([]);
  const [users, setUsers] = useState<UserResponse[]>([]);
  const [admins, setAdmins] = useState<UserResponse[]>([]);

  const quatersOptions = getFullQuarters(rebates, 'since');

  const loadCompanies = async (page: number = 1) => {
    // partners company api
    const res = await CompanyClient.getAll(page);
    setCompanies((c) => [...c, ...res.items]);
    if (res.totalPages > page) {
      await loadCompanies(page + 1);
    }
  };

  const loadAdmins = useCallback(async (page: number = 1) => {
    const res = await UserClient.getAll(page, undefined, false);
    setAdmins((x) => [...x, ...res.items]);
    if (res.totalPages > page) {
      await loadAdmins(page + 1);
    }
  }, []);

  const loadUsers = useCallback(
    async (page: number = 1) => {
      // just return empty array if no company selected
      if (!filter.company) {
        setUsers([]);
        return;
      }

      const res = await UserClient.getAll(page, undefined, false);
      setUsers((x) => [...x, ...res.items]);
      if (res.totalPages > page) {
        await loadUsers(page + 1);
      }
    },
    [filter.company]
  );

  useEffect(() => {
    if (isPartners) {
      return;
    }
    loadCompanies();
    loadAdmins();
  }, []);

  useEffect(() => {
    if (isPartners) {
      return;
    }
    setUsers([]);
    setFilter(Object.assign(filter, { applicant: null }));
    loadUsers();
  }, [filter.company]);

  return (
    <>
      <DefaultModal
        visible={visible}
        setVisible={(v) => setVisible(v)}
        title='Rebate Filters'
      >
        <div>
          <Select
            selectedValue={filter.company}
            onValueChange={(v) => {
              setFilter(
                filter ? Object.assign({ ...filter }, { company: v }) : filter
              );
            }}
            label='Partner Company'
            // required
            searchable
            options={[
              {
                label: 'Select Company',
                value: null,
              },
              ...companies.map((c) => ({
                label: c.name,
                value: c,
              })),
            ]}
          />
        </div>
        <div>
          <Select
            selectedValue={filter.applicant}
            onValueChange={(v) => {
              setFilter(
                filter ? Object.assign({ ...filter }, { applicant: v }) : filter
              );
            }}
            label='Applicant'
            // required
            searchable
            options={[
              { label: 'Select Applicant', value: null },
              ...users.map((u) => ({
                label: `${u.firstName} ${u.lastName}`,
                value: u,
              })),
            ]}
          />
        </div>
        <div>
          <Select
            selectedValue={filter.reviewer}
            onValueChange={(v) => {
              setFilter(
                filter ? Object.assign({ ...filter }, { reviewer: v }) : filter
              );
            }}
            label='Reviewer'
            // required
            searchable
            options={[
              { label: 'Select Reviewer', value: null },
              ...admins
                .filter((u) => u.role === UserRole.Admin)
                .map((u) => ({
                  label: `${u.firstName} ${u.lastName}`,
                  value: u,
                })),
            ]}
          />
        </div>
        <div>
          <InputLabel label={`Quarters`} />
          <ReactSelect
            value={filter.quarters}
            styles={{
              control: (b, p) => ({
                ...b,
                ...tw.style(
                  'bg-white px-4 py-0 mb-4 font-sans border-2 border-blue h-[50px] flex flex-row items-center min-w-48 flex-1 rounded-none'
                ),
              }),
              menu: (b, p) => ({ ...b, ...tw.style(`rounded-none`) }),
              option: (b, p) => ({
                ...b,
                ...tw.style(`justify-center font-sans`),
              }),
              noOptionsMessage: (b, p) => ({ ...b, ...tw.style(`font-sans`) }),
              container: (b) => ({ ...b, ...tw.style('mt-[4px]') }),
            }}
            onChange={(v) => {
              
              setFilter(filter ? Object.assign({...filter}, { quarters: v }) : filter);
            }}
            isMulti
            required
            options={quatersOptions}
          />
        </div>
        <div>
          <Button style='mb-2' onPress={() => setVisible(false)}>
            Close
          </Button>
          <Button
            variant={'dark'}
            onPress={() => {
              setFilter(defaultFilter);
              setVisible(false);
            }}
          >
            Reset
          </Button>
        </div>
      </DefaultModal>
    </>
  );
};

export default RebateFilterModal;
