import { format, parse } from 'date-fns';
import { FC, useEffect } from 'react';
import {
  createElement,
  NativeSyntheticEvent,
  Platform,
  Pressable,
  TextInputFocusEventData,
  View,
} from 'react-native';

import tw from '../../config/tailwind';
import removeTime from '../../utils/removeTime';
import CrossSVG from '../svg/CrossSVG';
import PlusCircleFillSVG from '../svg/PlusCircleFillSVG';
import A from './A';
import DatePicker from './DatePicker';
import InputLabel from './InputLabel';
import Text from './Text';

type Props = {
  value: Date[];
  placeholder?: string;
  setValue: (dates: Date[]) => void;
  onBlur?: (e: NativeSyntheticEvent<TextInputFocusEventData>) => void;
  hasError?: boolean;
  label?: string;
  labelSize?: 'sm' | 'md';
  min?: Date;
  max?: Date;
};

const MultiDatePicker: FC<Props> = ({
  value,
  placeholder,
  setValue,
  hasError,
  label,
  labelSize,
  onBlur,
  min,
  max,
}) => {
  useEffect(() => {
    if (!value.length) {
      setValue([new Date()]);
    }
  }, [value]);

  const addDate = () => {
    setValue([...value, new Date()]);
  };

  const removeDate = (index: number) => {
    const dates = [...value];
    dates.splice(index, 1);
    setValue(dates);
  };

  return (
    <>
      {value.map((date, i) => (
        <View style={tw`flex items-center flex-row mb-3.5`} key={i}>
          <DatePicker
            style={tw`flex-1 mb-0`}
            value={date}
            setValue={(newDate) => {
              const newDates = [...value];
              newDates[i] = newDate;
              setValue(newDates);
            }}
            min={min}
            max={max}
          />
          <Pressable
            style={tw`ml-4 flex items-center w-6`}
            onPress={() => (i < value.length - 1 ? removeDate(i) : addDate())}
          >
            {i < value.length - 1 ? <CrossSVG /> : <PlusCircleFillSVG />}
          </Pressable>
        </View>
      ))}
    </>
  );
};

export default MultiDatePicker;
