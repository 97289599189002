import { Link } from '@react-navigation/native';
import { format } from 'date-fns';
import React, { useContext, useEffect, useState } from 'react';
import { ProjectClient } from '../../../api';

import { AuthContext } from '../../../context/AuthContextProvider';
import { useAppContext } from '../../../hooks/useAppContext';
import { PaginatedResult } from '../../../shared/types/paginatedResult';
import { ProjectResponse, ProjectStatus } from '../../../shared/types/projects';
import { UserRole } from '../../../shared/types/users';
import GenericTable from '../../shared/GenericTable';
import GenericTableAction from '../../shared/GenericTableAction';

interface ProjectTableProps {
  status: ProjectStatus | undefined;
}
const ProjectTable: React.FC<ProjectTableProps> = ({ status }) => {
  const [page, setPage] = useState(1);
  const [queryInput, setQueryInput] = useState('');

  const { user, activeCompany } = useContext(AuthContext);
  const { projectStats } = useAppContext();

  const [paginatedResult, setPaginatedResult] =
    useState<PaginatedResult<ProjectResponse>>();

  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    if (user?.role === UserRole.Admin || activeCompany)
      ProjectClient.getAll(activeCompany?.id, page, status, queryInput).then(
        (result) => {
          setPaginatedResult(result);
        }
      );
    return () => {
      setPaginatedResult(undefined);
    };
  }, [page, activeCompany?.id, queryInput, refresh, status]);

  useEffect(() => {
    if (paginatedResult) {
      const delay = setTimeout(() => {
        setRefresh((x) => x + 1);
      }, 100);
      return () => clearTimeout(delay);
    }
  }, [projectStats]);

  return (
    <GenericTable
      paginatedResult={paginatedResult}
      searchPlaceholder='Search projects'
      headers={{
        name: {
          title: 'Name',
          width: '30%',
          bold: true,
        },
        location: {
          title: 'Location',
          width: '20%',
        },
        createdAt: {
          title: 'Start date',
          width: '20%',
          format: (date) => format(new Date(date), 'dd / MM / yyyy'),
        },
      }}
      actions={(project) => [
        <Link
          to={{
            screen: 'authenticatedStackNavigator',
            params: {
              screen: 'projectStack',
              params: {
                screen: 'project',
                params: {
                  id: project.id,
                },
              },
            },
          }}
        >
          <GenericTableAction label='Manage' />
        </Link>,
      ]}
      setPage={setPage}
      setQueryInput={setQueryInput}
    />
  );
};

export default ProjectTable;
