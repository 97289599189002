import { Formik } from 'formik';
import React, { useState } from 'react';
import { View } from 'react-native';
import * as Yup from 'yup';
import { FormClient } from '../../../api';

import tw from '../../../config/tailwind';
import { phoneRegExp, postcodeRegExp } from '../../../constants';
import InfoBox from '../../InfoBox';
import Button from '../../shared/Button';
import Col from '../../shared/Col';
import H2 from '../../shared/H2';
import InputCheckbox from '../../shared/InputCheckbox';
import InputText from '../../shared/InputText';
import InputValidationMessage from '../../shared/InputValidationMessage';
import Row from '../../shared/Row';
import Text from '../../shared/Text';

interface RecyclingRequestFormProps {}

const RecyclingRequestForm: React.FC<RecyclingRequestFormProps> = () => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [recyclingAgent, setRecyclingAgent] = useState('');

  const initialValues = {
    recyclingAgent: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    postcode: '',
    phone: '',
    email: '',
    comments: '',
    confirmationCheckbox: false,
  };

  const schema: Yup.SchemaOf<typeof initialValues> = Yup.object().shape({
    recyclingAgent: Yup.string().required().label('Recycling Agent'),
    addressLine1: Yup.string().required().label('Address Line 1'),
    addressLine2: Yup.string().default('').label('Address Line 2'),
    city: Yup.string().required().label('City'),
    postcode: Yup.string()
      .required()
      .matches(postcodeRegExp, 'Please provide a valid postcode')
      .label('Postcode'),
    phone: Yup.string()
      .required()
      .matches(phoneRegExp, 'Please provide a valid phone number')
      .label('Phone'),
    email: Yup.string().email().required().label('Email'),
    comments: Yup.string().default(''),
    confirmationCheckbox: Yup.boolean()
      .isTrue()
      .required()
      .label('Terms of use'),
  });

  const onSubmit = async ({
    recyclingAgent: recyclingAgent,
    ...formValues
  }: typeof initialValues) => {
    await FormClient.recyclingRequest({
      recyclingAgent,
      ...formValues,
    });
    setRecyclingAgent(recyclingAgent);
    setFormSubmitted(true);
  };

  if (formSubmitted) {
    return (
      <View style={tw`min-h-[300px]`}>
        <H2>Thank you for submitting your request!</H2>
        <Text>
          A member of the Zentia team will be in touch to confirm the pick up
          details.
        </Text>
      </View>
    );
  }

  return (
    <View>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={schema}
        // validateOnChange={false}
        validateOnBlur={false}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          values,
          errors,
        }) => (
          <View>
            <Text style={tw`mb-8`}>
              To make a recycling request, please enter your contact details
              below and provide as much information as possible about the
              ceiling tiles and suspended ceiling grids to be recycled, as well
              as any other waste packaging. A member of the Zentia team will be
              in touch to confirm your request.
            </Text>

            <Row>
              <Col style='w-full sm:w-2/3'>
                <Row>
                  <Col style={tw`w-full sm:1/2`}>
                    <InputText
                      value={values.recyclingAgent}
                      setValue={handleChange('recyclingAgent')}
                      onBlur={handleBlur('recyclingAgent')}
                      placeholder='Recycling Agent'
                      hasError={!!errors.recyclingAgent}
                      label='Recycling Agent'
                      required
                      labelSize='md'
                    />
                    {errors.recyclingAgent && (
                      <InputValidationMessage>
                        {errors.recyclingAgent}
                      </InputValidationMessage>
                    )}
                  </Col>

                  <Col style={tw`w-full`}>
                    <H2>Recycling materials and waste packaging</H2>
                  </Col>
                  <Col style={tw`w-full`}>
                    <InputText
                      value={values.addressLine1}
                      setValue={handleChange('addressLine1')}
                      onBlur={handleBlur('addressLine1')}
                      placeholder='Address Line 1'
                      hasError={!!errors.addressLine1}
                      label='Address Line 1'
                      required
                      labelSize='md'
                    />
                    {errors.addressLine1 && (
                      <InputValidationMessage>
                        {errors.addressLine1}
                      </InputValidationMessage>
                    )}
                  </Col>
                  <Col style={tw`w-full`}>
                    <InputText
                      value={values.addressLine2}
                      setValue={handleChange('addressLine2')}
                      onBlur={handleBlur('addressLine2')}
                      placeholder='Address Line 2'
                      hasError={!!errors.addressLine2}
                      label='Address Line 2'
                      labelSize='md'
                    />
                    {errors.addressLine2 && (
                      <InputValidationMessage>
                        {errors.addressLine2}
                      </InputValidationMessage>
                    )}
                  </Col>
                  <Col style={tw`w-full`}>
                    <InputText
                      value={values.city}
                      setValue={handleChange('city')}
                      onBlur={handleBlur('city')}
                      placeholder='City'
                      hasError={!!errors.city}
                      label='City'
                      labelSize='md'
                    />
                    {errors.city && (
                      <InputValidationMessage>
                        {errors.city}
                      </InputValidationMessage>
                    )}
                  </Col>
                  <Col style={tw`w-full sm:w-1/2`}>
                    <InputText
                      value={values.postcode}
                      setValue={handleChange('postcode')}
                      onBlur={handleBlur('postcode')}
                      placeholder='Postcode'
                      hasError={!!errors.postcode}
                      label='Postcode'
                      labelSize='md'
                    />
                    {errors.postcode && (
                      <InputValidationMessage>
                        {errors.postcode}
                      </InputValidationMessage>
                    )}
                  </Col>
                </Row>

                <Row>
                  <Col style={tw`w-full sm:w-1/2`}>
                    <InputText
                      value={values.phone}
                      setValue={handleChange('phone')}
                      onBlur={handleBlur('phone')}
                      placeholder='Phone'
                      hasError={!!errors.phone}
                      label='Phone'
                      labelSize='md'
                    />
                    {errors.phone && (
                      <InputValidationMessage>
                        {errors.phone}
                      </InputValidationMessage>
                    )}
                  </Col>
                  <Col style={tw`w-full sm:w-1/2`}>
                    <InputText
                      value={values.email}
                      setValue={handleChange('email')}
                      onBlur={handleBlur('email')}
                      placeholder='Email'
                      hasError={!!errors.email}
                      label='Email'
                      labelSize='md'
                    />
                    {errors.email && (
                      <InputValidationMessage>
                        {errors.email}
                      </InputValidationMessage>
                    )}
                  </Col>
                  <Col style={tw`w-full`}>
                    <InputText
                      value={values.comments}
                      setValue={handleChange('comments')}
                      onBlur={handleBlur('postcode')}
                      placeholder='Give as much detail as possible including which floor, room and who to report to...'
                      hasError={!!errors.comments}
                      label='Any further details on where the materials and waste packing are stored'
                      labelSize='md'
                      multiline
                    />
                    {errors.comments && (
                      <InputValidationMessage>
                        {errors.comments}
                      </InputValidationMessage>
                    )}
                  </Col>
                  <Col style={tw`w-full`}>
                    <InputCheckbox
                      value={values.confirmationCheckbox}
                      setValue={(value) =>
                        setFieldValue('confirmationCheckbox', value)
                      }
                      hasError={!!errors.confirmationCheckbox}
                      label='I confirm that all materials and waste packing to be recycled are Zentia products and are stored in safe containers.'
                      labelSize='md'
                    />
                    {errors.confirmationCheckbox && (
                      <InputValidationMessage>
                        {errors.confirmationCheckbox}
                      </InputValidationMessage>
                    )}
                  </Col>
                </Row>
              </Col>
              <Col style={tw`w-full sm:w-1/3`}>
                <InfoBox
                  content='For safety reasons, all materials and waste packaging to be recycled must be packaged and stored in a secure fashion.'
                  style={tw`mb-5`}
                />
              </Col>
            </Row>

            <Button onPress={handleSubmit} style={tw`mb-4 text-center`}>
              Submit request
            </Button>
          </View>
        )}
      </Formik>
    </View>
  );
};

export default RecyclingRequestForm;
