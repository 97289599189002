import React from 'react';
import { View } from 'react-native';
import { ClassInput } from 'twrnc/dist/esm/types';

import tw from '../config/tailwind';
import Text from './shared/Text';
import InfoSVG from './svg/InfoSVG';

interface InfoBoxProps {
  children?: React.ReactNode;
  content?: string;
  style?: ClassInput;
}

const InfoBox: React.FC<InfoBoxProps> = ({ content, children, style }) => {
  return (
    <View style={tw.style(`p-8 border border-blue`, style)}>
      <InfoSVG width={21} height={21} style={tw`mb-4`} />
      {content && (
        <Text style={tw`${children ? 'mb-7' : ''} text-sm text-blue`}>
          {content}
        </Text>
      )}
      {children}
    </View>
  );
};

export default InfoBox;
