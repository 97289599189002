import React from 'react';
import { SvgProps } from 'react-native-svg';
import { ClassInput } from 'twrnc/dist/esm/types';

import PinnacleLogo from '../../assets/svg/pinnacle-logo.svg';
import tw from '../../config/tailwind';

const PinnacleLogoSvg: React.FC<SvgProps> = ({
  style,
  height = 56,
  ...props
}) => {
  return (
    <PinnacleLogo
      height={height}
      style={tw.style(`flex align-start`, style as ClassInput)}
      {...props}
    />
  );
};

export default PinnacleLogoSvg;
