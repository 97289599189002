import React, { useState } from 'react';
import { TaskClient, SnagClient } from '../../../api';

import { useToasts } from '../../../hooks/useToasts';
import { SnagImageResponse, SnagResponse } from '../../../shared/types/snags';
import { TaskImageResponse, TaskResponse } from '../../../shared/types/tasks';
import DefaultModal from '../../shared/DefaultModal';
import FilePicker from '../../shared/FilePicker';

interface AddImageModalProps {
  task: TaskResponse | SnagResponse;
  isSnag?: boolean;
  visible: boolean;
  setVisible: (v: boolean) => void;
  onImageAdded: (comment: TaskImageResponse | SnagImageResponse) => void;
}

const AddImageModal: React.FC<AddImageModalProps> = ({
  task,
  isSnag,
  visible,
  setVisible,
  onImageAdded,
}) => {
  const { addToast, addUnhandledErrorToast } = useToasts();
  const [showSpinner, setShowSpinner] = useState(false);

  const onSubmit = async (file: File) => {
    setShowSpinner(true);
    try {
      let comment: TaskImageResponse | SnagImageResponse;
      if (!isSnag) {
        comment = await TaskClient.addImage(task.project.id, task.id, file);
      } else {
        comment = await SnagClient.addImage(task.project.id, task.id, file);
      }
      setVisible(false);
      addToast({
        title: 'Image uploaded',
        description: `You added an image to the ${isSnag ? 'issue' : 'task'} '${
          task.name
        }'`,
        type: 'success',
      });
      onImageAdded(comment);
    } catch (e) {
      addUnhandledErrorToast(e);
    }
    setShowSpinner(false);
  };

  return (
    <DefaultModal
      setVisible={setVisible}
      visible={visible}
      title='Create an image'
    >
      <FilePicker
        type='image/*'
        label='Select image'
        setValue={onSubmit}
        loading={showSpinner}
      />
    </DefaultModal>
  );
};

export default AddImageModal;
