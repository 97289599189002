import {
  Link,
  NavigationProp,
  useNavigation,
  useNavigationState,
} from '@react-navigation/native';
import { To } from '@react-navigation/native/lib/typescript/src/useLinkTo';
import React, { useContext, useMemo, useRef } from 'react';
import { View } from 'react-native';
import { SvgProps } from 'react-native-svg';
import { useHover } from 'react-native-web-hooks';

import tw from '../../config/tailwind';
import { GlobalLabels } from '../../constants';
import { AuthContext } from '../../context/AuthContextProvider';
import { useContainer } from '../../hooks/useContainer';
import { AuthenticatedStackNavigatorParamList } from '../../routes/types';
import { CompanyUserRole } from '../../shared/types/companies';
import { UserRole, UserTier } from '../../shared/types/users';
import Text from '../shared/Text';
import DistributorSVG from '../svg/DistributorSVG';
import DownloadSVG from '../svg/DownloadSVG';
import GearSvg from '../svg/GearSvg';
import NewsSVG from '../svg/NewsSVG';
import OverviewSVG from '../svg/OverviewSVG';
import PhoneSVG from '../svg/PhoneSVG';
import ProjectsSVG from '../svg/ProjectsSVG';
import TrainingSVG from '../svg/TrainingSVG';

interface NavigationProps {}

const Navigation: React.FC<NavigationProps> = () => {
  const { user, activeCompany, setActiveCompanyId } = useContext(AuthContext);
  const navigation =
    useNavigation<NavigationProp<AuthenticatedStackNavigatorParamList>>();

  const companyRole = user?.companyUsers.find(
    (x) => x.companyId === activeCompany?.id
  )?.companyRole;

  return (
    <>
      {activeCompany && (
        <>
          {user?.role === UserRole.Admin && (
            <Text style={tw`text-sm mb-2`}>
              Viewing as{' '}
              <Text style={tw`font-bold text-sm`}>{activeCompany.name}</Text>{' '}
              <Text
                style={tw`text-green text-sm underline`}
                onPress={() => {
                  navigation.navigate('globalAdminStack', {
                    screen: 'company',
                    params: {
                      id: activeCompany.id,
                    },
                  });
                  setActiveCompanyId(undefined);
                }}
              >
                End
              </Text>
            </Text>
          )}
          <NavLink
            svg={OverviewSVG}
            linkProps={{
              screen: 'authenticatedStackNavigator',
              params: { screen: 'home' },
            }}
          >
            Overview
          </NavLink>
          <NavLink
            svg={ProjectsSVG}
            linkProps={{
              screen: 'authenticatedStackNavigator',
              params: { screen: 'projectsOverview' },
            }}
          >
            {user?.role === UserRole.User ? 'My projects' : `Projects`}
          </NavLink>
        </>
      )}

      <NavLink
        svg={DistributorSVG}
        linkProps={{
          screen: 'authenticatedStackNavigator',
          params: { screen: 'distributors' },
        }}
      >
        {GlobalLabels.FindContact}
      </NavLink>

      {activeCompany && (
        <>
          <NavLink
            svg={DownloadSVG}
            linkProps={{
              screen: 'authenticatedStackNavigator',
              params: { screen: 'resources' },
            }}
          >
            Resources
          </NavLink>
          <NavLink
            svg={TrainingSVG}
            linkProps={{
              screen: 'authenticatedStackNavigator',
              params: { screen: 'training' },
            }}
          >
            Training
          </NavLink>
        </>
      )}

      <NavLink
        svg={NewsSVG}
        linkProps={{
          screen: 'authenticatedStackNavigator',
          params: {
            screen: 'newsStack',
            params: {
              screen: 'news',
            },
          },
        }}
      >
        Pinnacle news
      </NavLink>
      <NavLink
        key={activeCompany?.id || 'admin-contacts'}
        svg={PhoneSVG}
        linkProps={
          activeCompany || user?.role !== UserRole.Admin
            ? {
                screen: 'authenticatedStackNavigator',
                params: { screen: 'contacts' },
              }
            : {
                screen: 'authenticatedStackNavigator',
                params: {
                  screen: 'globalAdminStack',
                  params: {
                    screen: 'contacts',
                  },
                },
              }
        }
      >
        Zentia contacts
      </NavLink>

      {/* Rebate Tab */}
      {user && user?.tier <= UserTier.ONE ? (
        <NavLink
          svg={DownloadSVG}
          linkProps={{
            screen: 'authenticatedStackNavigator',
            params: { screen: 'rebates' },
          }}
        >
          {activeCompany || user?.role !== UserRole.Admin
            ? GlobalLabels.Rebates
            : 'Rebates Admin'
          }
        </NavLink>
      ) : null}

      {user?.role === UserRole.Admin ? (
        <NavLink
          svg={GearSvg}
          linkProps={{
            screen: 'authenticatedStackNavigator',
            params: { screen: 'globalAdminStack', params: { screen: 'admin' } },
          }}
        >
          {GlobalLabels.GlobalAdmin}
        </NavLink>
      ) : (
        activeCompany &&
        companyRole === CompanyUserRole.Admin && (
          <NavLink
            svg={GearSvg}
            linkProps={{
              screen: 'authenticatedStackNavigator',
              params: {
                screen: 'companyAdminStack',
                params: { screen: 'company' },
              },
            }}
          >
            Company Admin
          </NavLink>
        )
      )}
    </>
  );
};

export default Navigation;

type NavLinkProps = {
  children: string & React.ReactNode;
  onPress?: () => void;
  linkProps: To<
    ReactNavigation.RootParamList,
    keyof ReactNavigation.RootParamList
  >;
  svg: React.FC<SvgProps>;
};

const NavLink: React.FC<NavLinkProps> = ({
  children,
  linkProps,
  onPress,
  svg: Svg,
}) => {
  const ref = useRef(null);
  const isHovered = useHover(ref);

  const [margin] = useContainer();
  const activeItemName = useNavigationState((state) => {
    return state.routes[state.index].name;
  });

  const getLinkPropsScreen = (propScreen: typeof linkProps) => {
    if (typeof propScreen === 'string') return propScreen;

    type GenericTo = {
      screen?: string;
      params?: {
        [key: string]: unknown;
      } & GenericTo;
    };

    function getFinalScreen(screen: GenericTo): string | undefined {
      if (screen?.params && 'screen' in screen?.params) {
        return getFinalScreen(screen?.params);
      }
      return screen?.screen;
    }

    return getFinalScreen(propScreen);
  };

  const isActive = useMemo(
    () => activeItemName === getLinkPropsScreen(linkProps),
    [activeItemName]
  );

  return (
    <View
      style={tw.style(
        `no-underline`,
        {
          marginLeft: `-${margin}`,
          paddingLeft: `calc(${margin} + 12px)`,
          paddingRight: 32,
          marginRight: '-32px',
        },
        isHovered && 'bg-grey-100',
        isActive && 'bg-green'
      )}
    >
      <View ref={ref}>
        <Link
          onPress={() => onPress && onPress()}
          // TODO: Type this correctlybg-green
          // @ts-ignore
          to={linkProps}
          style={tw.style(`no-underline py-3`)}
        >
          <View
            style={{
              ...tw`flex-row items-center`,
            }}
          >
            <View style={tw`items-center justify-center h-full  mr-3 h-8 w-8`}>
              {Svg && (
                <Svg
                  color='currentColor'
                  style={tw.style(`text-green`, isActive && `text-blue`)}
                />
              )}
            </View>
            {typeof children === 'string' ? (
              <Text
                style={tw`font-ubuntu-bold font-bold ${
                  isActive ? 'text-blue' : 'text-green'
                }`}
              >
                {children}
              </Text>
            ) : (
              children
            )}
          </View>
        </Link>
      </View>
    </View>
  );
};
