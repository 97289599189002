import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { ClassInput } from 'twrnc/dist/esm/types';
import { ProjectClient } from '../../api';
import tw from '../../config/tailwind';

import { useToasts } from '../../hooks/useToasts';
import {
  ProjectResponse,
  ProjectStatsResponse,
} from '../../shared/types/projects';
import { decodeEntities } from '../../utils/decodeEntities';
import Col from '../shared/Col';
import EditableText from '../shared/EditableText';
import H3 from '../shared/H3';
import P from '../shared/P';
import ProgressBar from '../shared/ProgressBar';
import Row from '../shared/Row';

import Text from '../shared/Text';
import EditProjectModal from './modals/EditProjectModal';

interface ProjectDetailsProps {
  project: ProjectResponse;
  setProject: (project: ProjectResponse) => void;
  style?: ClassInput;
}

const ProjectDetails: React.FC<ProjectDetailsProps> = ({
  project,
  setProject,
  style,
}) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [stats, setStats] = useState<ProjectStatsResponse>();
  const { addUnhandledErrorToast } = useToasts();

  useEffect(() => {
    ProjectClient.getStatsById(project.id)
      .then((result) => {
        setStats(result);
      })
      .catch((e) => {
        addUnhandledErrorToast(e);
      });
  }, [project]);

  const startEditing = () => {
    setShowEditModal(true);
  };

  const completeItems =
    (stats?.snags.complete || 0) + (stats?.tasks.complete || 0);

  const incompleteItems =
    (stats?.snags.incomplete || 0) + (stats?.tasks.incomplete || 0);

  const totalItems = completeItems + incompleteItems;

  return (
    <Row
      style={tw.style(`p-6 bg-white justify-center items-center mx-0`, style)}
    >
      <Col style={tw`w-full  px-0`}>
        <H3>Your project breakdown</H3>
        <Row style={tw`-mb-7 pb-5`}>
          <Col style={tw`mb-7 w-1/2 sm:w-1/3`}>
            <Text style={tw`mb-1`}>Project created</Text>
            <Text style={tw`font-bold font-ubuntu-bold`}>
              {format(new Date(project.createdAt), 'dd/MM/yyyy')}
            </Text>
          </Col>
          <Col style={tw`mb-7 w-1/2 sm:w-1/3`}>
            <Text style={tw`mb-1`}>Sector</Text>
            <EditableText onEditClick={startEditing}>
              {decodeEntities(project.sector!.name)}
            </EditableText>
          </Col>
          <Col style={tw`mb-7 w-1/2 sm:w-1/3`}>
            <Text style={tw`mb-1`}>Location</Text>
            <EditableText onEditClick={startEditing} style={tw`items-start`}>
              {project.location}
            </EditableText>
          </Col>
        </Row>
        <ProgressBar
          title='Project progress'
          progress={totalItems ? completeItems / totalItems : 0}
        />
      </Col>

      {showEditModal && (
        <EditProjectModal
          setVisible={setShowEditModal}
          visible
          project={project}
          setProject={setProject}
        />
      )}
    </Row>
  );
};

export default ProjectDetails;
