import { serialize } from 'object-to-formdata';
import { API_BASE_URL } from '../env-consts';
import {
  AssignContactPayload,
  ContactResponse,
  CreateContactPayload,
  RemoveContactPayload,
  UpdateContactPayload,
} from '../shared/types/contacts';
import { PaginatedResult } from '../shared/types/paginatedResult';
import ApiClient from './ApiClient';

const client = new ApiClient(`${API_BASE_URL}/contacts`);

export const ContactClient = {
  async create(payload: CreateContactPayload, file?: File) {
    const data = serialize(
      {
        ...payload,
        telephone: payload.telephone || undefined,
        mobile: payload.mobile || undefined,
      },
      {
        indices: true,
      }
    );
    if (file) {
      data.append('file', file);
    }

    return client.post<ContactResponse>(``, data).then(({ data }) => data);
  },
  async update(payload: UpdateContactPayload, file?: File) {
    const data = serialize(
      {
        ...payload,
        telephone: payload.telephone || undefined,
        mobile: payload.mobile || undefined,
      },
      {
        indices: true,
      }
    );
    if (file) {
      data.append('file', file);
    }
    return client.put<ContactResponse>(``, data).then(({ data }) => data);
  },

  async assign(payload: AssignContactPayload) {
    return client.post<void>('/assign', payload).then(() => {});
  },

  async remove(payload: RemoveContactPayload) {
    return client.post<void>(`/remove`, payload).then(() => {});
  },

  async delete(contactId: string) {
    return client.delete<void>(`/${contactId}`).then(() => {});
  },

  async findAll(
    query: string,
    page: number,
    companyId?: string,
    perPage?: number
  ) {
    return client
      .get<PaginatedResult<ContactResponse>>(``, {
        params: {
          query,
          page,
          companyId,
          perPage,
        },
      })
      .then((res) => res.data);
  },
};
