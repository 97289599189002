import { useFocusEffect } from '@react-navigation/native';
import React, { useContext, useState } from 'react';
import { CompanyClient } from '../../api';

import ContactCard from '../../components/contacts/ContactCard';
import Col from '../../components/shared/Col';
import H1 from '../../components/shared/H1';
import P from '../../components/shared/P';
import Row from '../../components/shared/Row';
import tw from '../../config/tailwind';
import { AuthContext } from '../../context/AuthContextProvider';
import { useAuthContext } from '../../hooks/useAuthContext';
import DashboardLayout from '../../layout/DashboardLayout';
import { ContactResponse } from '../../shared/types/contacts';
import { UserRole } from '../../shared/types/users';

interface ContactsScreenProps {}

const ContactsScreen: React.FC<ContactsScreenProps> = ({}) => {
  const { activeCompany } = useContext(AuthContext);
  const [contacts, setContacts] = useState<ContactResponse[]>([]);

  useFocusEffect(
    React.useCallback(() => {
      let isActive = true;
      if (activeCompany) {
        CompanyClient.getCompanyContacts(activeCompany.id).then((contacts) => {
          if (isActive) {
            setContacts(
              contacts.sort((a, b) =>
                a.name === b.name ? 0 : a.name > b.name ? 1 : -1
              )
            );
          }
        });
      }
      return () => {
        setContacts([]);
        isActive = false;
      };
    }, [activeCompany])
  );

  return (
    <DashboardLayout>
      <H1 xl>Useful contacts</H1>
      <P>
        Please contact the people below for any technical help and advice you
        may need.
      </P>

      <Row style={tw`flex flex-wrap -mx-1.5`}>
        {contacts.map(({ id, name, mobile, role, telephone, email, image }, i) => (
          <Col 
            style={tw`w-full sm:w-1/2 md:w-1/3 mb-3 px-1.5`}
            key={`contact-screen-item-${i}`}
          >
            <ContactCard
              key={id}
              name={name}
              role={role}
              mob={mobile}
              tel={telephone}
              email={email}
              imageUrl={image?.publicUrl}
            />
          </Col>
        ))}
      </Row>
    </DashboardLayout>
  );
};

export default ContactsScreen;
