import AsyncStorage from '@react-native-async-storage/async-storage';
import jwtDecode from 'jwt-decode';
import { createContext, FC, ReactNode, useEffect, useState } from 'react';

import { AuthClient, CompanyClient } from '../api';
import { axiosClient } from '../api/ApiClient';
import { ASYNC_STORE_ACTIVE_COMPANY_ID } from '../constants';
import { CompanyResponse, CompanyUserStatus } from '../shared/types/companies';
import { JwtResponse } from '../shared/types/jwt';
import { UserResponse, UserRole } from '../shared/types/users';

type AuthContextType = {
  loading: boolean;
  isAuthenticated: boolean;
  isConsented: boolean;
  user: UserResponse | undefined;
  activeCompany: CompanyResponse | null;
  login: (accessToken: string) => void;
  logout: () => Promise<void>;
  updateUser: (user: UserResponse) => void;
  setActiveCompanyId: (companyId?: string) => void;
  setIsConsented: (b: boolean) => void;
};

export const AuthContext = createContext<AuthContextType>({
  loading: true,
  isAuthenticated: false,
  isConsented: false,
  user: undefined,
  activeCompany: null,
  login: () => {},
  logout: async () => {},
  updateUser: (user: UserResponse) => {},
  setActiveCompanyId: (companyId?: string) => {},
  setIsConsented: (b: boolean) => {}
});

type Props = {
  children: ReactNode;
};

const AuthContextProvider: FC<Props> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isConsented, setIsConsented] = useState(false);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<UserResponse | undefined>(undefined);
  const [activeCompany, setActiveCompany] = useState<CompanyResponse | null>(
    null
  );

  useEffect(() => {
    (async () => {
      await AuthClient.refreshToken()
        .then((res) => login(res))
        .catch(() => {})
        .finally(() => setLoading(false));
    })();
  }, []);

  useEffect(() => {
    if (user && user.role === UserRole.Admin) {
      AsyncStorage.getItem(ASYNC_STORE_ACTIVE_COMPANY_ID).then(
        (storedCompanyId) => {
          if (storedCompanyId) {
            setActiveCompanyId(storedCompanyId);
          }
        }
      );
    }
  }, [user]);

  const logout = async () => {
    axiosClient.defaults.headers.common['Authorization'] = '';
    await AuthClient.logout();
    setIsAuthenticated(false);
    setUser(undefined);
    setActiveCompany(null);
    await AsyncStorage.removeItem(ASYNC_STORE_ACTIVE_COMPANY_ID);
  };

  const setActiveCompanyId = async (companyId?: string) => {
    if (!companyId) {
      setActiveCompany(null);
    } else {
      const company = await CompanyClient.getById(companyId);
      setActiveCompany(company);
    }

    if (companyId)
      await AsyncStorage.setItem(ASYNC_STORE_ACTIVE_COMPANY_ID, companyId);
    else await AsyncStorage.removeItem(ASYNC_STORE_ACTIVE_COMPANY_ID);
  };

  const login = (accessToken: string) => {
    axiosClient.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${accessToken}`;

    const user = jwtDecode<JwtResponse>(accessToken).user;

    setUser(user);
    // TODO: Remove this hardcoded value, this will need to be set via a company switcher at some point
    setActiveCompanyId(
      user.companyUsers?.find(
        (cUser) => cUser.status === CompanyUserStatus.Active
      )?.companyId
    );
    setIsAuthenticated(true);

    // temporary consent
    setIsConsented(user.isConsented);
  };

  const updateUser = ({
    role,
    companyUsers,
    ...newUserDetails
  }: UserResponse) => {
    if (!user) {
      console.error('Invalid flow: User not set');
      return;
    }

    setUser({ ...user, ...newUserDetails });
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isConsented,
        login,
        logout,
        user,
        loading,
        activeCompany,
        setActiveCompanyId,
        setIsConsented,
        updateUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
