import axios from 'axios';

import { ToastNotification } from '../context/ToastContextProvider';

export const onConflictError = async (
  e: unknown,
  addToast: (data: ToastNotification) => void,
  defaultConflictMessage = 'Data conflict'
) => {
  if (axios.isAxiosError(e) && e.response?.status === 409) {
    let description = defaultConflictMessage;

    const data = e.response?.data as Record<string, unknown>;
    if (data && data?.code === 'DUPLICATE_TELEPHONE') {
      description = 'Telephone number is already in use on another account.';
    }

    addToast({
      type: 'error',
      title: 'Invalid form submission',
      description,
    });
  } else {
    throw e;
  }
};
