import { StackActions, useNavigation } from '@react-navigation/native';
import { Formik } from 'formik';
import React from 'react';
import { View } from 'react-native';
import * as Yup from 'yup';

import { UserClient, AuthClient } from '../../api';
import tw from '../../config/tailwind';
import { useAuthContext } from '../../hooks/useAuthContext';
import { LoginResponseType } from '../../shared/types/auth';
import { UserInviteResponse } from '../../shared/types/users';
import Button from '../shared/Button';
import Col from '../shared/Col';
import H1 from '../shared/H1';
import H2 from '../shared/H2';
import InputPassword from '../shared/InputPassword';
import InputText from '../shared/InputText';
import InputValidationMessage from '../shared/InputValidationMessage';
import Row from '../shared/Row';
import Text from '../shared/Text';
import PinnacleLogoSvg from '../svg/PinnacleLogoSVG';

interface AdminInviteFormProps {
  userId: string;
  token: string;
  invite: UserInviteResponse;
}

const AdminInviteForm: React.FC<AdminInviteFormProps> = ({
  userId,
  token,
  invite,
}) => {
  const { login, loading: authLoading, setActiveCompanyId } = useAuthContext();
  const navigation = useNavigation();

  const initialValues = {
    email: invite?.email || '',
    password: '',
    confirmPassword: '',
  };

  const schema: Yup.SchemaOf<typeof initialValues> = Yup.object().shape({
    password: Yup.string().required().label('Password').min(8).max(24),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required()
      .label('Confirm password'),
    email: Yup.string().email().required().label('Email'),
  });

  const acceptInvite = async (formValues?: typeof initialValues) => {
    let accessToken: string;

    if (formValues) {
      await UserClient.acceptInvite({
        userId,
        token,
        password: formValues.password,
        confirmPassword: formValues.confirmPassword,
        gdprConsent: true,
      });

      accessToken = await AuthClient.login({
        email: formValues.email,
        password: formValues.password,
      }).then((res) =>
        res.type === LoginResponseType.Authenticated ? res.accessToken : ''
      );
    } else {
      await UserClient.acceptInvite({
        userId,
        token,
        gdprConsent: true,
      });

      accessToken = await AuthClient.refreshToken();
    }

    login(accessToken);
    navigation.dispatch(
      StackActions.push('authenticatedStackNavigator', {
        screen: 'home',
      })
    );
  };

  if (authLoading) {
    return null;
  }

  if (!invite.isNewUser) {
    return (
      <Button onPress={() => acceptInvite()} style={tw`self-end`}>
        Accept Invite
      </Button>
    );
  }

  return (
    <>
      <PinnacleLogoSvg style={tw`mb-20`} />
      <H1 xl>Hi {invite?.firstName},</H1>
      <H1>
        You've been invited to join the Pinnacle Portal as a super-admin
        <Text
          style={tw`text-green text-2xl font-bold font-ubuntu-bold border-b-2 border-dark`}
        >
          {invite.companyName}
        </Text>
        .
      </H1>

      <H2>Set your password below to get started</H2>

      <Formik
        initialValues={initialValues}
        onSubmit={acceptInvite}
        validationSchema={schema}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          values,
          errors,
        }) => (
          <View>
            <Row>
              <Col style={tw`w-full`}>
                <Row>
                  <Col style={tw`w-full opacity-50`} pointerEvents='none'>
                    <InputText
                      value={values.email}
                      setValue={handleChange('email')}
                      onBlur={handleBlur('email')}
                      placeholder='Email Address'
                      hasError={!!errors.email}
                      label='Email Address'
                      required
                      readonly
                    />
                  </Col>
                  <Col>
                    <InputPassword
                      value={values.password}
                      setValue={handleChange('password')}
                      onBlur={handleBlur('password')}
                      placeholder='Enter a password'
                      hasError={!!errors.password}
                      label='Password'
                      required
                    />
                    {errors.password && (
                      <InputValidationMessage>
                        {errors.password}
                      </InputValidationMessage>
                    )}
                  </Col>
                  <Col>
                    <InputPassword
                      value={values.confirmPassword}
                      setValue={handleChange('confirmPassword')}
                      onBlur={handleBlur('confirmPassword')}
                      placeholder='Confirm your password'
                      hasError={!!errors.confirmPassword}
                      label='Confirm Password'
                      required
                    />
                    {errors.confirmPassword && (
                      <InputValidationMessage>
                        {errors.confirmPassword}
                      </InputValidationMessage>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
            <Button onPress={handleSubmit} style={tw`self-end`}>
              Accept Invite
            </Button>
          </View>
        )}
      </Formik>
    </>
  );
};

export default AdminInviteForm;
