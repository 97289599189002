import { FormikErrors, FormikValues, useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import tw from '../../config/tailwind';
import { BookingTrainingFormValues } from '../training/BookTraining';
import Col from './Col';
import InputText from './InputText';
import InputValidationMessage from './InputValidationMessage';
import Row from './Row';

interface LoqateAddressFormFieldsProps {
  address: string;
}

const LoqateAddressFormFields: React.FC<LoqateAddressFormFieldsProps> = ({
  address,
}) => {
  const {
    errors,
    values,
    setFieldValue,
    validateForm,
    handleChange,
    handleBlur,
  } = useFormikContext<BookingTrainingFormValues>();

  // const [address, setAddress] = useState({})
  useEffect(() => {
    const [addressLine1, addressLine2, city, postcode] = address.split(',');
    setFieldValue('addressLine1', addressLine1);
    setFieldValue('addressLine2', addressLine2);
    setFieldValue('county', city);
    setFieldValue('postcode', postcode);
  }, [address]);

  return (
    <Row>
      <Col style={tw`w-full`}>
        <InputText
          value={values.addressLine1}
          setValue={handleChange('addressLine1')}
          onBlur={handleBlur('addressLine1')}
          placeholder='Address Line 1'
          hasError={!!errors.addressLine1}
          label='Address Line 1'
          required
        />
        {errors.addressLine1 && (
          <InputValidationMessage>{errors.addressLine1}</InputValidationMessage>
        )}
      </Col>
      <Col style={tw`w-full`}>
        <InputText
          value={values.addressLine2}
          setValue={handleChange('addressLine2')}
          onBlur={handleBlur('addressLine2')}
          placeholder='Address Line 2'
          hasError={!!errors.addressLine2}
          label='Address Line 2'
        />
        {errors.addressLine2 && (
          <InputValidationMessage>{errors.addressLine2}</InputValidationMessage>
        )}
      </Col>
      <Col style={tw`w-full`}>
        <InputText
          value={values.county}
          setValue={handleChange('county')}
          onBlur={handleBlur('county')}
          placeholder='County'
          hasError={!!errors.county}
          label='County'
        />
        {errors.county && (
          <InputValidationMessage>{errors.county}</InputValidationMessage>
        )}
      </Col>
      <Col style={tw`w-full sm:w-1/2`}>
        <InputText
          value={values.postcode}
          setValue={handleChange('postcode')}
          onBlur={handleBlur('postcode')}
          placeholder='Postcode'
          hasError={!!errors.postcode}
          label='Postcode'
        />
        {errors.postcode && (
          <InputValidationMessage>{errors.postcode}</InputValidationMessage>
        )}
      </Col>
    </Row>
  );
};

export default LoqateAddressFormFields;
