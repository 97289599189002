import { StackActions, useFocusEffect } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { ActivityIndicator, ScrollView, View } from 'react-native';
import { UserClient } from '../../api';

import LoginForm from '../../components/auth/LoginForm';
import AdminInviteForm from '../../components/invitation/AdminInviteForm';
import CompanyInviteForm from '../../components/invitation/CompanyInviteForm';
import Button from '../../components/shared/Button';
import Col from '../../components/shared/Col';
import ContentWrapper from '../../components/shared/ContentWrapper';
import H1 from '../../components/shared/H1';
import H2 from '../../components/shared/H2';
import H3 from '../../components/shared/H3';
import InputPassword from '../../components/shared/InputPassword';
import InputText from '../../components/shared/InputText';
import InputValidationMessage from '../../components/shared/InputValidationMessage';
import P from '../../components/shared/P';
import Row from '../../components/shared/Row';
import Text from '../../components/shared/Text';
import DoubleChevronRightSVG from '../../components/svg/DoubleChevronRightSVG';
import PinnacleLogoSvg from '../../components/svg/PinnacleLogoSVG';
import tw from '../../config/tailwind';
import { useAuthContext } from '../../hooks/useAuthContext';
import { useContainer } from '../../hooks/useContainer';
import { RootStackParamList } from '../../routes/types';
import { UserInviteResponse } from '../../shared/types/users';

type InvitationScreenProps = NativeStackScreenProps<
  RootStackParamList,
  'acceptInviteCompany'
> & {
  //
};

const InvitationScreen: React.FC<InvitationScreenProps> = ({
  route,
  navigation,
}) => {
  const { userId, companyId, token } = route.params;

  const [loading, setLoading] = useState(true);
  const { login, user, loading: authLoading } = useAuthContext();
  const [margin] = useContainer();

  const [invite, setInvite] = useState<UserInviteResponse>();
  const [inviteNotFound, setInviteNotFound] = useState<404 | 403 | undefined>();

  useFocusEffect(
    React.useCallback(() => {
      let isActive = true;

      if (!authLoading) {
        if (user && user.id !== userId) {
          setInviteNotFound(403);
          setLoading(false);
        }
      }

      UserClient.findInvite(userId, companyId, token)
        .then((res) => {
          if (isActive) {
            setInvite(res);
            setLoading(false);
          }
        })
        .catch((e) => {
          if (e.response.status === 404 || e.response.status === 401) {
            if (isActive) {
              setInviteNotFound(e.response.status);
              setLoading(false);
            }
          }
        });

      return () => {
        isActive = false;
        setLoading(true);
        setInvite(undefined);
        setInviteNotFound(undefined);
      };
    }, [user, authLoading, userId])
  );

  if (loading || authLoading) {
    return (
      <View style={tw`flex-1 justify-center`}>
        <ActivityIndicator
          size={50}
          style={tw`text-green`}
          color='currentColor'
        />
      </View>
    );
  }

  if (!invite || inviteNotFound) {
    return (
      <View
        style={tw.style(` items-center flex-1  mt-40`, {
          paddingLeft: margin,
          paddingRight: margin,
        })}
      >
        <PinnacleLogoSvg style={tw`mb-10`} />
        <View style={tw`text-center pt-10`}>
          <P
            style={tw.style(`text-green font-bold font-ubuntu-bold mb-10`, {
              fontSize: '75px',
            })}
          >
            {inviteNotFound ? inviteNotFound : 404}
          </P>
          <H1>
            {inviteNotFound === 403
              ? 'Access forbidden'
              : 'Invitation not found'}
          </H1>
          {user && user.id !== userId && (
            <P>You do not have access to this invite</P>
          )}

          <Button
            variant='transparent'
            style={tw`self-center border-b-2 py-1 px-0 pr-3 mt-2`}
            onPress={() =>
              !user
                ? navigation.navigate('unauthenticatedStackNavigator', {
                    screen: 'login',
                  })
                : navigation.navigate('authenticatedStackNavigator', {
                    screen: 'home',
                  })
            }
          >
            <View style={tw`flex-row`}>
              <DoubleChevronRightSVG style={tw`mt-px`} />
              <Text>Return home</Text>
            </View>
          </Button>
        </View>
      </View>
    );
  }

  if (!invite.isNewUser && !user) {
    return (
      <View
        style={tw.style(` items-center flex-1 mt-40`, {
          paddingLeft: margin,
          paddingRight: margin,
        })}
      >
        <PinnacleLogoSvg style={tw`mb-10`} />
        <H3>Welcome back, please login to continue</H3>
        <ContentWrapper
          style={tw.style(`w-full`, {
            maxWidth: 500,
          })}
        >
          <LoginForm />
        </ContentWrapper>
      </View>
    );
  }

  return (
    <ScrollView
      style={tw.style('pt-20 pb-5', {
        paddingLeft: margin,
        paddingRight: margin,
      })}
    >
      <View style={tw`w-full lg:w-3/4 xl:w-3/5 mx-auto`}>
        <View>
          {companyId ? (
            <CompanyInviteForm
              userId={userId}
              companyId={companyId}
              token={token}
              invite={invite}
            />
          ) : (
            <AdminInviteForm userId={userId} token={token} invite={invite} />
          )}
        </View>
      </View>
    </ScrollView>
  );
};

export default InvitationScreen;
