import AsyncStorage from '@react-native-async-storage/async-storage';
import { Cache } from 'react-native-cache';

const cacheHelper = new Cache({
  namespace: 'zentia.cache',
  policy: {
    maxEntries: 0,
    stdTTL: 60 * 10, // seconds
  },
  backend: AsyncStorage,
});

export const cache = {
  async get<T>(key: string): Promise<T | undefined> {
    const json = await cacheHelper.get(key);
    if (!json) return undefined;
    return JSON.parse(json);
  },

  async set<T>(key: string, value: T) {
    await cacheHelper.set(key, JSON.stringify(value));
  },
};
