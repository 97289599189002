import { API_BASE_URL } from '../env-consts';
import { CommentResponse } from '../shared/types/comments';
import { PaginatedResult } from '../shared/types/paginatedResult';
import {
  CreateSnagCommentPayload,
  CreateSnagPayload,
  SnagImageResponse,
  SnagResponse,
  UpdateSnagPayload,
} from '../shared/types/snags';
import { WpNews } from '../shared/types/wordpress';
import ApiClient from './ApiClient';

const client = new ApiClient(`${API_BASE_URL}/projects`);

export const SnagClient = {
  async create(projectId: string, payload: CreateSnagPayload) {
    return client
      .post<SnagResponse>(`/${projectId}/snags`, payload)
      .then(({ data }) => data);
  },

  async getAllByProjectId(projectId: string, page: number) {
    return client
      .get<PaginatedResult<SnagResponse>>(`/${projectId}/snags`, {
        params: { page },
      })
      .then(({ data }) => data);
  },

  async getById(projectId: string, snagId: string) {
    return client
      .get<SnagResponse>(`/${projectId}/snags/${snagId}`)
      .then(({ data }) => data);
  },

  async completeSnag(projectId: string, snagId: string) {
    return client
      .post<SnagResponse>(`/${projectId}/snags/${snagId}/complete`)
      .then(({ data }) => data);
  },

  async update(projectId: string, snagId: string, payload: UpdateSnagPayload) {
    return client
      .patch<SnagResponse>(`/${projectId}/snags/${snagId}`, payload)
      .then(({ data }) => data);
  },

  async addImage(projectId: string, snagId: string, file: File) {
    const data = new FormData();
    data.append('file', file, file.name);

    return client
      .post<SnagImageResponse>(`/${projectId}/snags/${snagId}/image`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(({ data }) => data);
  },

  async deleteImage(projectId: string, snagId: string, imageId: string) {
    return client
      .delete<void>(`/${projectId}/snags/${snagId}/image/${imageId}`, {})
      .then(({ data }) => data);
  },

  async getComments(projectId: string, snagId: string, page: number) {
    return client
      .get<PaginatedResult<CommentResponse>>(
        `/${projectId}/snags/${snagId}/comments`,
        {
          params: {
            page,
          },
        }
      )
      .then(({ data }) => data);
  },

  async createComment(
    projectId: string,
    snagId: string,
    payload: CreateSnagCommentPayload
  ) {
    return client
      .post<CommentResponse>(`/${projectId}/snags/${snagId}/comments`, payload)
      .then(({ data }) => data);
  },
};
