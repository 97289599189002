import React from 'react';
import { View } from 'react-native';
import { ClassInput } from 'twrnc/dist/esm/types';

import tw from '../../config/tailwind';

interface RowProps {
  children: React.ReactNode;
  style?: ClassInput;
}

const Row: React.FC<RowProps> = ({ children, style }) => {
  return (
    <View style={tw.style(`flex flex-row -mx-3 flex-wrap`, style)}>
      {children}
    </View>
  );
};

export default Row;
