import { API_BASE_URL } from '../env-consts';
import {
  CreateFavouritePayload,
  FavouriteResponse,
  FavouriteType,
  UpdateFavouriteTagsPayload,
} from '../shared/types/favourites';
import { PaginatedResult } from '../shared/types/paginatedResult';
import { WpNews } from '../shared/types/wordpress';
import ApiClient from './ApiClient';

const client = new ApiClient(`${API_BASE_URL}/favourites`);

export const FavouritesClient = {
  async create(payload: CreateFavouritePayload) {
    return client.post<FavouriteResponse>(``, payload).then(({ data }) => data);
  },

  async getAll(page: number, perPage = 50, type?: FavouriteType) {
    return client
      .get<PaginatedResult<FavouriteResponse>>(``, {
        params: {
          page,
          perPage,
          type,
        },
      })
      .then(({ data }) => data);
  },

  async getById(favouriteId: string) {
    return client
      .get<FavouriteResponse>(`/${favouriteId}`)
      .then(({ data }) => data);
  },

  async delete(favouriteId: string) {
    return client.delete(`/${favouriteId}`).then(() => {});
  },

  async setTags(favouriteId: string, payload: UpdateFavouriteTagsPayload) {
    return client
      .put<FavouriteResponse>(`/${favouriteId}/tags`, payload)
      .then(({ data }) => data);
  },
};
