import { Dimensions } from 'react-native';

export const useContainer = () => {
  const windowWidth = Dimensions.get('window').width;
  const breakpoints = [1536, 1280 /*1024 , 768, 640*/];
  const currentBreakpoint =
    breakpoints.find((breakpoint) => breakpoint < windowWidth) ||
    breakpoints[breakpoints.length - 1];

  const minMargin = 16;
  const margin =
    Math.max(minMargin, (windowWidth - currentBreakpoint) / 2) + 'px';

  return [margin, currentBreakpoint] as const;
};
