import React, { FC, ReactElement, useEffect, useRef, useState } from 'react';
import {
  ActivityIndicator,
  Animated,
  Modal,
  Pressable,
  ScrollView,
  TextInput,
  TouchableWithoutFeedback,
  useWindowDimensions,
  View,
} from 'react-native';
import { useHover } from 'react-native-web-hooks';
import { ClassInput } from 'twrnc/dist/esm/types';

import tw from '../../config/tailwind';
import DoubleChevronRightSVG from '../svg/DoubleChevronRightSVG';
import InputLabel from './InputLabel';
import Text from './Text';

interface SelectProps<ValueType> {
  selectedValue: ValueType | undefined;
  onValueChange: (itemValue: ValueType, itemIndex: number) => void;
  label?: string;
  required?: boolean;
  placeholderOption?: string;
  hasError?: boolean;
  labelSize?: 'sm' | 'md';
  options?: {
    label: string;
    value: ValueType;
  }[];
  searchable?: boolean;
  style?: ClassInput;
  loading?: boolean;
}

const Select = <ItemType extends unknown>({
  selectedValue,
  onValueChange,
  label,
  required,
  placeholderOption = '- Select -',
  hasError,
  labelSize,
  options,
  searchable,
  style,
  loading,
}: SelectProps<ItemType>): ReactElement | null => {
  const [showSelectMenu, setShowSelectMenu] = useState(false);
  const [search, setSearch] = useState('');
  const chevronAnim = useRef(new Animated.Value(0)).current;
  const dropdownRef = useRef<View>(null);
  const textInputRef = useRef<TextInput>(null);
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const dimensions = useWindowDimensions();
  const [selectMaxHeight, setSelectMaxHeight] = useState<number>(0);

  useEffect(() => {
    if (dropdownRef.current) {
      dropdownRef.current.measureInWindow((left, top, width, height) => {
        setTop(top);
        setLeft(left);
        setWidth(width);
        setHeight(height);

        const defaultMaxHeight = 5 * height;
        const containerOffsetY = top + height + defaultMaxHeight;
        const offsetBottom = dimensions.height - containerOffsetY;

        setSelectMaxHeight(
          defaultMaxHeight - Math.abs(Math.min(0, offsetBottom))
        );
      });
    }
  }, [top, left, width, dimensions, showSelectMenu]);

  useEffect(() => {
    Animated.timing(chevronAnim, {
      toValue: showSelectMenu ? 1 : 0,
      duration: 200,
      useNativeDriver: true,
    }).start();

    if (showSelectMenu) {
      setSearch('');
      setTimeout(() => {
        textInputRef.current?.focus();
      }, 100);
    }
  }, [showSelectMenu]);

  useEffect(() => {
    if (loading && showSelectMenu) {
      setShowSelectMenu(false);
    }
  }, [loading]);

  const onValueClick = (value: any, index: number) => {
    onValueChange(value, index);
    setShowSelectMenu(false);
  };

  const showPlaceholder = selectedValue === undefined;

  return (
    <View>
      {label && (
        <InputLabel label={label} required={required} labelSize={labelSize} />
      )}
      <View style={tw`flex-row`}>
        <Pressable
          disabled={loading}
          ref={dropdownRef}
          onPress={() => setShowSelectMenu(true)}
          style={tw.style(
            `bg-white px-4 py-3 mb-4 font-sans border-2 h-[50px] flex flex-row items-center min-w-48 flex-1`,
            hasError ? 'border-red' : 'border-blue',
            style
          )}
        >
          {(!showSelectMenu || !searchable) && (
            <Text
              style={tw.style(`text-xs mr-4`, showPlaceholder && `text-grey`, {
                fontSize: '14px', // TODO: this matches other inputs, update to correct utility class
              })}
            >
              {showPlaceholder
                ? placeholderOption
                : options
                    ?.find((x) => x.value === selectedValue)
                    ?.label.toString()}
            </Text>
          )}

          <Animated.View
            style={{
              ...tw`ml-auto`,
              transform: [
                {
                  rotate: chevronAnim.interpolate({
                    inputRange: [0, 1],
                    outputRange: ['90deg', '-90deg'],
                  }),
                },
              ],
            }}
          >
            <DoubleChevronRightSVG style={tw`text-green`} />
          </Animated.View>
        </Pressable>
        {loading && <ActivityIndicator style={tw`ml-2.5`} />}
      </View>

      <Modal
        transparent
        visible={showSelectMenu}
        onDismiss={() => setShowSelectMenu(false)}
      >
        <Pressable style={tw`flex-1`} onPress={() => setShowSelectMenu(false)}>
          {searchable && (
            <TouchableWithoutFeedback>
              <TextInput
                ref={textInputRef}
                autoFocus
                value={search}
                onChangeText={setSearch}
                style={tw.style(`absolute font-sans px-4 py-3`, {
                  top,
                  left,
                  height,
                  width,
                })}
                placeholder='Type to search...'
              />
            </TouchableWithoutFeedback>
          )}
          <ScrollView
            style={tw.style(`absolute bg-white mt-0.5 shadow-md`, {
              width,
              top: top + height,
              left,
              maxHeight: selectMaxHeight,
            })}
          >
            {options?.map(
              (option, i) =>
                (search === '' ||
                  option.label
                    ?.toLowerCase()
                    .includes(search.toLowerCase())) && (
                  <SelectOption
                    label={option.label}
                    value={option.value}
                    index={i}
                    key={i}
                    setValue={onValueClick}
                  />
                )
            )}
            {options?.filter(
              (option) =>
                search === '' ||
                option.label?.toLowerCase().includes(search.toLowerCase())
            ).length === 0 && (
              <Text style={tw`text-center p-4 text-sm`}>
                No options match your search...
              </Text>
            )}
          </ScrollView>
        </Pressable>
      </Modal>
    </View>
  );
};

type SelectOptionProps = {
  label: string;
  value: any;
  index: number;
  setValue: (v: any, index: number) => void;
};

const SelectOption: FC<SelectOptionProps> = ({
  label,
  value,
  index,
  setValue,
}) => {
  const viewRef = useRef<View>(null);
  const isHovered = useHover(viewRef);

  return (
    <Pressable
      ref={viewRef}
      style={tw.style(`h-[32px] px-4 justify-center`, {
        backgroundColor: (isHovered && tw.color('bg-grey-100')) || '',
      })}
      onPress={() => setValue(value, index)}
    >
      <Text>{label}</Text>
    </Pressable>
  );
};

export default Select;
