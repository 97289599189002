import React from 'react';
import { ScrollView, View } from 'react-native';

import ArrowUpBgSVG from '../components/svg/ArrowUpBgSVG';
import PinnacleLogoSVG from '../components/svg/PinnacleLogoSVG';
import tw from '../config/tailwind';
import { useContainer } from '../hooks/useContainer';

interface LoginLayoutProps {}

const LoginLayout: React.FC<LoginLayoutProps> = ({ children }) => {
  const [margin] = useContainer();

  return (
    <View style={tw`max-w-screen overflow-hidden`}>
      <View
        style={tw.style(`flex flex-none flex-row h-screen bg-white`, {
          marginLeft: margin,
          marginRight: margin,
        })}
      >
        <View style={tw`sm:w-4/12 w-full sm:min-w-[430px]`}>
          <View
            style={tw`absolute top-0 right-0 w-screen h-screen bg-white -mx-4 sm:mx-0`}
          />
          <ScrollView style={tw`-mx-4 sm:mx-0`}>
            <View style={tw`pl-8 pr-28 pt-24  flex flex-col`}>
              <PinnacleLogoSVG style={tw`mb-20 z-10`} />
              <View style={tw`flex-none mb-10`}>{children}</View>
            </View>
          </ScrollView>
        </View>

        <View
          style={tw.style(`relative flex-1 hidden sm:flex bg-blue`, {
            marginRight: `-${margin}`,
          })}
        >
          <ArrowUpBgSVG style={tw`absolute inset-0 w-full h-full text-green`} />
        </View>
      </View>
    </View>
  );
};

export default LoginLayout;
