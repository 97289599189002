import { Formik } from 'formik';
import React from 'react';
import { ActivityIndicator, View } from 'react-native';
import * as Yup from 'yup';
import { SnagClient, TaskClient } from '../../../api';
import { LeadClient } from '../../../api/LeadClient';

import tw from '../../../config/tailwind';
import { useToasts } from '../../../hooks/useToasts';
import { CommentResponse } from '../../../shared/types/comments';
import { LeadResponse } from '../../../shared/types/leads';
import { SnagResponse } from '../../../shared/types/snags';
import {
  CreateTaskCommentPayload,
  TaskResponse,
} from '../../../shared/types/tasks';
import Button from '../../shared/Button';
import DefaultModal from '../../shared/DefaultModal';
import InputText from '../../shared/InputText';
import InputValidationMessage from '../../shared/InputValidationMessage';

interface AddCommentModalProps {
  task: TaskResponse | SnagResponse | LeadResponse;
  type: 'snag' | 'task' | 'lead';
  visible: boolean;
  setVisible: (v: boolean) => void;
  onCommentAdded: (comment: CommentResponse) => void;
}

const AddCommentModal: React.FC<AddCommentModalProps> = ({
  task,
  type,
  visible,
  setVisible,
  onCommentAdded,
}) => {
  const { addToast, addUnhandledErrorToast } = useToasts();

  const initialValues: CreateTaskCommentPayload = {
    comment: '',
  };

  const schema: Yup.SchemaOf<typeof initialValues> = Yup.object().shape({
    comment: Yup.string().required(),
  });

  const onSubmit = async (payload: CreateTaskCommentPayload) => {
    try {
      let comment: CommentResponse;
      if (type === 'task') {
        comment = await TaskClient.createComment(
          task.project.id,
          task.id,
          payload
        );
      } else if (type === 'snag') {
        comment = await SnagClient.createComment(
          task.project.id,
          task.id,
          payload
        );
      } else {
        comment = await LeadClient.createComment(task.id, payload);
      }
      addToast({
        title: 'Comment saved',
        description: `You added a comment to the ${
          type === 'snag' ? 'issue' : type === 'lead' ? 'lead' : 'task'
        } '${task.name}'`,
        type: 'success',
      });

      setVisible(false);
      onCommentAdded(comment);
    } catch (e) {
      addUnhandledErrorToast(e);
    }
  };

  return (
    <DefaultModal
      setVisible={setVisible}
      visible={visible}
      title='Create a comment'
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={schema}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          values,
          errors,
          isSubmitting,
        }) => (
          <View style={tw`mt-4`}>
            <InputText
              placeholder='Type your comment'
              label='Comment'
              required
              value={values.comment}
              setValue={handleChange('comment')}
              onBlur={handleBlur('comment')}
              hasError={!!errors.comment}
              multiline
            />
            {errors.comment && (
              <InputValidationMessage>{errors.comment}</InputValidationMessage>
            )}
            <View style={tw`flex flex-row items-center justify-end mt-4`}>
              {isSubmitting && <ActivityIndicator style={tw`mr-4`} />}
              <Button onPress={handleSubmit} disabled={isSubmitting}>
                Save comment
              </Button>
            </View>
          </View>
        )}
      </Formik>
    </DefaultModal>
  );
};

export default AddCommentModal;
