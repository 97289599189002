import axios from 'axios';
import { Formik } from 'formik';
import React from 'react';
import { ActivityIndicator, View } from 'react-native';
import * as Yup from 'yup';

import { CompanyClient } from '../../../api';
import tw from '../../../config/tailwind';
import { useToasts } from '../../../hooks/useToasts';
import {
  CompanyResponse,
  CreateCompanyPayload,
} from '../../../shared/types/companies';
import Button from '../../shared/Button';
import Col from '../../shared/Col';
import DefaultModal from '../../shared/DefaultModal';
import InputText from '../../shared/InputText';
import InputValidationMessage from '../../shared/InputValidationMessage';
import Row from '../../shared/Row';

interface CreateCompanyModalProps {
  visible: boolean;
  setVisible: (v: boolean) => void;
  onCompanyAdded: (company: CompanyResponse) => void;
}

const CreateCompanyModal: React.FC<CreateCompanyModalProps> = ({
  visible,
  setVisible,
  onCompanyAdded,
}) => {
  const { addToast, addUnhandledErrorToast } = useToasts();

  const initialValues: CreateCompanyPayload = {
    name: '',
  };

  const schema: Yup.SchemaOf<CreateCompanyPayload> = Yup.object().shape({
    name: Yup.string().required(),
  });

  const onSubmit = async (values: CreateCompanyPayload) => {
    try {
      const newCompany = await CompanyClient.create(values);

      addToast({
        title: 'Company created',
        description: `We've created the company '${newCompany.name}'`,
        type: 'success',
      });

      onCompanyAdded(newCompany);
      setVisible(false);
    } catch (e) {
      if (axios.isAxiosError(e) && e.response?.status === 409) {
        addToast({
          title: 'Duplicate company name',
          description:
            'A company with this name already exists, please try something else.',
          type: 'error',
        });
      } else {
        addUnhandledErrorToast(e);
      }
    }
  };

  return (
    <DefaultModal
      setVisible={setVisible}
      visible={visible}
      title='Create a new company'
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={schema}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          values,
          errors,
          isSubmitting,
        }) => (
          <View style={tw`mt-4`}>
            <Row>
              <Col style={tw`w-1/2`}>
                <InputText
                  value={values.name}
                  setValue={handleChange('name')}
                  onBlur={handleBlur('name')}
                  placeholder='Name'
                  hasError={!!errors.name}
                  label='Name'
                  required
                />
                {errors.name && (
                  <InputValidationMessage>{errors.name}</InputValidationMessage>
                )}
              </Col>
            </Row>
            <View style={tw`flex flex-row items-center justify-end mt-4`}>
              {isSubmitting && <ActivityIndicator style={tw`mr-4`} />}
              <Button onPress={handleSubmit} disabled={isSubmitting}>
                Create
              </Button>
            </View>
          </View>
        )}
      </Formik>
    </DefaultModal>
  );
};

export default CreateCompanyModal;
