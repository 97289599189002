import * as Location from 'expo-location';

import { LatLng } from '../@types/geolocation';

export const getUserLocation = async (): Promise<LatLng | undefined> => {
  const hasPermission = await getPermission();
  if (!hasPermission) return;

  const location = await Location.getLastKnownPositionAsync().then(
    async (res) => {
      if (!res) {
        return await Location.getCurrentPositionAsync();
      }
      return res;
    }
  );

  return {
    lat: location.coords.latitude,
    long: location.coords.longitude,
  };
};

export const getPermission = async (): Promise<boolean> => {
  return await Location.getForegroundPermissionsAsync().then(async (res) => {
    return res.status === Location.PermissionStatus.GRANTED;
  });
};

export function getDistanceFromLatLonInKm(
  location1: LatLng,
  location2: LatLng
): number {
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(location2.lat - location1.lat); // deg2rad below
  const dLon = deg2rad(location2.long - location1.long);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(location1.lat)) *
      Math.cos(deg2rad(location2.long)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in km
  return d;
}

export function getDistanceFromLatLonInMiles(
  location1: LatLng,
  location2: LatLng
): number {
  const KM_TO_MILE = 1.60934;
  return getDistanceFromLatLonInKm(location1, location2) / KM_TO_MILE;
}

function deg2rad(deg: number): number {
  return deg * (Math.PI / 180);
}
