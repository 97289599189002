export enum LeadStatus {
  Closed = 'CLOSED',
  Complete = 'COMPLETE',
  InProgress = 'IN_PROGRESS',
  Open = 'OPEN',
}

export enum LeadRegion {
  SouthEast = 'South East',
  London = 'London',
  NorthWest = 'North West',
  EastOfEngland = 'East of England',
  WestMidlands = 'West Midlands',
  SouthWest = 'South West',
  YorkshireAndTheHumber = 'Yorkshire and the Humber',
  EastMidlands = 'East Midlands',
  NorthEast = 'North East',
  Scotland = 'Scotland',
  Wales = 'Wales',
  NorthernIreland = 'Northern Ireland' 
}

export enum LeadSourceType {
  PinnaclePartner = 'Pinnacle Partner',
  Others = 'Others'
}

export interface LeadUserResponse {
  id: string;
  firstName: string;
  lastName: string;
  businessName: string;
  email: string;
}

export interface LeadStat {
  status: LeadStatus;
  value: number;
}

export interface LeadProject {
  id: string;
  companyId: string;
  name: string;
  location: string;
  caseStudyOptOut: boolean;
}

export interface LeadResponse {
  id: string;
  project?: LeadProject;
  name: string;
  description: string;
  source?: string;
  status: LeadStatus;
  region: LeadRegion;
  addressLine1: string;
  addressLine2: string;
  addressCity: string;
  addressPostCode: string;
  phone: string;
  createdAt: Date;
  updatedAt: Date;
  updatedByUserId?: string;
  updatedByUser?: LeadUserResponse;
  pinnaclePartner?: LeadUserResponse;
  companyId?: string;
}

export interface CreateLeadCommentPayload {
  comment: string;
}

export interface CreateLeadPayload {
  projectId?: string | null;
  name: string;
  description: string;
  otherSource?: boolean;
  source?: string | null;
  status: LeadStatus;
  region?: LeadRegion;
  addressLine1: string;
  addressLine2: string;
  addressCity: string;
  addressPostCode: string;
  phone: string;
  pinnaclePartnerId?: string | undefined | null;
  companyId?: string | undefined | null;
}

export interface UpdateLeadPayload extends CreateLeadPayload{}

export interface LeadSmallResponse {
  id: string;
  name: string;
  description: string;
  status: LeadStatus;
  createdAt: Date;
  updatedAt: Date;
  completedByUserId: string;
  completedAt: Date;
}
