import { Formik } from 'formik';
import React, { useState } from 'react';
import { View } from 'react-native';
import * as Yup from 'yup';
import { FormClient } from '../../api';

import tw from '../../config/tailwind';
import { TRAINING_NUMBER_OF_ATTENDEE_OPTIONS } from '../../constants';
import Button from '../shared/Button';
import Col from '../shared/Col';
import ContentWrapper from '../shared/ContentWrapper';
import DatePicker from '../shared/DatePicker';
import H2 from '../shared/H2';
import H3 from '../shared/H3';
import InputText from '../shared/InputText';
import InputValidationMessage from '../shared/InputValidationMessage';
import P from '../shared/P';
import Row from '../shared/Row';
import Select from '../shared/Select';

interface RequestARigFormProps {}

const REQUEST_A_RIG_NUM_OF_ATTENDEE_OPTIONS = ['1', '2', '3', '4', '5', '6'];

type FormValues = {
  numberOfAttendees: number;
  preferredDate: Date;
  attendees: string[];
};

const schema: Yup.SchemaOf<FormValues> = Yup.object().shape({
  numberOfAttendees: Yup.number()
    .required()
    .label('Number of attendees')
    .moreThan(0),
  preferredDate: Yup.date().required().label('Preferred date'),
  attendees: Yup.array().test(
    'attendeesTest',
    'Please fill in all attendee information.',
    function (value) {
      return value
        ? value.filter((v) => !!v).length >=
            Math.max(1, this.parent.numberOfAttendees)
        : false;
    }
  ),
});

const RequestARigForm: React.FC<RequestARigFormProps> = ({}) => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const initialValues: FormValues = {
    numberOfAttendees: 0,
    preferredDate: new Date(),
    attendees: [],
  };

  const onSubmit = async (formValues: typeof initialValues) => {
    await FormClient.requestARig({
      ...formValues,
      // TODO: validate date properly
      preferredDate: (() => {
        const date = new Date(formValues.preferredDate);
        return date instanceof Date && !isNaN(date.getTime())
          ? date
          : new Date();
      })(),
    });
    setFormSubmitted(true);
  };

  if (formSubmitted) {
    return (
      <ContentWrapper style={tw`min-height-[300px]`}>
        <H3>Thank you for your request</H3>
        <P>A member of the Zentia team will be in touch</P>
      </ContentWrapper>
    );
  }

  return (
    <ContentWrapper>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={schema}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          values,
          errors,
        }) => (
          <View>
            <H2>Book a rig</H2>
            <P>
              Select your preferred booking date and we'll get back to you as
              soon as possible to confirm.
            </P>

            <View style={tw`min-h-[343px]`}>
              <Row style={tw`mt-3`}>
                <Col style={tw`w-full sm:w-1/2`}>
                  <DatePicker
                    value={values.preferredDate}
                    setValue={(date) => setFieldValue('preferredDate', date)}
                    onBlur={handleBlur('preferredDate')}
                    placeholder='Date'
                    hasError={!!errors.preferredDate}
                    label='Preferred Date'
                    labelSize='md'
                    min={new Date()}
                  />
                  {errors.preferredDate && (
                    <InputValidationMessage>
                      {errors.preferredDate}
                    </InputValidationMessage>
                  )}
                </Col>
                <Col style={tw`w-full sm:w-1/2`}>
                  <Select
                    label='Number of attendees'
                    labelSize='md'
                    onValueChange={(v) => {
                      const value = parseInt(v, 10);
                      setFieldValue(
                        'numberOfAttendees',
                        isNaN(value) ? 0 : value
                      );

                      setFieldValue(
                        'attendees',
                        Array.of(
                          ...values.attendees,
                          ...Array(
                            Math.max(0, value - values.attendees.length)
                          ).fill('')
                        ).slice(0, value)
                      );
                    }}
                    selectedValue={
                      values.numberOfAttendees > 0
                        ? values.numberOfAttendees.toString()
                        : undefined
                    }
                    required
                    options={REQUEST_A_RIG_NUM_OF_ATTENDEE_OPTIONS.map(
                      (item) => ({
                        label: item,
                        value: item,
                      })
                    )}
                  />
                  {errors.numberOfAttendees && (
                    <InputValidationMessage>
                      {errors.numberOfAttendees}
                    </InputValidationMessage>
                  )}
                </Col>
              </Row>
              {values.numberOfAttendees > 0 && (
                <>
                  {Array(values.numberOfAttendees)
                    .fill({})
                    .map((v, i) => (
                      <Row key={i}>
                        <Col style={tw`w-full`}>
                          <InputText
                            value={values.attendees[i] || ''}
                            setValue={(v) => {
                              const attendees = ((current) =>
                                current.map((a, _i) => (_i === i ? v : a)))(
                                values.attendees
                              );
                              setFieldValue('attendees', attendees);
                            }}
                            placeholder='Attendee Name'
                            hasError={
                              !!errors.attendees && !values.attendees[i]
                            }
                            label='Attendee Name'
                            required
                          />
                        </Col>
                      </Row>
                    ))}
                  {errors.attendees && (
                    <InputValidationMessage>
                      {errors.attendees}
                    </InputValidationMessage>
                  )}
                </>
              )}
              <Button onPress={handleSubmit} style={tw`mt-5 mb-4`}>
                Submit enquiry
              </Button>
            </View>
          </View>
        )}
      </Formik>
    </ContentWrapper>
  );
};

export default RequestARigForm;
