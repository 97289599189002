import { Picker } from '@react-native-picker/picker';
import axios from 'axios';
import { Formik } from 'formik';
import React from 'react';
import { ActivityIndicator, View } from 'react-native';
import * as Yup from 'yup';

import { CompanyUserClient } from '../../../api';
import tw from '../../../config/tailwind';
import { COMPANY_USER_ROLE_LABELS } from '../../../constants';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { useToasts } from '../../../hooks/useToasts';
import {
  CompanyResponse,
  CompanyUserRole,
  CreateCompanyUserPayload,
} from '../../../shared/types/companies';
import { UserRole } from '../../../shared/types/users';
import Button from '../../shared/Button';
import Col from '../../shared/Col';
import DefaultModal from '../../shared/DefaultModal';
import InputText from '../../shared/InputText';
import InputValidationMessage from '../../shared/InputValidationMessage';
import Row from '../../shared/Row';
import Select from '../../shared/Select';

interface CreateCompanyUserModalProps {
  visible: boolean;
  setVisible: (v: boolean) => void;
  company: CompanyResponse;
  onUserAdded: () => void;
}

const CreateCompanyUserModal: React.FC<CreateCompanyUserModalProps> = ({
  visible,
  setVisible,
  company,
  onUserAdded,
}) => {
  const { addToast, addUnhandledErrorToast } = useToasts();
  const { user } = useAuthContext();

  const initialValues: CreateCompanyUserPayload = {
    email: '',
    businessName: '',
    firstName: '',
    lastName: '',
    role: CompanyUserRole.Contractor,
  };

  const schema: Yup.SchemaOf<CreateCompanyUserPayload> = Yup.object().shape({
    email: Yup.string().required().email().label('Email'),
    businessName: Yup.string().required().min(3).label('Business Name'),
    firstName: Yup.string().required().label('First Name'),
    lastName: Yup.string().required().label('Last Name'),
    role: Yup.mixed()
      .required()
      .oneOf(Object.values(CompanyUserRole))
      .label('Role'),
  });

  const onSubmit = async (values: CreateCompanyUserPayload) => {
    try {
      await CompanyUserClient.inviteUser(company.id, values);

      addToast({
        title: 'Company user invited',
        description: `We've sent an invite to ${values.email}`,
        type: 'success',
      });

      onUserAdded();
      setVisible(false);
    } catch (e) {
      if (axios.isAxiosError(e) && e.response?.status === 409) {
        addToast({
          title: 'Invite failed: Duplicate user',
          description: 'You have already invited this user.',
          type: 'error',
        });
      } else {
        addUnhandledErrorToast(e);
      }
    }
  };

  return (
    <DefaultModal
      setVisible={setVisible}
      visible={visible}
      title='Invite company user'
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={schema}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          values,
          errors,
          isSubmitting,
        }) => (
          <View style={tw`mt-4`}>
            <Row>
              <Col style={tw`w-1/2`}>
                <InputText
                  value={values.firstName}
                  setValue={handleChange('firstName')}
                  onBlur={handleBlur('firstName')}
                  placeholder='First Name'
                  hasError={!!errors.firstName}
                  label='First Name'
                  required
                />
                {errors.firstName && (
                  <InputValidationMessage>
                    {errors.firstName}
                  </InputValidationMessage>
                )}
              </Col>
              <Col style={tw`w-1/2`}>
                <InputText
                  value={values.lastName}
                  setValue={handleChange('lastName')}
                  onBlur={handleBlur('lastName')}
                  placeholder='Last Name'
                  hasError={!!errors.lastName}
                  label='Last Name'
                  required
                />
                {errors.lastName && (
                  <InputValidationMessage>
                    {errors.lastName}
                  </InputValidationMessage>
                )}
              </Col>
              <Col style={tw`w-1/2`}>
                <InputText
                  value={values.email}
                  setValue={handleChange('email')}
                  onBlur={handleBlur('email')}
                  placeholder='Email Address'
                  hasError={!!errors.email}
                  label='Email Address'
                  required
                />
                {errors.email && (
                  <InputValidationMessage>
                    {errors.email}
                  </InputValidationMessage>
                )}
              </Col>
              <Col style={tw`w-1/2`}>
                <InputText
                  value={values.businessName}
                  setValue={handleChange('businessName')}
                  onBlur={handleBlur('businessName')}
                  placeholder='Business Name'
                  hasError={!!errors.businessName}
                  label='Business Name'
                  required
                />
                {errors.businessName && (
                  <InputValidationMessage>
                    {errors.businessName}
                  </InputValidationMessage>
                )}
              </Col>
              {user!.role === UserRole.Admin && (
                <Col>
                  <Select
                    selectedValue={values.role}
                    onValueChange={(iValue, iIndex) =>
                      handleChange('role')(iValue)
                    }
                    label='Select a company role'
                    required
                    hasError={!!errors.role}
                    options={Object.values(CompanyUserRole).map((item) => ({
                      label: COMPANY_USER_ROLE_LABELS[item],
                      value: item,
                    }))}
                  />
                  {errors.role && (
                    <InputValidationMessage>
                      {errors.role}
                    </InputValidationMessage>
                  )}
                </Col>
              )}
            </Row>
            <View style={tw`flex flex-row items-center justify-end mt-4`}>
              {isSubmitting && <ActivityIndicator style={tw`mr-4`} />}
              <Button onPress={handleSubmit} disabled={isSubmitting}>
                Invite user
              </Button>
            </View>
          </View>
        )}
      </Formik>
    </DefaultModal>
  );
};

export default CreateCompanyUserModal;
