import { Formik } from "formik";
import { useCallback, useEffect, useState } from "react";
import { ActivityIndicator, View } from "react-native";
import * as Yup from 'yup';
import { CompanyClient, ProjectClient, UserClient } from "../../api";
import tw from "../../config/tailwind";
import { phoneRegExp, postcodeRegExp } from '../../constants';
import { CompanyResponse } from "../../shared/types/companies";
import { CreateLeadPayload, LeadRegion, LeadSourceType, LeadStatus, UpdateLeadPayload } from "../../shared/types/leads";
import { ProjectResponse } from "../../shared/types/projects";
import { UserResponse } from "../../shared/types/users";
import Button from '../shared/Button';
import Col from "../shared/Col";
import InputText from "../shared/InputText";
import InputValidationMessage from "../shared/InputValidationMessage";
import Row from "../shared/Row";
import Select from "../shared/Select";

interface Props {
    initialValues: CreateLeadPayload | UpdateLeadPayload;
    onSubmit: (values: CreateLeadPayload) => void;
    submitText: string;
}

const getRequiredText = (fieldName: string) => {
    return `${fieldName} is a required field.`;
}

// for onSubmits to massage the payload on create and edit
export const massagePayload = (values: CreateLeadPayload | UpdateLeadPayload) => {
    const { otherSource, companyId, pinnaclePartnerId, projectId, source, ...data } = {...values};
    let payload: CreateLeadPayload | UpdateLeadPayload | null = null;
    if(otherSource){
      payload = {source, ...data} as typeof values;
    }else{
      payload = {companyId, pinnaclePartnerId, projectId, ...data} as typeof values;
    }

    return payload as typeof values;
}

const LeadForm: React.FC<Props> = ({
    initialValues,
    onSubmit,
    submitText
}) => {
    // const { addToast, addUnhandledErrorToast } = useToasts();
    const [companies, setCompanies] = useState<CompanyResponse[]>([]);
    const [users, setUsers] = useState<UserResponse[]>([]);
    const [selectedCompany, setSelectedCompany] = useState<string | undefined>(initialValues.companyId || undefined);
    const [projects, setProjects] = useState<ProjectResponse[]>([]);
    const [sourceType, setSourceType] = useState<LeadSourceType>(initialValues.otherSource ? LeadSourceType.Others : LeadSourceType.PinnaclePartner);

    const loadCompanies = async (page: number) => {
        // partners company api
        const res = await CompanyClient.getAll(page);
        setCompanies(c => [...c, ...res.items]);
        if (res.totalPages > page) {
            await loadCompanies(page + 1);
        }
    }

    const loadUserPage = useCallback(async (page: number) => {
        // just return empty array if no company selected
        if (!selectedCompany) {
            setUsers([])
            return
        }

        const res = await UserClient.getAll(page, undefined, false);
        setUsers((x) => [...x, ...res.items]);
        if (res.totalPages > page) {
            await loadUserPage(page + 1);
        }
    }, [selectedCompany])

    const loadProjectPage = useCallback(async (page: number) => {
        // just return empty array if no company selected
        if (!selectedCompany) {
            setProjects([])
            return
        }

        const res = await ProjectClient.getAll(selectedCompany, page, undefined);
        setProjects((x) => [...x, ...res.items]);
        if (res.totalPages > page) {
            await loadProjectPage(page + 1);
        }
    }, [selectedCompany]);

    const schema: Yup.SchemaOf<CreateLeadPayload | UpdateLeadPayload> = Yup.object().shape({
        name: Yup.string().required(getRequiredText('Name')),
        addressCity: Yup.string().required(getRequiredText('City')),
        addressLine1: Yup.string().required(getRequiredText('Address Line 1')),
        addressLine2: Yup.string().required(getRequiredText('Address Line 2')),
        addressPostCode: Yup.string()
            .required(getRequiredText('Postcode'))
            .matches(postcodeRegExp, 'Please provide a valid postcode'),
        description: Yup.string().required('Details is a required field'),
        phone: Yup.string()
            .required(getRequiredText('Phone Number'))
            .matches(phoneRegExp, 'Please provide a valid phone number'),
        status: Yup.mixed().required(getRequiredText('Status')).oneOf(Object.values(LeadStatus)),
        region: Yup.mixed().oneOf(Object.values(LeadRegion)),
        otherSource: Yup.boolean(),
        // these below are conditionals
        source: Yup.string().when(`otherSource`, {
            is: true,
            then: Yup.string().required("Source cannot be empty"),
            otherwise: Yup.string().nullable().notRequired()
        }),
        pinnaclePartnerId: Yup.string().when('otherSource', {
            is: false,
            then: Yup.string().required('Please select a Pinnacle Partner'),
            otherwise: Yup.string().nullable().notRequired()
        }),
        projectId: Yup.string().nullable().notRequired(),
        companyId: Yup.string().when('otherSource', {
            is: false,
            then: Yup.string().required('Please select a company'),
            otherwise: Yup.string().nullable().notRequired()
        }),
    });

    // when user choosen or change company id
    useEffect(() => {
        const reloadProjects = async () => {
            if(!selectedCompany){
                setProjects([]);
                setUsers([]);
                return
            }
            await loadProjectPage(1);
            await loadUserPage(1);
        }
        reloadProjects();
    }, [selectedCompany]);

    // only on load
    useEffect(() => {
        const firstLoad = async () => {
            await loadCompanies(1);
        }
        firstLoad();
    }, [initialValues]);

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={schema}
            validateOnChange={false}
            validateOnBlur={false}
        >
            {({
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                values,
                errors,
                isSubmitting,
            }) => (
                <View style={tw`mt-4`}>
                    <Row>
                        <Col style={tw`w-full`}>
                            <InputText
                                value={values.name}
                                setValue={handleChange('name')}
                                onBlur={handleBlur('name')}
                                placeholder='Name'
                                hasError={!!errors.name}
                                label='Name'
                                required
                            />
                            {errors.name && (
                                <InputValidationMessage absolute>
                                    {errors.name}
                                </InputValidationMessage>
                            )}
                        </Col>
                        <Col style={tw`w-1/2`}>
                            <InputText
                                value={values.addressLine1}
                                setValue={handleChange('addressLine1')}
                                onBlur={handleBlur('addressLine1')}
                                placeholder='Address line 1'
                                hasError={!!errors.addressLine1}
                                label='Address line 1'
                                required
                            />
                            {errors.addressLine1 && (
                                <InputValidationMessage absolute>
                                    {errors.addressLine1}
                                </InputValidationMessage>
                            )}
                        </Col>
                        <Col style={tw`w-1/2`}>
                            <InputText
                                value={values.addressLine2}
                                setValue={handleChange('addressLine2')}
                                onBlur={handleBlur('addressLine2')}
                                placeholder='Address line 2'
                                hasError={!!errors.addressLine2}
                                label='Address line 2'
                                required
                            />
                            {errors.addressLine2 && (
                                <InputValidationMessage absolute>
                                    {errors.addressLine2}
                                </InputValidationMessage>
                            )}
                        </Col>
                        <Col style={tw`w-1/2`}>
                            <InputText
                                value={values.addressCity}
                                setValue={handleChange('addressCity')}
                                onBlur={handleBlur('addressCity')}
                                placeholder='City'
                                hasError={!!errors.addressCity}
                                label='City'
                                required
                            />
                            {errors.addressCity && (
                                <InputValidationMessage absolute>
                                    {errors.addressCity}
                                </InputValidationMessage>
                            )}
                        </Col>
                        <Col style={tw`w-1/2`}>
                            <InputText
                                value={values.addressPostCode}
                                setValue={handleChange('addressPostCode')}
                                onBlur={handleBlur('addressPostCode')}
                                placeholder='Postcode'
                                hasError={!!errors.addressPostCode}
                                label='Postcode'
                                required
                            />
                            {errors.addressPostCode && (
                                <InputValidationMessage absolute>
                                    {errors.addressPostCode}
                                </InputValidationMessage>
                            )}
                        </Col>
                        <Col style={tw`w-1/2`}>
                            <Select
                                selectedValue={values.region}
                                onValueChange={(iValue, iIndex) =>
                                    handleChange('region')(iValue)
                                }
                                label='Region'
                                required
                                hasError={!!errors.region}
                                options={Object.values(LeadRegion).map((item) => ({
                                    label: item,
                                    value: item,
                                }))}
                            />
                            {errors.region && (
                                <InputValidationMessage absolute>
                                    {errors.region}
                                </InputValidationMessage>
                            )}
                        </Col>
                        <Col style={tw`w-1/2`}>
                            <InputText
                                value={values.phone}
                                setValue={handleChange('phone')}
                                onBlur={handleBlur('phone')}
                                placeholder='Phone'
                                hasError={!!errors.phone}
                                label='Phone'
                                required
                            />
                            {errors.phone && (
                                <InputValidationMessage absolute>
                                    {errors.phone}
                                </InputValidationMessage>
                            )}


                        </Col>

                        {/* source type selection */}
                        <Col style={tw`w-1/2`}>
                            <Select
                                selectedValue={sourceType}
                                onValueChange={(v) => {
                                    const isOther = v === LeadSourceType.Others;
                                    setSourceType(v)
                                    setFieldValue('otherSource', isOther);
                                    // needs to reset the company, source, partner and project fields
                                    setSelectedCompany(undefined);
                                    setFieldValue('companyId', undefined);
                                    setFieldValue('source', '');
                                    setFieldValue('pinnaclePartnerId', '');
                                    setFieldValue('projectId', '');
                                }}
                                label='Source Type'
                                required
                                options={Object.values(LeadSourceType).map(x => ({
                                    label: x,
                                    value: x
                                }))}
                            />
                        </Col>

                        {/* status */}
                        <Col style={tw`w-1/2`}>
                            <Select
                                selectedValue={values.status}
                                onValueChange={(iValue, iIndex) =>
                                    handleChange('status')(iValue)
                                }
                                label='Status'
                                required
                                hasError={!!errors.status}
                                options={Object.values(LeadStatus).map((item) => ({
                                    label: item,
                                    value: item,
                                }))}
                            />
                            {errors.status && (
                                <InputValidationMessage absolute>
                                    {errors.status}
                                </InputValidationMessage>
                            )}
                        </Col>

                        {
                            sourceType === LeadSourceType.Others ?
                                <>
                                    <Col style={tw`w-full`}>
                                        <InputText
                                            value={values.source || ''}
                                            setValue={handleChange('source')}
                                            onBlur={handleBlur('source')}
                                            placeholder='Source'
                                            hasError={!!errors.source}
                                            label='Source'
                                            required
                                        />
                                        {errors.source && (
                                            <InputValidationMessage absolute>
                                                {errors.source}
                                            </InputValidationMessage>
                                        )}
                                    </Col>
                                </>
                                :
                                <>
                                    <Col style={tw`w-full`}>
                                        <Select
                                            selectedValue={values.companyId}
                                            onValueChange={(v, i) => {
                                                if(v){
                                                    setFieldValue('companyId', v);
                                                    setSelectedCompany(v);
                                                }
                                            }}
                                            label='Pinnacle Partner'
                                            // required
                                            // searchable
                                            // hasError={!!errors.pinnaclePartnerId}
                                            options={companies.map((item) => ({
                                                label: `${item.name}`,
                                                value: item.id,
                                            }))}
                                        />
                                    </Col>

                                    <Col style={tw`w-1/2`}>
                                        <Select
                                            selectedValue={values.pinnaclePartnerId}
                                            onValueChange={(v, i) => {
                                                handleChange('pinnaclePartnerId')(v || '')
                                            }
                                            }
                                            label='Partner Contact'
                                            required
                                            // searchable
                                            hasError={!!errors.pinnaclePartnerId}
                                            options={users.filter(u => u.companyUsers.find(c => c.companyId === selectedCompany)).map((item) => ({
                                                label: item.email,
                                                value: item.id,
                                            }))}
                                        />
                                        {errors.pinnaclePartnerId && (
                                            <InputValidationMessage absolute>
                                                {errors.pinnaclePartnerId}
                                            </InputValidationMessage>
                                        )}
                                    </Col>
                                    <Col style={tw`w-1/2`}>
                                        <Select
                                            selectedValue={values.projectId}
                                            onValueChange={(v, i) =>
                                                handleChange('projectId')(v || '')
                                            }
                                            label='Project'
                                            // required
                                            hasError={!!errors.projectId}
                                            options={projects.map((item) => ({
                                                label: item.name,
                                                value: item.id,
                                            }))}
                                        />
                                        {errors.projectId && (
                                            <InputValidationMessage absolute>
                                                {errors.projectId}
                                            </InputValidationMessage>
                                        )}
                                    </Col>
                                </>
                        }

                        <Col style={tw`w-full`}>
                            <InputText
                                value={values.description}
                                setValue={handleChange('description')}
                                onBlur={handleBlur('description')}
                                placeholder='Details'
                                hasError={!!errors.description}
                                label='Details'
                                required
                                multiline
                                numberOfLines={3}
                            />
                            {errors.description && (
                                <InputValidationMessage absolute>
                                    {errors.description}
                                </InputValidationMessage>
                            )}
                        </Col>
                    </Row>
                    <View style={tw`flex flex-row items-center justify-end mt-4`}>
                        {isSubmitting && <ActivityIndicator style={tw`mr-4`} />}
                        <Button onPress={handleSubmit} disabled={isSubmitting}>
                            {submitText}
                        </Button>
                    </View>
                </View>
            )}
        </Formik>
    )
}

export default LeadForm;