import { useFocusEffect } from '@react-navigation/native';
import { format } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';
import { FeedbackClient } from '../../api';

import { useToasts } from '../../hooks/useToasts';
import { FeedbackResponse } from '../../shared/types/feedback';
import { PaginatedResult } from '../../shared/types/paginatedResult';
import GenericTable from '../shared/GenericTable';

interface AdminFeedbackTableProps {}

const AdminFeedbackTable: React.FC<AdminFeedbackTableProps> = ({}) => {
  const [users, setUsers] = useState<PaginatedResult<FeedbackResponse>>();
  const [page, setPage] = useState(1);
  const [refresh, setRefresh] = useState(0);
  const [inviteModalVisible, setInviteModalVisible] = useState(false);
  const { addToast, addUnhandledErrorToast } = useToasts();

  useEffect(() => {
    setUsers(undefined);
    FeedbackClient.getAll(page).then((results) => {
      setUsers(results);
    });
  }, [page, refresh]);

  useFocusEffect(
    useCallback(() => {
      setRefresh((old) => old + 1);
    }, [])
  );

  return (
    <>
      <GenericTable
        paginatedResult={users}
        searchPlaceholder='Search users'
        headers={{
          createdBy: {
            title: 'Created by',
            width: '25%',
            format: (user) =>
              `${user.firstName} ${user.lastName} (${user.email})`,
          },
          createdAt: {
            title: 'Created at',
            width: '35%',
            format: (date, row) =>
              format(new Date(date), 'dd / MM / yyyy') + '\n' + row.page,
          },
          message: {
            title: 'Message',
            width: '40%',
          },
        }}
        setPage={setPage}
      />
    </>
  );
};

export default AdminFeedbackTable;
