import { useFocusEffect } from '@react-navigation/native';
import React, { useCallback, useEffect, useState } from 'react';
import { Pressable } from 'react-native';

import { ContactClient } from '../../api';
import tw from '../../config/tailwind';
import { ContactResponse } from '../../shared/types/contacts';
import { PaginatedResult } from '../../shared/types/paginatedResult';
import Button from '../shared/Button';
import GenericTable from '../shared/GenericTable';
import GenericTableAction from '../shared/GenericTableAction';
import AdminContactModal from './modals/AdminContactModal';

interface AdminContactsTableProps {}

const AdminContactsTable: React.FC<AdminContactsTableProps> = () => {
  const [contacts, setContacts] = useState<PaginatedResult<ContactResponse>>();
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState('');
  const [refresh, setRefresh] = useState(0);
  const [modalActive, setModalActive] = useState(false);
  const [editContactId, setEditContactId] = useState<string>();

  useEffect(() => {
    if (refresh === 0) return;

    setContacts(undefined);
    ContactClient.findAll(query, page).then((results) => {
      setContacts(results);
    });
  }, [page, query, refresh]);

  useFocusEffect(
    useCallback(() => {
      setRefresh((old) => old + 1);
      return () => {
        setContacts(undefined);
      };
    }, [])
  );

  return (
    <>
      {modalActive && (
        <AdminContactModal
          onUpdated={() => setRefresh((x) => x + 1)}
          onDeleteUpdated={() => setRefresh((x) => x + 1)}
          setVisible={setModalActive}
          contact={
            editContactId
              ? contacts?.items.find((c) => c.id === editContactId)
              : undefined
          }
        />
      )}

      <Button
        style={tw`ml-auto mb-4`}
        onPress={() => {
          setEditContactId(undefined);
          setModalActive(true);
        }}
      >
        Add new contact
      </Button>

      <GenericTable
        paginatedResult={contacts}
        searchPlaceholder='Search contacts'
        headers={{
          name: {
            title: 'Name',
            width: '200px',
          },
          role: {
            title: 'Role',
            width: '200px',
          },
          email: {
            title: 'Email',
            width: '200px',
          },
        }}
        actions={(contact) => {
          return [
            <Pressable
              onPress={() => {
                setEditContactId(contact.id);
                setModalActive(true);
              }}
            >
              <GenericTableAction label='Edit' />
            </Pressable>,
          ];
        }}
        setPage={setPage}
        setQueryInput={setQuery}
      />
    </>
  );
};

export default AdminContactsTable;
