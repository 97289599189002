export default function getBase64(file: File) {
  return new Promise((r, e) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      r(reader.result);
    };
    reader.onerror = function (error) {
      e(error);
    };
  });
}
