import { PublicFileResponse } from './files';

export interface TrainingRecordResponse {
  id: string;
  title: string;
  completedOn: string;
  userId: string;
  createdByUserId: string;
  certificate: TrainingCertificateResponse | undefined;
}

export type TrainingCertificateResponse = PublicFileResponse & {
  id: string;
  trainingRecordId: string;
  userId: string;
};

export enum TrainingLocation {
  ZENTIA = 'ZENTIA',
  ON_SITE = 'ON_SITE',
  OTHER = 'OTHER',
}

export interface CreateTrainingRecordPayload {
  title: string;
  completedOn: Date;
  userId: string;
  companyId: string;
}

export interface UpdateTrainingRecordPayload {
  title?: string;
  completedOn?: Date;
}
