import { useFocusEffect } from '@react-navigation/native';
import React, { useCallback, useEffect, useState } from 'react';
import { View } from 'react-native';

import { ProjectClient } from '../../api';
import tw from '../../config/tailwind';
import { useAuthContext } from '../../hooks/useAuthContext';
import { PaginatedResult } from '../../shared/types/paginatedResult';
import {
  ProjectProductResponse,
  ProjectResponse,
} from '../../shared/types/projects';
import { decodeEntities } from '../../utils/decodeEntities';
import GenericTable from '../shared/GenericTable';
import AddRoomModal from './modals/AddRoomModal';

interface ProductListTableProps {
  project: ProjectResponse;
}
const ProductListTable: React.FC<ProductListTableProps> = ({ project }) => {
  const [page, setPage] = useState(1);
  const [refresh, setRefresh] = useState(0);
  const [showNewRoomModal, setShowNewRoomModal] = useState(false);

  const { user, activeCompany } = useAuthContext();

  const [paginatedResult, setPaginatedResult] =
    useState<PaginatedResult<ProjectProductResponse>>();

  useEffect(() => {
    ProjectClient.getProducts(project.id, page).then((result) => {
      setPaginatedResult(result);
    });
  }, [page, activeCompany?.id, refresh]);

  useFocusEffect(
    useCallback(() => {
      setRefresh((old) => old + 1);
    }, [])
  );

  return (
    <View style={tw`bg-white p-8 mb-6`}>
      <GenericTable
        paginatedResult={paginatedResult}
        headers={{
          name: {
            title: 'Product name',
            width: '60%',
            bold: true,
            format: (v) => decodeEntities(v),
          },
          roomName: {
            title: 'Room',
            width: '40%',
          },
        }}
        setPage={setPage}
      />

      <AddRoomModal
        visible={showNewRoomModal}
        project={project}
        setVisible={setShowNewRoomModal}
        onRoomAdded={() => setRefresh((x) => x + 1)}
      />
    </View>
  );
};

export default ProductListTable;
