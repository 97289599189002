import React from 'react';
import { ActivityIndicator, Text, View } from 'react-native';

import tw from '../../config/tailwind';
import Button from './Button';
import DefaultModal from './DefaultModal';

interface InfoBoxProps {
  prompt: string;
  title?: string;
  visible: boolean;
  onPromptEnd: (didConfirm?: boolean) => void;
  loading?: boolean;
}

const ConfirmationModal: React.FC<InfoBoxProps> = ({
  prompt,
  title,
  visible,
  onPromptEnd,
  loading,
}) => {
  const close = () => {
    onPromptEnd();
  };

  return (
    <DefaultModal
      setVisible={close}
      visible={visible}
      title={title || 'Please confirm'}
    >
      {loading ? (
        <ActivityIndicator />
      ) : (
        <>
          <Text>{prompt}</Text>
          <View style={tw`flex flex-row justify-between mt-6`}>
            <Button variant='dark' onPress={() => onPromptEnd(false)}>
              No
            </Button>
            <Button onPress={() => onPromptEnd(true)}>Yes</Button>
          </View>
        </>
      )}
    </DefaultModal>
  );
};

export default ConfirmationModal;
