import React, { useState } from 'react';
import { Pressable, View } from 'react-native';

import tw from '../../config/tailwind';
import {
  FavouriteResponse,
  FavouriteType,
} from '../../shared/types/favourites';
import { WpProduct, WpProductSearchResult } from '../../shared/types/wordpress';
import { decodeEntities } from '../../utils/decodeEntities';
import FavButton from '../favourites/FavButton';
import TagsButton from '../favourites/TagsButton';
import InputLabel from '../shared/InputLabel';
import Text from '../shared/Text';
import CrossSVG from '../svg/CrossSVG';
import TagsSVG from '../svg/TagsSVG';
import TagsModal from './modals/TagsModal';
import ProductTag from './ProductTag';

type ProductListingProps = {
  hideFavBtn?: boolean;
  showTagBtn?: boolean;
  tags?: FavouriteResponse['tags'];
  onShowTagModal?: () => void;
} & (
  | {
      hideRemoveBtn: true;
      onPressRemove?: undefined;
    }
  | {
      hideRemoveBtn?: false;
      onPressRemove: (productId: number) => void;
    }
) &
  (
    | {
        product: WpProductSearchResult;
        type: 'searchresult';
      }
    | {
        product: WpProduct;
        type?: undefined;
      }
  );

const ProductListing: React.FC<ProductListingProps> = ({
  product,
  hideRemoveBtn,
  hideFavBtn,
  onPressRemove,
  type,
  showTagBtn,
  onShowTagModal = () => {},
  tags = [],
}) => {
  const [showTagsModal, setShowTagsModal] = useState(false);

  let title: string;

  if (type === 'searchresult') {
    title = decodeEntities(product.post_title);
  } else {
    //  Access to full product data
    title = decodeEntities(product.title.rendered);
  }

  return (
    <>
      <View style={tw`py-2 border-b border-grey`} key={product.id}>
        <View style={tw`flex-row items-center`}>
          <Text>{title}</Text>
          <View
            style={tw`flex-initial flex-row ml-auto items-center flex pl-3`}
          >
            {showTagBtn && (
              <TagsButton
                onPress={onShowTagModal}
                style={tw.style(!hideFavBtn && `mr-1`)}
              />
            )}
            {!hideFavBtn && (
              <FavButton entityId={product.id} type={FavouriteType.Product} />
            )}
            {!hideRemoveBtn && (
              <Pressable
                onPress={() => onPressRemove(product.id)}
                style={tw.style(
                  `h-10 w-10 flex items-center justify-center`,
                  !hideFavBtn && `ml-1`
                )}
              >
                <CrossSVG style={tw`text-green`} width={24} height={24} />
              </Pressable>
            )}
          </View>
        </View>
        {tags.length > 0 && (
          <>
            <View style={tw`flex flex-row -mr-1 mt-2 -mb-1 flex-wrap`}>
              <View style={tw`mr-1 flex-initial mb-1 py-1 mt-px`}>
                <Text style={tw`font-bold font-ubunutu-bold text-sm`}>
                  Tags:
                </Text>
              </View>
              {tags.map((tag) => (
                <View style={tw`mr-1 flex-initial mb-1`}>
                  <ProductTag>{tag}</ProductTag>
                </View>
              ))}
            </View>
          </>
        )}
      </View>
    </>
  );
};

export default ProductListing;
