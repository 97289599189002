import {
  NavigationProp,
  RouteProp,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import { format } from 'date-fns';
import { FC, useEffect, useState } from 'react';
import { View } from 'react-native';

import TaskCommentList from '../../../components/project/TaskCommentList';
import TaskImageList from '../../../components/project/TaskImageList';
import SnagCompletionModal from '../../../components/project/modals/SnagCompletionModal';
import BackButton from '../../../components/shared/BackButton';
import Button from '../../../components/shared/Button';
import H1 from '../../../components/shared/H1';
import H2 from '../../../components/shared/H2';
import P from '../../../components/shared/P';
import Select from '../../../components/shared/Select';
import tw from '../../../config/tailwind';
import { useToasts } from '../../../hooks/useToasts';
import DashboardLayout from '../../../layout/DashboardLayout';
import { ProjectStackNavigatorParamList } from '../../../routes/types';
import { ProjectResponse } from '../../../shared/types/projects';
import { SnagResponse, SnagStatus } from '../../../shared/types/snags';
import { ProjectClient, SnagClient } from '../../../api';

const ProjectSnagScreen: FC = () => {
  const [project, setProject] = useState<ProjectResponse>();
  const [snag, setSnag] = useState<SnagResponse>();
  const [refresh, setRefresh] = useState(0);
  const [showCompletionModal, setShowCompletionModal] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const navigation =
    useNavigation<NavigationProp<ProjectStackNavigatorParamList>>();
  const route = useRoute<RouteProp<ProjectStackNavigatorParamList, 'snag'>>();
  const { addToast, addUnhandledErrorToast } = useToasts();

  useEffect(() => {
    ProjectClient.getById(route.params.projectId).then((projectResponse) =>
      setProject(projectResponse)
    );
  }, [route.params.projectId]);

  useEffect(() => {
    SnagClient.getById(route.params.projectId, route.params.snagId).then(
      (taskResponse) => setSnag(taskResponse)
    );
  }, [route.params.snagId, refresh]);

  const updateStatus = async (status: SnagStatus) => {
    if (snag) {
      setLoading(true);
      try {
        const updatedSnag = await SnagClient.update(
          route.params.projectId,
          route.params.snagId,
          {
            name: snag.name,
            description: snag.description,
            status,
          }
        );
        setSnag(updatedSnag);
        addToast({
          title: 'Issue updated',
          description: 'Your changes were saved successfully.',
          type: 'success',
        });
      } catch (e) {
        addUnhandledErrorToast(e);
      }
      setLoading(false);
    }
  };

  return (
    <DashboardLayout>
      <BackButton
        onPress={() =>
          navigation.navigate('project', { id: route.params.projectId })
        }
      >
        Back to project
      </BackButton>

      <P style={tw`font-ubuntu-bold font-bold mb-0 mt-4`}>{project?.name}</P>
      {snag && (
        <>
          <H1 xl>{snag?.name}</H1>
          <View style={tw.style(`p-6 bg-white mx-0 mb-4`)}>
            <H2>Issue description</H2>
            <P>{snag?.description}</P>

            {snag && snag.completedAt ? (
              <P>
                Completed at {format(snag.completedAt, 'dd/MM/yyyy')} by{' '}
                {snag.completedBy.firstName} {snag.completedBy.lastName}
              </P>
            ) : (
              <View style={tw`flex flex-row items-center`}>
                <Select
                  onValueChange={updateStatus}
                  selectedValue={snag?.status}
                  label='Issue status'
                  style={tw`mb-0 min-w-54`}
                  options={[
                    { label: 'Open', value: SnagStatus.Open },
                    { label: 'In progress', value: SnagStatus.InProgress },
                  ]}
                  loading={loading}
                />

                {snag && !snag?.completedAt && (
                  <Button
                    style={tw`ml-auto self-end`}
                    onPress={() => setShowCompletionModal(true)}
                  >
                    Complete issue
                  </Button>
                )}
              </View>
            )}
          </View>

          <View style={tw.style(`p-6 bg-white mx-0 mb-4`)}>
            <H2>Comments</H2>
            <TaskCommentList task={snag} type='snag' />
          </View>

          <View style={tw.style(`p-6 bg-white mx-0 mb-4`)}>
            <H2>Images</H2>
            <TaskImageList
              task={snag}
              isSnag
              onImageAdded={() => setRefresh((x) => x + 1)}
            />
          </View>

          <SnagCompletionModal
            visible={showCompletionModal}
            setVisible={setShowCompletionModal}
            snag={snag}
            setSnag={setSnag}
          />
        </>
      )}
    </DashboardLayout>
  );
};

export default ProjectSnagScreen;
