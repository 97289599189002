import { PublicFileResponse } from './files';

export enum SnagStatus {
  Complete = 'COMPLETE',
  InProgress = 'IN_PROGRESS',
  Open = 'OPEN',
}

export interface SnagUserResponse {
  id: string;
  firstName: string;
  lastName: string;
  businessName: string;
}

export interface SnagResponse {
  id: string;
  name: string;
  description: string;
  status: SnagStatus;
  createdAt: Date;
  updatedAt: Date;
  completedBy: SnagUserResponse;
  completedAt: Date;
  project: {
    id: string;
    companyId: string;
    name: string;
    location: string;
    caseStudyOptOut: boolean;
  };
  snagImages?: SnagImageResponse[];
}

export type SnagImageResponse = PublicFileResponse & {
  id: string;
  snagId: string;
  createdBy: string;
  createdAt: Date;
};

export interface CreateSnagCommentPayload {
  comment: string;
}

export interface CreateSnagPayload {
  name: string;
  description: string;
}

export interface UpdateSnagPayload {
  name: string;
  description: string;
  status: SnagStatus;
}

export interface SnagSmallResponse {
  id: string;
  name: string;
  description: string;
  status: SnagStatus;
  createdAt: Date;
  updatedAt: Date;
  completedByUserId: string;
  completedAt: Date;
}
