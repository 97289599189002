import { useNavigation } from '@react-navigation/native';
import { FC } from 'react';
import { View } from 'react-native';

import LoginForm from '../../components/auth/LoginForm';
import A from '../../components/shared/A';
import H1 from '../../components/shared/H1';
import P from '../../components/shared/P';
import tw from '../../config/tailwind';
import LoginLayout from '../../layout/LoginLayout';
import useLinkProps from '../../routes/types';

const LoginScreen: FC = () => {
  const navigation = useNavigation();
  const forgotPasswordLink = useLinkProps<'unauthenticatedStackNavigator'>({
    screen: 'unauthenticatedStackNavigator',
    params: {
      screen: 'forgotPassword',
    },
  });

  const onLogin = () => {}; //navigation.navigate('authenticatedStackNavigator', { screen: 'home' });

  return (
    <LoginLayout>
      <View>
        <H1 style={tw`text-xl font-ubuntu-bold font-bold`}>
          Log in to your account
        </H1>
        <P>
          Enter your email address and password to log in to your Pinnacle
          dashboard.
        </P>
      </View>
      <LoginForm onLogin={onLogin} />
      <A linkProps={forgotPasswordLink} style={tw`text-xs`}>
        Forgot password?
      </A>
    </LoginLayout>
  );
};

export default LoginScreen;
