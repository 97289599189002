import ScaleLoader from 'react-spinners/ScaleLoader';
import tw from '../../config/tailwind';
import { useAppContext } from '../../hooks/useAppContext';

interface Props {
  message?: string;
}

const LoadingScreen: React.FC<Props> = ({ message }) => {
    const {globalLoading} = useAppContext()
  return (
    <>
      <div
        className='loading-screen-container'
        style={{
            ...tw`${
                globalLoading ? 'flex' : 'hidden'
              } items-center align-center justify-center bg-[rgba(255,255,255,0.8)] w-full h-full top-0 left-0`,
            position: 'absolute',
            zIndex: '99999'
        }}
      >
        <div
            style={tw`font-sans flex flex-rows w-fit h-fit p-5 bg-white shadow-xl`}
        >
          <ScaleLoader
            height={26}
            width={3}
            color={`#3CDBC0`}
          />
          {message ? <p style={tw`mr-6`} >{message}</p> : null}
        </div>
      </div>
    </>
  );
};

export default LoadingScreen;
