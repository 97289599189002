import React, { useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';

import { LatLng } from '../../../@types/geolocation';
import { ProjectClient, WpClient } from '../../../api';
import tw from '../../../config/tailwind';
import { GlobalLabels } from '../../../constants';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { ProjectResponse } from '../../../shared/types/projects';
import { WpDistributor } from '../../../shared/types/wordpress';
import { filterDistributors } from '../../../utils/filterDistributors';
import { getUserLocation } from '../../../utils/geolocation';
import Pagination from '../../Pagination';
import SearchBar from '../../SearchBar';
import Button from '../../shared/Button';
import Col from '../../shared/Col';
import DefaultModal from '../../shared/DefaultModal';
import FullWidthButton from '../../shared/FullWidthButton';
import H2 from '../../shared/H2';
import InputLabel from '../../shared/InputLabel';
import P from '../../shared/P';
import Row from '../../shared/Row';
import Text from '../../shared/Text';
import DistributorListing from './DistributorListing';

interface ProjectDistributorsProps {
  project: ProjectResponse;
  setProject: (projectData: ProjectResponse) => void;
}

const ProjectDistributors: React.FC<ProjectDistributorsProps> = ({
  project,
  setProject,
}) => {
  const { activeCompany } = useAuthContext();
  const [loading, setLoading] = useState(true);
  const [allDistributors, setAllDistributors] = useState<WpDistributor[]>();

  const [searchResults, setSearchResults] = useState<WpDistributor[]>([]);
  const [nameInput, setNameInput] = useState('');
  const [page, setPage] = useState(1);
  const [hasInteractedWithSearch, setHasInteractedWithSearch] = useState(false);
  const [showAddDistributorModal, setShowAddDistributorModal] = useState(false);

  const [location, setLocation] = useState<LatLng | null>();

  const distributorIds = useMemo(
    () => project.distributors.map(({ id }) => id),
    [project.distributors]
  );

  useEffect(() => {
    WpClient.getAllDistributors().then((res) => {
      setAllDistributors(res);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    let isActive = true;
    if (!loading && !allDistributors) {
      return;
    }

    if (allDistributors) {
      if (location === undefined) {
        getUserLocation()
          .then(async (location) => {
            if (isActive) {
              setLocation(location);
            }
          })
          .catch(() => {
            if (isActive) {
              setLocation(null);
            }
          });
        return;
      }

      filterDistributors({
        distributors: allDistributors!,
        name: nameInput,
        location: location!,
      }).then((res) => {
        if (isActive) {
          setSearchResults(res);
        }
      });
    }

    if (loading) {
      setLoading(false);
    }

    return () => {
      isActive = false;
    };
  }, [nameInput, allDistributors, location]);

  const addDistributor = async (distributorId: number) => {
    const newProjectData = await ProjectClient.update(project.id, {
      name: project.name,
      applicationId: project.application?.id,
      caseStudyOptOut: project.caseStudyOptOut,
      companyId: activeCompany!.id,
      distributors: [...distributorIds, distributorId],
      location: project.location,
      sectorId: project.sector?.id,
    });
    setProject(newProjectData);
  };

  const removeDistributor = async (distributorId: number) => {
    const newProjectData = await ProjectClient.update(project.id, {
      name: project.name,
      applicationId: project.application?.id,
      caseStudyOptOut: project.caseStudyOptOut,
      companyId: activeCompany!.id,
      distributors: distributorIds.filter((id) => id !== distributorId),
      location: project.location,
      sectorId: project.sector?.id,
    });
    setProject(newProjectData);
  };

  if (loading) return null;

  const perPage = 6;
  const totalPages = Math.ceil(searchResults.length / perPage);
  const items = searchResults.slice(
    Math.max(0, (page - 1) * perPage),
    page * perPage
  );

  return (
    <View style={{ minHeight: 500 }}>
      <Button
        onPress={() => setShowAddDistributorModal(true)}
        style={tw`ml-auto`}
      >
        Assign a distributor
      </Button>
      <DefaultModal
        visible={showAddDistributorModal}
        setVisible={setShowAddDistributorModal}
        title='Assign a distributor'
        modalWidth='lg'
      >
        <View style={tw.style(`mb-3 mt-2 max-w-[400px]`)}>
          <InputLabel label={GlobalLabels.SearchForContact} />
          <SearchBar
            textInputProps={{
              placeholder: 'Start typing a name...',
              value: nameInput,
            }}
            onStoppedTyping={(v) => {
              if (v !== nameInput) {
                setHasInteractedWithSearch(true);
                setNameInput(v);
                setPage(1);
              }
              if (!v) {
                setHasInteractedWithSearch(false);
              }
            }}
            style={tw`lg:w-full bg-white`}
          />
        </View>

        {items.length === 0 && hasInteractedWithSearch && (
          <P>No results found</P>
        )}

        {items.length > 0 && (
          <Row style={tw`-mx-1.5`}>
            {items.map((item) => (
              <Col
                key={item.id}
                style={tw`w-full sm:w-1/2 md:w-1/3 mb-3 px-1.5 `}
              >
                <DistributorListing
                  item={item}
                  actions={[
                    !distributorIds.includes(item.id) ? (
                      <FullWidthButton
                        onPress={() => addDistributor(item.id)}
                        variant='transparent'
                        buttonStyle={tw`px-3 py-3 mt-6`}
                        underline
                      >
                        Add
                      </FullWidthButton>
                    ) : (
                      <Text style={tw`text-green font-bold font-ubuntu-bold`}>
                        Assigned
                      </Text>
                    ),
                  ]}
                />
              </Col>
            ))}
          </Row>
        )}

        {searchResults.length > perPage && (
          <View
            style={tw.style(totalPages <= 1 && 'opacity-0')}
            pointerEvents={totalPages <= 1 ? 'none' : 'auto'}
          >
            <Pagination
              paginatedResult={{
                currentPage: page,
                totalItems: searchResults.length,
                totalPages,
                perPage,
                items,
              }}
              onChangePage={setPage}
            />
          </View>
        )}
      </DefaultModal>

      <H2>Project distributors</H2>

      {project.distributors.length === 0 ? (
        <P>No distributors have been assigned to this project.</P>
      ) : (
        <Row style={tw`-mx-1.5`}>
          {project.distributors.map((item) => (
            <Col
              key={item.id}
              style={tw` w-full sm:w-1/2 md:w-1/3 mb-3 px-1.5 `}
            >
              <DistributorListing
                item={item}
                actions={[
                  <FullWidthButton
                    onPress={() => removeDistributor(item.id)}
                    variant='transparent'
                    buttonStyle={tw`px-3 py-3 mt-6`}
                    underline
                  >
                    Remove
                  </FullWidthButton>,
                ]}
              />
            </Col>
          ))}
        </Row>
      )}
    </View>
  );
};

export default ProjectDistributors;
