import { Picker } from '@react-native-picker/picker';
import { Formik } from 'formik';
import React from 'react';
import { ActivityIndicator, View } from 'react-native';
import * as Yup from 'yup';

import { CompanyUserClient } from '../../../api';
import tw from '../../../config/tailwind';
import { useToasts } from '../../../hooks/useToasts';
import {
  CompanyUserResponse,
  CompanyUserRole,
} from '../../../shared/types/companies';
import Button from '../../shared/Button';
import Col from '../../shared/Col';
import DefaultModal from '../../shared/DefaultModal';
import InputValidationMessage from '../../shared/InputValidationMessage';
import Row from '../../shared/Row';
import Select from '../../shared/Select';

interface EditCompanyUserModalProps {
  companyUser?: CompanyUserResponse;
  onFinished: (user?: CompanyUserResponse) => void;
}

type FormValues = {
  role: CompanyUserRole;
};

const EditCompanyUserModal: React.FC<EditCompanyUserModalProps> = ({
  companyUser,
  onFinished,
}) => {
  const { addToast, addUnhandledErrorToast } = useToasts();

  if (!companyUser) return <></>;

  const initialValues: FormValues = {
    role: companyUser.companyRole,
  };

  const schema: Yup.SchemaOf<FormValues> = Yup.object().shape({
    role: Yup.mixed().required().oneOf(Object.values(CompanyUserRole)),
  });

  const onSubmit = async ({ role }: typeof initialValues) => {
    try {
      await CompanyUserClient.updateUser(
        companyUser.companyId,
        companyUser.userId,
        {
          companyRole: role,
        }
      );

      addToast({
        title: 'Company user updated',
        description: `${companyUser.user?.firstName} ${companyUser.user?.lastName}'s role is now ${role}.`,
        type: 'success',
      });

      onFinished({ ...companyUser, companyRole: role });
    } catch (e) {
      addUnhandledErrorToast(e);
    }
  };

  return (
    <DefaultModal
      setVisible={() => onFinished(undefined)}
      visible={companyUser !== undefined}
      title={`Update ${companyUser.user?.firstName} ${companyUser.user?.lastName}`}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={schema}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          values,
          errors,
          isSubmitting,
        }) => (
          <View style={tw`mt-4`}>
            <Row>
              <Col>
                <Select
                  selectedValue={values.role}
                  onValueChange={(iValue, iIndex) =>
                    handleChange('role')(iValue)
                  }
                  label='Select a company role'
                  required
                  hasError={!!errors.role}
                  options={Object.values(CompanyUserRole).map((item) => ({
                    label: item,
                    value: item,
                  }))}
                />
                {errors.role && (
                  <InputValidationMessage>{errors.role}</InputValidationMessage>
                )}
              </Col>
            </Row>
            <View style={tw`flex flex-row items-center justify-end mt-4`}>
              {isSubmitting && <ActivityIndicator style={tw`mr-4`} />}
              <Button onPress={handleSubmit} disabled={isSubmitting}>
                Update user
              </Button>
            </View>
          </View>
        )}
      </Formik>
    </DefaultModal>
  );
};

export default EditCompanyUserModal;
