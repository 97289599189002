import { useFocusEffect } from '@react-navigation/native';
import { format } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';
import { View } from 'react-native';
import { TaskClient, SnagClient } from '../../api';
import { LeadClient } from '../../api/LeadClient';

import tw from '../../config/tailwind';
import { useAuthContext } from '../../hooks/useAuthContext';
import { CommentResponse } from '../../shared/types/comments';
import { LeadResponse } from '../../shared/types/leads';
import { PaginatedResult } from '../../shared/types/paginatedResult';
import { SnagResponse } from '../../shared/types/snags';
import { TaskResponse } from '../../shared/types/tasks';
import Pagination from '../Pagination';
import FullWidthButton from '../shared/FullWidthButton';
import Text from '../shared/Text';
import PersonSVG from '../svg/PersonSVG';
import AddCommentModal from './modals/AddCommentModal';

interface TaskCommentListProps {
  task: TaskResponse | SnagResponse | LeadResponse;
  type: 'snag' | 'task' | 'lead';
}
const TaskCommentList: React.FC<TaskCommentListProps> = ({ task, type }) => {
  const [page, setPage] = useState(1);
  const [refresh, setRefresh] = useState(0);
  const [showCommentModal, setShowCommentModal] = useState(false);

  const { user, activeCompany } = useAuthContext();

  const [paginatedResult, setPaginatedResult] =
    useState<PaginatedResult<CommentResponse>>();

  useEffect(() => {
    if (type === 'task') {
      TaskClient.getComments(task.project.id, task.id, page).then((result) => {
        setPaginatedResult(result);
      });
    } else if (type === 'snag') {
      SnagClient.getComments(task.project.id, task.id, page).then((result) => {
        setPaginatedResult(result);
      });
    } else if (type === 'lead') {
      LeadClient.getComments(task.id, page).then((result) => {
        setPaginatedResult(result);
      });
    }
  }, [page, activeCompany?.id, refresh]);

  useFocusEffect(
    useCallback(() => {
      setRefresh((old) => old + 1);
    }, [])
  );

  const onCommentAdded = (comment: CommentResponse) => {
    setPage(1);
    setRefresh((old) => old + 1);
  };

  return (
    <View>
      {paginatedResult?.items.map((comment) => (
        <View style={tw`border border-background-200 p-4 mb-4`}>
          <Text>{comment.comment}</Text>
          <View style={tw`flex-row items-center mt-4`}>
            <View
              style={tw`bg-background-100 mr-4 w-8 h-8 rounded-full justify-center items-center`}
            >
              <PersonSVG width={18} height={18} />
            </View>
            <Text style={tw`mr-4`}>
              {comment.user.firstName} {comment.user.lastName}
            </Text>
            <Text>
              {format(new Date(comment.createdAt), 'dd/MM/yyyy HH:mm')}
            </Text>
          </View>
        </View>
      ))}

      <View style={tw`flex flex-row justify-between`}>
        <FullWidthButton
          variant='transparent'
          buttonStyle={tw`py-2 pr-4 w-48`}
          underline
          onPress={() => setShowCommentModal(true)}
        >
          + Add a comment
        </FullWidthButton>
        {paginatedResult && (
          <Pagination
            paginatedResult={paginatedResult}
            onChangePage={setPage}
          />
        )}
      </View>
      <AddCommentModal
        visible={showCommentModal}
        setVisible={setShowCommentModal}
        task={task}
        type={type}
        onCommentAdded={onCommentAdded}
      />
    </View>
  );
};

export default TaskCommentList;
