import { Formik } from 'formik';
import React from 'react';
import { ActivityIndicator, View } from 'react-native';
import * as Yup from 'yup';
import { SnagClient } from '../../../api';

import tw from '../../../config/tailwind';
import { useToasts } from '../../../hooks/useToasts';
import { ProjectResponse } from '../../../shared/types/projects';
import { CreateSnagPayload, SnagResponse } from '../../../shared/types/snags';
import Button from '../../shared/Button';
import DefaultModal from '../../shared/DefaultModal';
import InputText from '../../shared/InputText';
import InputValidationMessage from '../../shared/InputValidationMessage';

interface AddSnagModalProps {
  project: ProjectResponse;
  visible: boolean;
  setVisible: (v: boolean) => void;
  onSnagAdded: (comment: SnagResponse) => void;
}

const AddSnagModal: React.FC<AddSnagModalProps> = ({
  project,
  visible,
  setVisible,
  onSnagAdded,
}) => {
  const { addToast, addUnhandledErrorToast } = useToasts();

  const initialValues: CreateSnagPayload = {
    name: '',
    description: '',
  };

  const schema: Yup.SchemaOf<typeof initialValues> = Yup.object().shape({
    name: Yup.string().required(),
    description: Yup.string().required(),
  });

  const onSubmit = async (payload: CreateSnagPayload) => {
    try {
      const snag = await SnagClient.create(project.id, payload);
      setVisible(false);
      addToast({
        title: 'Issue created',
        description: `You added an issue to this project`,
        type: 'success',
      });
      onSnagAdded(snag);
    } catch (e) {
      addUnhandledErrorToast(e);
    }
  };

  return (
    <DefaultModal setVisible={setVisible} visible={visible} title='New issue'>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={schema}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          values,
          errors,
          isSubmitting,
        }) => (
          <View style={tw`mt-4`}>
            <View>
              <InputText
                placeholder='Issue title'
                label='Issue title'
                required
                value={values.name}
                setValue={handleChange('name')}
                onBlur={handleBlur('name')}
                hasError={!!errors.name}
              />
              {errors.name && (
                <InputValidationMessage>{errors.name}</InputValidationMessage>
              )}
            </View>
            <View>
              <InputText
                placeholder='Issue description'
                label='Issue description'
                required
                value={values.description}
                setValue={handleChange('description')}
                onBlur={handleBlur('description')}
                hasError={!!errors.description}
                multiline
              />
              {errors.description && (
                <InputValidationMessage>
                  {errors.description}
                </InputValidationMessage>
              )}
            </View>

            <View style={tw`flex flex-row items-center justify-end mt-4`}>
              {isSubmitting && <ActivityIndicator style={tw`mr-4`} />}
              <Button onPress={handleSubmit} disabled={isSubmitting}>
                Create issue
              </Button>
            </View>
          </View>
        )}
      </Formik>
    </DefaultModal>
  );
};

export default AddSnagModal;
