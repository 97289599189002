import { Link, useNavigation } from '@react-navigation/native';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';

import { CompanyClient } from '../../api';
import tw from '../../config/tailwind';
import { GlobalAdminStackNavigationProp } from '../../routes/types';
import { CompanyResponse } from '../../shared/types/companies';
import { PaginatedResult } from '../../shared/types/paginatedResult';
import Button from '../shared/Button';
import GenericTable from '../shared/GenericTable';
import GenericTableAction from '../shared/GenericTableAction';
import CreateCompanyModal from './modals/CreateCompanyModal';

interface AdminCompaniesTableProps {}

const AdminCompaniesTable: React.FC<AdminCompaniesTableProps> = ({}) => {
  const [companies, setCompanies] =
    useState<PaginatedResult<CompanyResponse>>();
  const [page, setPage] = useState(1);
  const [refresh, setRefresh] = useState(0);
  const [query, setQuery] = useState('');
  const [showCreateModal, setShowCreateModal] = useState(false);
  const adminStackNavigation =
    useNavigation<GlobalAdminStackNavigationProp<'admin'>>();

  useEffect(() => {
    setCompanies(undefined);
    CompanyClient.getAll(page, query).then((results) => {
      setCompanies(results);
    });
  }, [page, query, refresh]);

  const onCompanyAdded = (company: CompanyResponse) => {
    setRefresh((x) => x + 1);
    adminStackNavigation.navigate('company', {
      id: company.id,
    });
  };

  return (
    <>
      <Button style={`mb-4`} onPress={() => setShowCreateModal(true)}>
        Create a company
      </Button>

      <CreateCompanyModal
        visible={showCreateModal}
        setVisible={setShowCreateModal}
        onCompanyAdded={onCompanyAdded}
      />

      <GenericTable
        paginatedResult={companies}
        searchPlaceholder='Search companies'
        headers={{
          name: {
            title: 'Name',
            width: '30%',
          },
          createdAt: {
            title: 'Created at',
            width: '20%',
            format: (date) => format(new Date(date), 'dd / MM / yyyy'),
          },
        }}
        actions={(company) => [
          <Link
            to={{
              screen: 'authenticatedStackNavigator',
              params: {
                screen: 'globalAdminStack',
                params: {
                  screen: 'company',
                  params: {
                    id: company.id,
                  },
                },
              },
            }}
          >
            <GenericTableAction label='Manage' />
          </Link>,
        ]}
        setPage={setPage}
        setQueryInput={setQuery}
      />
    </>
  );
};

export default AdminCompaniesTable;
