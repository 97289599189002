import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { FC } from 'react';

import CreateProjectScreen from '../views/AuthenticatedStack/ProjectStack/CreateProjectScreen';
import ProjectDocumentScreen from '../views/AuthenticatedStack/ProjectStack/ProjectDocumentScreen';
import ProjectRoomScreen from '../views/AuthenticatedStack/ProjectStack/ProjectRoomScreen';
import ProjectScreen from '../views/AuthenticatedStack/ProjectStack/ProjectScreen';
import ProjectSnagScreen from '../views/AuthenticatedStack/ProjectStack/ProjectSnagScreen';
import ProjectTaskScreen from '../views/AuthenticatedStack/ProjectStack/ProjectTaskScreen';
import { ProjectStackNavigatorParamList } from './types';

const Stack = createNativeStackNavigator<ProjectStackNavigatorParamList>();

const ProjectStackNavigator: FC = () => {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen
        name='createProject'
        component={CreateProjectScreen}
        options={{ title: 'Create project' }}
      />
      <Stack.Screen
        name='project'
        component={ProjectScreen}
        options={{ title: 'Project' }}
      />
      <Stack.Screen
        name='task'
        component={ProjectTaskScreen}
        options={{ title: 'Task' }}
      />
      <Stack.Screen
        name='room'
        component={ProjectRoomScreen}
        options={{ title: 'Room' }}
      />
      <Stack.Screen
        name='snag'
        component={ProjectSnagScreen}
        options={{ title: 'Issue' }}
      />
      <Stack.Screen
        name='document'
        component={ProjectDocumentScreen}
        options={{ title: 'Document' }}
      />
    </Stack.Navigator>
  );
};

export default ProjectStackNavigator;
