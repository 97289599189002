import {
  NavigationProp,
  StackActions,
  useNavigation,
} from '@react-navigation/native';

import React, { useRef, useState } from 'react';
import { ActivityIndicator, Linking, View } from 'react-native';
import { useHover } from 'react-native-web-hooks';
import tw from '../../config/tailwind';
import { useAuthContext } from '../../hooks/useAuthContext';
import { useToasts } from '../../hooks/useToasts';
import { AuthenticatedStackNavigatorParamList } from '../../routes/types';
import { ProjectResponse, ProjectStatus, UpdateProjectPayload } from '../../shared/types/projects';
import { UserRole } from '../../shared/types/users';
import BackButton from '../shared/BackButton';
import Button from '../shared/Button';
import H1 from '../shared/H1';
import Row from '../shared/Row';
import Text from '../shared/Text';
import BackspaceSVG from '../svg/BackspaceSVG';
import FlagSVG from '../svg/FlagSVG';
import FlagSolidSVG from '../svg/FlagSolidSVG';
import ProjectCompletionModal from './modals/ProjectCompletionModal';
import { API_BASE_URL } from '../../env-consts';
import { useAppContext } from '../../hooks/useAppContext';
import { ProjectClient } from '../../api';

interface ProjectHeaderProps {
  project: ProjectResponse;
  setProject: (project: ProjectResponse) => void;
}

const ProjectHeader: React.FC<ProjectHeaderProps> = ({
  project,
  setProject,
}) => {
  const authStackNavigation =
    useNavigation<NavigationProp<AuthenticatedStackNavigatorParamList>>();
  const { addToast, addUnhandledErrorToast } = useToasts();

  const [deleteInProgress, setDeleteInProgress] = useState(false);
  const [showCompletionModal, setShowCompletionModal] = useState(false);

  const { user } = useAuthContext();
  const { projectStats, setProjectStats } = useAppContext();

  const buttonRef = useRef(null);
  const isHovered = useHover(buttonRef);

  const setProjectCaseStudyFlag = async (flagged: boolean) => {
    const { 
      id, status, createdAt, 
      completedAt, application, 
      distributors, sector, customApplication, contacts, 
      ...rest 
    } = project;

    const res = await ProjectClient.update(project.id, {
      ...rest,
      distributors: project.distributors.map((d) => d.id),
      caseStudyFlag: flagged,
    });

    setProject(res);

    addToast({
      title: 'Project updated',
      description: flagged
        ? 'Project case study flag added'
        : 'Project case study flag removed',
      type: 'success',
    });
  };

  const deleteProject = async () => {
    try {
      setDeleteInProgress(true);
      await ProjectClient.delete(project.id);
      setProjectStats((stats) => ({
        completedCount:
          project.status === ProjectStatus.Completed
            ? Math.max(0, stats!.completedCount - 1)
            : stats!.completedCount,
        liveCount:
          project.status === ProjectStatus.Live
            ? Math.max(0, stats!.liveCount - 1)
            : stats!.completedCount,
      }));
      setDeleteInProgress(false);

      addToast({
        title: 'Project deleted',
        description: `The project ${project.name} was successfully deleted.`,
        type: 'success',
      });

      authStackNavigation.dispatch(StackActions.push('projectsOverview'));
    } catch (e) {
      addUnhandledErrorToast(e);
    }
  };

  const generateReport = () => {
    Linking.openURL(
      `${API_BASE_URL}/reports?projectId=${
        project.id
      }&clientTimezoneOffset=${new Date().getTimezoneOffset()}`
    );
  };

  const ProjectAction =
    project.status !== ProjectStatus.Completed ? (
      <Button
        variant='default'
        style={tw`py-5`}
        onPress={() => setShowCompletionModal(true)}
      >
        Complete project
      </Button>
    ) : user?.role === UserRole.Admin && !project.caseStudyOptOut ? (
      <Button
        variant='default'
        outlined
        style={tw`py-5`}
        onPress={() => setProjectCaseStudyFlag(!project.caseStudyFlag)}
      >
        <View style={tw`flex items-center justify-center flex-row`}>
          {project.caseStudyFlag || isHovered ? <FlagSolidSVG /> : <FlagSVG />}
          <Text style={tw`ml-2 font-bold font-ubuntu-bold`}>
            {project.caseStudyFlag
              ? 'Remove case study flag'
              : 'Add case study flag'}
          </Text>
        </View>
      </Button>
    ) : null;

  return (
    <>
      <BackButton
        onPress={() => authStackNavigation.navigate('projectsOverview')}
      >
        Back to all projects
      </BackButton>

      <H1 xl>{project.name}</H1>

      <Row style={tw`justify-between mx-0 mb-5`}>
        {/* <View style={tw`w-1/5 mb-6`}> */}
        <Button
          variant='transparent'
          style={tw`pl-0 pr-4 py-5`}
          onPress={deleteProject}
          disabled={deleteInProgress}
        >
          <View
            style={tw`flex flex-row items-center  ${
              deleteInProgress ? 'justify-center min-w-20' : ''
            }`}
          >
            {deleteInProgress ? (
              <ActivityIndicator color='#464646' />
            ) : (
              <>
                <BackspaceSVG style={tw`mr-1`} />
                <Text>Delete</Text>
              </>
            )}
          </View>
        </Button>

        <View style={tw`flex flex-row`}>
          {ProjectAction ? <View ref={buttonRef}>{ProjectAction}</View> : null}

          {project.status === ProjectStatus.Completed && (
            <Button
              variant='default'
              style={tw`py-5 ml-4`}
              onPress={generateReport}
              outlined
            >
              Generate report
            </Button>
          )}

          <ProjectCompletionModal
            project={project}
            setProject={setProject}
            setVisible={setShowCompletionModal}
            visible={showCompletionModal}
          />
        </View>
      </Row>
    </>
  );
};

export default ProjectHeader;
