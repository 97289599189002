import { API_BASE_URL } from '../env-consts';
import { PaginatedResult } from '../shared/types/paginatedResult';
import {
  CreateUserPayload,
  ForgotPasswordPayload,
  IConsentsCSVData,
  ResetPasswordPayload,
  UpdateUserPayload,
  UpdateUserPreferencesPayload,
  UserInvitePayload,
  UserInviteResponse,
  UserPreferencesResponse,
  UserResponse,
} from '../shared/types/users';
import ApiClient from './ApiClient';

const client = new ApiClient(`${API_BASE_URL}/users`);

export const UserClient = {
  async createUser(payload: CreateUserPayload) {
    return client.post<UserResponse>(``, payload).then(({ data }) => data);
  },

  async getAll(page: number, query?: string, showDeleted?: boolean) {
    return client
      .get<PaginatedResult<UserResponse>>('', {
        params: {
          page,
          query,
          showDeleted,
        },
      })
      .then((res) => res.data);
  },

  async getById(userId: string) {
    return client.get<UserResponse>(`/${userId}`).then(({ data }) => data);
  },

  async verifyResetToken(userId: string, resetToken: string) {
    return client
      .get<void>(`/reset-password/verify`, {
        params: {
          userId,
          token: resetToken,
        },
      })
      .then(() => {});
  },

  async resetPassword(
    userId: string,
    resetToken: string,
    payload: ResetPasswordPayload
  ) {
    return client
      .post<void>(`/reset-password/${userId}`, payload, {
        params: {
          token: resetToken,
        },
      })
      .then(({ data }) => data);
  },

  async forgotPassword(email: string) {
    const payload: ForgotPasswordPayload = {
      email,
    };
    return client
      .post<void>(`/forgot-password`, payload)
      .then(({ data }) => data);
  },

  async updateUser(userId: string, payload: UpdateUserPayload) {
    return client
      .patch<UserResponse>(`/${userId}`, payload)
      .then(({ data }) => data);
  },

  async deleteUser(userId: string) {
    return client.delete<void>(`/${userId}`).then(({ data }) => data);
  },

  async recoverUser(userId: string) {
    return client.post<void>(`/${userId}/recover`).then(({ data }) => data);
  },

  async findInvite(userId: string, companyId?: string, token?: string) {
    return client
      .get<UserInviteResponse>('/invite', {
        params: {
          userId,
          companyId,
          token,
        },
      })
      .then((res) => res.data);
  },

  async acceptInvite(payload: UserInvitePayload) {
    return client.post('/accept-invite', payload).then((res) => res.data);
  },

  async getPreferences(userId: string) {
    return client
      .get<UserPreferencesResponse>(`/preferences/${userId}`)
      .then((res) => res.data);
  },

  async updatePreferences(
    userId: string,
    payload: UpdateUserPreferencesPayload
  ) {
    return client
      .put<UserPreferencesResponse>(`/preferences/${userId}`, payload)
      .then((res) => res.data);
  },

  async getAllConsentsData(){
    return client.get<IConsentsCSVData[]>('/all-consents').then((res) => res.data);
  }
};
