import {
  NavigationProp,
  RouteProp,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import React, { useEffect, useRef, useState } from 'react';
import { View } from 'react-native';
import WebView from 'react-native-webview';
import { axiosClient, WpClient } from '../../../api';

import BackButton from '../../../components/shared/BackButton';
import tw from '../../../config/tailwind';
import { API_BASE_URL } from '../../../env-consts';
import { useToasts } from '../../../hooks/useToasts';
import DashboardLayout from '../../../layout/DashboardLayout';
import { NewsStackNavigatorParamList } from '../../../routes/types';
import { WpNews } from '../../../shared/types/wordpress';

interface SingleNewsScreenProps {}

const css = `
  html {
	overflow: hidden;	
  }

  #onetrust-consent-sdk, 
  #site-header,
  #site-footer,
  .c__hero__breadcrumb-wrapper,
  .c__knowledge-content .l__col__span3,
  .m__relatedCarousel {
    display: none;
  }

  #site-content {
    margin-top: 0 !important;
  }

  .c__hero {
    padding-top: 0 !important;
    background: transparent !important;
  }

  .c__knowledge-content .l__col__span8 {
    width: 100% !important;
  }

  #site-content p {
	background: none !important;
  }
`;

const js = `
  document.addEventListener("DOMContentLoaded", function(event){
    window.parent.postMessage(document.body.scrollHeight);
  });

  window.addEventListener("resize", function(event) {
    window.parent.postMessage(document.body.scrollHeight);
  }, true); 

  document.head.insertAdjacentHTML("beforeend", \`<style>${css}</style>\`); 
  true;
`;

const SingleNewsScreen: React.FC<SingleNewsScreenProps> = () => {
  const [article, setArticle] = useState<WpNews>();
  const [height, setHeight] = useState<number>();

  const webRef = useRef<WebView>(null);
  const route =
    useRoute<RouteProp<NewsStackNavigatorParamList, 'newsArticle'>>();
  const navigation =
    useNavigation<NavigationProp<NewsStackNavigatorParamList>>();
  const { addUnhandledErrorToast } = useToasts();

  // console.log(`article`, article)

  useEffect(() => {
    setHeight(undefined);
    WpClient.fetchNewsPost(route.params.slug)
      .then((x) => {
        setArticle(x);
      })
      .catch((e) => {
        addUnhandledErrorToast(e);
      });
  }, [route.params.slug]);

  return (
    <DashboardLayout>
      <BackButton onPress={() => navigation.canGoBack() ? navigation.goBack() : navigation.navigate('news')}>
        Back to news
      </BackButton>

      {article && (
        <View
          style={tw`flex-1 flex-row`}
          onLayout={(e) =>
            height === undefined ? setHeight(e.nativeEvent.layout.height) : null
          }
        >
          {height !== undefined && (
            <WebView
              key={article.id}
              ref={webRef}
              style={tw.style(`mb-4`, { height })}
              source={{
                // TODO: replace this with direct URL once CORs is sorted
                uri: `${API_BASE_URL}/news/html?slug=${article?.slug}`,
                method: 'GET',
                headers: {
                  Authorization:
                    axiosClient.defaults.headers.common['Authorization'],
                },
              }}
              onMessage={(event) => {
                setHeight(Number(event.nativeEvent.data) || 800);
              }}
              injectedJavaScript={js}
            />
          )}
        </View>
      )}
    </DashboardLayout>
  );
};

export default SingleNewsScreen;
