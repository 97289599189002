import { useFocusEffect } from '@react-navigation/native';
import { format } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';
import { UserStatsClient } from '../../api';

import { FeedbackClient } from '../../api/FeedbackClient';
import tw from '../../config/tailwind';
import { useToasts } from '../../hooks/useToasts';
import { FeedbackResponse } from '../../shared/types/feedback';
import { PaginatedResult } from '../../shared/types/paginatedResult';
import { UserLoginStatResponse } from '../../shared/types/users';
import Col from '../shared/Col';
import GenericTable from '../shared/GenericTable';
import Row from '../shared/Row';
import Select from '../shared/Select';
import Spacer from '../shared/Spacer';
import ViewAction from '../shared/ViewAction';

interface AdminUserActivityTableProps {
  userId?: string;
  perPage?: number;
  showFilters?: boolean;
  showTableActions?: boolean;
}

const AdminUserActivityTable: React.FC<AdminUserActivityTableProps> = ({
  userId,
  perPage = 20,
  showFilters,
  showTableActions,
}) => {
  const [paginatedResults, setPaginatedResults] =
    useState<PaginatedResult<UserLoginStatResponse>>();
  const [refresh, setRefresh] = useState(0);

  const [page, setPage] = useState(1);
  const [desc, setDesc] = useState(true);

  useEffect(() => {
    let isActive = true;

    UserStatsClient.getLoginStats(page, desc, perPage, userId).then(
      (results) => isActive && setPaginatedResults(results)
    );

    return () => {
      isActive = false;
    };
  }, [page, desc, refresh]);

  useFocusEffect(
    useCallback(() => {
      setRefresh((old) => old + 1);
    }, [])
  );

  return (
    <>
      {showFilters && (
        <Row style={tw`bg-background -mx-11  -mt-8`}>
          <Spacer />
          <Col style={tw.style('w-auto max-w-54')}>
            <Select
              onValueChange={setDesc}
              label='Sort by'
              selectedValue={desc}
              options={[
                { label: 'Most recent first', value: true },
                { label: 'Oldest first', value: false },
              ]}
            />
          </Col>
        </Row>
      )}

      <GenericTable
        paginatedResult={paginatedResults}
        headers={{
          user: {
            title: 'User',
            width: '400px',
            format: (user) =>
              [user.firstName, user.lastName].join(' ') + `(${user.email})`,
          },
          createdAt: {
            title: 'Login Time',
            width: '200px',
            format: (createdAt) => format(createdAt, 'dd/MM/yy h:mm a'),
          },
        }}
        setPage={setPage}
        rowStyle={showTableActions ? () => tw`py-1.5` : undefined}
        actions={
          showTableActions
            ? ({ user }) => [
                <ViewAction
                  to={{
                    screen: 'authenticatedStackNavigator',
                    params: {
                      screen: 'globalAdminStack',
                      params: {
                        screen: 'user',
                        params: {
                          id: user.id,
                        },
                      },
                    },
                  }}
                />,
              ]
            : undefined
        }
      />
    </>
  );
};

export default AdminUserActivityTable;
