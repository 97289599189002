import { API_BASE_URL } from '../env-consts';
import {
  LoqateAddressCaptureFindPayload,
  LoqateCaptureAddress,
  LoqateFullAddress,
  LoqateGeocodeResponseIntl,
  LoqateResponse,
} from '../shared/types/loqate';
import ApiClient from './ApiClient';

const client = new ApiClient(`${API_BASE_URL}/loqate`);

export const LoqateClient = {
  async geolocateAddress(
    location: string,
    country = 'GBR'
  ): Promise<LoqateGeocodeResponseIntl> {
    return client
      .post(
        '',
        {},
        {
          params: {
            location,
            country,
          },
        }
      )
      .then((res) => res.data);
  },

  async searchAddress(
    payload: LoqateAddressCaptureFindPayload
  ): Promise<LoqateResponse<LoqateCaptureAddress>> {
    return client.post('/find-address', payload).then((res) => res.data);
  },

  async retrieveAddress(
    id: string
  ): Promise<LoqateResponse<LoqateFullAddress>> {
    return client
      .post(
        '/retrieve-address',
        {},
        {
          params: {
            id,
          },
        }
      )
      .then((res) => res.data);
  },
};
