import { UserResponse } from './users';

export enum CompanyUserRole {
  Admin = 'Admin',
  Contractor = 'Contractor',
}

export enum CompanyUserStatus {
  Active = 'ACTIVE',
  Pending = 'PENDING',
  Suspended = 'SUSPENDED',
}

export interface CompanyUserResponse {
  userId: string;
  user?: UserResponse;
  companyId: string;
  companyRole: CompanyUserRole;
  companyName: string;
  status: CompanyUserStatus;
}

export interface CompanyResponse {
  id: string;
  name: string;
  rebateBackdate?: boolean;
  createdAt: Date;
}

// export interface DetailedCompanyResponse {
//   id: string;
//   name: string;
//   createdAt: Date;
//   contacts: CompanyContactResponse[];
// }

export interface CreateCompanyUserPayload {
  email: string;
  firstName: string;
  lastName: string;
  businessName: string;
  role: CompanyUserRole;
}

export interface UpdateCompanyUserPayload {
  companyRole: CompanyUserRole;
}

export interface CreateCompanyPayload {
  name: string;
}

export interface UpdateCompanyPayload {
  name: string;
  rebateBackdate?: boolean;
}
