import {
  NavigationProp,
  StackActions,
  useFocusEffect,
  useNavigation,
} from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React, { useState } from 'react';

import Tabs from '../../../components/navigation/Tabs';
import DocumentListTable from '../../../components/project/DocumentListTable';
import InstallationChecklistTable from '../../../components/project/InstallationChecklistTable';
import ProductListTable from '../../../components/project/ProductListTable';
import ProjectDetails from '../../../components/project/ProjectDetails';
import ProjectHeader from '../../../components/project/ProjectHeader';
import RoomListTable from '../../../components/project/RoomListTable';
import SnagTable from '../../../components/project/SnagTable';
import ProjectDistributors from '../../../components/project/distributor/ProjectDistributors';
import RecyclingRequestForm from '../../../components/project/forms/RecyclingRequestForm';
import tw from '../../../config/tailwind';
import DashboardLayout from '../../../layout/DashboardLayout';
import {
  AuthenticatedStackNavigatorParamList,
  ProjectStackNavigatorParamList,
} from '../../../routes/types';
import { ProjectResponse } from '../../../shared/types/projects';
import { ProjectClient } from '../../../api';
import ProjectContacts from '../../../components/project/ProjectContacts';

type ProjectScreenProps = NativeStackScreenProps<
  ProjectStackNavigatorParamList,
  'project'
> & {
  //
};

type Tab = typeof tabs[number];
const tabs = [
  //   'Installation Checklist',
  'Project Issues',
  'Rooms',
  'Products',
  'Documents',
  'Distributors',
  'Contacts',
  'Recycling Request',
] as const;

const initialTab = tabs[0];

const ProjectScreen: React.FC<ProjectScreenProps> = ({ route }) => {
  const [project, setProject] = useState<ProjectResponse>();
  const [activeTab, setActiveTab] = useState<Tab>(initialTab);
  const navigation =
    useNavigation<NavigationProp<AuthenticatedStackNavigatorParamList>>();

  useFocusEffect(
    React.useCallback(() => {
      let isActive = true;

      if (activeTab !== initialTab) {
        setActiveTab(initialTab);
      }

      ProjectClient.getById(route.params.id)
        .then((res) => {
          if (isActive) {
            setProject(res);
          }
        })
        .catch((_e) => {
          // TODO: Toast notification
          // if (axios.isAxiosError(e)) {
          // if 404 "Not found"
          // else generic
          // }
          if (!isActive) return;
          if (navigation.canGoBack()) {
            navigation.dispatch(StackActions.pop());
          } else {
            navigation.dispatch(StackActions.replace('projectsOverview'));
          }
        });

      return () => {
        isActive = false;
      };
    }, [route.params.id])
  );

  return (
    <DashboardLayout key={project?.id}>
      {!project ? null : (
        <>
          <ProjectHeader project={project} setProject={setProject} />
          <ProjectDetails
            project={project}
            setProject={setProject}
            style={tw`mb-5`}
          />

          <Tabs
            activeTab={activeTab}
            tabs={[...tabs]}
            emitActiveTab={(tab: string) => setActiveTab(tab as Tab)}
          />

          {/* {activeTab === 'Installation Checklist' && (
            <InstallationChecklistTable project={project} />
          )} */}
          {activeTab === 'Project Issues' && <SnagTable project={project} />}
          {activeTab === 'Products' && <ProductListTable project={project} />}
          {activeTab === 'Rooms' && <RoomListTable project={project} />}
          {activeTab === 'Documents' && <DocumentListTable project={project} />}
          {activeTab === 'Recycling Request' && <RecyclingRequestForm />}
          {activeTab === 'Distributors' && (
            <ProjectDistributors project={project} setProject={setProject} />
          )}
          {activeTab === 'Contacts' && (
            <ProjectContacts project={project} setProject={setProject} />
          )}
        </>
      )}
    </DashboardLayout>
  );
};

export default ProjectScreen;
