import { useFocusEffect } from '@react-navigation/native';
import { format } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';
import { View } from 'react-native';
import { UserStatsClient } from '../../api';

import { FeedbackClient } from '../../api/FeedbackClient';
import { LeadClient } from '../../api/LeadClient';
import tw from '../../config/tailwind';
import { useToasts } from '../../hooks/useToasts';
import { FeedbackResponse } from '../../shared/types/feedback';
import { LeadProject, LeadResponse, LeadStat, LeadUserResponse } from '../../shared/types/leads';
import { PaginatedResult } from '../../shared/types/paginatedResult';
import { UserLoginStatResponse } from '../../shared/types/users';
import Button from '../shared/Button';
import Col from '../shared/Col';
import GenericTable from '../shared/GenericTable';
import Row from '../shared/Row';
import Select from '../shared/Select';
import Spacer from '../shared/Spacer';
import Text from '../shared/Text';
import ViewAction from '../shared/ViewAction';
import CreateLeadModal from './modals/CreateLeadModal';

interface AdminLeadsTableProps {
  userId?: string;
  perPage?: number;
  showFilters?: boolean;
  showTableActions?: boolean;
}

const defaultProject: LeadProject = {
  id: 'default',
  companyId: '',
  name: 'No Project Selected',
  location: '',
  caseStudyOptOut: true
}

const defaultPartner: LeadUserResponse = {
  id: 'default',
  firstName: '',
  lastName: '',
  businessName: '',
  email: ''
}

const AdminLeadsTable: React.FC<AdminLeadsTableProps> = ({
  userId,
  perPage = 20,
  showFilters,
  showTableActions,
}) => {
  const [paginatedResults, setPaginatedResults] =
    useState<PaginatedResult<LeadResponse>>();
  const [refresh, setRefresh] = useState(0);
  const [stats, setStats] = useState<LeadStat[]>([]);

  const [page, setPage] = useState(1);
  const [desc, setDesc] = useState(true);
  const [showLeadModal, setShowLeadModal] = useState(false);

  useEffect(() => {
    let isActive = true;

    LeadClient.getAll(page).then(
      (results) => isActive && //setPaginatedResults(results)
      setPaginatedResults(() => {
        const tempResults = {...results};
        if(!tempResults.items){
          return results;
        }
  
        const items = [...tempResults.items];
        const fixedItems: LeadResponse[] = [];
  
        items.forEach(item => {
          const {project, source, pinnaclePartner, ...data} = item;
          fixedItems.push({
            project: project?.id ? project : defaultProject,
            source: source || '',
            pinnaclePartner: pinnaclePartner?.id ? pinnaclePartner : Object.assign({...defaultPartner}, {
              firstName: `Other Source:`,
              email: source || `External`
            }) as LeadUserResponse,
            ...data
          } as LeadResponse);
        })
  
        return Object.assign({...tempResults}, {
          items: fixedItems
        } as PaginatedResult<LeadResponse>)
      })
    );

    LeadClient.getStats().then((result) => {
      setStats(result);
    });

    return () => {
      isActive = false;
    };
  }, [page, desc, refresh]);

  useFocusEffect(
    useCallback(() => {
      setRefresh((old) => old + 1);
    }, [])
  );

  const onLeadAdded = (lead: LeadResponse) => {
    setRefresh((x) => x + 1);
  };

  return (
    <>
      <CreateLeadModal
        setVisible={setShowLeadModal}
        visible={showLeadModal}
        onLeadAdded={onLeadAdded}
      />

      <View style={tw`flex flex-row mb-4`}>
        {stats.map((stat, i) => (
          <View key={i} style={tw`flex-1 flex items-center`}>
            <Text style={tw`text-3xl text-green`}>{stat.value}</Text>
            <Text style={tw`font-ubuntu-bold font-bold`}>{stat.status}</Text>
          </View>
        ))}
      </View>

      <Button style={tw`ml-auto mb-4`} onPress={() => setShowLeadModal(true)}>
        Create a lead
      </Button>

      {showFilters && (
        <Row style={tw`bg-background -mx-11  -mt-8`}>
          <Spacer />
          <Col style={tw.style('w-auto max-w-54')}>
            <Select
              onValueChange={setDesc}
              label='Sort by'
              selectedValue={desc}
              options={[
                { label: 'Most recent first', value: true },
                { label: 'Oldest first', value: false },
              ]}
            />
          </Col>
        </Row>
      )}

      <GenericTable
        paginatedResult={paginatedResults}
        headers={{
          name: {
            title: 'Name',
            width: '175px',
          },
          pinnaclePartner: {
            title: 'Pinnacle partner / Other Sources',
            width: '325px',
            format: (item) => {
              if(item){
                return [item.firstName, item.lastName].join(' ') + `\n(${item.email})`;
              }
            },
          },
          status: {
            title: 'Status',
            width: '150px',
          },
          project: {
            title: 'Project',
            width: '150px',
            link: ({project}) => project ? '/dashboard/projects/' + project?.id : '#',
            format: (project) => project ? project.name : null
          },
          createdAt: {
            title: 'Created at',
            width: '150px',
            format: (createdAt) => createdAt ? format(createdAt, 'dd/MM/yy h:mm a') : null,
          },
          updatedAt: {
            title: 'Updated at',
            width: '150px',
            format: (updatedAt) => updatedAt ? format(updatedAt, 'dd/MM/yy h:mm a') : null,
          },
          updatedByUser: {
            title: 'Updated By',
            width: '150px',
            format: (updatedByUser) => `${updatedByUser?.firstName || ''} ${updatedByUser?.lastName || ''}`,
          }
        }}
        setPage={setPage}
        rowStyle={showTableActions ? () => tw`py-1.5` : undefined}
        actions={
          showTableActions
            ? (lead) => [
                <ViewAction
                  to={{
                    screen: 'authenticatedStackNavigator',
                    params: {
                      screen: 'globalAdminStack',
                      params: {
                        screen: 'leads',
                        params: {
                          id: lead.id,
                        },
                      },
                    },
                  }}
                />,
              ]
            : undefined
        }
      />
    </>
  );
};

export default AdminLeadsTable;
