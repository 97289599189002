import React, {
  createContext,
  FC,
  ReactNode,
  useEffect,
  useState,
} from 'react';

import { ProjectStats } from '../@types/project';
import { ProjectClient } from '../api';
import { FavouritesClient } from '../api/FavouritesClient';
import { useAuthContext } from '../hooks/useAuthContext';
import { FavouriteResponse, FavouriteType } from '../shared/types/favourites';

export type AppContextType = {
  projectStats: ProjectStats;
  isLoading: boolean;
  setProjectStats: React.Dispatch<
    React.SetStateAction<ProjectStats | undefined>
  >;
  favourites: Record<number | string, string | boolean>;
  addFavourite: (entityId: number | string, type: FavouriteType) => void;
  removeFavourite: (entityId: number | string) => void;
  checkFavourite: (entityId: number | string) => void;
  menuActive: boolean;
  setMenuActive: (v: boolean) => void;
  globalLoading?: boolean;
  setGlobalLoading?: (v: boolean) => void;
};

export const AppContext = createContext<AppContextType | null>(null);

type Props = {
  children: ReactNode;
};

const AppContextProvider: FC<Props> = ({ children }) => {
  const { activeCompany, user } = useAuthContext();
  const [projectStats, setProjectStats] = useState<ProjectStats>();
  const [loading, setLoading] = useState(true);
  const [globalLoading, setGlobalLoading] = useState(false);
  const [lastActiveCompanyId, setLastActiveCompanyId] = useState<
    string | undefined
  >('not set');

  const [menuActive, setMenuActive] = useState(false);

  const [favourites, setFavourites] = useState<Record<
    number | string,
    string | boolean
  > | null>(null);

  useEffect(() => {
    if (!user) {
      setProjectStats(undefined);
      setFavourites(null);
      return
    }

    if (activeCompany?.id === lastActiveCompanyId) return;

    setLoading(true);

    // console.log("loading init projects and fav", user, activeCompany)

    Promise.all([
      initProjectStats(activeCompany?.id || undefined),
      initFavourites(),
    ]).then(() => {
      setLastActiveCompanyId(activeCompany?.id);
      setLoading(false);
    });
  }, [user, activeCompany?.id, lastActiveCompanyId]);

  const initProjectStats = async (companyId: string | undefined) => {
    const stats: ProjectStats = await ProjectClient.getStats(companyId);
    setProjectStats(stats);
    return stats;
  };

  const initFavourites = async () => {
    // console.log('initfav');
    if (favourites !== null) return;

    // console.log('get existing');
    const existingFavourites = await FavouritesClient.getAll(1).then(
      ({ items }) => {
        // console.log("items? ", items)
        const favourites: Record<number | string, string | false> = {};

        items.forEach((item) => {
          // console.log(item);
          if(item && item.id && item.entity?.id)
          favourites[item.entity.id] = item.id;
        });

        return favourites;
      }
    );
    // .finally(() => {
    //   return [];
    // });
    // console.log('set', existingFavourites);
    setFavourites(existingFavourites);
  };

  const addFavourite = async (
    entityId: number | string,
    type: FavouriteType
  ) => {
    if (!favourites) return;

    const favourite = await FavouritesClient.create({
      entityId,
      type,
    });

    setFavourites((favourites) => ({
      ...favourites,
      [entityId]: favourite.id,
    }));
  };

  const removeFavourite = async (entityId: number | string) => {
    if (!favourites) return;
    const favouriteId = favourites[entityId];
    if (favouriteId) {
      await FavouritesClient.delete(favouriteId as string);
    }
    setFavourites((favourites) => ({ ...favourites, [entityId]: false }));
  };

  const checkFavourite = async (entityId: number | string) => {
    console.log('checkFavourite:::', favourites);
    if (!favourites){
      setFavourites((favourites) => ({
        ...favourites,
      }));
      return
    };
    const inMemoryFavourite = favourites[entityId];
    console.log('checkFavourite1:::', entityId, inMemoryFavourite);
    if (inMemoryFavourite === undefined) {
      console.log('res:::');
      const result: string | false = await FavouritesClient.getById(
        entityId.toString()
      )
        .then((res) => res.id)
        .catch(() => false);
      setFavourites((favourites) => ({
        ...favourites,
        [entityId]: result,
      }));
    }
  };

  return (
    <AppContext.Provider
      value={{
        projectStats: projectStats as ProjectStats,
        setProjectStats,
        isLoading: loading,
        favourites: favourites || {},
        addFavourite,
        removeFavourite,
        checkFavourite,
        menuActive,
        setMenuActive,
        globalLoading,
        setGlobalLoading
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
