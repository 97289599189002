import { useFocusEffect } from '@react-navigation/native';
import React, { useCallback, useEffect, useState } from 'react';
import { View } from 'react-native';
import { ProjectClient } from '../../api';

import tw from '../../config/tailwind';
import { useAuthContext } from '../../hooks/useAuthContext';
import { PaginatedResult } from '../../shared/types/paginatedResult';
import {
  ProjectResponse,
  ProjectRoomResponse,
} from '../../shared/types/projects';
import { UserRole } from '../../shared/types/users';
import FullWidthButton from '../shared/FullWidthButton';
import GenericTable from '../shared/GenericTable';
import ViewAction from '../shared/ViewAction';
import EyeSVG from '../svg/EyeSVG';
import AddRoomModal from './modals/AddRoomModal';

interface RoomListTableProps {
  project: ProjectResponse;
}
const RoomListTable: React.FC<RoomListTableProps> = ({ project }) => {
  const [page, setPage] = useState(1);
  const [refresh, setRefresh] = useState(0);
  const [showNewRoomModal, setShowNewRoomModal] = useState(false);

  const { user, activeCompany } = useAuthContext();

  const [paginatedResult, setPaginatedResult] =
    useState<PaginatedResult<ProjectRoomResponse>>();

  useEffect(() => {
    if (user?.role === UserRole.Admin || activeCompany)
      ProjectClient.rooms.getAllByProjectId(project.id, page).then((result) => {
        setPaginatedResult(result);
      });
    return () => {
      setPaginatedResult(undefined);
    };
  }, [page, activeCompany?.id, refresh]);

  useFocusEffect(
    useCallback(() => {
      setRefresh((old) => old + 1);
    }, [])
  );

  return (
    <View style={tw`bg-white p-8 mb-6`}>
      <GenericTable
        paginatedResult={paginatedResult}
        headers={{
          name: {
            title: 'Room name',
            width: '30%',
            bold: true,
          },
        }}
        actions={(room) => [
          <ViewAction
            to={{
              screen: 'authenticatedStackNavigator',
              params: {
                screen: 'projectStack',
                params: {
                  screen: 'room',
                  params: {
                    projectId: project.id,
                    roomId: room.id,
                  },
                },
              },
            }}
          />,
        ]}
        setPage={setPage}
      />

      <View style={tw`flex flex-row justify-between`}>
        <FullWidthButton
          variant='transparent'
          buttonStyle={tw`py-2 pr-4 w-48`}
          underline
          onPress={() => setShowNewRoomModal(true)}
        >
          + Add a room
        </FullWidthButton>
      </View>

      <AddRoomModal
        visible={showNewRoomModal}
        project={project}
        setVisible={setShowNewRoomModal}
        onRoomAdded={() => setRefresh((x) => x + 1)}
      />
    </View>
  );
};

export default RoomListTable;
