import React from 'react';
import { View } from 'react-native';

import tw from '../../../config/tailwind';
import { WpDistributor } from '../../../shared/types/wordpress';
import Button from '../../shared/Button';
import ContentWrapper from '../../shared/ContentWrapper';
import H3 from '../../shared/H3';
import P from '../../shared/P';
import Spacer from '../../shared/Spacer';

interface DistributorListingProps {
  item: WpDistributor;
  actions?: React.ReactNode[];
}

const DistributorListing: React.FC<DistributorListingProps> = ({
  item,
  actions,
}) => {
  return (
    <ContentWrapper style={tw`h-full border border-dark-300`}>
      <>
        <H3>{item.title.rendered}</H3>
        <P>{item.acf.address.full_address}</P>
        {!!item.acf.telephone && <P>Phone: {item.acf.telephone}</P>}
        {!!item.acf.website_url && <P>Website: {item.acf.website_url}</P>}

        <Spacer />

        <View style={tw`flex flex-row justify-between`}>
          {actions?.map((action, i) => (
            <View key={i}>{action}</View>
          ))}
        </View>
      </>
    </ContentWrapper>
  );
};

export default DistributorListing;
