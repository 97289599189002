import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { ActivityIndicator, View } from 'react-native';
import * as Yup from 'yup';
import { DocumentClient } from '../../../api';

import tw from '../../../config/tailwind';
import { useToasts } from '../../../hooks/useToasts';
import {
  CreateDocumentPayload,
  DocumentResponse,
  UpdateDocumentPayload,
} from '../../../shared/types/documents';
import {
  MarkupResponse,
  UpdateImageMarkupPayload,
} from '../../../shared/types/markup';
import { ProjectResponse } from '../../../shared/types/projects';
import getBase64 from '../../../utils/getBase64';
import Button from '../../shared/Button';
import DefaultModal from '../../shared/DefaultModal';
import FilePicker from '../../shared/FilePicker';
import InputText from '../../shared/InputText';
import InputValidationMessage from '../../shared/InputValidationMessage';
import MarkupEditor from './MarkupEditor';

interface DocumentModalProps {
  projectId: string;
  visible: boolean;
  setVisible: (v: boolean) => void;
  onDocumentAdded: (comment: DocumentResponse) => void;
  document?: DocumentResponse;
}

const DocumentModal: React.FC<DocumentModalProps> = ({
  projectId,
  visible,
  setVisible,
  onDocumentAdded,
  document,
}) => {
  const { addToast, addUnhandledErrorToast } = useToasts();
  const [file, setFile] = useState<File>();
  const [fileError, setFileError] = useState('');
  const [fileUrl, setFileUrl] = useState<string>(document?.publicUrl || '');
  const [showMarkupEditor, setShowMarkupEditor] = useState(!!document?.markup);
  const [markup, setMarkup] = useState<UpdateImageMarkupPayload[]>(
    document?.markup?.map(
      ({ createdByUserId, createdAt, updatedAt, ...markup }) => markup
    ) || []
  );

  const initialValues: CreateDocumentPayload = {
    title: document?.title || '',
    description: document?.description || '',
    projectId,
  };

  const schema: Yup.SchemaOf<typeof initialValues> = Yup.object().shape({
    title: Yup.string().required().label('Document title').max(255),
    description: Yup.string().required().label('Description'),
    projectId: Yup.string().required(),
    markup: Yup.array().optional(),
  });

  useEffect(() => {
    if (file) {
      getBase64(file).then((res) => setFileUrl(res as string));
    }
  }, [file]);

  const onSubmit = async (payload: CreateDocumentPayload) => {
    try {
      let savedDocument: DocumentResponse;

      if (document) {
        // Update Document
        savedDocument = await DocumentClient.update(
          document.id,
          {
            title: payload.title,
            description: payload.description,
            markup,
          },
          file
        );
      } else {
        // Create Document
        if (!file) {
          setFileError('Please provide a document.');
          return;
        }

        payload.markup = markup.map(({ id, ...m }) => m);
        savedDocument = await DocumentClient.create(payload, file);
      }
      addToast({
        title: 'Document ' + document ? 'updated' : 'created',
        description: `You ${document ? 'updated' : 'added'} the document '${
          payload.title
        }'`,
        type: 'success',
      });
      resetModal();
      onDocumentAdded(savedDocument);
    } catch (error) {
      addUnhandledErrorToast(error);
    }
  };

  const resetModal = () => {
    setFile(undefined);
    setFileUrl('');
    setShowMarkupEditor(false);
    setMarkup([]);
    setVisible && setVisible(false);
  };

  const onFileChange = (file: File) => {
    setFile(file);
    setFileError('');
  };

  return (
    <DefaultModal
      setVisible={resetModal}
      visible={visible}
      title='New document'
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={schema}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          values,
          errors,
          isSubmitting,
        }) => (
          <View style={tw`mt-4`}>
            <View>
              <InputText
                placeholder='Document title'
                label='Document title'
                required
                value={values.title}
                setValue={handleChange('title')}
                onBlur={handleBlur('title')}
                hasError={!!errors.title}
              />
              {errors.title && (
                <InputValidationMessage>{errors.title}</InputValidationMessage>
              )}
            </View>
            <View>
              <InputText
                placeholder='Description'
                label='Description'
                required
                value={values.description}
                setValue={handleChange('description')}
                onBlur={handleBlur('description')}
                hasError={!!errors.description}
                multiline
              />
              {errors.description && (
                <InputValidationMessage>
                  {errors.description}
                </InputValidationMessage>
              )}
            </View>

            {!showMarkupEditor && (
              <FilePicker
                type='*/*'
                label='Select document'
                setValue={ (f) => f && onFileChange(f)}
                hasError={!!fileError}
              />
            )}

            {fileError && (
              <InputValidationMessage>{fileError}</InputValidationMessage>
            )}

            {showMarkupEditor && fileUrl && (
              <MarkupEditor
                markers={markup}
                image={fileUrl}
                onChange={(markers) => setMarkup(markers)}
              />
            )}

            <View style={tw`flex flex-row items-center justify-end mt-4`}>
              {isSubmitting && <ActivityIndicator style={tw`mr-4`} />}
              {!showMarkupEditor &&
                file &&
                file.type.startsWith('image') &&
                fileUrl && (
                  <Button
                    onPress={() => {
                      setShowMarkupEditor((editor) => !editor);
                    }}
                    disabled={isSubmitting || !file}
                    style={tw`mr-3`}
                  >
                    Add markup
                  </Button>
                )}

              <Button onPress={handleSubmit} disabled={isSubmitting}>
                Upload document
              </Button>
            </View>
          </View>
        )}
      </Formik>
    </DefaultModal>
  );
};

export default DocumentModal;
