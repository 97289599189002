import { Formik } from 'formik';
import { PhoneNumberUtil } from 'google-libphonenumber';
import React, { useState } from 'react';
import { ActivityIndicator, Image, View } from 'react-native';
import * as Yup from 'yup';
import { ContactClient } from '../../../api';
import tw from '../../../config/tailwind';
import { phoneRegExp } from '../../../constants';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { useToasts } from '../../../hooks/useToasts';
import {
  ContactResponse,
  UpdateContactPayload
} from '../../../shared/types/contacts';
import { formatToE164 } from '../../../utils/transformToE164';
import InputMap from '../../maps/InputMap';
import Button from '../../shared/Button';
import Col from '../../shared/Col';
import DefaultModal from '../../shared/DefaultModal';
import FilePicker from '../../shared/FilePicker';
import FullWidthButton from '../../shared/FullWidthButton';
import InputLabel from '../../shared/InputLabel';
import InputText from '../../shared/InputText';
import InputValidationMessage from '../../shared/InputValidationMessage';
import Row from '../../shared/Row';
import { UserRole } from '../../../shared/types/users';

const phoneUtil: PhoneNumberUtil =
  require('google-libphonenumber').PhoneNumberUtil.getInstance();

interface AdminContactModalProps {
  setVisible: (v: boolean) => void;
  contact?: ContactResponse;
  onUpdated: (contact: ContactResponse) => void;
  onDeleteUpdated: () => void;
}

const AdminContactModal: React.FC<AdminContactModalProps> = ({
  setVisible,
  contact: currentContact,
  onUpdated,
  onDeleteUpdated
}) => {
  const { addToast, addUnhandledErrorToast } = useToasts();
  const [file, setFile] = useState<File>();
  const [contact, setContact] = useState(currentContact);
  const { user } = useAuthContext();

  const initialValues: Required<Omit<UpdateContactPayload, 'image'>> = {
    id: contact?.id || '',
    name: contact?.name || '',
    email: contact?.email || '',
    mobile: contact?.mobile || '',
    role: contact?.role || '',
    telephone: contact?.telephone || '',
    latitude: String(contact?.latitude || 54.9306686),
    longitude: String(contact?.longitude || -1.6233506),
  };

  const schema: Yup.SchemaOf<Omit<UpdateContactPayload, 'image'>> =
    Yup.object().shape({
      id: Yup.string().default(''),
      name: Yup.string().required().label('Name'),
      email: Yup.string().required().email().label('Email'),
      mobile: Yup.string()
        .matches(phoneRegExp, 'Please provide a valid mobile number')
        .transform(formatToE164)
        .test('phoneFormatTest', 'Invalid format', (value) => {
          if (!value) return true;
          try {
            return phoneUtil.isValidNumberForRegion(
              phoneUtil.parse(value, 'GB'),
              'GB'
            );
          } catch {
            return false;
          }
        }),
      telephone: Yup.string()
        .matches(phoneRegExp, 'Please provide a valid phone number')
        .transform(formatToE164)
        .test('phoneFormatTest', 'Invalid format', (value) => {
          if (!value) return true;
          try {
            return phoneUtil.isValidNumberForRegion(
              phoneUtil.parse(value, 'GB'),
              'GB'
            );
          } catch {
            return false;
          }
        }),
      role: Yup.string().required().label('Role'),
      latitude: Yup.string().required(),
      longitude: Yup.string().required(),
    });

  const onSubmit = async (values: typeof initialValues) => {
    if (values.telephone) {
      values.telephone = formatToE164(values.telephone);
    }

    if (values.mobile) {
      values.mobile = formatToE164(values.mobile);
    }

    try {
      if (!contact) {
        const { id, ...createContactPayload } = values;
        const res = await ContactClient.create(createContactPayload, file);

        addToast({
          title: 'Contact created',
          description: `Contact has been created`,
          type: 'success',
        });
        onUpdated(res);
      } else {
        const res = await ContactClient.update(
          {
            ...values,
            image: contact.image,
          },
          file
        );
        addToast({
          title: 'Contact updated',
          description: `Contact has been updated`,
          type: 'success',
        });
        onUpdated(res);
      }
      //   onUpdated();
      setVisible(false);
    } catch (e) {
      addUnhandledErrorToast(e);
    }
  };

  const handleDelete = async (contactId: string) => {
    // call delete api
    console.log('calling delete api');
    await ContactClient.delete(contactId);
    setVisible(false);
    addToast({
      title: 'Contact deleted',
      description: `Contact has been deleted`,
      type: 'success',
    });

    onDeleteUpdated();
  }

  return (
    <DefaultModal
      setVisible={setVisible}
      visible
      title={contact ? 'Edit contact' : 'Create contact'}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={schema}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          values,
          errors,
          isSubmitting,
        }) => (
          <View style={tw`mt-4`}>
            <Row>
              <Col style={tw`w-full sm:w-1/2`}>
                <InputText
                  value={values.name}
                  setValue={handleChange('name')}
                  onBlur={handleBlur('name')}
                  placeholder='Name'
                  hasError={!!errors.name}
                  label='Name'
                  required
                />
                {errors.name && (
                  <InputValidationMessage>{errors.name}</InputValidationMessage>
                )}
              </Col>
              <Col style={tw`w-full sm:w-1/2`}>
                <InputText
                  value={values.role}
                  setValue={handleChange('role')}
                  onBlur={handleBlur('role')}
                  placeholder='Role'
                  hasError={!!errors.role}
                  label='Role'
                  required
                />
                {errors.role && (
                  <InputValidationMessage>{errors.role}</InputValidationMessage>
                )}
              </Col>
              <Col style={tw`w-full sm:w-1/2`}>
                <InputText
                  value={values.email}
                  setValue={handleChange('email')}
                  onBlur={handleBlur('email')}
                  placeholder='Email'
                  hasError={!!errors.email}
                  label='Email'
                  required
                />
                {errors.email && (
                  <InputValidationMessage>
                    {errors.email}
                  </InputValidationMessage>
                )}
              </Col>
              <Col style={tw`w-full sm:w-1/2`}>
                <InputText
                  value={values.telephone}
                  setValue={handleChange('telephone')}
                  onBlur={handleBlur('telephone')}
                  placeholder='Telephone'
                  hasError={!!errors.telephone}
                  label='Telephone'
                />
                {errors.telephone && (
                  <InputValidationMessage>
                    {errors.telephone}
                  </InputValidationMessage>
                )}
              </Col>
              <Col style={tw`w-full sm:w-1/2`}>
                <InputText
                  value={values.mobile}
                  setValue={handleChange('mobile')}
                  onBlur={handleBlur('mobile')}
                  placeholder='Mobile'
                  hasError={!!errors.mobile}
                  label='Mobile'
                />
                {errors.mobile && (
                  <InputValidationMessage>
                    {errors.mobile}
                  </InputValidationMessage>
                )}
              </Col>
            </Row>
            <InputLabel label='Photo' />
            {contact?.image ? (
              <>
                <Row>
                  <Col style={tw`w-auto flex-initial`}>
                    <View
                      style={tw`w-[100px] h-[100px] bg-background-100 border border-background-600`}
                    >
                      <Image
                        source={{ uri: contact.image.publicUrl }}
                        resizeMode={'contain'}
                        style={{
                          width: '100%',
                          height: '100%',
                        }}
                      />
                    </View>
                  </Col>
                  <Col style={tw`w-auto flex grow self-center`}>
                    <View style={tw`self-start`}>
                      <FullWidthButton
                        onPress={() => setContact({ ...contact, image: null })}
                        variant='transparent'
                        buttonStyle={tw`py-2 pr-4 mb-0`}
                        underline
                      >
                        Remove
                      </FullWidthButton>
                    </View>
                  </Col>
                </Row>
              </>
            ) : (
              <FilePicker
                type='image/*'
                setValue={setFile}
                label='Select photo'
              />
            )}
            <InputMap
              latitude={parseFloat(values.latitude) ?? 0}
              longitude={parseFloat(values.longitude) ?? 0}
              setLatitude={(v) => setFieldValue('latitude', v)}
              setLongitude={(v) => setFieldValue('longitude', v)}
            />
            <View style={tw`flex flex-row items-center justify-end mt-4`}>
              {isSubmitting && <ActivityIndicator style={tw`mr-4`} />}
              <Button onPress={handleSubmit} disabled={isSubmitting}>
                Save
              </Button>
              {(contact && user?.role === UserRole.Admin) && <Button onPress={() => handleDelete(values.id)} disabled={false} variant={'danger'} style={`ml-2`} >Delete</Button>}
            </View>
          </View>
        )}
      </Formik>
    </DefaultModal>
  );
};

export default AdminContactModal;
