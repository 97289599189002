import React, { useEffect, useState } from 'react';
import { TextInput, TextInputProps, View } from 'react-native';
import { ClassInput } from 'twrnc/dist/esm/types';

import SearchSvg from '../assets/svg/search.svg';
import tw from '../config/tailwind';
import { GEOLOCATE_SEARCH_LABEL } from '../constants';

interface SearchBarProps {
  textInputProps: TextInputProps;
  style?: ClassInput;
  onStoppedTyping?: (query: string) => void;
  action?: React.ReactNode;
  onChangeText?: (v: string, setValue: (v: string) => void) => void;
}

const SearchBar: React.FC<SearchBarProps> = ({
  textInputProps,
  style,
  onStoppedTyping,
  action,
  onChangeText,
}) => {
  const [value, setValue] = useState(textInputProps?.value || '');

  useEffect(() => {
    if (textInputProps?.value !== undefined && textInputProps.value !== value) {
      setValue(textInputProps.value);
    }
  }, [textInputProps.value]);

  useEffect(() => {
    if (onStoppedTyping) {
      const delay = setTimeout(() => {
        onStoppedTyping(value);
      }, 750);

      return () => clearTimeout(delay);
    }
  }, [value]);

  return (
    <View
      style={tw.style(
        `w-full lg:w-1/2 mb-2 border-2 relative flex-row items-center h-[50px] relative`,
        style
      )}
    >
      <TextInput
        {...textInputProps}
        style={tw`flex py-3.5 px-5 font-ubuntu-bold w-full h-full`}
        value={value}
        onChangeText={(v) =>
          onChangeText ? onChangeText(v, setValue) : setValue(v)
        }
      />
      {action}
      {!action && (
        <View
          style={tw`flex justify-center items-center w-8 mr-2 ml-auto`}
          pointerEvents='none'
        >
          <SearchSvg style={tw`text-green`} width={16} height={16} />
        </View>
      )}
    </View>
  );
};

export default SearchBar;
