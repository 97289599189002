import React, { useEffect, useState } from 'react';
import { ActivityIndicator, ScrollView, View } from 'react-native';
import { WpClient } from '../../api';

import tw from '../../config/tailwind';
import { WpProductSearchResult } from '../../shared/types/wordpress';
import { decodeEntities } from '../../utils/decodeEntities';
import Select from '../shared/Select';
import ProductListing from './ProductListing';

interface WpProductPickerProps {
  productIds: number[];
  setProductIds: (value: number[]) => void;
  hideFavBtn?: boolean;
  noOverflow?: boolean;
}

const WpProductPicker: React.FC<WpProductPickerProps> = ({
  productIds,
  setProductIds,
  hideFavBtn,
  noOverflow,
}) => {
  const [products, setProducts] = useState<WpProductSearchResult[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    WpClient.searchProducts(currentPage).then(async ({ items, totalPages }) => {
      let products = items;
      const promises = [];
      for (let i = currentPage + 1; i <= totalPages; i++) {
        promises.push(WpClient.searchProducts(i));
      }
      const results = await Promise.all(promises);
      results.forEach((res) => {
        products = [...products, ...res.items];
      });
      setProducts(
        products.sort((a, b) => {
          return a.post_title < b.post_title ? -1 : 1;
        })
      );
      setLoading(false);
    });
  }, [currentPage]);

  const addProduct = (id: number) => {
    setProductIds([...productIds, id]);
  };

  const removeProduct = (id: number) => {
    setProductIds(productIds.filter((x) => x !== id));
  };

  if (loading) return <ActivityIndicator />;

  return (
    <View>
      <ScrollView style={tw.style(!noOverflow && `max-h-52`)}>
        {productIds.map((productId) => {
          const product = products.find((x) => x.id === productId)!;
          return (
            <ProductListing
              key={`product-picker-product-${productId}`}
              product={product}
              type='searchresult'
              onPressRemove={removeProduct}
              hideFavBtn={hideFavBtn}
            />
          );
        })}
      </ScrollView>

      <View style={tw`mt-4`}>
        <Select
          selectedValue={undefined}
          onValueChange={addProduct}
          placeholderOption='Add a product...'
          options={products
            .filter((product) => !productIds.includes(product.id))
            .map((product) => ({
              label: decodeEntities(product.post_title),
              value: product.id,
            }))}
          searchable
        />
        {loading && <ActivityIndicator />}
      </View>
    </View>
  );
};

export default WpProductPicker;
