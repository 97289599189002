import { ContactResponse } from './contacts';
import { WpDistributor, WpProduct, WpResource } from './wordpress';

export enum FavouriteType {
  Resource = 'resource',
  Product = 'product',
  Distributor = 'distributor',
  Contact = 'contact',
}

export type FavouriteEntity =
  | WpProduct
  | WpResource
  | WpDistributor
  | ContactResponse;

export type FavouriteResponse = {
  id: string;
  userId: string;
  createdAt: Date;
  tags: string[];
} & (
  | {
      type: FavouriteType.Resource;
      entity: WpResource;
    }
  | {
      type: FavouriteType.Product;
      entity: WpProduct;
    }
  | {
      type: FavouriteType.Distributor;
      entity: WpDistributor;
    }
  | {
      type: FavouriteType.Contact;
      entity: ContactResponse;
    }
);

export interface CreateFavouritePayload {
  entityId: number | string;
  type: FavouriteType;
}

export interface UpdateFavouriteTagsPayload {
  tags: string[];
}
