import { RouteProp, useRoute } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { ActivityIndicator, Text, View } from 'react-native';

import { UserClient } from '../../../api';
import { LeadClient } from '../../../api/LeadClient';
import AdminUserActivityTable from '../../../components/admin/AdminUserActivityTable';
import EditLeadPage from '../../../components/admin/EditLeadPage';
import DeleteUserModal from '../../../components/admin/modals/DeleteUserModal';
import EditUserRoleModal from '../../../components/admin/modals/EditUserRoleModal';
import UserDetailsForm from '../../../components/my-account/UserDetailsForm';
import TaskCommentList from '../../../components/project/TaskCommentList';
import BackButton from '../../../components/shared/BackButton';
import Button from '../../../components/shared/Button';
import ContentWrapper from '../../../components/shared/ContentWrapper';
import H1 from '../../../components/shared/H1';
import H2 from '../../../components/shared/H2';
import H3 from '../../../components/shared/H3';
import tw from '../../../config/tailwind';
import DashboardLayout from '../../../layout/DashboardLayout';
import useLinkProps, {
  GlobalAdminStackNavigatorParamList,
} from '../../../routes/types';
import { LeadResponse } from '../../../shared/types/leads';
import { UserResponse } from '../../../shared/types/users';

interface AdminScreenProps {}

const AdminLeadScreen: React.FC<AdminScreenProps> = ({}) => {
  const route =
    useRoute<RouteProp<GlobalAdminStackNavigatorParamList, 'leads'>>();

  const [lead, setLead] = useState<LeadResponse>();
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [editUserAccessModalVisible, setEditUserAccessModalVisible] =
    useState(false);

  const usersLink = useLinkProps<'authenticatedStackNavigator'>({
    screen: 'authenticatedStackNavigator',
    params: {
      screen: 'globalAdminStack',
      params: {
        screen: 'admin',
      },
    },
  });

  useEffect(() => {
    LeadClient.getById(route.params.id).then((result) => {
      setLead(result);
    });
  }, [route.params.id]);

  return (
    <DashboardLayout>
      <BackButton onPress={usersLink.onPress}>Back to admin</BackButton>

      {lead ? (
        <>
          <H1 xl>Lead: {lead.name}</H1>
          <EditLeadPage lead={lead} updateLead={setLead} />

          <View style={tw.style(`p-6 bg-white mx-0 mt-4`)}>
            <H2>Comments</H2>
            <TaskCommentList task={lead} type='lead' />
          </View>
        </>
      ) : (
        <ActivityIndicator />
      )}
    </DashboardLayout>
  );
};

export default AdminLeadScreen;
