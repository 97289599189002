import React, { ComponentPropsWithRef, useEffect, useRef } from 'react';
import { ActivityIndicator, Animated, Pressable } from 'react-native';
import { darken } from 'react-native-color-toolkit';
import { useHover } from 'react-native-web-hooks';
import { ClassInput } from 'twrnc/dist/esm/types';

import { twMerge } from 'tailwind-merge';
import tw from '../../config/tailwind';
import { isWeb } from '../../constants';
import Text from './Text';

type Props = ComponentPropsWithRef<any> & {
  children: string | React.ReactNode;
  onPress?: () => void;
  style?: ClassInput;
  variant?: 'default' | 'dark' | 'transparent' | 'danger';
  outlined?: boolean;
  disabled?: boolean;
  loading?: boolean;
};

const buttonColor: Record<Required<Props>['variant'], string> = {
  default: 'green',
  dark: 'dark',
  transparent: 'transparent',
  danger: 'red'
};

const textColor: Record<Required<Props>['variant'], string> = {
  default: 'blue',
  dark: 'white',
  transparent: 'dark',
  danger: 'white'
};

const AnimatedPressable = Animated.createAnimatedComponent(Pressable);

// const Button = React.forwardRef<any, Props>(
//   (
//     { children, onPress, style, variant = 'default', outlined, disabled },
//     ref
//   ) => {

const Button: React.FC<Props> = ({
  children,
  onPress,
  style,
  variant = 'default',
  outlined,
  disabled,
  loading,
}) => {
  const bColor = buttonColor[variant];
  const tColor = outlined ? textColor['default'] : textColor[variant];

  const ref = useRef(null);
  const isHovered = useHover(ref);

  const hoverAnim = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    Animated.timing(hoverAnim, {
      toValue: isHovered ? 1 : 0,
      duration: 200,
      useNativeDriver: true,
    }).start();
  }, [isHovered]);

  const mergedStyles = twMerge(`px-8 py-4 self-start justify-center items-center ${
    outlined ? `border-2 border-${bColor}` : `bg-${bColor}`
  }`, style);
  const buttonStyles: any = [{ minHeight: 60 }, tw`${mergedStyles}`];

  if (isWeb && !outlined && bColor !== 'transparent') {
    if (disabled || loading) {
      buttonStyles.push({
        backgroundColor: tw.color(bColor),
      });
    } else {
      buttonStyles.push({
        backgroundColor: hoverAnim.interpolate({
          inputRange: [0, 1],
          outputRange: [
            tw.color(bColor) || '',
            darken(tw.color(bColor) || '', 0.14),
          ],
        }),
      });
    }
  }

  if (disabled || loading) {
    buttonStyles.push(tw`opacity-75`);
  }

  return (
    <>
      <AnimatedPressable
        style={buttonStyles}
        onPress={onPress}
        disabled={disabled || loading}
        ref={ref}
      >
        <Text style={tw`font-ubuntu-bold relative font-bold text-${tColor}`}>
          {children}
          <Animated.View
            style={[
              tw`bg-${
                variant === 'transparent' ? 'green' : tColor
              } absolute h-0.5 left-0 -bottom-1`,
              isWeb && {
                width:
                  disabled || loading
                    ? '0%'
                    : hoverAnim.interpolate({
                        inputRange: [0, 1],
                        outputRange: ['0%', '100%'],
                      }),
              },
            ]}
          />
        </Text>
        {loading && (
          <ActivityIndicator style={tw`absolute top-0 h-full -right-9`} />
        )}
      </AnimatedPressable>
    </>
  );
};

export default Button;
