import React, { useEffect, useRef, useState } from 'react';
import { ActivityIndicator, Pressable } from 'react-native';
import { useHover } from 'react-native-web-hooks';

import tw from '../../config/tailwind';
import { useAppContext } from '../../hooks/useAppContext';
import { useToasts } from '../../hooks/useToasts';
import { FavouriteType } from '../../shared/types/favourites';
import Col from '../shared/Col';
import HeartSVG from '../svg/HeartSVG';
import HeartSolidSVG from '../svg/HeartSolidSVG';

interface FavButtonProps {
  entityId: number | string;
  type: FavouriteType;
}

const FavButton: React.FC<FavButtonProps> = ({ entityId, type }) => {
  const { addToast } = useToasts();
  const { favourites, addFavourite, removeFavourite, checkFavourite } =
    useAppContext();
  const [isFavourited, setIsFavourited] = useState(false);
  const [isUpdating, setUpdating] = useState(false);

  const ref = useRef(null);
  const isHovered = useHover(ref);

  /**
   *  ? Fetch isFavourite state if we do not have it in memory => this works as an edge case when
   *  ? the favourite state has not been pre-loaded and has not been checked at a component level before mounting the button
   */
  useEffect(() => {
    // console.log(entityId, favourites);
    const inMemoryFavourite = favourites[entityId];

    if (inMemoryFavourite === undefined) {
      if (isUpdating) return;
      setUpdating(true);
      checkFavourite(entityId);
    }
  });

  /**
   * ? Keeps track of isFavourited state
   */
  useEffect(() => {
    const inMemoryFavourite = favourites[entityId];

    if (inMemoryFavourite !== undefined) {
      setIsFavourited(!!inMemoryFavourite);
      setUpdating(false);
    }
  }, [favourites]);

  const toggleFavourite = async () => {
    if (isUpdating) return;
    try {
      setUpdating(true);
      if (isFavourited) {
        removeFavourite(entityId);
      } else {
        addFavourite(entityId, type);
      }
    } catch (error) {
      addToast({
        title: 'Something went wrong',
        description: '',
        type: 'error',
      });
      setUpdating(false);
    }
  };

  return (
    <Pressable ref={ref} onPress={toggleFavourite}>
      <Col
        style={tw.style(
          `bg-background-300 rounded-full justify-center items-center w-[40px] h-[40px]`
        )}
      >
        {isUpdating ? (
          <ActivityIndicator
            color='currentColor'
            size={18}
            style={tw`text-green`}
          />
        ) : isFavourited ? (
          <HeartSolidSVG
            height={18}
            width={18}
            style={tw.style(`mt-px -mb-0.5 text-green`)}
          />
        ) : (
          <HeartSVG
            height={18}
            width={18}
            style={tw.style(`mt-px -mb-0.5`, isHovered && 'text-green')}
          />
        )}
      </Col>
    </Pressable>
  );
};

export default FavButton;
