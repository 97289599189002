import { FC, useState } from 'react';
import {
  NativeSyntheticEvent,
  TextInputFocusEventData,
  Pressable,
  TextInput,
  View,
} from 'react-native';

import tw from '../../config/tailwind';
import EyeSVG from '../svg/EyeSVG';
import InputLabel from './InputLabel';

type Props = {
  value: string;
  placeholder?: string;
  setValue?: (v: string) => void;
  onBlur?: (e: NativeSyntheticEvent<TextInputFocusEventData>) => void;
  hasError?: boolean;
  label?: string;
  required?: boolean;
  labelSize?: 'sm' | 'md';
};

const InputPassword: FC<Props> = ({
  value,
  setValue,
  onBlur,
  placeholder,
  hasError,
  label,
  required,
  labelSize,
}) => {
  const [hidden, setHidden] = useState(true);

  return (
    <>
      {label && (
        <InputLabel label={label} required={required} labelSize={labelSize} />
      )}
      <View
        style={tw`mb-4 border-2 font-sans ${
          hasError ? 'border-red' : 'border-blue'
        } flex flex-row bg-white min-h-[50px]`}
      >
        <TextInput
          style={tw`px-4 py-3 flex-1`}
          value={value}
          onChangeText={setValue}
          onBlur={onBlur}
          placeholder={placeholder}
          placeholderTextColor={tw.color(`dark opacity-50`)}
          secureTextEntry={hidden}
        />
        <Pressable
          onPress={() => setHidden((x) => !x)}
          style={tw`items-center flex flex-row`}
        >
          <EyeSVG style={tw`mx-4 ${hidden ? 'text-green' : 'text-blue'}`} />
        </Pressable>
      </View>
    </>
  );
};

export default InputPassword;
