import { API_BASE_URL } from "../env-consts";
import { GlobalSettingsResponse, UpdateGlobalSettingsPayload } from "../shared/types/globalSettings";
import ApiClient from "./ApiClient";

const client = new ApiClient(`${API_BASE_URL}/global-settings`);
export const GlobalSettingsClient = {
    async update(id: string, payload: UpdateGlobalSettingsPayload) {
        const res = await client.patch(`/${id}`, payload, {
            headers:{
                'Content-Type': 'application/json'
            }
        });
    },

    async getAll(){
        const res = await client.get<GlobalSettingsResponse[]>(`/`);
        return res.data;
    }
}