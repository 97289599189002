export default function requestWithTimeout<T>(
  req: () => Promise<T>,
  timeoutMs = 5000
): Promise<T> {
  return new Promise(async (resolve, reject) => {
    const timer = setTimeout(() => {
      reject(new Error(`Promise timed out after ${timeoutMs} ms`));
    }, timeoutMs);

    try {
      const res = await req();
      clearTimeout(timer);
      resolve(res);
    } catch (error) {
      clearTimeout(timer);
      reject(error);
    }
  });
}
