import { API_BASE_URL } from '../env-consts';
import {
  RecyclingRequestPayload,
  RequestARigPayload,
  TrainingBookingPayload,
} from '../shared/types/forms';
import ApiClient from './ApiClient';

const client = new ApiClient(`${API_BASE_URL}/forms`);

export const FormClient = {
  async bookTraining(payload: TrainingBookingPayload) {
    return client
      .post<string>(`/book-training`, payload)
      .then(({ data }) => data);
  },

  async requestARig(payload: RequestARigPayload) {
    return client
      .post<string>(`/request-a-rig`, {
        ...payload,
        preferredDate: payload.preferredDate.toISOString(),
      })
      .then(({ data }) => data);
  },

  async recyclingRequest(payload: RecyclingRequestPayload) {
    return client
      .post<string>(`/recycling-request`, payload)
      .then(({ data }) => data);
  },
};
