import { MarkerProps } from '@react-google-maps/api';
import { FC, useEffect, useState } from 'react';
import {
  KeyboardTypeOptions,
  NativeSyntheticEvent,
  TextInput,
  TextInputFocusEventData,
  View,
} from 'react-native';

import tw from '../../config/tailwind';
import InputText from '../shared/InputText';
import Map from './Map';

type Props = {
  longitude: number;
  setLongitude: (v: number) => void;
  latitude: number;
  setLatitude: (v: number) => void;
};

const InputMap: FC<Props> = ({
  longitude,
  setLongitude,
  latitude,
  setLatitude,
}) => {
  const [longInput, setLongInput] = useState(longitude.toString());
  const [latInput, setLatInput] = useState(latitude.toString());

  useEffect(() => {
    setLongInput(longitude.toString());
  }, [longitude]);
  useEffect(() => {
    setLatInput(latitude.toString());
  }, [latitude]);

  const [markers, setMarkers] = useState<MarkerProps[]>([
    {
      position: { lat: latitude, lng: longitude },
      draggable: true,
      onDrag: (e) => {
        if (e.latLng) {
          setLongitude(e.latLng?.lng());
          setLatitude(e.latLng?.lat());
        }
      },
    },
  ]);

  return (
    <>
      <View style={tw`flex flex-row`}>
        <View style={tw`mr-4`}>
          <InputText
            value={latInput}
            setValue={(v) => {
              setLatInput(v);
              if (Number(v)) {
                setLatitude(Number(v));
                markers[0].position = {
                  lat: Number(v),
                  lng:
                    typeof markers[0].position.lng === 'number'
                      ? markers[0].position.lng
                      : markers[0].position.lng(),
                };
              }
            }}
            // hasError={latInput !== latitude.toString()}
            label='Latitude'
          />
        </View>
        <View>
          <InputText
            value={longInput}
            setValue={(v) => {
              setLongInput(v);
              if (Number(v)) {
                setLongitude(Number(v));
                markers[0].position = {
                  lat:
                    typeof markers[0].position.lat === 'number'
                      ? markers[0].position.lat
                      : markers[0].position.lat(),
                  lng: Number(v),
                };
              }
            }}
            label='Longitude'
            // hasError={longInput !== longitude.toString()}
          />
        </View>
      </View>
      <Map markers={markers} height={240} onMarkerPress={(id) => {}} />
    </>
  );
};

export default InputMap;
