import { FC, ReactNode } from 'react';
import { ClassInput } from 'twrnc/dist/esm/types';

import tw from '../../config/tailwind';
import Text from './Text';

type Props = {
  children: ReactNode;
  style?: ClassInput;
};

const H2: FC<Props> = ({ children, style }) => {
  return (
    <Text
      style={tw.style(
        `mt-2 mb-5 text-lg font-bold font-ubuntu-bold text-blue`,
        style
      )}
    >
      {children}
    </Text>
  );
};

export default H2;
