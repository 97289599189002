import React, { useRef } from 'react';
import { View } from 'react-native';
import { useHover } from 'react-native-web-hooks';

import tw from '../../config/tailwind';
import Text from '../shared/Text';

interface TabProps {
  tab: string;
  active: boolean;
  onPress: () => void;
}

const Tab: React.FC<TabProps> = ({ tab, active, onPress }) => {
  const ref = useRef();
  const isHovered = useHover(ref);

  return (
    <View
      style={tw`w-1/4 px-2 py-4 relative flex justify-center items-center border-b border-blue mb-1`}
    >
      {active && (
        <View
          style={tw`absolute left-0 right-0 -bottom-.75 mb-px border-b-4 border-green`}
        />
      )}
      <Text
        ref={ref}
        style={tw.style(
          `font-ubuntu-bold font-bold text-green text-center`,
          (active || isHovered) && 'text-blue'
        )}
        onPress={onPress}
      >
        {tab}
      </Text>
    </View>
  );
};

export default Tab;
