import React, { useEffect } from 'react';
import { Modal, Pressable, ScrollView, View, Animated } from 'react-native';

import tw from '../../config/tailwind';
import { useToasts } from '../../hooks/useToasts';
import CrossSVG from '../svg/CrossSVG';
import H2 from './H2';

interface InfoBoxProps {
  children?: React.ReactNode;
  title?: string;
  visible: boolean;
  setVisible: (v: boolean) => void;
  modalWidth?: 'md' | 'lg';
  transparent?: boolean;
  animationType?: "none" | "slide" | "fade" | undefined;
}

const DefaultModal: React.FC<InfoBoxProps> = ({
  children,
  title,
  visible,
  setVisible,
  modalWidth,
  transparent = true,
  animationType = 'fade'
}) => {
  const { setShowModalUnderlay } = useToasts();

  const close = () => {
    setVisible(false);
  };

  // TODO: This is an awful solution,
  // We want toasts to render ontop of the modal backdrop, but there doesn't seem to be an obvious
  // way to do so. A very dirty solution is to have a transparent View rendered in the toast context
  // provider when a modal is shown.
  useEffect(() => {
    setShowModalUnderlay(visible);

    return () => setShowModalUnderlay(false);
  }, [visible]);

  return (
    <>
      <Modal
        visible={visible}
        transparent={transparent}
        onRequestClose={close}
        animationType={animationType}
      >
        <Animated.View
        pointerEvents='none'
        style={[
          tw`bg-black bg-opacity-40 w-full h-full top-0 left-0`,
          { 
            opacity: visible ? 1 : 0,
            position: 'fixed'
          },
        ]}
      />
        <Pressable
          style={tw.style(`flex-1 flex items-center justify-center mx-3`, {
            cursor: 'auto',
          })}
          onPress={close}
        >
          <Pressable
            style={tw.style(
              `bg-white w-full max-w-screen-${
                modalWidth || 'md'
              } shadow my-24 max-h-screen`,
              { cursor: 'auto' }
            )}
          >
            <ScrollView style={tw`px-12 py-10`}>
              <View style={tw`flex flex-row items-start`}>
                {title && <H2 style={tw`mt-0 mr-4 mb-2`}>{title}</H2>}
                <Pressable style={tw`ml-auto`} onPress={close}>
                  <CrossSVG width={22} height={22} style={tw`text-border`} />
                </Pressable>
              </View>
              {children}
            </ScrollView>
          </Pressable>
        </Pressable>
      </Modal>
    </>
  );
};

export default DefaultModal;
