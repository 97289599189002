import { axiosDeserialiseISO } from '../utils/axiosDeserialiseISO';
import { axiosSessionKeepAlive } from '../utils/axiosSessionKeepAlive';
import { axiosClient } from './ApiClient';

export * from './ApiClient';
export * from './AuthClient';
export * from './CompanyClient';
export * from './CompanyUserClient';
export * from './ContactClient';
export * from './DocumentClient';
export * from './FormClient';
export * from './LoqateClient';
export * from './ProjectClient';
export * from './SnagClient';
export * from './TaskClient';
export * from './TrainingClient';
export * from './UserClient';
export * from './WpClient';
export * from './FeedbackClient';
export * from './UserStatsClient';

axiosClient.interceptors.response.use((res) => res, axiosSessionKeepAlive);

axiosClient.interceptors.response.use(axiosDeserialiseISO);
