import { Link } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { ActivityIndicator, Image, ImageBackground, Text } from 'react-native';
import { WpClient } from '../../api';

import tw from '../../config/tailwind';
import { WP_SITE_URL } from '../../env-consts';
import { WpNewsCategory, WpNews } from '../../shared/types/wordpress';
import { decodeEntities } from '../../utils/decodeEntities';
import Col from '../shared/Col';
import ContentWrapper from '../shared/ContentWrapper';
import Row from '../shared/Row';

interface NewsListingProps {
  type?: 'card' | 'tile';
  post?: WpNews;
  skeleton: boolean;
}

const NewsListing: React.FC<NewsListingProps> = ({
  post,
  skeleton,
  type = 'card',
}) => {
  const [categories, setCategories] = useState<WpNewsCategory[]>([]);

  useEffect(() => {
    if (!skeleton && post) {
      WpClient.getCategories().then((wpCategories) => {
        setCategories(
          wpCategories.filter((wpCat) => post.categories.includes(wpCat.id))
        );
      });
    }
  }, []);

  if (skeleton || !post) {
    return (
      <ContentWrapper
        style={tw`flex-1 w-full h-full justify-center items-center`}
      >
        <ActivityIndicator
          style={tw`text-green`}
          color='currentColor'
          size={24}
        />
      </ContentWrapper>
    );
  }

  return (
    <Link
      onPress={() => {
        // Intentionally left in to debug, intermittent issue where all post route to the wrong a single post even though the urls are correct.
        console.debug({ post, slug: post.slug });
      }}
      style={tw`h-full`}
      to={{
        screen: 'authenticatedStackNavigator',
        params: {
          screen: 'newsStack',
          params: {
            screen: 'newsArticle',
            params: {
              slug: post.slug,
            },
          },
        },
      }}
    >
      <ContentWrapper style={tw`flex-1 w-full h-full`}>
        <Row style={tw.style(`h-full -mx-6`, type === 'tile' && 'flex-col')}>
          <Col
            style={tw.style(
              `border border-background-600`,
              type === 'card' && 'w-1/3 -my-6 -mb-6 p-0 ',
              type === 'tile' && 'w-full aspect-1  px-0 -mt-6 mb-6 flex-initial'
            )}
          >
            <Image
              source={
                post.featured_media
                  ? { uri: `${WP_SITE_URL}?p=${post.featured_media}` }
                  : require('../../assets/placeholder.jpg')
              }
              style={{
                width: '100%',
                height: '100%',
              }}
            />
          </Col>
          <Col
            style={tw.style(
              ``,
              type === 'card' && `flex-1 pl-6 justify-center`,
              type === 'tile' && 'w-full flex-1 px-6'
            )}
          >
            <Text
              style={tw`text-sm text-green font-bold font-ubuntu-bold pb-1 uppercase`}
            >
              {categories.map((x) => x.name).join(', ')}
            </Text>
            <Text style={tw`font-bold font-ubuntu-bold`}>
              {decodeEntities(post.title.rendered)}
            </Text>
          </Col>
        </Row>
      </ContentWrapper>
    </Link>
  );
};

export default NewsListing;
