import { useFocusEffect } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';

import Pagination from '../../components/Pagination';
import FavouriteListing from '../../components/favourites/FavouriteListing';
import Tabs from '../../components/navigation/Tabs';
import Col from '../../components/shared/Col';
import H1 from '../../components/shared/H1';
import H3 from '../../components/shared/H3';
import P from '../../components/shared/P';
import Row from '../../components/shared/Row';
import HeartSVG from '../../components/svg/HeartSVG';
import HeartSolidSVG from '../../components/svg/HeartSolidSVG';
import tw from '../../config/tailwind';
import { useAppContext } from '../../hooks/useAppContext';
import DashboardLayout from '../../layout/DashboardLayout';
import {
  FavouriteResponse,
  FavouriteType,
} from '../../shared/types/favourites';
import { PaginatedResult } from '../../shared/types/paginatedResult';
import { FavouritesClient } from '../../api/FavouritesClient';

const tabs = [
  'Resources',
  'Products',
  'Distributors', // TODO: Review/Implement Distributors
  'Contacts',
] as const;
const initialTab = tabs[0];
type Tab = typeof tabs[number];

interface FavouritesScreenProps {}

const FavouritesScreen: React.FC<FavouritesScreenProps> = ({}) => {
  const [activeTab, setActiveTab] = useState<Tab>(initialTab);

  const [paginatedResult, setPaginatedResult] =
    useState<PaginatedResult<FavouriteResponse>>();

  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(1);
  const [page, setPage] = useState(1);
  const [selectedType, setSelectedType] = useState<FavouriteType>(
    FavouriteType.Resource
  );

  const { favourites, isLoading: appLoading } = useAppContext();

  const [activeHeartState, setActiveHeartState] = useState(false);

  useEffect(() => {
    if (appLoading) return;

    setLoading(true);

    if (
      paginatedResult?.items.length === 1 &&
      page === paginatedResult.currentPage
    ) {
      const itemId = paginatedResult.items[0].entity.id;
      if (!favourites[itemId]) {
        if (page > 1) {
          setPage(Math.max(page - 1));
        } else {
          setPaginatedResult(undefined);
          setLoading(false);
        }
        return;
      }
    }

    FavouritesClient.getAll(page, 12, selectedType).then((res) => {
      setPaginatedResult(res);
      setLoading(false);
    });
  }, [page, selectedType, refresh, appLoading, favourites]);

  useFocusEffect(
    React.useCallback(() => {
      setRefresh((x) => x + 1);
    }, [])
  );

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setActiveHeartState((activeState) => !activeState);
  //   }, 1250);

  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, []);

  const onTabChange = (tab: string) => {
    setActiveTab(tab as Tab);
    setPaginatedResult(undefined);
    const type: FavouriteType = (() => {
      switch (tab as Tab) {
        case 'Products':
          return FavouriteType.Product;
        case 'Resources':
          return FavouriteType.Resource;
        case 'Distributors':
          return FavouriteType.Distributor;
        case 'Contacts':
          return FavouriteType.Contact;
      }
    })();

    setSelectedType(type);
  };

  const getEmptyActiveTabContent = () => {
    const favouriteName = `${selectedType.toLowerCase()}s`;
    const location: string = (() => {
      switch (selectedType) {
        case FavouriteType.Product:
          return 'inside of a project';
        case FavouriteType.Resource:
          return 'inside the "Resources" screen, inside of a project or from the portal overview';
        case FavouriteType.Distributor:
          return 'inside the "Find a distributor" screen, of from project under the "Distributors" tab';
        case FavouriteType.Contact:
          return 'inside the "Find a distributor" screen';
      }
    })();

    return (
      <>
        <H3>You currently have no favourited {favouriteName}</H3>
        <Row style={tw`align-center flex-nowrap mx-0`}>
          <P style={tw`flex items-center mb-0 mr-3`}>
            You can favourite new {favouriteName} from {location} by clicking on
            the heart icon.
          </P>

          <Col
            style={tw.style(
              `bg-background-300 rounded-full justify-center items-center w-[40px] h-[40px]`
            )}
          >
            {activeHeartState ? (
              <HeartSolidSVG
                height={18}
                width={18}
                style={tw.style(`mt-px -mb-0.5 text-green`)}
              />
            ) : (
              <HeartSVG
                height={18}
                width={18}
                style={tw.style(`mt-px -mb-0.5`)}
              />
            )}
          </Col>
        </Row>
      </>
    );
  };

  return (
    <DashboardLayout>
      <H1 xl>Favourites</H1>
      <P>View and filter your favourite resources here.</P>

      <Tabs
        tabs={[...tabs]}
        activeTab={activeTab}
        emitActiveTab={onTabChange}
      />

      {!loading && paginatedResult && paginatedResult.items.length > 0 && (
        <>
          <Row style={tw`-mx-1.5`}>
            {paginatedResult?.items.map((item) => (
              <Col
                key={item.id}
                style={tw.style(
                  `mb-3 px-1.5`,
                  activeTab === 'Products'
                    ? `w-full sm:w-1/2`
                    : 'w-full sm:w-1/2 md:w-1/3'
                )}
              >
                <FavouriteListing
                  item={item}
                  onDataOutdated={() => setRefresh((x) => x + 1)}
                />
              </Col>
            ))}
          </Row>

          <Pagination
            paginatedResult={paginatedResult}
            onChangePage={setPage}
          />
        </>
      )}

      {!loading &&
        (!paginatedResult || paginatedResult.items.length === 0) &&
        getEmptyActiveTabContent()}
    </DashboardLayout>
  );
};

export default FavouritesScreen;
