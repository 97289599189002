import { FC, ReactNode } from 'react';
import { ClassInput } from 'twrnc/dist/esm/types';

import tw from '../../config/tailwind';
import Text from './Text';

type Props = {
  children: ReactNode;
  style?: ClassInput;
};

const LI: FC<Props> = ({ children, style }) => {
  return (
    <Text style={tw.style(`text-base mb-0.5`, style)}>
      <Text style={tw`mr-2 ml-1`}>•</Text> {children}
    </Text>
  );
};

export default LI;
