import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';

import { LatLng } from '../../@types/geolocation';
import { ContactClient, ProjectClient, WpClient } from '../../api';
import { FavouritesClient } from '../../api/FavouritesClient';
import tw from '../../config/tailwind';
import { useAuthContext } from '../../hooks/useAuthContext';
import { ContactResponse } from '../../shared/types/contacts';
import { FavouriteType } from '../../shared/types/favourites';
import { ProjectResponse } from '../../shared/types/projects';
import { WpDistributor } from '../../shared/types/wordpress';
import { filterDistributors } from '../../utils/filterDistributors';
import { getUserLocation } from '../../utils/geolocation';
import ContactCard from '../contacts/ContactCard';
import Pagination from '../Pagination';
import SearchBar from '../SearchBar';
import Button from '../shared/Button';
import Col from '../shared/Col';
import DefaultModal from '../shared/DefaultModal';
import FullWidthButton from '../shared/FullWidthButton';
import H2 from '../shared/H2';
import InputLabel from '../shared/InputLabel';
import P from '../shared/P';
import Row from '../shared/Row';
import Text from '../shared/Text';




interface ProjectContactsProps {
  project: ProjectResponse;
  setProject: (projectData: ProjectResponse) => void;
}

const ProjectContacts: React.FC<ProjectContactsProps> = ({
  project,
  setProject,
}) => {
  const { activeCompany } = useAuthContext();
  const [showAddModal, setShowAddModal] = useState(false);
  const [favContacts, setFavContacts] = useState<ContactResponse[]>([]);
  const [allContacts, setAllContacts] = useState<ContactResponse[]>([]);

  const contactIds = useMemo(
    () => project.contacts.map(({ id }) => id),
    [project.contacts]
  );

  const getFavouritesContacts = useCallback(
    async () => {
      const contacts = await FavouritesClient.getAll(1, 1000, FavouriteType.Contact);
      // setAllContacts(contacts.items.map((x) => x.entity as ContactResponse));
      setFavContacts(contacts.items.map((x) => x.entity as ContactResponse));
    },
    [setAllContacts],
  );

  const getAllContacts = useCallback(async (page: number = 1) => {
    const res = await ContactClient.findAll('', page);
    console.log(`getAllContacts`, res.items);
    setAllContacts(c => c.concat(res.items));
    if (res.totalPages > page) {
      await getAllContacts(page++);
    }
  }, []);


  useEffect(() => {
    const favContacts = getFavouritesContacts();
    const tempAllContacts = getAllContacts();
  }, []);

  const addContact = async (contactId: string) => {
    const newProjectData = await ProjectClient.update(project.id, {
      name: project.name,
      applicationId: project.application?.id,
      caseStudyOptOut: project.caseStudyOptOut,
      companyId: activeCompany!.id,
      contacts: [...contactIds, contactId],
      location: project.location,
      sectorId: project.sector?.id,
    });

    setProject(newProjectData);
  };

  const removeContact = async (contactId: string) => {
    const newProjectData = await ProjectClient.update(project.id, {
      name: project.name,
      applicationId: project.application?.id,
      caseStudyOptOut: project.caseStudyOptOut,
      companyId: activeCompany!.id,
      contacts: contactIds.filter((id) => id !== contactId),
      location: project.location,
      sectorId: project.sector?.id,
    });

    setProject(newProjectData);
  };

  //if (loading) return null;

  return (
    <View style={{ minHeight: 500 }}>
      <Button onPress={() => setShowAddModal(true)} style={tw`ml-auto`}>
        Assign a contact
      </Button>
      <DefaultModal
        visible={showAddModal}
        setVisible={setShowAddModal}
        title='Assign a contact'
        modalWidth='lg'
      >
        {allContacts.length === 0 ? (
          <P>
            {/* You have no favourited contacts, head over to the Find a distributor */}
            {/* page to find your contacts. */}
            No contacts available, please check again later.
          </P>
        ) : (
          <P>
            {/* Contacts that you've previously favourited can be assigned from
            below. */}
          </P>
        )}

        {allContacts.length > 0 && (
          <Row style={tw`-mx-1.5`}>
            {
              favContacts.map(c => (
                <Col
                  key={c.id}
                  style={tw`w-full sm:w-1/2 md:w-1/3 mb-3 px-1.5 `}
                >
                  <ContactCard
                    {...c}
                    favorite
                    style={tw`border border-dark-300`}
                    bottomAction={
                      !project.contacts?.map((x) => x.id).includes(c.id) ? (
                        <FullWidthButton
                          onPress={() => addContact(c.id)}
                          variant='transparent'
                          buttonStyle={tw`px-3 py-3 mt-6`}
                          underline
                        >
                          Add
                        </FullWidthButton>
                      ) : (
                        <Text style={tw`text-green font-bold font-ubuntu-bold`}>
                          Assigned
                        </Text>
                      )
                    }
                  />
                </Col>
              ))
            }
            {allContacts.filter(x => !favContacts.find(y => y.id === x.id)).map((item) => (
              <Col
                key={item.id}
                style={tw`w-full sm:w-1/2 md:w-1/3 mb-3 px-1.5 `}
              >
                <ContactCard
                  {...item}
                  style={tw`border border-dark-300`}
                  bottomAction={
                    !project.contacts?.map((x) => x.id).includes(item.id) ? (
                      <FullWidthButton
                        onPress={() => addContact(item.id)}
                        variant='transparent'
                        buttonStyle={tw`px-3 py-3 mt-6`}
                        underline
                      >
                        Add
                      </FullWidthButton>
                    ) : (
                      <Text style={tw`text-green font-bold font-ubuntu-bold`}>
                        Assigned
                      </Text>
                    )
                  }
                />
              </Col>
            ))}
          </Row>
        )}
      </DefaultModal>

      <H2>Project contacts</H2>

      {project.contacts.length === 0 ? (
        <P>No contacts have been assigned to this project.</P>
      ) : (
        <Row style={tw`-mx-1.5`}>
          {project.contacts.map((item) => (
            <Col
              key={item.id}
              style={tw` w-full sm:w-1/2 md:w-1/3 mb-3 px-1.5 `}
            >
              <ContactCard
                {...item}
                bottomAction={
                  <FullWidthButton
                    onPress={() => removeContact(item.id)}
                    variant='transparent'
                    buttonStyle={tw`px-3 py-3 mt-6`}
                    underline
                  >
                    Remove
                  </FullWidthButton>
                }
              />
            </Col>
          ))}
        </Row>
      )}
    </View>
  );
};

export default ProjectContacts;
