import React from 'react';
import { Image, View } from 'react-native';
import { ClassInput } from 'twrnc/dist/esm/types';

import tw from '../../config/tailwind';
import { PublicFileResponse } from '../../shared/types/files';
import ContentWrapper from '../shared/ContentWrapper';
import H3 from '../shared/H3';
import P from '../shared/P';
import Spacer from '../shared/Spacer';
import Text from '../shared/Text';
import HeartSolidSVG from '../svg/HeartSolidSVG';
import HeartSVG from '../svg/HeartSVG';
interface ContactCardProps {
  name?: string;
  email?: string;
  role?: string;
  tel?: string;
  mob?: string;
  imageUrl?: string;
  action?: React.ReactNode;
  bottomAction?: React.ReactNode;
  style?: ClassInput;
  favorite?: boolean;
}

const ContactCard: React.FC<ContactCardProps> = ({
  name,
  role,
  tel,
  mob,
  email,
  action,
  imageUrl,
  style,
  bottomAction,
  favorite
}) => {
  return (
    <ContentWrapper style={tw.style(`grow`, style)}>
      <>
        {(imageUrl || action) && (
          <View style={tw`justify-between flex-row mb-2`}>
            {imageUrl ? (
              <View
                style={tw.style(
                  ` bg-background-100 border border-background-600`,
                  {
                    width: '50px',
                    height: '50px',
                  }
                )}
              >
                <Image
                  source={{ uri: imageUrl }}
                  resizeMode='contain'
                  style={{
                    width: '100%',
                    height: '100%',
                  }}
                />
              </View>
            ) : (
              <Spacer />
            )}

            <View style={tw`flex flex-row justify-between`}>
              <>
                {action}
              </>
            </View>
          </View>
        )}

        <>
          {favorite && <View>
            <HeartSolidSVG/>
          </View>}
          <H3 style={tw`mb-3`}>{name}</H3>
          <P style={tw`mb-5`}>{role}</P>
        </>

        {(tel || mob) && (
          <View style={tw`mb-5`}>
            {tel && <Text>Tel: {tel}</Text>}
            {mob && <Text>Mob: {mob}</Text>}
          </View>
        )}

        {email && <Text>Email: {email}</Text>}
        <View style={tw`flex flex-row justify-between`}>
          <>
            {bottomAction}
          </>
        </View>
      </>
    </ContentWrapper>
  );
};

export default ContactCard;
