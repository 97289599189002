import axios from 'axios';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { ActivityIndicator, View } from 'react-native';
import * as Yup from 'yup';
import { ProjectClient } from '../../../api';

import tw from '../../../config/tailwind';
import { useToasts } from '../../../hooks/useToasts';
import {
  CompleteProjectErrorResponse,
  CompleteProjectPayload,
  ProjectResponse,
} from '../../../shared/types/projects';
import removeTime from '../../../utils/removeTime';
import Button from '../../shared/Button';
import DatePicker from '../../shared/DatePicker';
import DefaultModal from '../../shared/DefaultModal';
import InputCheckbox from '../../shared/InputCheckbox';
import InputValidationMessage from '../../shared/InputValidationMessage';
import LI from '../../shared/LI';
import P from '../../shared/P';

interface ProjectCompletionModalProps {
  project: ProjectResponse;
  setProject: (project: ProjectResponse) => void;
  visible: boolean;
  setVisible: (v: boolean) => void;
}

const ProjectCompletionModal: React.FC<ProjectCompletionModalProps> = ({
  project,
  setProject,
  visible,
  setVisible,
}) => {
  const [errors, setErrors] = useState<CompleteProjectErrorResponse>();
  const [loading, setLoading] = useState(true);
  const { addUnhandledErrorToast, addToast } = useToasts();

  useEffect(() => {
    if (visible) {
      setLoading(true);
      ProjectClient.canComplete(project.id)
        .then((errors) => {
          setLoading(false);
          setErrors(errors);
        })
        .catch((e) => {
          addUnhandledErrorToast(e);
        });
    }
  }, [project, visible]);

  const initialValues: CompleteProjectPayload = {
    caseStudyOptIn: true,
    completionDate: removeTime(new Date()),
  };

  const schema: Yup.SchemaOf<typeof initialValues> = Yup.object().shape({
    caseStudyOptIn: Yup.boolean().required(),
    completionDate: Yup.date()
      .required()
      .max(new Date(), 'The completion date can not be in the future'),
  });

  const onSubmit = async (formValues: typeof initialValues) => {
    try {
      const updatedProject = await ProjectClient.complete(
        project.id,
        formValues
      );
      setVisible(false);
      setProject(updatedProject);
      addToast({
        title: 'Project completed',
        description: 'The project was completed successfully.',
        type: 'success',
      });
    } catch (e) {
      if (axios.isAxiosError(e) && e.response?.status === 400) {
        setErrors(e.response.data as CompleteProjectErrorResponse);
      } else {
        addUnhandledErrorToast(e);
      }
    }
  };

  return (
    <DefaultModal
      setVisible={setVisible}
      visible={visible}
      title={
        errors &&
        (errors.incompleteSnags?.length || errors.incompleteTasks?.length)
          ? 'Incomplete items'
          : 'Complete this project'
      }
    >
      {loading ? (
        <ActivityIndicator />
      ) : errors &&
        (errors.incompleteSnags?.length || errors.incompleteTasks?.length) ? (
        <>
          <P>
            You can not complete this project due to the following incomplete
            items:
          </P>
          <View>
            {errors.incompleteSnags.map((snag) => (
              <LI>Issue - {snag.name}</LI>
            ))}
            {errors.incompleteTasks.map((task) => (
              <LI>Task - {task.name}</LI>
            ))}
          </View>
        </>
      ) : (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={schema}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            values,
            errors,
            isSubmitting,
          }) => (
            <View>
              <P>Please provide a completion date for this project.</P>

              <View>
                <DatePicker
                  label='Completion Date'
                  value={values.completionDate}
                  setValue={(date) => setFieldValue('completionDate', date)}
                  onBlur={handleBlur('completionDate')}
                  placeholder='Completeion Date'
                  hasError={!!errors.completionDate}
                  max={new Date()}
                />
                {errors.completionDate && (
                  <InputValidationMessage>
                    {typeof errors.completionDate === 'string'
                      ? errors.completionDate
                      : ''}
                  </InputValidationMessage>
                )}
              </View>

              <View>
                <InputCheckbox
                  labelSize='md'
                  label='Zentia may feature my project in a Pinnacle Portal case study.'
                  value={values.caseStudyOptIn}
                  setValue={(selected) =>
                    setFieldValue('caseStudyOptIn', selected)
                  }
                />
              </View>
              <View style={tw`flex flex-row items-center justify-end`}>
                {isSubmitting && <ActivityIndicator style={tw`mr-4`} />}
                <Button onPress={handleSubmit} disabled={isSubmitting}>
                  Mark as complete
                </Button>
              </View>
            </View>
          )}
        </Formik>
      )}
    </DefaultModal>
  );
};

export default ProjectCompletionModal;
