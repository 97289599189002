import React from 'react';

import tw from '../../config/tailwind';
import Text from './Text';

interface InputLabelProps {
  label: string;
  required?: boolean;
  labelSize?: 'sm' | 'md';
}

const InputLabel: React.FC<InputLabelProps> = ({
  label,
  required,
  labelSize,
}) => {
  return (
    <Text
      style={tw.style(
        `text-blue mb-1 ${labelSize === 'md' ? 'text-sm' : 'text-base'}`
      )}
    >
      {label}
      {required && (
        <Text style={tw`font-ubuntu-bold font-ubuntu-bold font-bold text-sm`}>
          {' '}
          *
        </Text>
      )}
    </Text>
  );
};

export default InputLabel;
