import { Picker } from '@react-native-picker/picker';
import {
  NavigationProp,
  StackActions,
  useFocusEffect,
  useNavigation,
} from '@react-navigation/native';
import { Formik } from 'formik';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  ActivityIndicator,
  ImageBackground,
  ScrollView,
  View,
} from 'react-native';
import * as Yup from 'yup';
import { ProjectClient, WpClient } from '../../../api';

import Button from '../../../components/shared/Button';
import Col from '../../../components/shared/Col';
import FullWidthButton from '../../../components/shared/FullWidthButton';
import H1 from '../../../components/shared/H1';
import H3 from '../../../components/shared/H3';
import InputText from '../../../components/shared/InputText';
import InputValidationMessage from '../../../components/shared/InputValidationMessage';
import Row from '../../../components/shared/Row';
import Select from '../../../components/shared/Select';
import Text from '../../../components/shared/Text';
import ArrowUpBgSVG from '../../../components/svg/ArrowUpBgSVG';
import DoubleChevronRightSVG from '../../../components/svg/DoubleChevronRightSVG';
import PinnacleLogoSvg from '../../../components/svg/PinnacleLogoSVG';
import tw from '../../../config/tailwind';
import { AuthContext } from '../../../context/AuthContextProvider';
import { LayoutContext } from '../../../context/LayoutContextProvider';
import { useAppContext } from '../../../hooks/useAppContext';
import { useContainer } from '../../../hooks/useContainer';
import { useToasts } from '../../../hooks/useToasts';
import DashboardLayout from '../../../layout/DashboardLayout';
import { AuthenticatedStackNavigatorParamList } from '../../../routes/types';
import { WpApplication, WpSector } from '../../../shared/types/wordpress';
import { decodeEntities } from '../../../utils/decodeEntities';

interface CreateProjectScreenProps {}

type FormValues = {
  sectorId: number;
  applicationId: number;
  name: string;
  location: string;
  customApplication: string;
};

const CreateProjectScreen: React.FC<CreateProjectScreenProps> = ({}) => {
  const { activeCompany } = useContext(AuthContext);
  const [margin] = useContainer();
  const navigation =
    useNavigation<NavigationProp<AuthenticatedStackNavigatorParamList>>();

  const { projectStats, setProjectStats } = useAppContext();

  const { addUnhandledErrorToast } = useToasts();
  const [sectors, setSectors] = useState<WpSector[]>([]);
  const [applications, setApplications] = useState<WpApplication[]>([]);
  const [selectedSectorId, setSelectedSectorId] = useState<number>();
  const [applicationOptions, setApplicationOptions] = useState<WpApplication[]>(
    []
  );
  const [selectedApplication, setSelectedApplication] = useState('');

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let isActive = true;
    Promise.all([
      WpClient.getApplications(),
      WpClient.getApplicationSectors(),
    ]).then(([applications, sectors]) => {
      if (!isActive) return;
      setSectors(sectors);
      setApplications(applications);
      setLoading(false);
    });

    return () => {
      isActive = false;
    };
  }, []);

  useEffect(() => {
    if (!selectedSectorId) return;

    const sector = sectors.find((sector) => selectedSectorId === sector.id);
    const applicationParentId = sector?.acf.related_application_term_id;

    if (!sector || !applicationParentId) {
      setApplicationOptions([]);
    }

    setApplicationOptions(
      applications.filter((app) => app.parent === applicationParentId)
    );
  }, [selectedSectorId]);

  const initialValues: FormValues = {
    sectorId: 0,
    applicationId: 0,
    name: '',
    location: '',
    customApplication: '',
  };

  const schema: Yup.SchemaOf<FormValues> = Yup.object().shape({
    name: Yup.string().required().label('Name'),
    location: Yup.string().required().label('Location'),
    sectorId: Yup.number()
      .required()
      .moreThan(0, 'Sector is a required field')
      .default(0)
      .label('Sector'),
    applicationId: Yup.number()
      .required()
      .moreThan(0, 'Application is a required field')
      .default(0)
      .label('Application'),
    customApplication: Yup.string()
      .label('Application')
      .transform((v) => v || '')
      .default('')
      .test('isOtherApplication', 'Application is a required field', (v) => {
        if (selectedApplication.toLowerCase() === 'other') {
          return !!(v && v.length > 0);
        }
        return true;
      }),
  });

  const onCancel = () => {
    if (navigation.canGoBack()) navigation.goBack();
    else navigation.dispatch(StackActions.push('projectsOverview'));
  };

  const createProject = async (formValues: FormValues) => {
    // console.log(formValues);

    if (!activeCompany) return;

    const res = await ProjectClient.create({
      companyId: activeCompany.id,
      caseStudyOptOut: false, // TODO: remove - unnecessary flag.
      name: formValues.name,
      location: formValues.location,
      distributors: [],
      contacts: [],
      sectorId: formValues.sectorId,
      applicationId: formValues.applicationId,
      customApplication: formValues.customApplication || undefined,
    });

    setProjectStats({
      ...projectStats,
      liveCount: projectStats.liveCount + 1,
    });

    navigation.dispatch(
      StackActions.push('projectStack', {
        screen: 'project',
        params: {
          id: res.id,
        },
      })
    );
  };

  return (
    <View style={tw.style(`flex h-full flex-col max-h-screen`)}>
      <ArrowUpBgSVG
        style={tw`absolute -left-1/3 -top-[39%] w-full h-[230%] text-[#e9eaea]`}
      />

      {loading ? (
        <>
          <View style={tw`flex-1 flex-row justify-center`}>
            <ActivityIndicator size={32} color={'currentColor'} />
          </View>
        </>
      ) : (
        <ScrollView
          style={tw.style(`flex flex-1`, {
            paddingLeft: margin,
            paddingRight: margin,
          })}
        >
          <View style={tw`min-h-screen`}>
            <PinnacleLogoSvg style={tw`mb-10 z-10 mt-18`} />
            <View
              style={tw.style(
                `pt-6 flex-1 max-w-md mx-auto z-10 mb-4 justify-center items-center `
              )}
            >
              <H1 xl style={tw`w-full`}>
                Create a project
              </H1>

              <View style={tw`w-full`}>
                <H3 style='mt-2'>Add project details</H3>

                <Formik
                  initialValues={initialValues}
                  onSubmit={createProject}
                  validationSchema={schema}
                >
                  {({
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    validateForm,
                    values,
                    errors,
                    isSubmitting,
                  }) => (
                    <View pointerEvents={isSubmitting ? 'none' : undefined}>
                      <Row>
                        <Col style={tw`w-full`}>
                          <InputText
                            placeholder='Enter a name'
                            label='Project name'
                            required
                            value={values.name}
                            setValue={handleChange('name')}
                            onBlur={handleBlur('name')}
                            hasError={!!errors.name}
                          />
                          {errors.name && (
                            <InputValidationMessage>
                              {errors.name}
                            </InputValidationMessage>
                          )}
                        </Col>
                        <Col style={tw`w-full`}>
                          <InputText
                            placeholder='Enter a postcode, city or town'
                            label='Location'
                            required
                            value={values.location}
                            setValue={handleChange('location')}
                            onBlur={handleBlur('location')}
                            hasError={!!errors.location}
                          />
                          {errors.location && (
                            <InputValidationMessage>
                              {errors.location}
                            </InputValidationMessage>
                          )}
                        </Col>
                        <Col style={tw`w-full`}>
                          <Select
                            selectedValue={values.sectorId || undefined}
                            onValueChange={(iValue, iIndex) => {
                              setFieldValue('applicationId', 0);
                              setFieldValue('sectorId', iValue);
                              setSelectedSectorId(iValue);
                              setSelectedApplication('');
                            }}
                            label='Select a sector'
                            required
                            hasError={!!errors.sectorId}
                            options={sectors.map((sector, i) => ({
                              label: decodeEntities(sector.name),
                              value: sector.id,
                            }))}
                            placeholderOption='- Select -'
                          />
                          {errors.sectorId && (
                            <InputValidationMessage>
                              {errors.sectorId}
                            </InputValidationMessage>
                          )}
                        </Col>
                        <Col
                          style={tw.style(
                            `w-full`,
                            !values.sectorId && 'opacity-50'
                          )}
                          pointerEvents={!values.sectorId ? 'none' : undefined}
                        >
                          <Select
                            selectedValue={values.applicationId || undefined}
                            onValueChange={(iValue) => {
                              setFieldValue('applicationId', iValue);
                              setSelectedApplication(
                                applicationOptions.find(
                                  (appl) => appl.id === iValue
                                )!.name
                              );
                            }}
                            label='Select application'
                            required
                            hasError={!!errors.applicationId}
                            options={applicationOptions.map((opt) => ({
                              label: decodeEntities(opt.name),
                              value: opt.id,
                            }))}
                            placeholderOption='- Select -'
                          />
                          {errors.applicationId && (
                            <InputValidationMessage>
                              {errors.applicationId}
                            </InputValidationMessage>
                          )}
                        </Col>
                        {selectedApplication.toLowerCase() === 'other' && (
                          <Col
                            style={tw.style(`w-full`)}
                            pointerEvents={
                              !values.sectorId ? 'none' : undefined
                            }
                          >
                            <InputText
                              placeholder='Application name'
                              label='Please enter an application name'
                              required
                              value={values.customApplication}
                              setValue={handleChange('customApplication')}
                              onBlur={handleBlur('customApplication')}
                              hasError={!!errors.customApplication}
                            />
                            {errors.customApplication && (
                              <InputValidationMessage>
                                {errors.customApplication}
                              </InputValidationMessage>
                            )}
                          </Col>
                        )}
                      </Row>

                      <Row
                        style={tw.style(
                          `justify-end mt-4`,
                          isSubmitting && tw`opacity-75`
                        )}
                      >
                        <Col style={tw`w-full`}>
                          <Button
                            style={tw`self-stretch`}
                            onPress={handleSubmit}
                          >
                            {!isSubmitting ? (
                              <>Create project</>
                            ) : (
                              <ActivityIndicator color='white' size={15} />
                            )}
                          </Button>
                        </Col>
                        <Col style={tw`w-full py-1`}>
                          <FullWidthButton
                            variant='transparent'
                            buttonStyle={tw`py-2 pr-4 items-center mt-4`}
                            onPress={onCancel}
                          >
                            <View style={tw`flex-row`}>
                              <DoubleChevronRightSVG />
                              <Text>Cancel</Text>
                            </View>
                          </FullWidthButton>
                        </Col>
                      </Row>
                    </View>
                  )}
                </Formik>
              </View>
            </View>
          </View>
        </ScrollView>
      )}
    </View>
  );
};

export default CreateProjectScreen;
