import React from 'react';
import { View } from 'react-native';
import { ClassInput } from 'twrnc/dist/esm/types';

import tw from '../../config/tailwind';

interface SpacerProps {
  style?: ClassInput;
}

const Spacer: React.FC<SpacerProps> = ({ style }) => {
  return <View style={tw.style(`flex-1`, style)} />;
};

export default Spacer;
