import { API_BASE_URL } from '../env-consts';
import {
  CreateFeedbackPayload,
  FeedbackResponse,
  FeedbackType,
} from '../shared/types/feedback';
import { PaginatedResult } from '../shared/types/paginatedResult';
import ApiClient from './ApiClient';

const client = new ApiClient(`${API_BASE_URL}/feedback`);

export const FeedbackClient = {
  async getAll(page: number, userId?: string, type?: FeedbackType) {
    return client
      .get<PaginatedResult<FeedbackResponse>>(`/`, {
        params: {
          page,
          userId,
          type,
        },
      })
      .then(({ data }) => data);
  },

  async getById(id: string) {
    return client.get<FeedbackResponse>(`/${id}`).then(({ data }) => data);
  },

  async create(data: CreateFeedbackPayload) {
    return client.post<FeedbackResponse>(`/`, data).then(({ data }) => data);
  },

  async delete(id: string) {
    return client.delete<void>(`/${id}`).then(({ data }) => data);
  },
};
