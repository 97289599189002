import React from 'react';
import { View } from 'react-native';

import tw from '../../config/tailwind';
import DoubleChevronRightSVG from '../svg/DoubleChevronRightSVG';
import Text from './Text';

type GenericTableActionProps = {
  label: string | React.ReactNode;
  onPress?: () => void;
};

const GenericTableAction: React.FC<GenericTableActionProps> = ({
  onPress,
  label,
}) => {
  return (
    <Text
      onPress={onPress}
      style={tw`font-ubuntu-bold font-bold text-center text-base items-center mx-1.5 flex`}
    >
      {typeof label === 'string' && (
        <View>
          <DoubleChevronRightSVG height={14} width={14} style={tw``} />
        </View>
      )}
      {label}
    </Text>
  );
};

export default GenericTableAction;
