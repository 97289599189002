import { useFocusEffect } from '@react-navigation/native';
import { format } from 'date-fns';
import React, { useRef, useState } from 'react';
import { Linking, Pressable, TouchableOpacity, View } from 'react-native';
import { useHover } from 'react-native-web-hooks';
import { TrainingClient } from '../../api';

import tw from '../../config/tailwind';
import { isWeb } from '../../constants';
import { useAuthContext } from '../../hooks/useAuthContext';
import { PaginatedResult } from '../../shared/types/paginatedResult';
import { TrainingRecordResponse } from '../../shared/types/training';
import { UserRole } from '../../shared/types/users';
import Pagination from '../Pagination';
import Col from '../shared/Col';
import ContentWrapper from '../shared/ContentWrapper';
import DownloadAction from '../shared/DownloadAction';
import H2 from '../shared/H2';
import P from '../shared/P';
import Row from '../shared/Row';
import Spacer from '../shared/Spacer';
import Text from '../shared/Text';
import DownloadSVG from '../svg/DownloadSVG';

interface TrainingCertificateListProps {}

const TrainingCertificateList: React.FC<
  TrainingCertificateListProps
> = ({}) => {
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const { user, activeCompany } = useAuthContext();
  const [paginatedResult, setPaginatedResult] =
    useState<PaginatedResult<TrainingRecordResponse>>();

  const downloadBtnRef = useRef(null);
  const isBtnHovered = useHover(downloadBtnRef);

  useFocusEffect(
    React.useCallback(() => {
      let isActive = true;
      const companyId = activeCompany?.id || null;
      TrainingClient.getAllRecords(
        page,
        companyId
      ).then((res) => {
        if (isActive) {
          setPaginatedResult(res);
          setLoading(false);
        }
      });

      return () => {
        setLoading(true);
        isActive = false;
      };
    }, [page, activeCompany])
  );

  if (paginatedResult === undefined || loading) return null;

  const { items } = paginatedResult;

  return (
    <ContentWrapper>
      <H2>Training certificates</H2>

      {items.length === 0 && <P>No certificates found.</P>}

      {items.length > 0 &&
        items.map((record, i) => (
          <Row key={i} style={tw`items-center mb-2`}>
            <Col style={tw`w-6/12 py-3`}>
              <Text>{record.title}</Text>
            </Col>
            <Col style={tw`w-4/12 py-3`}>
              <Text>
                {format(new Date(record.completedOn), 'dd / MM / yyyy')}
              </Text>
            </Col>
            <Spacer />
            {record.certificate && (
              <Col style={tw`pa-0 w-auto`}>
                {isWeb ? (
                  <a href={record.certificate.publicUrl} download>
                    <DownloadAction />
                  </a>
                ) : (
                  <Pressable
                    onPress={() =>
                      record?.certificate?.publicUrl ? Linking.openURL(record?.certificate?.publicUrl) : {}
                    }
                  >
                    <DownloadAction />
                  </Pressable>
                )}
              </Col>
            )}
          </Row>
        ))}

      <Pagination paginatedResult={paginatedResult} onChangePage={setPage} />
    </ContentWrapper>
  );
};

export default TrainingCertificateList;
