import React, { FC, ReactNode, useCallback, useState } from 'react';
import { ScrollView, useWindowDimensions, View } from 'react-native';

import Sidebar from '../components/navigation/Sidebar';
import TopBar from '../components/navigation/TopBar';
import LoadingScreen from '../components/shared/LoadingScreen';
import SupportOverlay from '../components/SupportOverlay';
import tw from '../config/tailwind';
import { useContainer } from '../hooks/useContainer';
import { useAuthContext } from '../hooks/useAuthContext';
import H1 from '../components/shared/H1';
import Button from '../components/shared/Button';
import InputCheckbox from '../components/shared/InputCheckbox';
import H3 from '../components/shared/H3';
import { ScaleLoader } from 'react-spinners';
import { UserClient } from '../api';
import P from '../components/shared/P';

type Props = {
  children: ReactNode;
};

const DashboardLayout: FC<Props> = ({ children }) => {
  const [margin] = useContainer();
  const { user, isConsented, setIsConsented, logout, updateUser } = useAuthContext();
  const [consentChecked, setConsentChecked] = useState(false);
  const [savingConsent, setSavingConsent] = useState(false);
  useWindowDimensions(); // trigger redraw when breakpoints change

  const handleConsentSubmit = useCallback(async () => {
    if (!consentChecked || isConsented || !user) {
      return;
    }
    setSavingConsent(true);
    // setTimeout(() => {
    //   setSavingConsent(false);
    //   setIsConsented(true);
    // }, 3000);
    const updatedUser = await UserClient.updateUser(user.id, {
      isConsented: true,
    });
    updateUser(updatedUser);
    setSavingConsent(false);
    setIsConsented(true);
  }, [consentChecked, isConsented]);

  if (!isConsented) {
    return (
      <View
        style={tw`flex flex-col w-screen h-screen bg-background items-center justify-center text-center`}
      >
        <H1>Zentia Partner Code of Conduct</H1>
        <P>Please read and agree to our code of conduct before using the portal.</P>
        <div
          id='content-scroll-div'
          style={{
            ...tw`w-full max-w-7xl mx-auto h-[60vh] overflow-y-auto pb-24 px-12`,
            overflowY: 'auto',
            maxWidth: '1024px',
          }}
        >
          {/* terms contents */}
          <div
            style={{
              textAlign: 'left',
              fontFamily: 'sans-serif',
            }}
          >
            <h3>What does Zentia's Partner Code of Conduct mean?</h3>
            <p>
              At Zentia, corporate integrity, responsible sourcing and the safety and wellbeing of employees in the
              countries where we conduct business are of paramount importance to Zentia. These core principles
              are reflected in this Partner Code of Conduct (Code), which establishes the minimum standards that
              must be met by any person or entity that partners with Zentia via the Pinnacle partnership scheme or
              in any capacity as a partner to Zentia.
            </p>
            <br />
            <h3>Who must comply with the Code?</h3>
            <p>The Code applies to all Zentia Pinnacle Approved Partners(Partners) or any partner of Zentia. Partners
              are expected to monitor their compliance with this Code and Zentia is entitled to audit any of its
              Partners from time to time including providing access to the Partner's own standards and practices for
              Zentia's review.</p>
            <br />
            <h3>What are the Principles of the Code?</h3>
            <h4>Compliance with laws and regulations</h4>
            <p>
              The Partner shall comply with all applicable UK laws and regulations (and if applicable, the laws and
              regulations of any other jurisdiction in which it operates) including but not limited to the laws and
              regulations relating to issues addressed in this Code.
            </p>
            <p>
              We require Partners to notify us immediately where they become the subject of any material criminal
              or civil legal action or sanctioned in any way by a regulator.
            </p>
            <br />
            <h4>Anti-bribery and corruption</h4>
            <p>The Partner shall comply with all applicable laws, statutes, codes and regulations relating to the
              prevention of bribery and corruption (including but not limited to the Bribery Act 2010). To that end,
              the Partner shall not accept, offer, promise, pay, permit or authorise:</p>
            <ol
              style={{
                listStyleType: 'upper-roman'
              }}
            >
              <li>bribes, facilitation payments, kickbacks or political contributions;</li>
              <li>money, goods, services, entertainment, employment, contracts or other things of value, in order to obtain or retain improper advantage; or</li>
              <li>any other unlawful or improper payments or benefits.</li>
            </ol>
            <p>
              This includes encouraging others to do so on your behalf through third party intermediaries or
              otherwise.
            </p>
            <p>Partners should have whistleblowing procedures and ensure that they have robust recruitment
              processesto ensure that all new recruits(as well as existing employees) are aware of anti-bribery laws
              and the requirement to comply with those laws.</p><br />
            <h3>Financial Crime</h3>
            <p>Partners must not become involved in any arrangements involving money known or suspected to be
              associated with any criminal activity. Partners should only deal with parties whom they have
              conducted adequate due diligence and whom are involved in legitimate business activities.</p><br />
            <h3>Unfair business practices</h3>
            <p>Zentia is open to fair competition and recognises that it is good for customers and good for business.
              Partners shall comply with all applicable anti-trust and competition laws. Partners must not engage
              in any anti-competitive behaviour. Any activity that may result in the prevention, restriction or
              distortion of competition affecting trade is anti-competitive and therefore, illegal.</p><br />
            <h3>Slavery, human trafficking and child labour</h3>
            <p>
              Partners shall comply with all applicable anti-slavery and human trafficking laws, statutes, regulations
              including the Modern Slavery Act 2015 in any part of its business. Partners must not participate in any
              form of human slavery or human trafficking and they and their directors must prohibit the same within
              their own business practices.
            </p><br />
            <h3>Child Labour</h3>
            <p>
              In no circumstances shall child labour (young people 15 or under, unless acceptable to the
              International Labour Organisation) be utilised by our Partners or within their business. Zentia expects
              its Partners to respect and comply with applicable law and regulation concerning the minimum age of
              employees. Where young employees are lawfully employed, they shall be guaranteed proper working
              hours, wages and safe working conditions. Partners must ensure that persons under the age of 18 do
              not carry out hazardous work i.e. work which exposes the child to physical, psychological or sexual
              abuse, work underground or underwater, at dangerous heights, in confined spaces or with dangerous
              machinery, equipment or tools.
            </p><br />
            <h3>Working environment</h3>
            <p>
              The Partner shall provide a safe, healthy, and sanitary working environment and comply with UK
              health and safety laws and any other relevant laws where it operates. This includes, but is not limited
              to, implementing general and relevant industry-specific procedures and safeguards to prevent
              workplace hazards and work-related accidents and injuries. Where such hazards cannot be adequately
              prevented or controlled, the Partner shall provide employees with appropriate personal protective
              equipment to protect against hazards typically encountered in that scope of work.
            </p><br />
            <h3>
              Data privacy and security
            </h3>
            <p>
              We expect Partners to use due skill, care and diligence to prevent the unauthorised or unlawful
              processing of data. We expect all of our Partners who process personally identifiable data to do so in
              accordance with applicable data protection laws in all jurisdictions in which they operate to ensure
              that they meet the required standards under law and regulation to safeguard the interests of the
              individual.
            </p><br />
            <h3>
              Intellectual Property
            </h3>
            <p>
              Zentia's intellectual property includes, but is not limited to, trademarks, trade secrets, know-how,
              patents, copyright, designs and any such intellectual property from time to time. You must respect and
              protect Zentia intellectual property and comply with all applicable guidelines, policies and procedures
              provided by Zentia. You must not use or share Zentia intellectual property without written permission
              or outside the scope of work agreed with Zentia. You agree to immediately cease using Zentia's any of
              Zentia's intellectual property including its logos immediately upon request.
            </p><br />
            <h3>
              Consequence of non-compliance
            </h3>
            <p>
              Where we reasonably believe that a Partner is not compliant with this Code, we will seek to raise the
              matter with the Partner in an attempt to rectify the issue. In addition, we reserve the right to end our
              business relationship with any Partner who is non-compliant with this Code where:
            </p>
            <ol
              style={{
                listStyleType: 'upper-roman'
              }}
            >
              <li>
                such non-compliance has a potentially serious adverse impact on our business (for
                example damage to our reputation, employees, customers, shareholders or supply chain)
                or the communities that we serve; or
              </li>

              <li>
                the Partner has repeatedly breached this Code and has failed to take appropriate steps to
                raise standards to ensure compliance with this Code.
              </li>
            </ol>
            <br />
            <h3>How do Partners report concerns?</h3>
            <p>The Partner shall monitor its compliance with the Code and shall report any breaches (actual or
              suspected) of this Code as soon as possible to Zentia. The Partnershall notretaliate ortake disciplinary
              action against any employee or otherwise that has, in good faith, reported breaches of this Code or
              questionable behaviour, or who has sought advice regarding this Code.</p>
            <p>There are a number of ways that Partners can report their concerns as follows:</p>
            <ol>
              <li>Email the Zentia Account Support Manager</li>
              <li>Email Zentia's Director of Sales, Graham Taylor <a href='mailto:GCTaylor@zentia.com'>GCTaylor@zentia.com</a></li>
              <li>Email Zentia's General Counsel and Company Secretary, Gillian Dunn at <a href='mailto:GDunn@zentia.com'>GDunn@zentia.com</a></li>
            </ol>
            <br />
            <h3>Updating this Code</h3>
            <p>Zentia has the right to modify this Code from time to time and will notify the Partner of any significant
              changes made to the Code.</p><br />
            <h4 style={tw`text-center`}>By checking the box below and submit, you acknowledge that you have read and will comply with the contents
              of this Code and are duly authorised to sign this Code on behalf of the Partner.</h4>
          </div>
          <div
            style={tw`w-fit flex flex-col items-center justify-center mt-12`}
          >
            <InputCheckbox
              label={`I have read and understood the terms and conditions`}
              labelSize='md'
              labelStyle={`${consentChecked ? '' : 'text-red-500'}`}
              value={consentChecked}
              setValue={(v) => setConsentChecked(v)}
            />
            <div style={tw`w-fit flex items-center justify-center`}>
              <Button
                disabled={!consentChecked}
                onPress={handleConsentSubmit}
                variant={consentChecked ? 'default' : 'dark'}
              >
                Submit
              </Button>
            </div>
            <div style={tw`w-fit flex items-center justify-center mt-8`}>
              <Button variant={'transparent'} onPress={logout}>
                Decline & Logout
              </Button>
            </div>
          </div>
        </div>

        {/* overlay loading */}
        {savingConsent ? (
          <div
            style={{
              ...tw`w-screen h-screen fixed left-0 top-0 bg-slate-800 bg-opacity-70 flex items-center justify-center`,
              position: 'fixed',
            }}
          >
            <div style={tw`p-8 shadow-xl bg-white w-fit`}>
              <ScaleLoader height={26} width={3} color={`#3CDBC0`} />
              <H3>Saving Your Consent</H3>
            </div>
          </div>
        ) : null}
      </View>
    );
  }

  return (
    <View style={tw`flex flex-row h-screen bg-background flex-1`}>
      <Sidebar key={1} />

      <ScrollView
        style={tw.style(`flex-1`, {
          paddingRight: margin,
        })}
        stickyHeaderIndices={[0]}
        contentContainerStyle={tw`flex-1`}
      >
        <TopBar />
        <View style={tw`pl-4 py-4 pt-6 flex-1`}>
          {
            children //
          }
          {/* Scroll View - ignores padding-bottom if flex 1 on parent element */}
          <View style={tw`pb-5`} />
        </View>
      </ScrollView>

      <SupportOverlay />
      {/* <LoadingScreen/> */}
    </View>
  );
};

export default DashboardLayout;
