import { isDate } from 'date-fns';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import { Formik, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { View } from 'react-native';
import ReactSelect from 'react-select';
import * as Yup from 'yup';
import { GlobalSettingsClient } from '../../../api/GlobalSettingsClient';
import { RebateClient } from '../../../api/RebateClient';
import { RebateTemplate } from '../../../components/admin/AdminGlobalSettingsPage';
import Button from '../../../components/shared/Button';
import ConsentInfo from '../../../components/shared/ConsentInfo';
import DefaultModal from '../../../components/shared/DefaultModal';
import FilePicker from '../../../components/shared/FilePicker';
import H3 from '../../../components/shared/H3';
import LoadingScreen from '../../../components/shared/LoadingScreen';
import DownloadSVG from '../../../components/svg/DownloadSVG';
import tw from '../../../config/tailwind';
import { useAppContext } from '../../../hooks/useAppContext';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { useToasts } from '../../../hooks/useToasts';
import { GlobalSettingsIds } from '../../../shared/types/globalSettings';
import { Rebate, RebateNewPayload } from '../../../shared/types/rebates';
import { displayQuarter, getFullQuarters } from './RebateFunction';
import RebateSupportingEvidencesUploader from './RebateSupportingEvidencesUploader';

dayjs.extend(advancedFormat);
dayjs.extend(quarterOfYear);

interface Props {
  rebates: Rebate[];
  visible: boolean | Date;
  setVisible: (v: boolean) => void;
  refresh?: () => void;
}

export const resolveTemplateFile = async (filename: string) => {
  console.clear();
  const freshUrl = await RebateClient.getTemplateFile(filename);
  const link = document.createElement('a');
  link.href = freshUrl.data;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const RebateNewModal: React.FC<Props> = ({
  visible,
  setVisible,
  refresh,
  rebates,
}) => {

  const { user, activeCompany } = useAuthContext();

  const defaultInitialValues: RebateNewPayload = {
    companyId: activeCompany?.id || '',
    rebateDate: null,
    evidence: null,
    supportingEvidences: [],
    consent: false,
  };

  const [QuarterOptions, setQuarterOptions] = useState(getFullQuarters(rebates, 'exclude', activeCompany?.rebateBackdate ?? false));
  useEffect(() => {
    if(!activeCompany){
      return
    }
    setQuarterOptions(getFullQuarters(rebates, 'exclude', activeCompany?.rebateBackdate ?? false));
  }, [activeCompany]);


  const [saving, setSaving] = useState(true);
  const [rebateTemplate, setRebateTemplate] = useState<
    RebateTemplate | undefined
  >(undefined);
  const { setGlobalLoading } = useAppContext();
  const [initialValues, setInitialValues] =
    useState<RebateNewPayload>(defaultInitialValues);
  const { addToast } = useToasts();

  const schema: Yup.SchemaOf<typeof initialValues> = Yup.object().shape({
    companyId: Yup.string().uuid().required(),
    rebateDate: Yup.date().required('Please select a quarter from the list'),
    evidence: Yup.mixed().required('Please upload a valid excel file'),
    supportingEvidences: Yup.array(),
    consent: Yup.bool()
      .required()
      .isTrue('Please agree to the rebate process terms'),
  });

  const loadSettings = async () => {
    const res = await GlobalSettingsClient.getAll();
    const tempRebateTemplate = JSON.parse(
      res.find((x) => x.id === GlobalSettingsIds.rebateTemplate)?.value || '{}'
    ) as RebateTemplate;
    setRebateTemplate(tempRebateTemplate);
  };

  useEffect(() => {
    loadSettings();
  }, []);

  // useEffect to modify the init value
  useEffect(() => {
    if (!activeCompany || !user) {
      setInitialValues(defaultInitialValues);
      return;
    }
    setInitialValues(
      Object.assign(
        { ...defaultInitialValues },
        { companyId: activeCompany.id }
      )
    );
  }, [user, activeCompany]);

  useEffect(() => {
    if (isDate(visible)) {
      console.log('visible is date, updating date', visible);
      setInitialValues((i) => Object.assign({ ...i }, { rebateDate: visible }));
    } else {
      setInitialValues(defaultInitialValues);
    }
  }, [visible]);

  const onSubmit = async (payload: RebateNewPayload) => {
    console.log('on submit');
    // set loading
    setGlobalLoading && setGlobalLoading(true);

    // post request
    const res = await RebateClient.create(payload);

    // remove loading
    setGlobalLoading && setGlobalLoading(false);

    // catch and handle erro response
    if (res.status !== 201) {
      addToast({
        title: 'Failed to create new rebate.',
        description: res.message,
        type: 'error',
      });
      return;
    }

    // update success toast
    addToast({
      title: 'New Rebate created',
      description: res.message,
      type: 'success',
    });

    refresh && refresh();

    setVisible(false);
  };

  return (
    <>
      <DefaultModal
        setVisible={setVisible}
        visible={!!visible}
        title='New Rebate Case'
      >
        <Formik
          initialValues={initialValues}
          enableReinitialize
          onSubmit={onSubmit}
          validationSchema={schema}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({ handleSubmit, setFieldValue, values, errors, isSubmitting }) => (
            <>
              <FormObserver
                onChange={(v) => {
                  console.log(`FormObserver`, v);
                }}
              />
              <View style={tw`font-sans`}>
                <p>Company: {activeCompany?.name}</p>
              </View>
              <View style={tw`font-sans`}>
                <p>Select Quarter</p>
                <div style={{ zIndex: '999' }}>
                  <ReactSelect
                    value={{
                      value: values.rebateDate
                        ? dayjs(values.rebateDate).startOf('quarter').toDate()
                        : null,
                      label: values.rebateDate
                        ? displayQuarter(values.rebateDate)
                        : 'Select quarter',
                    }}
                    styles={{
                      control: (b, p) => ({
                        ...b,
                        ...tw.style(
                          'bg-white px-4 py-0 mb-4 font-sans border-2 border-blue h-[50px] flex flex-row items-center min-w-48 flex-1 rounded-none'
                        ),
                      }),
                      menu: (b, p) => ({ ...b, ...tw.style(`rounded-none`) }),
                      option: (b, p) => ({
                        ...b,
                        ...tw.style(`justify-center font-sans`),
                      }),
                      noOptionsMessage: (b, p) => ({
                        ...b,
                        ...tw.style(`font-sans`),
                      }),
                    }}
                    options={QuarterOptions.sort((a, b) =>
                      a.value > b.value ? -1 : 1
                    )}
                    onChange={(v) => {
                      setFieldValue('rebateDate', v?.value);
                    }}
                  />
                  {errors.rebateDate ? (
                    <p style={tw`text-red`}>{errors.rebateDate}</p>
                  ) : null}
                </div>

                <div>
                  <H3>Evidence Spreadsheet</H3>
                  <FilePicker
                    type={[
                      `application/vnd.ms-excel`,
                      `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet`,
                    ]}
                    setValue={(v) => setFieldValue('evidence', v)}
                    label='Select File to upload'
                    hasError={!!errors.evidence}
                  />
                  {errors.evidence ? (
                    <p style={tw`text-red`}>{errors.evidence}</p>
                  ) : null}
                </div>

                <RebateSupportingEvidencesUploader
                  files={values.supportingEvidences}
                  addFiles={(f) => {
                    if (!f) {
                      return;
                    }
                    const files = [...values.supportingEvidences].concat([f]);
                    setFieldValue('supportingEvidences', files);
                  }}
                  removeFiles={(idx) => {
                    setFieldValue(
                      'supportingEvidences',
                      values.supportingEvidences.filter((f, i) => i !== idx)
                    );
                  }}
                  // error={errors.supportingEvidences}
                />

                <div style={tw`my-5`}>
                  <ConsentInfo
                    value={values.consent}
                    setValue={(v) => setFieldValue('consent', v)}
                  />
                  {errors.consent ? (
                    <p style={tw`text-red`}>{errors.consent}</p>
                  ) : null}
                </div>

                <div style={tw`w-full flex flex-rows justify-between`}>
                  {rebateTemplate ? (
                    <a
                      style={tw`font-sans mr-auto bg-[#eee] p-4 flex flex-rows justify-center align-center items-center text-dark no-underline`}
                      href='#'
                      onClick={() => resolveTemplateFile(rebateTemplate.name)}
                    >
                      <DownloadSVG style={tw`mr-2`} />
                      Download evidence template
                    </a>
                  ) : null}
                  <Button onPress={handleSubmit} disabled={isSubmitting}>
                    Submit New Rebate
                  </Button>
                </div>
              </View>
            </>
          )}
        </Formik>
        <LoadingScreen />
      </DefaultModal>
    </>
  );
};

export default RebateNewModal;

const FormObserver: React.FC<{ onChange: (values: unknown) => void }> = ({
  onChange,
}) => {
  const { values } = useFormikContext();

  useEffect(() => {
    onChange(values);
  }, [values]);

  return null;
};
