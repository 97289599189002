import React, { useRef } from 'react';
import { Pressable } from 'react-native';
import { useHover } from 'react-native-web-hooks';
import { ClassInput } from 'twrnc/dist/esm/types';

import tw from '../../config/tailwind';
import TagsSVG from '../svg/TagsSVG';

interface TagsButtonProps {
  onPress: () => void;
  style?: ClassInput;
}

const TagsButton: React.FC<TagsButtonProps> = ({ onPress, style }) => {
  const ref = useRef(null);
  const isHovered = useHover(ref);

  return (
    <Pressable
      ref={ref}
      onPress={onPress}
      style={tw.style(
        `h-10 w-10 flex items-center justify-center bg-background-300 rounded-full`,
        style
      )}
    >
      <TagsSVG style={tw.style(isHovered && 'text-green')} />
    </Pressable>
  );
};

export default TagsButton;
