import { API_BASE_URL } from '../env-consts';
import { PaginatedResult } from '../shared/types/paginatedResult';
import {
  CreateProjectRoomPayload,
  ProjectCompanyUserResponse,
  ProjectRole,
  UpdateProjectRoomPayload,
  ProjectRoomResponse,
  ProjectResponse,
  ProjectStatus,
  CreateProjectPayload,
  CompleteProjectErrorResponse,
  CompleteProjectPayload,
  UpdateProjectPayload,
  ProjectStatsResponse,
  ProjectProductResponse,
} from '../shared/types/projects';
import { WpProduct } from '../shared/types/wordpress';
import ApiClient from './ApiClient';

const client = new ApiClient(`${API_BASE_URL}`);

const ProjectRoomClient = {
  async create(projectId: string, payload: CreateProjectRoomPayload) {
    return client
      .post<ProjectRoomResponse>(`/projects/${projectId}/rooms`, payload)
      .then(({ data }) => data);
  },

  async getById(projectId: string, roomId: string) {
    return client
      .get<ProjectRoomResponse>(`/projects/${projectId}/rooms/${roomId}`)
      .then(({ data }) => data);
  },

  async update(
    projectId: string,
    roomId: string,
    payload: UpdateProjectRoomPayload
  ) {
    return client
      .put<ProjectRoomResponse>(
        `/projects/${projectId}/rooms/${roomId}`,
        payload
      )
      .then(({ data }) => data);
  },

  async getAllByProjectId(projectId: string, page: number) {
    return client
      .get<PaginatedResult<ProjectRoomResponse>>(
        `/projects/${projectId}/rooms`,
        {
          params: {
            page,
          },
        }
      )
      .then(({ data }) => data);
  },

  async deleteById(projectId: string) {
    return client
      .delete<void>(`/projects/${projectId}/rooms/:roomId`)
      .then(({ data }) => data);
  },
};

const ProjectUsersClient = {
  async getUsers(projectId: string) {
    return client
      .get<PaginatedResult<ProjectCompanyUserResponse>>(`/${projectId}/users`)
      .then(({ data }) => data);
  },

  async addUser(projectId: string, userId: string, newRole?: ProjectRole) {
    return client
      .post<ProjectCompanyUserResponse>(
        `/${projectId}/users/${userId}`,
        {},
        {
          params: {
            projectRole: newRole,
          },
        }
      )
      .then(({ data }) => data);
  },

  async removeUser(projectId: string, userId: string) {
    return client
      .delete<void>(`/${projectId}/users/${userId}`)
      .then(({ data }) => data);
  },
};

export const ProjectClient = {
  async create(payload: CreateProjectPayload) {
    return client
      .post<ProjectResponse>('/projects', payload)
      .then((res) => res.data);
  },

  async getAll(
    companyId: string | undefined,
    page: number,
    status: ProjectStatus | undefined,
    query?: string
  ) {
    return client
      .get<PaginatedResult<ProjectResponse>>('/projects', {
        params: {
          page,
          companyId,
          status,
          query,
        },
      })
      .then((res) => res.data);
  },

  async getById(projectId: string) {
    return client
      .get<ProjectResponse>(`/projects/${projectId}`)
      .then((res) => res.data);
  },

  async getProducts(projectId: string, page: number) {
    return client
      .get<PaginatedResult<ProjectProductResponse>>(
        `/projects/${projectId}/products`,
        {
          params: { page },
        }
      )
      .then(({ data }) => data);
  },

  async getStatsById(projectId: string) {
    return client
      .get<ProjectStatsResponse>(`/projects/${projectId}/stats`)
      .then((res) => res.data);
  },

  async getStats(companyId?: string) {
    return client
      .get('/projects/stats', {
        params: {
          companyId,
        },
      })
      .then((res) => res.data);
  },

  async update(projectId: string, payload: UpdateProjectPayload) {
    return client
      .patch<ProjectResponse>(`/projects/${projectId}`, payload)
      .then(({ data }) => data);
  },

  async delete(projectId: string) {
    return client
      .delete<ProjectResponse>(`/projects/${projectId}`)
      .then(() => {});
  },

  async complete(projectId: string, payload: CompleteProjectPayload) {
    return client
      .post<ProjectResponse>(`/projects/${projectId}/complete`, payload)
      .then(({ data }) => data);
  },

  async canComplete(projectId: string) {
    return client
      .get<CompleteProjectErrorResponse>(`/projects/${projectId}/complete`)
      .then(({ data }) => data);
  },

  users: ProjectUsersClient,
  rooms: ProjectRoomClient,
};
