import { createNativeStackNavigator } from '@react-navigation/native-stack';
import React from 'react';
import AdminLeadsTable from '../components/admin/AdminLeadsTable';

import AdminCompanyScreen from '../views/AuthenticatedStack/GlobalAdminStack/AdminCompanyScreen';
import AdminContactsScreen from '../views/AuthenticatedStack/GlobalAdminStack/AdminContactsScreen';
import AdminLeadScreen from '../views/AuthenticatedStack/GlobalAdminStack/AdminLeadScreen';
import AdminScreen from '../views/AuthenticatedStack/GlobalAdminStack/AdminScreen';
import AdminUserScreen from '../views/AuthenticatedStack/GlobalAdminStack/AdminUserScreen';
import { GlobalAdminStackNavigatorParamList } from './types';

const Stack = createNativeStackNavigator<GlobalAdminStackNavigatorParamList>();

const GlobalAdminStackNavigator: React.FC = () => {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen
        name='admin'
        component={AdminScreen}
        options={{ title: 'Admin' }}
      />
      <Stack.Screen
        name='user'
        component={AdminUserScreen}
        options={{ title: 'User' }}
      />
      <Stack.Screen
        name='company'
        component={AdminCompanyScreen}
        options={{ title: 'Company' }}
      />
      <Stack.Screen
        name='contacts'
        component={AdminContactsScreen}
        options={{ title: 'Contacts' }}
      />
      <Stack.Screen
        name='leads'
        component={AdminLeadScreen}
        options={{ title: 'Leads' }}
      />
    </Stack.Navigator>
  );
};

export default GlobalAdminStackNavigator;
