import React, { useState } from 'react';

import tw from '../../config/tailwind';
import A from './A';
import InputCheckbox from './InputCheckbox';
import P from './P';

interface ConsentInfoProps {
  value: boolean;
  setValue: (v: boolean) => void;
}

const ConsentInfo: React.FC<ConsentInfoProps> = ({ value, setValue }) => {
  return (
    <InputCheckbox
      label={
        <P style={tw`mb-0`}>
          I have read and accept the{' '}
          <A
            style={tw`text-blue`}
            url='https://www.zentia.com/en-gb/terms-conditions'
          >
            Terms and conditions
          </A>{' '}
          and{' '}
          <A
            style={tw`text-blue`}
            url='https://www.zentia.com/en-gb/website-privacy-policy'
          >
            Privacy policy
          </A>
          .
        </P>
      }
      value={value}
      setValue={setValue}
    />
  );
};

export default ConsentInfo;
