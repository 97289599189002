import React from 'react';
import tw from '../../config/tailwind';
import Text from '../shared/Text';

interface ProductTagProps {
  children: string;
}

const ProductTag: React.FC<ProductTagProps> = ({ children }) => {
  return (
    <Text
      style={tw`border border-background-600 rounded-full font-bold font-ubunutu-bold text-center px-3 py-1 text-xs`}
    >
      {children}
    </Text>
  );
};

export default ProductTag;
