import { API_BASE_URL } from "../env-consts";
import { CreateInvoicePayload, InvoiceResponse, UpdateInvoicePayload } from "../shared/types/invoices";
import ApiClient from "./ApiClient";
import { serialize } from 'object-to-formdata';
import { PaginatedResult } from "../shared/types/paginatedResult";

const client = new ApiClient(`${API_BASE_URL}/invoices`);
const cuHeader = { headers: { 'Content-Type': 'multipart/form-data' } };
export const InvoiceClient = {
    async create(payload: CreateInvoicePayload, file: File) {
        const data = serialize(payload, { indices: true });
        data.set('file', file, file.name);
        console.log('data', data);
        if(!payload.projectsId || !payload.projectsId.length){
            data.set('projectsId[0]', '');
        }
        const res = await client.post<InvoiceResponse>('', data, cuHeader);
        return res.data;
    },

    async update(id: string, payload: UpdateInvoicePayload, file?: File) {
        // const data = serialize(payload, { indices: true });
        // if (file) { data.set('file', file, file.name) };
        const res = await client.patch<InvoiceResponse>(`/${id}`, payload, {
            headers:{
                'Content-Type': 'application/json'
            }
        });
        return res.data;
    },

    async getList(page: number = 1, companyId?: string, onlyMyInvoices?: boolean) {
        const params = { params: { page, companyId, onlyMyInvoices } };
        const res = await client.get<PaginatedResult<InvoiceResponse>>(`/`, params);
        return res.data;
    },

    async getById(id: string) {
        const res = await client.get<InvoiceResponse>(`/${id}`);
        return res.data;
    },

    async delete(id: string) {
        await client.delete(`/${id}`);
        return
    }
}