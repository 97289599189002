import { StackActions, useNavigation } from '@react-navigation/native';
import axios from 'axios';
import { Formik, FormikErrors } from 'formik';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import React, { useState } from 'react';
import { View } from 'react-native';
import * as Yup from 'yup';

import { UserClient, AuthClient } from '../../api';
import tw from '../../config/tailwind';
import { phoneRegExp, postcodeRegExp } from '../../constants';
import { useAuthContext } from '../../hooks/useAuthContext';
import { useToasts } from '../../hooks/useToasts';
import { LoginResponseType } from '../../shared/types/auth';
import { UserInviteResponse } from '../../shared/types/users';
import { onConflictError } from '../../utils/onConflictError';
import { formatToE164 } from '../../utils/transformToE164';
import Button from '../shared/Button';
import Col from '../shared/Col';
import ConsentInfo from '../shared/ConsentInfo';
import H1 from '../shared/H1';
import H2 from '../shared/H2';
import InputPassword from '../shared/InputPassword';
import InputText from '../shared/InputText';
import InputValidationMessage from '../shared/InputValidationMessage';
import Row from '../shared/Row';
import Text from '../shared/Text';
import PinnacleLogoSvg from '../svg/PinnacleLogoSVG';

const phoneUtil: PhoneNumberUtil =
  require('google-libphonenumber').PhoneNumberUtil.getInstance();

interface CompanyInviteFormProps {
  userId: string;
  companyId: string;
  token: string;
  invite: UserInviteResponse;
}

const CompanyInviteForm: React.FC<CompanyInviteFormProps> = ({
  userId,
  companyId,
  token,
  invite,
}) => {
  const { login, loading: authLoading, setActiveCompanyId } = useAuthContext();
  const navigation = useNavigation();
  const [errors, setErrors] = useState<FormikErrors<typeof initialValues>>();
  const { addToast, addUnhandledErrorToast } = useToasts();

  const initialValues = {
    firstName: invite?.firstName || '',
    lastName: invite?.lastName || '',
    email: invite?.email || '',
    telephone: '',
    businessName: invite?.businessName || '',
    addressLine1: '',
    addressLine2: '',
    county: '',
    postcode: '',
    password: '',
    confirmPassword: '',
    gdprConsent: false,
  };

  const schema: Yup.SchemaOf<typeof initialValues> = Yup.object().shape({
    addressLine1: Yup.string().required().label('Address Line 1'),
    addressLine2: Yup.string().optional().label('Address Line 2').default(''),
    telephone: Yup.string()
      .required()
      .matches(phoneRegExp, 'Please provide a valid phone number')
      .transform(formatToE164)
      // .test('phoneFormatTest', 'Invalid format (not GB)', (value) => {
      //   try {
      //     return phoneUtil.isValidNumberForRegion(
      //       phoneUtil.parse(value, 'GB'),
      //       'GB'
      //     );
      //   } catch {
      //     return false;
      //   }
      // })
      .label('Telephone'),
    firstName: Yup.string().required().label('First name'),
    lastName: Yup.string().required().label('Last name'),
    password: Yup.string().required().label('Password').min(8).max(24),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required()
      .label('Confirm password'),
    businessName: Yup.string().required().label('Business name'),
    county: Yup.string().required().label('County'),
    email: Yup.string().email().required().label('Email'),
    postcode: Yup.string()
      .required()
      .matches(postcodeRegExp, 'Please provide a valid postcode')
      .label('Postcode'),
    gdprConsent: Yup.boolean()
      .isTrue('Terms of use must be accepted to access the Pinnacle portal')
      .required()
      .label('Terms of use'),
  });

  const acceptInvite = async (formValues?: typeof initialValues) => {
    let accessToken: string;

    if (formValues) {
      await UserClient.acceptInvite({
        userId,
        companyId,
        token,
        password: formValues.password,
        confirmPassword: formValues.confirmPassword,
        userDetails: {
          firstName: formValues.firstName,
          lastName: formValues.lastName,
          email: formValues.email,
          telephone: formatToE164(formValues.telephone),
          businessName: formValues.businessName,
          address: [
            formValues.addressLine1,
            formValues.addressLine2,
            formValues.county,
          ].join(','),
          postcode: formValues.postcode,
        },
        gdprConsent: formValues.gdprConsent,
      }).catch((e) =>
        onConflictError(e, addToast).catch((e) => addUnhandledErrorToast(e))
      );

      accessToken = await AuthClient.login({
        email: formValues.email,
        password: formValues.password,
      }).then((res) =>
        res.type === LoginResponseType.Authenticated ? res.accessToken : ''
      );
    } else {
      await UserClient.acceptInvite({
        userId,
        companyId,
        token,
        gdprConsent: true, // User has previously accepted
      }).catch((e) =>
        onConflictError(e, addToast).catch((e) => addUnhandledErrorToast(e))
      );

      accessToken = await AuthClient.refreshToken();
    }

    login(accessToken);
    setActiveCompanyId(companyId);
    navigation.dispatch(
      StackActions.push('authenticatedStackNavigator', {
        screen: 'home',
      })
    );
  };

  if (authLoading) {
    return null;
  }

  return (
    <>
      <PinnacleLogoSvg style={tw`mb-20`} />
      <H1 xl>Hi {invite?.firstName},</H1>
      <H1>
        You've been invited to join&nbsp;
        <Text
          style={tw`text-green text-2xl font-bold font-ubuntu-bold border-b-2 border-dark`}
        >
          {invite.companyName}
        </Text>
        .
      </H1>

      {!invite.isNewUser && (
        <Button onPress={() => acceptInvite()} style={tw`self-end`}>
          Accept Invite
        </Button>
      )}

      {invite.isNewUser && (
        <>
          <H2>
            As you are a new user, please provide us with some details and
            create a password for your account.
          </H2>
          <Formik
            initialValues={initialValues}
            onSubmit={acceptInvite}
            validationSchema={schema}
            validateOnChange={!!errors}
            validateOnBlur={!!errors}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              values,
              errors,
            }) => (
              <View>
                <Text
                  style={tw`text-lg mb-4 font-ubuntu-bold font-bold text-dark`}
                >
                  Your details
                </Text>
                <Row>
                  <Col style={tw`w-full`}>
                    <Row>
                      <Col style={tw`w-1/2 opacity-50`}>
                        <InputText
                          value={values.email}
                          setValue={handleChange('email')}
                          onBlur={handleBlur('email')}
                          placeholder='Email Address'
                          hasError={!!errors.email}
                          label='Email Address'
                          required
                          readonly
                        />
                      </Col>

                      <Col style={tw`w-1/2`}>
                        <InputText
                          value={values.telephone}
                          setValue={handleChange('telephone')}
                          onBlur={handleBlur('telephone')}
                          placeholder='Telephone'
                          hasError={!!errors.telephone}
                          label='Telephone'
                          required
                        />
                        {errors.telephone && (
                          <InputValidationMessage>
                            {errors.telephone}
                          </InputValidationMessage>
                        )}
                      </Col>
                      <Col style={tw`w-1/2`}>
                        <InputText
                          value={values.firstName}
                          setValue={handleChange('firstName')}
                          onBlur={handleBlur('firstName')}
                          placeholder='First Name'
                          hasError={!!errors.firstName}
                          label='First Name'
                          required
                        />
                        {errors.firstName && (
                          <InputValidationMessage>
                            {errors.firstName}
                          </InputValidationMessage>
                        )}
                      </Col>

                      <Col style={tw`w-1/2`}>
                        <InputText
                          value={values.lastName}
                          setValue={handleChange('lastName')}
                          onBlur={handleBlur('lastName')}
                          placeholder='Last Name'
                          hasError={!!errors.lastName}
                          label='Last Name'
                          required
                        />
                        {errors.lastName && (
                          <InputValidationMessage>
                            {errors.lastName}
                          </InputValidationMessage>
                        )}
                      </Col>

                      <Col>
                        <InputPassword
                          value={values.password}
                          setValue={handleChange('password')}
                          onBlur={handleBlur('password')}
                          placeholder='Enter a password'
                          hasError={!!errors.password}
                          label='Password'
                          required
                        />
                        {errors.password && (
                          <InputValidationMessage>
                            {errors.password}
                          </InputValidationMessage>
                        )}
                      </Col>
                      <Col>
                        <InputPassword
                          value={values.confirmPassword}
                          setValue={handleChange('confirmPassword')}
                          onBlur={handleBlur('confirmPassword')}
                          placeholder='Confirm your password'
                          hasError={!!errors.confirmPassword}
                          label='Confirm Password'
                          required
                        />
                        {errors.confirmPassword && (
                          <InputValidationMessage>
                            {errors.confirmPassword}
                          </InputValidationMessage>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Text
                  style={tw`text-lg mb-4 font-ubuntu-bold font-bold text-dark mt-4`}
                >
                  Your business details
                </Text>

                <Row>
                  <Col style={tw`w-full`}>
                    <Row>
                      <Col style={tw`w-full`}>
                        <InputText
                          value={values.businessName}
                          setValue={handleChange('businessName')}
                          onBlur={handleBlur('businessName')}
                          placeholder='Business Name'
                          hasError={!!errors.businessName}
                          label='Business Name'
                          required
                        />
                        {errors.businessName && (
                          <InputValidationMessage>
                            {errors.businessName}
                          </InputValidationMessage>
                        )}
                      </Col>
                      <Col style={tw`w-1/2`}>
                        <InputText
                          value={values.addressLine1}
                          setValue={handleChange('addressLine1')}
                          onBlur={handleBlur('addressLine1')}
                          placeholder='Address Line 1'
                          hasError={!!errors.addressLine1}
                          label='Address Line 1'
                          required
                        />
                        {errors.addressLine1 && (
                          <InputValidationMessage>
                            {errors.addressLine1}
                          </InputValidationMessage>
                        )}
                      </Col>

                      <Col style={tw`w-1/2`}>
                        <InputText
                          value={values.addressLine2}
                          setValue={handleChange('addressLine2')}
                          onBlur={handleBlur('addressLine2')}
                          placeholder='Address Line 2'
                          hasError={!!errors.addressLine2}
                          label='Address Line 2'
                        />
                        {errors.addressLine2 && (
                          <InputValidationMessage>
                            {errors.addressLine2}
                          </InputValidationMessage>
                        )}
                      </Col>
                      <Col style={tw`w-1/2`}>
                        <InputText
                          value={values.county}
                          setValue={handleChange('county')}
                          onBlur={handleBlur('county')}
                          placeholder='County'
                          hasError={!!errors.county}
                          label='County'
                          required
                        />
                        {errors.county && (
                          <InputValidationMessage>
                            {errors.county}
                          </InputValidationMessage>
                        )}
                      </Col>
                      <Col>
                        <InputText
                          value={values.postcode}
                          setValue={handleChange('postcode')}
                          onBlur={handleBlur('postcode')}
                          placeholder='Postcode'
                          hasError={!!errors.postcode}
                          label='Postcode'
                          required
                        />
                        {errors.postcode && (
                          <InputValidationMessage>
                            {errors.postcode}
                          </InputValidationMessage>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <ConsentInfo
                  value={values.gdprConsent}
                  setValue={(v) => setFieldValue('gdprConsent', v)}
                />
                {errors.gdprConsent && (
                  <InputValidationMessage>
                    {errors.gdprConsent}
                  </InputValidationMessage>
                )}

                <Button
                  onPress={() => {
                    handleSubmit();
                    setErrors(errors);
                  }}
                  style={tw`self-end`}
                >
                  Accept Invite
                </Button>
              </View>
            )}
          </Formik>
        </>
      )}
    </>
  );
};

export default CompanyInviteForm;
