import { RouteProp, useRoute } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { ActivityIndicator, View } from 'react-native';

import { CompanyClient } from '../../../api';
import AdminCompanyContactsTable from '../../../components/admin/AdminCompanyContactsTable';
import AdminCompanyUsersTable from '../../../components/admin/AdminCompanyUsersTable';
import AdminContactsTable from '../../../components/admin/AdminContactsTable';
import EditCompanyModal from '../../../components/admin/modals/EditCompanyModal';
import Tabs from '../../../components/navigation/Tabs';
import A from '../../../components/shared/A';
import BackButton from '../../../components/shared/BackButton';
import ContentWrapper from '../../../components/shared/ContentWrapper';
import H1 from '../../../components/shared/H1';
import tw from '../../../config/tailwind';
import { useAuthContext } from '../../../hooks/useAuthContext';
import DashboardLayout from '../../../layout/DashboardLayout';
import useLinkProps, {
  GlobalAdminStackNavigatorParamList,
} from '../../../routes/types';
import { CompanyResponse } from '../../../shared/types/companies';

const tabs = ['Users', 'Assigned Contacts'] as const;
const initialTab = tabs[0];
type Tab = typeof tabs[number];

interface AdminScreenProps {}

const AdminCompanyScreen: React.FC<AdminScreenProps> = ({}) => {
  const route =
    useRoute<RouteProp<GlobalAdminStackNavigatorParamList, 'company'>>();

  const { activeCompany, setActiveCompanyId } = useAuthContext();

  const [company, setCompany] = useState<CompanyResponse>();
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<Tab>(initialTab);

  const usersLink = useLinkProps<'authenticatedStackNavigator'>({
    screen: 'authenticatedStackNavigator',
    params: {
      screen: 'globalAdminStack',
      params: {
        screen: 'admin',
      },
    },
  });

  useEffect(() => {
    CompanyClient.getById(route.params.id).then((result) => {
      setCompany(result);
    });
  }, [route.params.id]);

  const onTabChange = (tab: string) => {
    setActiveTab(tab as Tab);
  };

  if (!company) {
    return (
      <DashboardLayout>
        <BackButton onPress={usersLink.onPress}>Back to admin</BackButton>
        <ActivityIndicator />
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <BackButton onPress={usersLink.onPress}>Back to admin</BackButton>

      {company && (
        <>
          <EditCompanyModal
            company={company}
            visible={showEditModal}
            setVisible={setShowEditModal}
            onUpdated={(company) => setCompany(company)}
          />

          <H1 xl>{company.name}</H1>
          {activeCompany?.id !== company.id && (
            <A onPress={() => setActiveCompanyId(company.id)}>
              Toggle to company
            </A>
          )}

          <A onPress={() => setShowEditModal(true)}>Edit company</A>
        </>
      )}

      <Tabs
        tabs={[...tabs]}
        activeTab={activeTab}
        emitActiveTab={onTabChange}
      />

      <ContentWrapper>
        {activeTab === 'Users' &&
          (company ? (
            <>
              <EditCompanyModal
                company={company}
                visible={showEditModal}
                setVisible={setShowEditModal}
                onUpdated={(company) => setCompany(company)}
              />

              <AdminCompanyUsersTable company={company} />
            </>
          ) : (
            <ActivityIndicator />
          ))}

        {activeTab === 'Assigned Contacts' && (
          <AdminCompanyContactsTable companyId={route.params.id} />
        )}
      </ContentWrapper>
    </DashboardLayout>
  );
};

export default AdminCompanyScreen;
