import { LocalStorage } from '../config/localStorage';
import { API_BASE_URL } from '../env-consts';
import {
  EnableTfaPayload,
  LoginPayload,
  LoginResponse,
  LoginResponseType,
  LogoutPayload,
  RefreshTokenPayload,
  TwoFactorAuthResponse,
} from '../shared/types/auth';
import ApiClient from './ApiClient';

const client = new ApiClient(`${API_BASE_URL}/auth`);

const CACHE_NAMESPACE = 'zentia.cache.auth.';
const REFRESH_TOKEN_KEY = CACHE_NAMESPACE + 'refreshToken';
const ACCESS_TOKEN_KEY = CACHE_NAMESPACE + 'accessToken';

export const AuthClient = {
  async login(payload: LoginPayload) {
    return client
      .post<LoginResponse>(`/login`, payload)
      .then(async ({ data }) => {
        if (data.type === LoginResponseType.Authenticated) {
          await LocalStorage.set(
            REFRESH_TOKEN_KEY,
            data.refreshToken,
            data.refreshTokenExpiry - Date.now()
          );
        }

        return data;
      });
  },

  async refreshToken(accessToken?: string) {
    const payload: RefreshTokenPayload = {
      refreshToken: await LocalStorage.get<string>(REFRESH_TOKEN_KEY).then(
        (res) => res || ''
      ),
      accessToken: accessToken || undefined,
    };

    return client
      .post<LoginResponse>(`/refresh-token`, payload)
      .then(async ({ data }) => {
        if (data.type === LoginResponseType.Authenticated) {
          await LocalStorage.set(
            REFRESH_TOKEN_KEY,
            data.refreshToken,
            data.refreshTokenExpiry - Date.now()
          );
          return data.accessToken;
        }
        throw new Error('Unexpected refresh token response');
      })
      .catch(async (e) => {
        await LocalStorage.set(REFRESH_TOKEN_KEY, undefined, 0);
        await LocalStorage.set(ACCESS_TOKEN_KEY, undefined, 0);
        throw e;
      });
  },

  async logout() {
    const payload: LogoutPayload = {
      refreshToken: await LocalStorage.get<string>(REFRESH_TOKEN_KEY).then(
        (res) => res || ''
      ),
    };

    return client.post(`/logout`, payload).then(() => {});
  },

  async enableTfa(payload: EnableTfaPayload) {
    return client
      .post<TwoFactorAuthResponse>(`/enable-tfa`, payload)
      .then((res) => res.data);
  },

  async disableTfa(otpCode?: string) {
    return client
      .post<TwoFactorAuthResponse>(`/disable-tfa`, { otpCode })
      .then((res) => res.data);
  },
};
