import React, { useEffect, useState } from 'react';
import { ActivityIndicator } from 'react-native';

import { WpClient } from '../../api';
import Pagination from '../../components/Pagination';
import Col from '../../components/shared/Col';
import H1 from '../../components/shared/H1';
import P from '../../components/shared/P';
import Row from '../../components/shared/Row';
import Select from '../../components/shared/Select';
import Spacer from '../../components/shared/Spacer';
import ResourceListing from '../../components/wordpress/ResourceListing';
import tw from '../../config/tailwind';
import { useAppContext } from '../../hooks/useAppContext';
import DashboardLayout from '../../layout/DashboardLayout';
import { PaginatedResult } from '../../shared/types/paginatedResult';
import { WpResource, WPResourceCategory } from '../../shared/types/wordpress';

interface ResourcesScreenProps {}

const ResourcesScreen: React.FC<ResourcesScreenProps> = ({}) => {
  const [paginatedResults, setPaginatedResults] =
    useState<PaginatedResult<WpResource>>();

  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [desc, setDesc] = useState(true);

  const [applicationTypes, setApplicationTypes] = useState<
    WPResourceCategory[]
  >([]);
  const [applicationSubtypes, setApplicationSubtypes] = useState<
    Record<number, WPResourceCategory[]>
  >({});

  const [selectedTypeId, setSelectedTypeId] = useState<number>(-1);
  const [selectedSubTypeId, setSelectedSubTypeId] = useState<number>(-1);

  const { isLoading: appLoading, checkFavourite } = useAppContext();

  useEffect(() => {
    if (appLoading) return;

    setLoading(true);

    const selectedId =
      selectedSubTypeId >= 0
        ? selectedSubTypeId
        : selectedTypeId >= 0
        ? selectedTypeId
        : undefined;

    // Bulk load - favourite state
    WpClient.getResources(page, desc, selectedId).then(async (res) => {
      await Promise.allSettled(
        res.items.map((item) => checkFavourite(item.id))
      );
      setPaginatedResults(res);
      setLoading(false);
    });
  }, [page, desc, appLoading, selectedTypeId, selectedSubTypeId]);

  useEffect(() => {
    let isActive = true;

    WpClient.getResourceApplications().then((categories) => {
      if (!isActive) return;
      const topLevelCategoryIds: number[] = [];
      const topLevelCategories: WPResourceCategory[] = [];
      const groupedSubCategories: Record<number, WPResourceCategory[]> = {};
      const categoryParentPairs: Record<number, number> = {};

      const findTopLevelParent = (termId: number): number | undefined => {
        if (categoryParentPairs[termId] > 0) {
          return findTopLevelParent(categoryParentPairs[termId]);
        }
        return categoryParentPairs[termId] || termId;
      };

      for (const cat of categories) {
        if (cat.parent === 0) {
          categoryParentPairs[cat.id] = cat.parent;
          topLevelCategories.push(cat);
          topLevelCategoryIds.push(cat.id);
          continue;
        }

        const topLevelParentId = findTopLevelParent(cat.parent);

        if (topLevelParentId === undefined) {
          categories.push(cat);
          continue;
        }

        const subCatGroup = groupedSubCategories[topLevelParentId];
        groupedSubCategories[topLevelParentId] = subCatGroup
          ? [...subCatGroup, cat]
          : [cat];
      }

      setApplicationTypes(topLevelCategories);
      setApplicationSubtypes(groupedSubCategories);
    });

    return () => {
      isActive = false;
    };
  }, []);

  return (
    <DashboardLayout>
      <H1 xl>Resources</H1>
      <P style={tw`mb-10`}>
        Search our collection of useful resources by type and product. Find the
        right resources to help you with your project, just when you need it.
        Searching for something often? Add it to your Favourites by clicking the
        heart icon.
      </P>

      {paginatedResults && (
        <Row style={tw`-mx-1.5`}>
          <Col style={tw`w-full sm:w-1/2 md:w-1/3 px-1.5`}>
            <Select
              label='Type'
              placeholderOption='All'
              selectedValue={selectedTypeId > -1 ? selectedTypeId : undefined}
              onValueChange={(typeId) => {
                setSelectedTypeId(typeId);
                setSelectedSubTypeId(-1);
              }}
              options={applicationTypes.map((type) => ({
                label: type.name,
                value: type.id,
              }))}
            />
          </Col>
          <Col
            style={tw.style(
              `w-full sm:w-1/2 md:w-1/3 px-1.5`,
              selectedTypeId === -1 && 'opacity-50'
            )}
            pointerEvents={selectedTypeId === -1 ? 'none' : undefined}
          >
            <Select
              label='Sub-category'
              placeholderOption='All'
              selectedValue={
                selectedSubTypeId > -1 ? selectedSubTypeId : undefined
              }
              onValueChange={setSelectedSubTypeId}
              options={applicationSubtypes[selectedTypeId]?.map((type) => ({
                label: type.name,
                value: type.id,
              }))}
            />
          </Col>

          <Spacer />

          <Col style={tw.style('w-full sm:w-1/2 md:w-1/3 md:max-w-54 px-1.5')}>
            <Select
              onValueChange={setDesc}
              label='Sort by'
              selectedValue={desc}
              options={[
                { label: 'Most recent first', value: true },
                { label: 'Oldest first', value: false },
              ]}
            />
          </Col>
        </Row>
      )}

      {loading || !paginatedResults ? (
        <ActivityIndicator color='currentColor' />
      ) : (
        <>
          <Row style={tw`-mx-1.5`}>
            {paginatedResults.items.map((item, i) => (
              <Col style={tw`w-full md:w-1/2 lg:w-1/3 mb-3 px-1.5`}>
                <ResourceListing resource={item} />
              </Col>
            ))}
          </Row>

          <Pagination
            paginatedResult={paginatedResults}
            onChangePage={setPage}
          />
        </>
      )}
    </DashboardLayout>
  );
};

export default ResourcesScreen;
