import { Formik } from 'formik';
import React from 'react';
import { ActivityIndicator, View } from 'react-native';
import * as Yup from 'yup';
import { ProjectClient } from '../../../api';

import tw from '../../../config/tailwind';
import { useToasts } from '../../../hooks/useToasts';
import {
  CreateProjectRoomPayload,
  ProjectResponse,
  ProjectRoomResponse,
} from '../../../shared/types/projects';
import Button from '../../shared/Button';
import DefaultModal from '../../shared/DefaultModal';
import InputText from '../../shared/InputText';
import InputValidationMessage from '../../shared/InputValidationMessage';
import WpProductPicker from '../WpProductPicker';

interface AddRoomModalProps {
  project: ProjectResponse;
  visible: boolean;
  setVisible: (v: boolean) => void;
  onRoomAdded: (comment: ProjectRoomResponse) => void;
}

const AddRoomModal: React.FC<AddRoomModalProps> = ({
  project,
  visible,
  setVisible,
  onRoomAdded,
}) => {
  const { addToast, addUnhandledErrorToast } = useToasts();

  const initialValues: CreateProjectRoomPayload = {
    name: '',
    relatedProductIds: undefined,
  };

  const schema: Yup.SchemaOf<typeof initialValues> = Yup.object().shape({
    name: Yup.string().required(),
    relatedProductIds: Yup.array().optional(),
  });

  const onSubmit = async (payload: CreateProjectRoomPayload) => {
    try {
      const room = await ProjectClient.rooms.create(project.id, payload);
      setVisible(false);
      addToast({
        title: 'Room created',
        description: `You added the room '${payload.name}'`,
        type: 'success',
      });
      onRoomAdded(room);
    } catch (e) {
      addUnhandledErrorToast(e);
    }
  };

  return (
    <DefaultModal
      setVisible={setVisible}
      visible={visible}
      title='Create a room'
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={schema}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          values,
          errors,
          isSubmitting,
        }) => (
          <View style={tw`mt-4`}>
            <InputText
              placeholder='Enter a room name'
              label='Room name'
              required
              value={values.name}
              setValue={handleChange('name')}
              onBlur={handleBlur('name')}
              hasError={!!errors.name}
            />
            {errors.name && (
              <InputValidationMessage>{errors.name}</InputValidationMessage>
            )}

            <WpProductPicker
              productIds={values.relatedProductIds || []}
              setProductIds={(value) =>
                setFieldValue('relatedProductIds', value)
              }
              hideFavBtn
            />

            <View style={tw`flex flex-row items-center justify-end mt-4`}>
              {isSubmitting && <ActivityIndicator style={tw`mr-4`} />}
              <Button onPress={handleSubmit} disabled={isSubmitting}>
                Create room
              </Button>
            </View>
          </View>
        )}
      </Formik>
    </DefaultModal>
  );
};

export default AddRoomModal;
