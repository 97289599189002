import React from 'react';
import { View } from 'react-native';
import { ClassInput } from 'twrnc/dist/esm/types';

import tw from '../../config/tailwind';

interface ColProps {
  children: React.ReactNode;
  style?: ClassInput;
  pointerEvents?: 'box-none' | 'none' | 'box-only' | 'auto' | undefined;
}

const Col: React.FC<ColProps> = ({ children, style, pointerEvents }) => {
  return (
    <View pointerEvents={pointerEvents} style={tw.style(`w-1/2 px-3`, style)}>
      {children}
    </View>
  );
};

export default Col;
