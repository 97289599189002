import { Platform } from 'react-native';

import { TrainingSession } from './@types/training';
import { CompanyUserRole } from './shared/types/companies';

export const META_TITLE_SUFFIX = '- Zentia Portal';
export const isWeb = Platform.OS === 'web';
export const phoneRegExp = /^[\+]?[0-9]+$/; // https://ihateregex.io/expr/phone/ - No longer used

// https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/488478/Bulk_Data_Transfer_-_additional_validation_valid_from_12_November_2015.pdf
export const postcodeRegExp = /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z])))) ?[0-9][A-Za-z]{2})$/; 

export const ASYNC_STORE_ACTIVE_COMPANY_ID = 'ACTIVE_COMPANY_ID';
export const TRAINING_NUMBER_OF_ATTENDEE_OPTIONS = ['1', '2'];

export const TRAINING_SESSIONS: TrainingSession[] = [
  {
    name: 'Pinnacle supervisor training',
    tooltip: 'Tooltip',
  },
];

export const GEOLOCATE_SEARCH_LABEL = 'Your location'

export const COMPANY_USER_ROLE_LABELS: Record<CompanyUserRole, string> = {
	[CompanyUserRole.Admin]: 'Admin',
	[CompanyUserRole.Contractor]: 'User'
}

export const MINUTE_IN_MS = 1000 * 60;
export const HOUR_IN_MS = MINUTE_IN_MS * 60;
export const DAY_IN_MS = HOUR_IN_MS * 24;
export const WEEK_IN_MS = DAY_IN_MS * 7;

export enum GlobalLabels{
  FindContact = 'Find Your Closest Contact',
  SearchForContact = `Search for a contact`,
  PinnacleNews = 'Pinnacle News',
  ZentiaContacts = 'Zentia Contacts',
  GlobalAdmin = 'Global Admin',
  Invoices = 'Invoices',
  Rebates = 'Rebates'
}