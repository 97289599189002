import {
  Link,
  NavigationProp,
  StackActions,
  useNavigation
} from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { Pressable, useWindowDimensions, View } from 'react-native';
import { SvgProps } from 'react-native-svg';
import { ClassInput } from 'twrnc/dist/esm/types';

import tw from '../../config/tailwind';
import { isWeb } from '../../constants';
import { useAppContext } from '../../hooks/useAppContext';
import { useAuthContext } from '../../hooks/useAuthContext';
import { useContainer } from '../../hooks/useContainer';
import { AuthenticatedStackNavigatorParamList } from '../../routes/types';
import InvoicePromoModal from '../invoices/modals/InvoicePromoModal';
import Button from '../shared/Button';
import Spacer from '../shared/Spacer';
import HeartAltSVG from '../svg/HeartAltSVG';
import ListSVG from '../svg/ListSVG';
import PersonSVG from '../svg/PersonSVG';

interface TopBarProps { }
const TopBar: React.FC<TopBarProps> = () => {
  const [margin] = useContainer();
  const navigation =
    useNavigation<NavigationProp<AuthenticatedStackNavigatorParamList>>();

  const iconSize = 24;

  const { menuActive, setMenuActive } = useAppContext();
  const { user, activeCompany } = useAuthContext();
  const [showInvoicePromo, setShowInvoicePromo] = useState(false);

  const window = useWindowDimensions();
  const [isMobileMenu, setIsMobileMenu] = useState(window.width <= 1024);

  useEffect(() => {
    if (window.width <= 1024 !== isMobileMenu) {
      setIsMobileMenu(window.width <= 1024);
    }
  }, [window.width]);

  return (
    <View
      style={tw.style(`bg-background pl-4`, {
        marginRight: `-${margin}`,
        paddingRight: `${margin}`,
      })}
      pointerEvents={isWeb ? 'auto' : undefined}
    >
      <View
        style={tw`flex flex-row justify-end border-b-2 py-4 border-grey-200`}
      >
        {isMobileMenu && (
          <>
            <Pressable
              onPress={() => setMenuActive(!menuActive)}
              style={tw`w-15 h-15 bg-white rounded-full flex items-center justify-center`}
            >
              <ListSVG width={24} height={24} style={tw`text-green`} />
            </Pressable>
            <Spacer />
          </>
        )}

        {!isMobileMenu && activeCompany && (
          <>
            <Button
              onPress={() =>
                navigation.dispatch(
                  StackActions.push('projectStack', {
                    screen: 'createProject',
                  })
                )
              }
              outlined
              style={`mr-3 px-6`}
            >
              + Add a new project
            </Button>
          </>
        )}

        <Link
          to={{
            screen: 'authenticatedStackNavigator',
            params: {
              screen: 'favourites',
            },
          }}
          style={tw.style(`aspect-1 mr-3`)}
        >
          <NavIcon
            svg={HeartAltSVG}
            size={iconSize}
            style={tw`mr-0 text-green h-15 w-15 h-full`}
            color='currentColor'
          />
        </Link>

        {/* <NavIcon
          svg={BellSvg}
          size={iconSize}
          style={tw`mr-3 text-green`}
          color='currentColor'
        /> */}

        <Link
          to={{
            screen: 'authenticatedStackNavigator',
            params: {
              screen: 'myAccount',
            },
          }}
          style={tw.style(`h-15 w-15 mr-3`)}
        >
          <NavIcon
            svg={PersonSVG}
            size={iconSize}
            style={tw`text-green`}
            color='currentColor'
          />
        </Link>
      </View>
    </View>
  );
};

interface NavIconProps {
  svg: React.FC<SvgProps>;
  style?: ClassInput;
  size: number;
  color?: string;
}

const NavIcon: React.FC<NavIconProps> = ({
  svg: IconSvg,
  size,
  style,
  color,
}) => {
  return (
    <View
      style={tw.style(
        `flex justify-center items-center rounded-full bg-white aspect-1`,
        style
      )}
    >
      <IconSvg width={size} height={size} color={color} />
    </View>
  );
};

export default TopBar;
