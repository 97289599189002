import React from 'react';
import { Text as NativeText } from 'react-native';
import { ClassInput } from 'twrnc/dist/esm/types';

import tw from '../../config/tailwind';

interface TextProps {
  onPress?: () => void;
  style?: ClassInput;
}

const Text: React.FC<TextProps> = React.forwardRef(
  ({ children, style, onPress }, ref) => {
    return (
      <NativeText
        ref={ref as React.LegacyRef<NativeText>}
        onPress={onPress}
        style={tw.style(`font-sans text-base text-blue`, style)}
      >
        {children}
      </NativeText>
    );
  }
);
export default Text;
