import {
  NavigationProp,
  StackActions,
  useNavigation,
} from '@react-navigation/native';
import React, { useContext, useState } from 'react';
import { View } from 'react-native';

import Tabs from '../../components/navigation/Tabs';
import ProjectTable from '../../components/projects/table/ProjectTable';
import Button from '../../components/shared/Button';
import H1 from '../../components/shared/H1';
import H3 from '../../components/shared/H3';
import ProgressDoughnut from '../../components/shared/ProgressDoughnut';
import Text from '../../components/shared/Text';
import tw from '../../config/tailwind';
import { AuthContext } from '../../context/AuthContextProvider';
import { useAppContext } from '../../hooks/useAppContext';
import DashboardLayout from '../../layout/DashboardLayout';
import useLinkProps, {
  AuthenticatedStackNavigatorParamList,
} from '../../routes/types';
import { ProjectStatus } from '../../shared/types/projects';
import { UserRole } from '../../shared/types/users';
import { addLeadingZero } from '../../utils/addLeadingZero';
import uuidV4 from '../../utils/uuidv4';

const tabs = ['Live projects', 'Completed projects'] as const;
type Tab = typeof tabs[number];

const ProjectTableStatusMap: Record<Tab, ProjectStatus | undefined> = {
  'Completed projects': ProjectStatus.Completed,
  'Live projects': ProjectStatus.Live,
};

const initialTab = tabs[0];
const ProjectsOverviewScreen: React.FC = () => {
  const { user } = useContext(AuthContext);
  const { projectStats } = useAppContext();
  const authStackNavigator =
    useNavigation<NavigationProp<AuthenticatedStackNavigatorParamList>>();

  const [activeTab, setActiveTab] = useState<Tab>(initialTab);

  const [projectStatus, setProjectStatus] = useState(
    ProjectTableStatusMap[initialTab]
  );

  const onTabChange = (activeTabName: string) => {
    const tab = activeTabName as Tab;

    setActiveTab(tab);
    setProjectStatus(ProjectTableStatusMap[tab]);
  };

  return (
    <DashboardLayout>
      <H1 style={tw`mb-8`} xl>
        {user?.role === UserRole.User ? 'My projects' : 'Projects'}
      </H1>
      <H3>
        {user?.role === UserRole.User ? 'Your project' : 'Project'} breakdown
      </H3>
      <View
        style={tw`flex md:flex-row md:items-center mb-6 px-8 py-5 bg-white `}
      >
        <View style={tw`flex-1 items-center sm:flex-row`}>
          <View style={tw`flex flex-row items-center sm:mr-12 sm:py-3 sm:pt-4`}>
            <ProgressDoughnut
              progress={
                projectStats?.liveCount > 0
                  ? projectStats.liveCount /
                    (projectStats.liveCount + projectStats.completedCount)
                  : 0
              }
            />
          </View>
          <View
            style={tw`flex-1 flex-row items-center h-16 flex-wrap md:flex-nowrap justify-center sm:justify-start`}
          >
            <View style={tw`sm:mr-8 sm:items-start min-w-54 sm:min-w-0`}>
              <View>
                {projectStats && (
                  <Text
                    style={tw`text-3xl text-green font-ubuntu-medium text-center sm:text-left`}
                  >
                    {addLeadingZero(projectStats.liveCount)}
                  </Text>
                )}
                <Text
                  style={tw`font-bold text-green font-ubuntu-bold text-center sm:text-left`}
                >
                  Live projects
                </Text>
              </View>
            </View>
            <View style={tw`sm:items-start min-w-54  sm:min-w-0`}>
              <View>
                {projectStats && (
                  <Text
                    style={tw`text-3xl text-center sm:text-left font-ubuntu-medium`}
                  >
                    {addLeadingZero(projectStats.completedCount)}
                  </Text>
                )}
                <Text
                  style={tw`font-bold font-ubuntu-bold text-center sm:text-left`}
                >
                  Completed projects
                </Text>
              </View>
            </View>
          </View>
        </View>
        <View style={tw`mt-3 sm:mt-0`}>
          <Button
            onPress={() =>
              authStackNavigator.navigate('projectStack', {
                screen: 'createProject',
              })
            }
            outlined
            style={tw`px-6 w-full md:w-auto mb-0`}
          >
            + Add a new project
          </Button>
        </View>
      </View>

      <Tabs
        activeTab={activeTab}
        tabs={[...tabs]}
        emitActiveTab={onTabChange}
      />

      <View style={tw`bg-white p-8 mb-6`}>
        <ProjectTable status={projectStatus} />
      </View>

      {/* <A linkProps={projectLink}>View Project</A> */}
    </DashboardLayout>
  );
};

export default ProjectsOverviewScreen;
