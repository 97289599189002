import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { ActivityIndicator, View } from 'react-native';
import * as Yup from 'yup';

import { ProjectClient, WpClient } from '../../../api';
import tw from '../../../config/tailwind';
import { useToasts } from '../../../hooks/useToasts';
import { ProjectResponse } from '../../../shared/types/projects';
import { WpApplication, WpSector } from '../../../shared/types/wordpress';
import { decodeEntities } from '../../../utils/decodeEntities';
import Button from '../../shared/Button';
import Col from '../../shared/Col';
import DefaultModal from '../../shared/DefaultModal';
import InputText from '../../shared/InputText';
import InputValidationMessage from '../../shared/InputValidationMessage';
import Row from '../../shared/Row';
import Select from '../../shared/Select';

interface EditProjectModalProps {
  project: ProjectResponse;
  setProject: (project: ProjectResponse) => void;
  visible: boolean;
  setVisible: (v: boolean) => void;
}

type FormValues = {
  sectorId: number;
  applicationId: number;
  location: string;
  customApplication: string;
};

const EditProjectModal: React.FC<EditProjectModalProps> = ({
  project,
  setProject,
  visible,
  setVisible,
}) => {
  const { addToast, addUnhandledErrorToast } = useToasts();

  const [sectors, setSectors] = useState<WpSector[]>([]);
  const [applications, setApplications] = useState<WpApplication[]>([]);
  const [selectedSectorId, setSelectedSectorId] = useState<number>(
    project.sector!.id
  );
  const [selectedApplication, setSelectedApplication] = useState<string>(
    project.application?.name || ''
  );
  const [loading, setLoading] = useState(true);
  const [applicationOptions, setApplicationOptions] = useState<WpApplication[]>(
    []
  );

  useEffect(() => {
    let isActive = true;
    Promise.all([
      WpClient.getApplications(),
      WpClient.getApplicationSectors(),
    ]).then(([applications, sectors]) => {
      if (!isActive) return;
      setSectors(sectors);
      setApplications(applications);
      setLoading(false);
    });

    return () => {
      isActive = false;
    };
  }, []);

  useEffect(() => {
    if (!selectedSectorId) return;

    const sector = sectors.find((sector) => selectedSectorId === sector.id);
    const applicationParentId = sector?.acf.related_application_term_id;

    if (!sector || !applicationParentId) {
      setApplicationOptions([]);
    }

    setApplicationOptions(
      applications.filter((app) => app.parent === applicationParentId)
    );
  }, [selectedSectorId, loading]);

  const initialValues: FormValues = {
    sectorId: project.sector!.id,
    applicationId: project.application!.id,
    location: project.location,
    customApplication: project.customApplication || '',
  };

  const schema: Yup.SchemaOf<FormValues> = Yup.object().shape({
    location: Yup.string().required(),
    sectorId: Yup.number()
      .required()
      .moreThan(0, 'Sector is a required field')
      .default(0)
      .label('Sector'),
    applicationId: Yup.number()
      .required()
      .moreThan(0, 'Application is a required field')
      .default(0)
      .label('Application'),
    customApplication: Yup.string()
      .label('Application')
      .default('')
      .test('isOtherApplication', 'Application is a required field', (v) => {
        if (selectedApplication.toLowerCase() === 'other') {
          return !!(v && v.length > 0);
        }
        return true;
      }),
  });

  const onSubmit = async ({
    sectorId,
    applicationId,
    location,
    customApplication,
  }: typeof initialValues) => {
    try {
      const updatedProject = await ProjectClient.update(project.id, {
        sectorId,
        applicationId,
        location,
        customApplication,
      });
      setVisible(false);
      setProject(updatedProject);
      addToast({
        title: 'Project updated',
        description: 'The project was saved successfully.',
        type: 'success',
      });
    } catch (e) {
      addUnhandledErrorToast(e);
    }
  };

  return (
    <DefaultModal
      setVisible={setVisible}
      visible={visible}
      title='Update project'
    >
      {loading ? (
        <View style={tw`min-h-[400px] flex-row justify-center`}>
          <ActivityIndicator color='currentColor' />
        </View>
      ) : (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={schema}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            values,
            errors,
            isSubmitting,
          }) => (
            <View style={tw`mt-4`}>
              <Row>
                <Col style={tw`w-full sm:w-1/2`}>
                  <InputText
                    placeholder='Enter a postcode, city or town'
                    label='Location'
                    required
                    value={values.location}
                    setValue={handleChange('location')}
                    onBlur={handleBlur('location')}
                    hasError={!!errors.location}
                  />
                  {errors.location && (
                    <InputValidationMessage>
                      {errors.location}
                    </InputValidationMessage>
                  )}
                </Col>

                <Col style={tw`w-full sm:w-1/2`}>
                  <Select
                    selectedValue={values.sectorId}
                    onValueChange={(iValue, iIndex) => {
                      setFieldValue('applicationId', 0);
                      setFieldValue('sectorId', iValue);
                      setFieldValue('customApplication', '', false);
                      setSelectedSectorId(iValue);
                      setSelectedApplication('');
                    }}
                    label='Select a sector'
                    required
                    hasError={!!errors.sectorId}
                    options={sectors.map((sector) => ({
                      label: decodeEntities(sector.name),
                      value: sector.id,
                    }))}
                    placeholderOption='- Select -'
                  />
                  {errors.sectorId && (
                    <InputValidationMessage>
                      {errors.sectorId}
                    </InputValidationMessage>
                  )}
                </Col>

                <Col style={tw`w-full sm:w-1/2`}>
                  <Select
                    selectedValue={values.applicationId || undefined}
                    onValueChange={(iValue) => {
                      setFieldValue('applicationId', iValue);
                      setSelectedApplication(
                        applicationOptions.find((appl) => appl.id === iValue)!
                          .name
                      );
                    }}
                    label='Select application'
                    required
                    hasError={!!errors.applicationId}
                    options={applicationOptions.map((opt) => ({
                      label: decodeEntities(opt.name),
                      value: opt.id,
                    }))}
                    placeholderOption='- Select -'
                  />
                  {errors.applicationId && (
                    <InputValidationMessage>
                      {errors.applicationId}
                    </InputValidationMessage>
                  )}
                </Col>

                {selectedApplication.toLowerCase() === 'other' && (
                  <Col
                    style={tw.style(`w-full sm:w-1/2`)}
                    pointerEvents={!values.sectorId ? 'none' : undefined}
                  >
                    <InputText
                      placeholder='Application name'
                      label='Application name'
                      required
                      value={values.customApplication}
                      setValue={handleChange('customApplication')}
                      onBlur={handleBlur('customApplication')}
                      hasError={!!errors.customApplication}
                    />
                    {errors.customApplication && (
                      <InputValidationMessage>
                        {errors.customApplication}
                      </InputValidationMessage>
                    )}
                  </Col>
                )}
              </Row>
              <View style={tw`flex flex-row items-center justify-end mt-4`}>
                {isSubmitting && <ActivityIndicator style={tw`mr-4`} />}
                <Button onPress={handleSubmit} disabled={isSubmitting}>
                  Update project
                </Button>
              </View>
            </View>
          )}
        </Formik>
      )}
    </DefaultModal>
  );
};

export default EditProjectModal;
