import {
  NavigationProp,
  RouteProp,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import { FC, useEffect, useState } from 'react';
import { ActivityIndicator, View } from 'react-native';
import { ProjectClient } from '../../../api';

import WpProductPicker from '../../../components/project/WpProductPicker';
import BackButton from '../../../components/shared/BackButton';
import Button from '../../../components/shared/Button';
import H1 from '../../../components/shared/H1';
import H2 from '../../../components/shared/H2';
import P from '../../../components/shared/P';
import tw from '../../../config/tailwind';
import { useToasts } from '../../../hooks/useToasts';
import DashboardLayout from '../../../layout/DashboardLayout';
import { ProjectStackNavigatorParamList } from '../../../routes/types';
import {
  ProjectResponse,
  ProjectRoomResponse,
} from '../../../shared/types/projects';

const ProjectRoomScreen: FC = () => {
  const [project, setProject] = useState<ProjectResponse>();
  const [room, setRoom] = useState<ProjectRoomResponse>();
  const [refresh, setRefresh] = useState(0);
  const [productIds, setProductIds] = useState<number[]>([]);
  const [hasChanges, setHasChanges] = useState(false);
  const [loading, setLoading] = useState(true);
  const { addUnhandledErrorToast, addToast } = useToasts();

  const navigation =
    useNavigation<NavigationProp<ProjectStackNavigatorParamList>>();
  const route = useRoute<RouteProp<ProjectStackNavigatorParamList, 'room'>>();

  useEffect(() => {
    ProjectClient.getById(route.params.projectId).then((projectResponse) =>
      setProject(projectResponse)
    );
  }, [route.params.projectId]);

  useEffect(() => {
    ProjectClient.rooms
      .getById(route.params.projectId, route.params.roomId)
      .then((roomResponse) => {
        setRoom(roomResponse);
        setProductIds(roomResponse?.relatedProducts?.map((x) => x.id) || []);
        setHasChanges(false);
        setLoading(false);
      });
  }, [route.params.roomId, refresh]);

  const onProductIdsChange = (values: number[]) => {
    if (values !== productIds) {
      setHasChanges(true);
    }

    setProductIds(values);
  };

  const saveChanges = async () => {
    if (project && room) {
      try {
        setLoading(true);
        await ProjectClient.rooms.update(project.id, room.id, {
          name: room.name,
          productsAdded: productIds.filter(
            (x) => !room.relatedProducts?.find((y) => y.id === x)
          ),
          productsRemoved:
            room.relatedProducts
              ?.map((x) => x.id)
              .filter((x) => !productIds.includes(x)) || [],
        });
        setLoading(false);
        setRefresh((x) => x + 1);
        addToast({
          title: 'Room saved',
          description:
            'The changes you made to this room have been saved successfully.',
          type: 'success',
        });
      } catch (e) {
        addUnhandledErrorToast(e);
      }
    }
  };

  return (
    <DashboardLayout>
      <BackButton
        onPress={() =>
          navigation.navigate('project', { id: route.params.projectId })
        }
      >
        Back to project
      </BackButton>

      <P style={tw`font-ubuntu-bold font-bold mb-0 mt-4`}>
        {project?.name} Room
      </P>
      <H1 xl>{room?.name}</H1>

      {loading ? (
        <ActivityIndicator />
      ) : (
        <>
          {room && (
            <View style={tw`bg-white p-8 mb-6`}>
              <H2>Products:</H2>
              <WpProductPicker
                productIds={productIds}
                setProductIds={onProductIdsChange}
                noOverflow
              />
            </View>
          )}

          {hasChanges && <Button onPress={saveChanges}>Save changes</Button>}
        </>
      )}
    </DashboardLayout>
  );
};

export default ProjectRoomScreen;
