import { Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { ActivityIndicator, View } from 'react-native';
import * as Yup from 'yup';
import { UserClient } from '../../api';

import tw from '../../config/tailwind';
import { AuthContext } from '../../context/AuthContextProvider';
import { useToasts } from '../../hooks/useToasts';
import { UserPreferencesResponse } from '../../shared/types/users';
import A from '../shared/A';
import Button from '../shared/Button';
import Col from '../shared/Col';
import InputCheckbox from '../shared/InputCheckbox';
import InputValidationMessage from '../shared/InputValidationMessage';
import P from '../shared/P';
import Row from '../shared/Row';
import Text from '../shared/Text';

interface MarketingPreferencesFormProps {}

const MarketingPreferencesForm: React.FC<
  MarketingPreferencesFormProps
> = () => {
  const { user } = useContext(AuthContext);

  const [preferences, setPreferences] = useState<UserPreferencesResponse>({
    email: false,
    post: false,
    sms: false,
  });
  const [loading, setLoading] = useState(true);

  const { addUnhandledErrorToast, addToast } = useToasts();
  useEffect(() => {
    let isActive = true;
    UserClient.getPreferences(user!.id)
      .then((res) => {
        if (isActive) {
          setPreferences(res);
          setLoading(false);
        }
      })
      .catch(addUnhandledErrorToast);
    return () => {
      isActive = false;
    };
  }, []);

  // TODO: Populate with user preferences
  const initialValues: UserPreferencesResponse = {
    email: preferences.email,
    sms: preferences.sms,
    post: preferences.post,
  };

  const schema: Yup.SchemaOf<typeof initialValues> = Yup.object().shape({
    email: Yup.boolean().required(),
    sms: Yup.boolean().required(),
    post: Yup.boolean().required(),
  });

  const onSubmit = async (formValues: typeof initialValues) => {
    if (!user?.id) return;

    try {
      await UserClient.updatePreferences(user!.id, formValues);
      addToast({
        title: 'Preferences updated',
        description: 'Your preferences have been stored against your account',
        type: 'success',
      });
    } catch (error) {
      addUnhandledErrorToast(error);
    }
  };

  if (loading) {
    return (
      <View>
        <ActivityIndicator color='currentColor' />
      </View>
    );
  }

  return (
    <View>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={schema}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          values,
          errors,
        }) => (
          <View>
            <P>
              As a Pinnacle Approved Partner, we will need to send you
              communications about Pinnacle by Zentia from time to time. Please
              let us know how you prefer to be contacted.
            </P>

            <P style={tw`mb-8`}>
              You can find more information on how we use your data in our{' '}
              <A
                style={tw`text-blue`}
                url='https://www.zentia.com/en-gb/website-privacy-policy'
              >
                Privacy Policy
              </A>
            </P>

            <Row>
              <Col style={tw`w-1/4`}>
                <InputCheckbox
                  value={values.email}
                  setValue={(value) => setFieldValue('email', value)}
                  label='Email'
                  labelStyle={tw`text-base`}
                  hasError={!!errors.email}
                />
                {errors.email && (
                  <InputValidationMessage>
                    {errors.email}
                  </InputValidationMessage>
                )}
              </Col>
              <Col style={tw`w-1/4`}>
                <InputCheckbox
                  value={values.sms}
                  setValue={(value) => setFieldValue('sms', value)}
                  label='SMS'
                  labelStyle={tw`text-base`}
                  hasError={!!errors.sms}
                />
                {errors.sms && (
                  <InputValidationMessage>{errors.sms}</InputValidationMessage>
                )}
              </Col>
              <Col style={tw`w-1/4`}>
                <InputCheckbox
                  value={values.post}
                  setValue={(value) => setFieldValue('post', value)}
                  label='Post'
                  labelStyle={tw`text-base`}
                  hasError={!!errors.post}
                />
                {errors.post && (
                  <InputValidationMessage>{errors.post}</InputValidationMessage>
                )}
              </Col>
            </Row>

            <Button onPress={handleSubmit} style={tw`mb-4`}>
              Save changes
            </Button>
          </View>
        )}
      </Formik>
    </View>
  );
};

export default MarketingPreferencesForm;
