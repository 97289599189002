import { UserResponse } from './users';

export enum FeedbackType {
  Bug = 'BUG',
  Suggestion = 'SUGGESTION',
}

export interface CreateFeedbackPayload {
  type: FeedbackType;
  message: string;
  page?: string;
}

export interface FeedbackResponse {
  id: string;
  createdBy: UserResponse;
  createdAt: Date;
  type: FeedbackType;
  message?: string;
  userAgent?: string;
  page?: string;
}
