import { API_BASE_URL } from '../env-consts';
import { PaginatedResult } from '../shared/types/paginatedResult';
import {
  CreateTrainingRecordPayload,
  TrainingCertificateResponse,
  TrainingRecordResponse,
  UpdateTrainingRecordPayload,
} from '../shared/types/training';
import { WpNews } from '../shared/types/wordpress';
import ApiClient from './ApiClient';

const client = new ApiClient(`${API_BASE_URL}/training`);

export const TrainingClient = {
  /**
   * Get all records assigned to the current user.
   */
  async getAllRecords(page: number, companyId: string | null = null) {
    return client
      .get<PaginatedResult<TrainingRecordResponse>>(`/records`, {
        params: {
          page,
          companyId
        },
      })
      .then(({ data }) => data);
  },

  /**
   * Get all records by User ID.
   *
   * If the user is not a super-admin, they can only request their own entities.
   * Use getAllRecords() for the same response
   */
  async getAllRecordsByUserId(userId: string, page: number) {
    return client
      .get<PaginatedResult<TrainingCertificateResponse>>(`/records/${userId}`, {
        params: {
          page,
        },
      })
      .then(({ data }) => data);
  },

  async getRecordById(recordId: string) {
    return client
      .get<TrainingRecordResponse>(`/record/${recordId}`)
      .then(({ data }) => data);
  },

  async createRecord(payload: CreateTrainingRecordPayload, file?: File) {
    const formData = new FormData();
    for (const [key, value] of Object.entries(payload)) {
      formData.set(key, value instanceof Date ? value?.toISOString() : value);
    }

    if (file) {
      formData.set('file', file, file.name);
    }

    return client
      .post<TrainingRecordResponse>(`/record`, formData)
      .then(({ data }) => data);
  },

  async updateRecord(
    recordId: string,
    payload: UpdateTrainingRecordPayload,
    file?: File
  ) {
    const formData = new FormData();
    for (const [key, value] of Object.entries(payload)) {
      formData.set(key, value);
    }

    if (file) {
      formData.set('file', file, file.name);
    }

    return client
      .patch<TrainingRecordResponse>(`/record/${recordId}`, formData)
      .then(({ data }) => data);
  },

  async deleteRecordById(recordId: string) {
    return client.delete<void>(`/record/${recordId}`).then(() => {});
  },

  async deleteCertificateById(certificateId: string) {
    return client.delete<void>(`/certificate/${certificateId}`).then(() => {});
  },
};
