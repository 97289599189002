import { useFonts } from 'expo-font';
import React, { useEffect } from 'react';
import { Platform } from 'react-native';
import { useDeviceContext } from 'twrnc';

import tw from './src/config/tailwind';
import AppContextProvider from './src/context/AppContextProvider';
import AuthContextProvider from './src/context/AuthContextProvider';
import ToastContextProvider from './src/context/ToastContextProvider';
import RootStackNavigator from './src/routes/RootStackNavigator';
import { getPermission } from './src/utils/geolocation';

export default function App() {
  useDeviceContext(tw);

  const [loaded] = useFonts({
    Ubuntu: require('./src/assets/fonts/Ubuntu-Regular.ttf'),
    'Ubuntu-Bold': require('./src/assets/fonts/Ubuntu-Bold.ttf'),
    'Ubuntu-Medium': require('./src/assets/fonts/Ubuntu-Medium.ttf'),
  });

  useEffect(() => {
    // Pre-emptively check the user has given us geoloaction permissions
    // This is experimental or not support on Safari, Safari on iOS and Webview. This is mostly and optimisation so doesn't matter if it fails
    // getPermission(false);
    if (Platform.OS === 'web') {
      navigator?.permissions?.query({ name: 'geolocation' }).then((status) => {
        if (status.state !== 'prompt') {
          getPermission();
        }
      });
    }
  }, []);

  if (!loaded) {
    return null;
  }

  return (
    <ToastContextProvider>
      <AuthContextProvider>
        <AppContextProvider>
          <RootStackNavigator />
        </AppContextProvider>
      </AuthContextProvider>
    </ToastContextProvider>
  );
}
