import { FC, useEffect, useRef } from 'react';
import { Animated, GestureResponderEvent, Pressable, View } from 'react-native';
import { useHover } from 'react-native-web-hooks';
import { ClassInput } from 'twrnc/dist/esm/types';

import tw from '../../config/tailwind';
import Text from './Text';

type Props = {
  children: string | React.ReactNode;
  onPress?: (e: GestureResponderEvent) => void;
  buttonStyle?: ClassInput;
  textStyle?: ClassInput;
  variant?: 'default' | 'dark' | 'transparent';
  underline?: boolean;
};

const buttonColor: Record<Required<Props>['variant'], string> = {
  default: 'bg-green',
  dark: 'bg-blue',
  transparent: 'bg-transparent',
};

const textColor: Record<Required<Props>['variant'], string> = {
  default: 'text-blue',
  dark: 'text-white',
  transparent: 'text-blue',
};

// TODO: Potentially refactor use of this component.
// We're passing in identical custom styles across various files,
// feels like we should just have a new component.
const FullWidthButton: FC<Props> = ({
  children,
  onPress,
  buttonStyle,
  textStyle,
  variant = 'default',
  underline,
}) => {
  const buttonStyles = `${buttonColor[variant]}`;
  const textStyles = `font-ubuntu-bold font-bold ${textColor[variant]}`;

  const ref = useRef(null);
  const isHovered = useHover(ref);

  const hoverAnim = useRef(new Animated.Value(0)).current;
  useEffect(() => {
    Animated.timing(hoverAnim, {
      toValue: isHovered ? 1 : 0,
      duration: 350,
      useNativeDriver: true,
    }).start();
  }, [isHovered]);

  return (
    <View>
      <Pressable onPress={onPress} ref={ref}>
        <Animated.View style={tw.style(buttonStyles, buttonStyle)}>
          <Text style={tw.style(textStyles, textStyle)}>{children}</Text>

          {underline && (
            <Animated.View
              style={[
                tw`bg-green absolute h-0.5 left-0 bottom-0`,
                {
                  width: isHovered
                    ? hoverAnim.interpolate({
                        inputRange: [0, 1],
                        outputRange: ['0%', '100%'],
                      })
                    : '100%',
                },
              ]}
            />
          )}
        </Animated.View>
      </Pressable>
    </View>
  );
};

export default FullWidthButton;
