export interface LoginPayload {
  email: string;
  password: string;
  otpCode?: string;
}

export enum LoginResponseType {
  Authenticated = 'AUTHENTICATED',
  Verify = 'VERIFY',
}

export type LoginResponse = SuccessLoginResponse | VerifyLoginResponse;

export interface VerifyLoginResponse {
  type: LoginResponseType.Verify;
  partialTel: string;
}

export interface SuccessLoginResponse {
  type: LoginResponseType.Authenticated;
  accessToken: string;
  refreshToken: string;
  refreshTokenExpiry: number;
}

export interface LogoutPayload {
  refreshToken?: string;
}

export interface RefreshTokenPayload {
  refreshToken?: string;
  accessToken?: string;
}

export interface TwoFactorAuthResponse {
  type: TwoFactorAuthResponseType;
}

export enum TwoFactorAuthResponseType {
  Success = 'SUCCESS',
  Verify = 'VERIFY',
}

export interface EnableTfaPayload {
  telephone: string;
  otpCode?: string;
}
export interface DisableTfaPayload {
  otpCode?: string;
}
