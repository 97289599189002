import { FC } from 'react';
import { Pressable, View } from 'react-native';
import { ClassInput } from 'twrnc/dist/esm/types';

import IconCheck from '../../assets/svg/check.svg';
import tw from '../../config/tailwind';
import Text from './Text';

type Props = {
  label: string | React.ReactNode;
  value: boolean;
  setValue?: (v: boolean) => void;
  hasError?: boolean;
  labelStyle?: ClassInput;
  labelSize?: 'sm' | 'md';
  containerStyle?: ClassInput;
};

const InputCheckbox: FC<Props> = ({
  value,
  setValue,
  label,
  hasError,
  labelStyle,
  labelSize,
  containerStyle,
}) => {
  return (
    <Pressable
      onPress={setValue ? () => setValue(!value) : undefined}
      style={tw.style(`flex flex-row items-center mb-4`, containerStyle)}
    >
      <View
        style={tw`border-2 ${
          hasError ? 'border-red' : 'border-blue'
        } bg-white w-5 h-5 mr-2.5 relative flex justify-center items-center`}
      >
        {value && <IconCheck width={24} height={24} />}
      </View>
      {typeof label === 'string' ? (
        <Text
          style={tw.style(
            `text-blue flex-1 ${labelSize === 'md' ? 'text-sm' : 'text-xs'}`,
            labelStyle
          )}
        >
          {label}
        </Text>
      ) : (
        label
      )}
    </Pressable>
  );
};

export default InputCheckbox;
