import { FC, useEffect, useRef, useState } from 'react';
import { Animated, Easing } from 'react-native';
import Svg, { Path } from 'react-native-svg';

import tw from '../../config/tailwind';

type Props = {
  progress: number;
};

const AnimatedPath = Animated.createAnimatedComponent(Path);

const ProgressDoughnut: FC<Props> = ({ progress }) => {
  const progressAnim = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    Animated.timing(progressAnim, {
      toValue: progress,
      duration: 1000,
      useNativeDriver: false,
    }).start();
  }, [progress]);

  return (
    <Svg style={tw`w-24 h-24`} viewBox='-2 -2 40 40'>
      <AnimatedPath
        d='M18 2.0845
      a 15.9155 15.9155 0 0 1 0 31.831
      a 15.9155 15.9155 0 0 1 0 -31.831'
        fill='none'
        stroke={tw.color('blue')}
        strokeWidth='7'
        strokeDasharray={progressAnim.interpolate({
          inputRange: [0, 1],
          outputRange: ['0, 0, 100', '0, 100, 100'],
          easing: Easing.inOut(Easing.ease),
        })}
      />
      <AnimatedPath
        d='M18 2.0845
      a 15.9155 15.9155 0 0 1 0 31.831
      a 15.9155 15.9155 0 0 1 0 -31.831'
        fill='none'
        stroke={tw.color('green')}
        strokeWidth='7'
        strokeDasharray={progressAnim.interpolate({
          inputRange: [0, 1],
          outputRange: ['0, 100', '100, 100'],
          easing: Easing.inOut(Easing.ease),
        })}
      />
    </Svg>
  );
};

export default ProgressDoughnut;
