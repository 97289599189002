import React, { useState } from 'react';
import { View } from 'react-native';

import AdminCompaniesTable from '../../../components/admin/AdminCompaniesTable';
import AdminFeedbackTable from '../../../components/admin/AdminFeedbackTable';
import AdminGlobalSettingsPage from '../../../components/admin/AdminGlobalSettingsPage';
import AdminLeadsTable from '../../../components/admin/AdminLeadsTable';
import AdminUserActivityTable from '../../../components/admin/AdminUserActivityTable';
import AdminUsersTable from '../../../components/admin/AdminUsersTable';
import Tabs from '../../../components/navigation/Tabs';
import H1 from '../../../components/shared/H1';
import tw from '../../../config/tailwind';
import DashboardLayout from '../../../layout/DashboardLayout';

const tabs = [
  'Companies',
  'Users',
  'Feedback',
  'Leads',
  'User Activity',
  'Global Settings',
] as const;
type Tab = typeof tabs[number];

const initialTab = tabs[0];

interface AdminScreenProps {}

const AdminScreen: React.FC<AdminScreenProps> = ({}) => {
  const [activeTab, setActiveTab] = useState<Tab>(initialTab);

  const onTabChange = (tab: string) => {
    setActiveTab(tab as Tab);
  };

  return (
    <DashboardLayout>
      <H1 xl>Global Admin</H1>

      <Tabs
        tabs={[...tabs]}
        activeTab={activeTab}
        emitActiveTab={onTabChange}
      />

      <View style={tw`bg-white p-8`}>
        {activeTab === 'Companies' && <AdminCompaniesTable />}

        {activeTab === 'Users' && <AdminUsersTable />}

        {activeTab === 'Feedback' && <AdminFeedbackTable />}
        {activeTab === 'Leads' && <AdminLeadsTable showTableActions />}
        {activeTab === 'User Activity' && (
          <AdminUserActivityTable showFilters showTableActions />
        )}

        {activeTab === 'Global Settings' && <AdminGlobalSettingsPage/>}
      </View>
    </DashboardLayout>
  );
};

export default AdminScreen;
