import { RouteProp, useRoute } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { ActivityIndicator, Text, View } from 'react-native';

import { UserClient } from '../../../api';
import AdminUserActivityTable from '../../../components/admin/AdminUserActivityTable';
import DeleteUserModal from '../../../components/admin/modals/DeleteUserModal';
import EditUserRoleModal from '../../../components/admin/modals/EditUserRoleModal';
import UserDetailsForm from '../../../components/my-account/UserDetailsForm';
import BackButton from '../../../components/shared/BackButton';
import Button from '../../../components/shared/Button';
import ContentWrapper from '../../../components/shared/ContentWrapper';
import H1 from '../../../components/shared/H1';
import H3 from '../../../components/shared/H3';
import tw from '../../../config/tailwind';
import DashboardLayout from '../../../layout/DashboardLayout';
import useLinkProps, {
  GlobalAdminStackNavigatorParamList,
} from '../../../routes/types';
import { UserResponse } from '../../../shared/types/users';

interface AdminScreenProps {}

const AdminUserScreen: React.FC<AdminScreenProps> = ({}) => {
  const route =
    useRoute<RouteProp<GlobalAdminStackNavigatorParamList, 'user'>>();

  const [user, setUser] = useState<UserResponse>();
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [editUserAccessModalVisible, setEditUserAccessModalVisible] =
    useState(false);

  const usersLink = useLinkProps<'authenticatedStackNavigator'>({
    screen: 'authenticatedStackNavigator',
    params: {
      screen: 'globalAdminStack',
      params: {
        screen: 'admin',
      },
    },
  });

  useEffect(() => {
    UserClient.getById(route.params.id).then((result) => {
      setUser(result);
    });
  }, [route.params.id]);

  return (
    <DashboardLayout>
      <BackButton onPress={usersLink.onPress}>Back to admin</BackButton>

      {user ? (
        <>
          <H1 xl>
            {user.firstName} {user.lastName}
          </H1>

          <UserDetailsForm user={user} updateUser={setUser} />

          <View style={tw`flex flex-row`}>
            <Button
              style={tw`mt-4 ml-auto`}
              onPress={() => setEditUserAccessModalVisible(true)}
            >
              Edit access
            </Button>
            <Button
              style={tw`mt-4 ml-4`}
              onPress={() => setDeleteModalVisible(true)}
            >
              Suspend account
            </Button>
          </View>

          <EditUserRoleModal
            setVisible={setEditUserAccessModalVisible}
            visible={editUserAccessModalVisible}
            user={user}
            updateUser={setUser}
          />

          <DeleteUserModal
            user={user}
            visible={deleteModalVisible}
            setVisible={setDeleteModalVisible}
            onUserDeleted={() => usersLink.onPress()}
          />

          <H3>Recent login history</H3>
          <ContentWrapper>
            <AdminUserActivityTable userId={user.id} perPage={5} />
          </ContentWrapper>
        </>
      ) : (
        <ActivityIndicator />
      )}
    </DashboardLayout>
  );
};

export default AdminUserScreen;
