import { serialize } from 'object-to-formdata';

import { API_BASE_URL } from '../env-consts';
import {
  CreateDocumentPayload,
  DocumentResponse,
  UpdateDocumentPayload,
} from '../shared/types/documents';
import { PaginatedResult } from '../shared/types/paginatedResult';
import ApiClient from './ApiClient';

const client = new ApiClient(`${API_BASE_URL}/documents`);

export const DocumentClient = {
  async create(payload: CreateDocumentPayload, file: File) {
    const data = serialize(payload, {
      indices: true,
    });
    data.set('file', file, file.name);
    return client
      .post<DocumentResponse>('', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(({ data }) => data);
  },

  async update(
    documentId: string,
    payload: UpdateDocumentPayload,
    file?: File
  ) {
    const data = serialize(payload, {
      indices: true,
    });

    if (file) {
      data.set('file', file, file.name);
    }

    return client
      .patch<DocumentResponse>(`/${documentId}`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(({ data }) => data);
  },

  async getById(documentId: string) {
    return client
      .get<DocumentResponse>(`/${documentId}`)
      .then(({ data }) => data);
  },

  async getByProjectId(projectId: string, page: number) {
    return client
      .get<PaginatedResult<DocumentResponse>>(`/`, {
        params: { projectId, page },
      })
      .then(({ data }) => data);
  },

  async delete(documentId: string) {
    return client.delete(`/${documentId}`).then(() => {});
  },
};
