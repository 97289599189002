import { Formik } from 'formik';
import React, { useState } from 'react';
import { View } from 'react-native';
import * as Yup from 'yup';

import { FeedbackClient } from '../../api/FeedbackClient';
import tw from '../../config/tailwind';
import { useAuthContext } from '../../hooks/useAuthContext';
import { useToasts } from '../../hooks/useToasts';
import {
  CreateFeedbackPayload,
  FeedbackType,
} from '../../shared/types/feedback';
import Button from '../shared/Button';
import Col from '../shared/Col';
import H3 from '../shared/H3';
import InputText from '../shared/InputText';
import InputValidationMessage from '../shared/InputValidationMessage';
import P from '../shared/P';
import Row from '../shared/Row';
import Text from '../shared/Text';

interface Props {}

const FeedbackForm: React.FC<Props> = ({}) => {
  const { addToast, addUnhandledErrorToast } = useToasts();
  const [sent, setSent] = useState(false);

  const initialValues: CreateFeedbackPayload = {
    type: FeedbackType.Suggestion,
    message: '',
    page: window?.location?.href || 'N/A',
  };

  const schema: Yup.SchemaOf<typeof initialValues> = Yup.object().shape({
    message: Yup.string().required('Please provide'),
    page: Yup.string().required(),
    type: Yup.mixed().required().oneOf(Object.values(FeedbackType)),
  });

  const onSubmit = async (values: typeof initialValues) => {
    FeedbackClient.create(values)
      .then((data) => {
        addToast({
          title: 'Feedback sent',
          description: 'Thank you for your feedback!',
          type: 'success',
        });
        setSent(true);
      })
      .catch((e) => {
        addUnhandledErrorToast(e);
      });
  };

  if (sent) {
    return (
      <View>
        <H3>Thank you for submitting your feedback!</H3>
        <Text>
          A member of the Zentia team will review your message shortly.{'\n\n'}
          <Text style={tw`text-green underline`} onPress={() => setSent(false)}>
            Click here
          </Text>{' '}
          to send more feedback.
        </Text>
      </View>
    );
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={schema}
    >
      {({
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        values,
        errors,
        isSubmitting,
      }) => (
        <View style={tw``}>
          <H3>Want to provide feedback?</H3>
          {/* <P>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Id, alias
            odit et sit veniam minima non accusamus ad in ipsam debitis
            accusantium quidem nobis dicta at libero aut culpa architecto.
          </P> */}
          <Row>
            <Col style={tw`w-full`}>
              <InputText
                value={values.message}
                setValue={handleChange('message')}
                onBlur={handleBlur('message')}
                placeholder='Type your feedback here...'
                hasError={!!errors.message}
                label='Your feedback'
                required
                multiline
              />
              {errors.message && (
                <InputValidationMessage>
                  {errors.message}
                </InputValidationMessage>
              )}
            </Col>
            {/* <Col style={tw`w-full`}>
              <Select
                selectedValue={values.type}
                onValueChange={(iValue, iIndex) => handleChange('type')(iValue)}
                label='Select feedback type'
                required
                hasError={!!errors.type}
                options={Object.values(FeedbackType).map((item) => ({
                  label:
                    item.slice(0, 1).toUpperCase() +
                    item.toLowerCase().slice(1),
                  value: item,
                }))}
              />
              {errors.message && (
                <InputValidationMessage>
                  {errors.message}
                </InputValidationMessage>
              )}
            </Col> */}
            <Col>
              <Button
                onPress={handleSubmit}
                disabled={isSubmitting}
                style={tw.style({ minHeight: 50 })}
              >
                Submit feedback
              </Button>
            </Col>
          </Row>
        </View>
      )}
    </Formik>
  );
};

export default FeedbackForm;
