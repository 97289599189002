import { Formik, useFormikContext } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { ActivityIndicator, View } from 'react-native';
import * as Yup from 'yup';
import { CompanyClient, ProjectClient } from '../../../api';
import { InvoiceClient } from '../../../api/InvoiceClient';

import ReactSelect from 'react-select';
import tw from '../../../config/tailwind';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { useToasts } from '../../../hooks/useToasts';
import { CompanyResponse } from '../../../shared/types/companies';
import { CreateInvoicePayload, InvoiceResponse } from '../../../shared/types/invoices';
import { ProjectResponse } from '../../../shared/types/projects';
import getBase64 from '../../../utils/getBase64';
import Button from '../../shared/Button';
import DefaultModal from '../../shared/DefaultModal';
import FilePicker from '../../shared/FilePicker';
import InputText from '../../shared/InputText';
import InputValidationMessage from '../../shared/InputValidationMessage';
import Select from '../../shared/Select';

const FormObserver: React.FC<{ onChange: (values: unknown) => void }> = ({ onChange }) => {
  const { values } = useFormikContext();

  useEffect(() => {
    onChange(values);
  }, [values]);

  return null;
};

interface InvoiceNewModalProps {
  visible: boolean;
  setVisible: (v: boolean) => void;
  onInvoiceAdded: (comment: InvoiceResponse) => void;
  onInvoiceDelete: () => void,
  onDismiss: () => void;
  initInvoice?: InvoiceResponse;
}

const InvoiceModal: React.FC<InvoiceNewModalProps> = ({
  visible,
  setVisible,
  onDismiss,
  onInvoiceAdded,
  onInvoiceDelete,
  initInvoice,
}) => {
  const { addToast, addUnhandledErrorToast } = useToasts();
  const { user, activeCompany } = useAuthContext();
  const [companies, setCompanies] = useState<CompanyResponse[]>([]);
  const [projects, setProjects] = useState<ProjectResponse[]>([]);
  const [file, setFile] = useState<File>();
  const [fileError, setFileError] = useState('');
  const [fileUrl, setFileUrl] = useState<string>(initInvoice?.publicUrl || '');
  const [deleting, setDeleting] = useState<'idle' | 'confirming' | 'deleting' | 'deleted'>('idle');

  const [observedValues, setObservedValues] = useState<CreateInvoicePayload | null | undefined>(null);

  const initialValues: CreateInvoicePayload = {
    title: initInvoice?.title || '',
    desc: initInvoice?.desc || '',
    companyId: initInvoice?.companyId || '',
    projectsId: initInvoice?.projectsId || []
  };

  const schema: Yup.SchemaOf<typeof initialValues> = Yup.object().shape({
    title: Yup.string().required().label('Document title').max(255),
    desc: Yup.string().required().label('Description'),
    projectsId: Yup.array(),
    companyId: Yup.string().uuid().required('Please select a Pinnacle partner')
  });

  // updating the file state
  useEffect(() => {
    if (file) {
      getBase64(file).then((res) => setFileUrl(res as string));
    }
  }, [file]);

  // function to load the companies options and project options
  const loadCompanies = useCallback(async (page: number = 1) => {
    const res = await CompanyClient.getAll(page);
    setCompanies(c => [...c, ...res.items]);
    if (res.totalPages > page) {
      await loadCompanies(page + 1);
    }
  }, []);

  const loadActiveCompany = useCallback(async () => {
    if (!activeCompany) { return };
    const company = await CompanyClient.getById(activeCompany.id);
    setCompanies([company]);
  }, [activeCompany]);

  const loadProjects = useCallback(async (page: number = 1) => {
    // just return empty array if no company selected
    if (!observedValues || !observedValues.companyId) { return };
    const res = await ProjectClient.getAll(observedValues.companyId, page, undefined);
    setProjects((x) => [...x, ...res.items]);
    if (res.totalPages > page) {
      await loadProjects(page + 1);
    }
  }, [observedValues?.companyId]);

  useEffect(() => {
    const onLoad = async () => {
      setCompanies([]);
      setProjects([]);
      if (activeCompany) {
        await loadActiveCompany();
      } else {
        await loadCompanies();
      }
      await loadProjects();
    }

    onLoad()
  }, [initInvoice, activeCompany]);

  // update available projects for selection
  useEffect(() => {
    if (!observedValues?.companyId) { return };
    const reloadProjects = async () => {
      setProjects([]);
      await loadProjects();
    }
    reloadProjects()
  }, [observedValues?.companyId]);

  // logging
  useEffect(() => console.log(observedValues), [observedValues]);

  // handle submit for changes or creation
  const onSubmit = async (payload: CreateInvoicePayload) => {
    try {
      let savedInvoice: InvoiceResponse;

      if (initInvoice) {
        // Update an existing Invoice
        savedInvoice = await InvoiceClient.update(
          initInvoice.id,
          {
            title: payload.title,
            desc: payload.desc,
            companyId: payload.companyId,
            projectsId: payload.projectsId
          }
        );
      } else {
        // Create Document
        if (!file) {
          setFileError('Please provide a document.');
          return;
        }

        savedInvoice = await InvoiceClient.create(payload, file);
      }
      addToast({
        title: 'Invoice ' + initInvoice ? 'updated' : 'created',
        description: `You ${initInvoice ? 'updated' : 'added'} the document '${payload.title
          }'`,
        type: 'success',
      });
      resetModal();
      onInvoiceAdded(savedInvoice);
    } catch (error) {
      addUnhandledErrorToast(error);
    }
  };

  const resetModal = () => {
    setFile(undefined);
    setFileUrl('');
    setVisible && setVisible(false);
    onDismiss()
  };

  const onFileChange = (file: File) => {
    setFile(file);
    setFileError('');
  };

  const handleDelete = async () => {
    if (!initInvoice) { return };
    try {
      await InvoiceClient.delete(initInvoice.id);
      resetModal();
      onInvoiceDelete();
    } catch (error) {
      addUnhandledErrorToast(error);
    }
    setDeleting('idle');
  }

  return (
    <DefaultModal
      setVisible={resetModal}
      visible={visible}
      title={`${initInvoice ? 'Edit' : 'New'} Invoice`}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={schema}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          values,
          errors,
          isSubmitting,
        }) => (
          <View style={tw`mt-4`}>
            <>
              <FormObserver
                onChange={(v) => setObservedValues(v as CreateInvoicePayload)}
              />
              <View>
                <>
                  <InputText
                    placeholder='My new invoice'
                    label='Invoice title'
                    required
                    value={values.title}
                    setValue={handleChange('title')}
                    onBlur={handleBlur('title')}
                    hasError={!!errors.title}
                  />
                  {errors.title && (
                    <InputValidationMessage>{errors.title}</InputValidationMessage>
                  )}
                </>
              </View>
              <View>
                <>
                  <InputText
                    placeholder='Description'
                    label='Description'
                    required
                    value={values.desc}
                    setValue={handleChange('desc')}
                    onBlur={handleBlur('desc')}
                    hasError={!!errors.desc}
                    multiline
                  />
                  {errors.desc && (
                    <InputValidationMessage>
                      {errors.desc}
                    </InputValidationMessage>
                  )}
                </>
              </View>
              {/* for company selection */}
              <View>
                <>
                  <Select
                    selectedValue={values.companyId}
                    onValueChange={(v, i) => {
                      if (v) {
                        setFieldValue('companyId', v);
                      }
                    }}
                    label='Pinnacle Partner'
                    options={companies.filter(x => (
                      initInvoice ? x.id === initInvoice.companyId
                        : true
                    )).map((item) => ({
                      label: `${item.name}`,
                      value: item.id,
                    }))}
                    hasError={!!errors.companyId}
                  />
                  {errors.companyId && (
                    <InputValidationMessage>
                      {errors.companyId}
                    </InputValidationMessage>
                  )}
                </>
              </View>
              {/* for projects selection */}

              <div
                style={{ zIndex: '999' }}
              >
                <ReactSelect
                  value={projects.filter(p => p.contacts).filter(x => values.projectsId.includes(x.id)).map(y => ({ label: y.name, value: y.id })) || []}
                  styles={{
                    control: (b, p) => ({ ...b, ...tw.style('bg-white px-4 py-0 mb-4 font-sans border-2 border-blue h-[50px] flex flex-row items-center min-w-48 flex-1 rounded-none') }),
                    menu: (b, p) => ({ ...b, ...tw.style(`rounded-none`) }),
                    option: (b, p) => ({ ...b, ...tw.style(`justify-center font-sans`) }),
                    noOptionsMessage: (b, p) => ({ ...b, ...tw.style(`font-sans`) })
                  }}
                  options={projects.map(x => (
                    { label: x.name, value: x.id }
                  ))}
                  isMulti
                  onChange={(v) => {
                    setFieldValue('projectsId', v.map(x => x.value));
                  }}
                />
              </div>

              {
                initInvoice ?
                  <>
                    <View>
                      <div>
                        <p style={tw.style('font-sans')} >
                          File: {initInvoice.filename}
                        </p>
                        <Button>
                          <a href={initInvoice.publicUrl} download style={tw.style(`text-dark flex no-underline`)} >
                            <span style={{ textDecoration: 'none' }}>Download</span>
                          </a>
                        </Button>
                      </div>
                    </View>
                  </>
                  :
                  <>
                    <FilePicker
                      type={`*/*`}
                      label='Select File'
                      setValue={(f) => f && onFileChange(f)}
                      hasError={!!fileError}
                    />

                    {fileError && (
                      <InputValidationMessage>{fileError}</InputValidationMessage>
                    )}
                  </>
              }
              <>

              </>
              {
                deleting !== 'confirming' ?
                  <View style={tw`flex flex-row items-center justify-end mt-4`}>
                    <>
                      {isSubmitting && <ActivityIndicator style={tw`mr-4`} />}
                      <Button
                        onPress={handleSubmit}
                        disabled={isSubmitting}
                      >
                        {initInvoice ? `Update Invoice` : `Upload Invoice`}
                      </Button>
                      {
                        initInvoice ?
                          <Button
                            variant='danger'
                            onPress={() => setDeleting('confirming')}
                            disabled={deleting === 'deleting'}
                            style={tw.style(`ml-4`)}
                          >
                            {deleting === 'idle' ? `Delete` : deleting === 'deleting' ? 'Deleting...' : 'Delete'}
                          </Button> : null
                      }

                    </>
                  </View> : deleting === 'confirming' ?
                    <View style={tw`flex flex-row items-center justify-start mt-4`}>
                      <p style={tw.style(`m-auto font-sans text-red`)} >Confirm to delete this invoice?</p>
                      <Button
                        onPress={handleDelete}
                        variant='danger'
                      >Confirm</Button>
                      <Button
                        variant='dark'
                        style={tw.style(`ml-4`)}
                        onPress={() => setDeleting('idle')}
                      >Cancel</Button>
                    </View> : deleting === 'deleting' ?
                      <View style={tw`flex flex-row items-center justify-start mt-4`}>
                        <p style={tw.style(`m-auto font-sans text-gray`)} >Deleting...</p>
                      </View> : null
              }
            </>
          </View>
        )}
      </Formik>
    </DefaultModal>
  );
};

export default InvoiceModal;
