import { createNativeStackNavigator } from '@react-navigation/native-stack';
import React from 'react';

import NewsScreen from '../views/AuthenticatedStack/NewsStack/NewsScreen';
import SingleNewsScreen from '../views/AuthenticatedStack/NewsStack/SingleNewsScreen';
import { NewsStackNavigatorParamList } from './types';

const Stack = createNativeStackNavigator<NewsStackNavigatorParamList>();

const NewsStackNavigator: React.FC = () => {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen
        name='news'
        component={NewsScreen}
        options={{ title: 'Pinnacle News' }}
      />
      <Stack.Screen name='newsArticle' component={SingleNewsScreen} />
    </Stack.Navigator>
  );
};

export default NewsStackNavigator;
