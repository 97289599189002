import { Link, useLinkProps } from '@react-navigation/native';
import React, { useRef } from 'react';
import { View } from 'react-native';
import { useHover } from 'react-native-web-hooks';
import { ClassInput } from 'twrnc/dist/esm/types';

import tw from '../../config/tailwind';
import EyeSVG from '../svg/EyeSVG';

interface ViewActionProps {
  to: Parameters<typeof useLinkProps>[0]['to'];
  style?: ClassInput;
}

const ViewAction: React.FC<ViewActionProps> = ({ to, style }) => {
  const ref = useRef(null);
  const isHovered = useHover(ref);

  return (
    <Link to={to} style={tw.style(style)}>
      <View
        ref={ref}
        style={tw`w-10 h-10 rounded-full bg-background-100 justify-center items-center`}
      >
        <EyeSVG
          width={18}
          height={10}
          style={tw.style(`text-green`, isHovered && 'text-blue')}
        />
      </View>
    </Link>
  );
};

export default ViewAction;
