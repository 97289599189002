import { Picker } from '@react-native-picker/picker';
import { useFocusEffect } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { ActivityIndicator } from 'react-native';

import { WpClient } from '../../api';
import tw from '../../config/tailwind';
import { useAppContext } from '../../hooks/useAppContext';
import { useToasts } from '../../hooks/useToasts';
import { PaginatedResult } from '../../shared/types/paginatedResult';
import { WpNewsCategory, WpNews } from '../../shared/types/wordpress';
import Col from '../shared/Col';
import P from '../shared/P';
import Row from '../shared/Row';
import Select from '../shared/Select';
import Spacer from '../shared/Spacer';
import NewsListing from '../wordpress/NewsListing';
import Pagination from '../Pagination';

interface NewsArticlesProps {}

const NewsArticles: React.FC<NewsArticlesProps> = ({}) => {
  const params = new URLSearchParams(window.location.search);
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState<WpNewsCategory[]>([]);
  const [desc, setDesc] = useState(true);
  const [page, setPage] = useState(parseInt(params.get('page') || '1'));
  const [refresh, setRefresh] = useState(1);
  const [category, setCategory] = useState(parseInt(params.get('category') || '-1'));
  const [paginatedResults, setPaginatedResults] =
    useState<PaginatedResult<WpNews>>();

  const { addUnhandledErrorToast } = useToasts();

  const [error, setError] = useState<string>('');

  useEffect(() => {
    WpClient.getCategories().then((categories) => {
      setCategories(categories);
      // console.log(categories)
      // const defaultCategory = categories.find(
      //   (x) => x.name === 'Pinnacle News'
      // )?.id;
      // if (defaultCategory) setCategory(defaultCategory);
    });
  }, []);

  useEffect(() => {
    let isActive = true;
    // if (categories.length > 0 && category > -1) {
    if (categories.length > 0) {
      setLoading(true);
      setError('');
      // console.log('getNews page, category:', page, category)
      WpClient.getNews(page, category, desc)
        .then((res) => {
          isActive && res && setPaginatedResults(res);
        })
        .catch((e) => {
          if (isActive) {
            setError('Something went wrong, please try again');
            addUnhandledErrorToast(e);
          }
        })
        .finally(() => {
          isActive && setLoading(false);
        });
    }

    return () => {
      isActive = false;
    };
  }, [page, desc, category, refresh, categories]);

  useFocusEffect(
    React.useCallback(() => {
      setRefresh((x) => x + 1);
    }, [])
  );

  if (loading) {
    return <ActivityIndicator />;
  }

  if (error) {
    return (
      <>
        <P>{error}</P>
      </>
    );
  }

  return (
    <>
      <Row>
        <Col style={tw.style('w-auto max-w-54')}>
          <Select
            onValueChange={(v) => {
              window.location.assign(v > 0 ? `/dashboard/news?category=${v}` : `/dashboard/news`)
              setCategory(v)
            }}
            selectedValue={category}
            label='Filter by'
            options={[
              {label: 'All News', value: -1},
              ...categories.map((cat) => ({
                label: cat.name,
                value: cat.id,
              }))
            ]}
          />
        </Col>
        <Spacer />
        <Col style={tw.style('w-auto max-w-54')}>
          <Select
            onValueChange={setDesc}
            label='Sort by'
            selectedValue={desc}
            options={[
              { label: 'Most recent first', value: true },
              { label: 'Oldest first', value: false },
            ]}
          />
        </Col>
      </Row>
      <Row style={tw`-mx-1.5`}>
        {!paginatedResults || !paginatedResults.items.length ? (
          <P>No articles found</P>
        ) : (
          paginatedResults.items.map((item) => (
            <Col key={item.id} style={tw`w-full sm:w-1/2 md:w-1/3 mb-3 px-1.5`}>
              <NewsListing skeleton={false} post={item} type='tile' />
            </Col>
          ))
        )}
      </Row>
      {
        paginatedResults && paginatedResults.totalPages > 1 ?
      <Row>
        <Pagination paginatedResult={paginatedResults} onChangePage={(p) => {
          window.location.assign(`/dashboard/news?page=${p}${category > 0 ? `&category=${category}` : ``}`)
        }} />
      </Row>
      : null
      }
    </>
  );
};

export default NewsArticles;
