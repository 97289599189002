import React from 'react';

import { UserClient } from '../../../api';
import { useToasts } from '../../../hooks/useToasts';
import { UserResponse } from '../../../shared/types/users';
import ConfirmationModal from '../../shared/ConfirmationModal';

interface DeleteUserModalProps {
  visible: boolean;
  setVisible: (v: boolean) => void;
  user: UserResponse;
  onUserDeleted: (user: UserResponse) => void;
}

const DeleteUserModal: React.FC<DeleteUserModalProps> = ({
  visible,
  setVisible,
  user,
  onUserDeleted,
}) => {
  const { addToast, addUnhandledErrorToast } = useToasts();

  const onSubmit = async () => {
    try {
      await UserClient.deleteUser(user.id);

      addToast({
        title: 'User deleted',
        description: `We've disabled ${user.firstName}'s account.`,
        type: 'success',
      });

      onUserDeleted(user);
      setVisible(false);
    } catch (e) {
      addUnhandledErrorToast(e);
    }
  };

  return (
    <ConfirmationModal
      prompt={`Are you sure you'd like to suspend ${user.firstName} ${user.lastName}'s account?`}
      onPromptEnd={async (didConfirm) => {
        if (didConfirm) {
          await onSubmit();
        } else {
          setVisible(false);
        }
      }}
      visible={visible}
    />
  );
};

export default DeleteUserModal;
