import { Formik } from 'formik';
import React from 'react';
import { View } from 'react-native';
import * as Yup from 'yup';

import { UserClient } from '../../../api';
import tw from '../../../config/tailwind';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { useToasts } from '../../../hooks/useToasts';
import { UserResponse, UserRole } from '../../../shared/types/users';
import Button from '../../shared/Button';
import Col from '../../shared/Col';
import DefaultModal from '../../shared/DefaultModal';
import InputValidationMessage from '../../shared/InputValidationMessage';
import Row from '../../shared/Row';
import Select from '../../shared/Select';

interface DeleteUserModalProps {
  visible: boolean;
  setVisible: (v: boolean) => void;
  user: UserResponse;
  updateUser: (user: UserResponse) => void;
}

const EditUserRoleModal: React.FC<DeleteUserModalProps> = ({
  visible,
  setVisible,
  user,
  updateUser,
}) => {
  const { addToast, addUnhandledErrorToast } = useToasts();
  const authContext = useAuthContext();

  const initialValues = {
    role: user.role,
  };

  const schema: Yup.SchemaOf<typeof initialValues> = Yup.object().shape({
    role: Yup.mixed().required().oneOf(Object.values(UserRole)),
  });

  const onSubmit = async (formValues: typeof initialValues) => {
    if (!user?.id) return;

    try {
      const updatedUser = await UserClient.updateUser(user.id, formValues);

      addToast({
        title: 'User details updated',
        description: `You updated ${user.firstName}'s role to ${updatedUser.role}.`,
        type: 'success',
      });

      updateUser(updatedUser);
      setVisible(false);
    } catch (e) {
      addUnhandledErrorToast(e);
    }
  };

  return (
    <DefaultModal
      setVisible={setVisible}
      visible={visible}
      title={`Update ${user?.firstName} ${user?.lastName}`}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={schema}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          values,
          errors,
        }) => (
          <View>
            <Row>
              <Col style={tw`w-full`}>
                <Row>
                  <Col style={tw`w-1/2`}>
                    <Select
                      selectedValue={values.role}
                      onValueChange={(iValue, iIndex) =>
                        handleChange('role')(iValue)
                      }
                      label='Select a role'
                      required
                      hasError={!!errors.role}
                      options={Object.values(UserRole).map((item) => ({
                        label: item,
                        value: item,
                      }))}
                    />
                    {errors.role && (
                      <InputValidationMessage>
                        {errors.role}
                      </InputValidationMessage>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>

            <Button onPress={handleSubmit} style={tw`mb-4`}>
              Update
            </Button>
          </View>
        )}
      </Formik>
    </DefaultModal>
  );
};

export default EditUserRoleModal;
