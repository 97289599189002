import React, { useState } from 'react';
import { Pressable, ScrollView, View } from 'react-native';
import { FavouritesClient } from '../../../api/FavouritesClient';
import tw from '../../../config/tailwind';
import { useToasts } from '../../../hooks/useToasts';
import { FavouriteResponse } from '../../../shared/types/favourites';
import Button from '../../shared/Button';
import DefaultModal from '../../shared/DefaultModal';
import InputLabel from '../../shared/InputLabel';
import InputText from '../../shared/InputText';
import P from '../../shared/P';
import Spacer from '../../shared/Spacer';
import Text from '../../shared/Text';
import CrossSVG from '../../svg/CrossSVG';

interface TagsModalProps {
  setVisible: (v: boolean) => void;
  tags: string[];
  favourite: FavouriteResponse;
  onUpdated: (updatedTags: string[]) => void;
}

const TagsModal: React.FC<TagsModalProps> = ({
  setVisible,
  tags: initialTags,
  favourite: {
    id: favouriteId,
    entity: {
      title: { rendered: entityName },
    },
  },
  onUpdated,
}) => {
  const [tags, setTags] = useState([...initialTags]);

  const [tag, setTag] = useState('');
  const [addingTag, setAddingTag] = useState(false);
  const { addToast } = useToasts();

  const addTag = () => {
    const newTag = tag.trim();
    if (addingTag) return;

    if (!newTag) {
      addToast({
        title: 'Invalid tag',
        description: 'Please make sure you have entered a tag.',
        type: 'error',
      });
      return;
    }

    if (tags.includes(newTag.toLowerCase())) {
      addToast({
        title: 'Duplicate tag',
        description: 'You have already assigned this tag.',
        type: 'error',
      });
      return;
    }

    setAddingTag(true);
    setTags((tags) => [...tags, newTag]);
    setTag('');
    setAddingTag(false);
  };

  const saveChanges = async () => {
    await FavouritesClient.setTags(favouriteId, { tags });
    addToast({
      title: 'Tags updated',
      description: '',
      type: 'success',
    });
    onUpdated(tags);
  };

  return (
    <DefaultModal
      setVisible={setVisible}
      visible
      title={'Manage Tags' + (entityName ? `: ${entityName}` : '')}
    >
      <View style={tw`max-h-[400px] border-b border-background mt-6 mb-6`}>
        <Text style={tw`font-bold font-ubuntu-bold mb-3`}>Assigned tags:</Text>
        {tags.length === 0 && (
          <View
            style={tw`py-2 border-t border-background border-background-600 mb-0 flex-row`}
          >
            <Text>No tags have been assigned yet</Text>
          </View>
        )}
        <ScrollView>
          {tags.map((tag, i) => (
            <View
              style={tw`py-2 border-t border-background border-background-600 mb-0 flex-row`}
            >
              <Text>{tag}</Text>
              <Spacer />
              <Pressable
                onPress={() => {
                  setTags((tags) => {
                    tags.splice(i, 1);
                    return tags;
                  });
                }}
              >
                <CrossSVG height={22} width={22} style={tw`text-red`} />
              </Pressable>
            </View>
          ))}
        </ScrollView>
      </View>

      <View style={tw`flex-row`}>
        <View style={tw`flex-1 mr-3`}>
          <InputText label='Create a new tag' setValue={setTag} value={tag} />
        </View>
        <View style={tw`flex-initial`}>
          <InputLabel label=' ' />
          <Button
            style={tw`min-h-0 h-[50px] py-0 `}
            onPress={addTag}
            disabled={!tag}
          >
            Add
          </Button>
        </View>
      </View>

      <View style={tw`flex-row justify-end`}>
        <Button onPress={saveChanges}>Save changes</Button>
      </View>
    </DefaultModal>
  );
};

export default TagsModal;
