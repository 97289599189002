import { WpDistributorWithDistance } from '../@types/distributors';
import { LatLng } from '../@types/geolocation';
import { ContactResponse } from '../shared/types/contacts';
import { WpDistributor } from '../shared/types/wordpress';
import { getDistanceFromLatLonInMiles } from './geolocation';

export const filterDistributors = async (options: {
  distributors: WpDistributor[];
  name: string;
  location?: LatLng;
  distanceInMiles?: number;
}): Promise<WpDistributor[]> => {
  const { distributors, name, distanceInMiles, location } = options;
  const results: (WpDistributor | WpDistributorWithDistance)[] = [];

  if (!name.length && !location) return distributors;

  (distributors as unknown as WpDistributorWithDistance[]).forEach(
    (distributor) => {
      if (name && !stringContains(distributor.title.rendered, name)) return;

      if (location) {
        const dif = getDistanceFromLatLonInMiles(
          {
            lat: parseFloat(distributor.acf.latitude),
            long: parseFloat(distributor.acf.longitude),
          },
          location
        );
        distributor.distance = dif;
      }

      if (distanceInMiles && location) {
        if (distributor.distance < distanceInMiles) results.push(distributor);
      } else {
        results.push(distributor);
      }
    }
  );

  results.sort((a, b) => {
    const a1 = a as WpDistributorWithDistance;
    const b1 = b as WpDistributorWithDistance;
    return a1.distance > b1.distance ? 1 : -1;
  });

  return results;
};

export interface ContactResponseWithDistance extends ContactResponse{
  distance: number;
}

export const filterMapContact = async (options: {
  contacts: ContactResponse[]
  name: string;
  location?: LatLng;
  distanceInMiles?: number;
}): Promise<ContactResponse[]> => {
  const { contacts, name, distanceInMiles, location } = options;
  const results: (ContactResponse | ContactResponseWithDistance)[] = [];

  if (!name.length && !location) return contacts;

  (contacts as unknown as ContactResponseWithDistance[]).forEach(
    (c) => {
      let tempC = {...c};
      if (location) {
        const dif = getDistanceFromLatLonInMiles(
          {
            lat: c.latitude,
            long: c.longitude,
          },
          location
        );
        tempC.distance = dif;
      }
      
      if (distanceInMiles && location) {
        // console.log('temC', tempC, `c.distance , distanceInMiles`, c.distance, distanceInMiles)
        if (tempC.distance < distanceInMiles) results.push(tempC);
      } else {
        results.push(tempC);
      }
    }
  );

  results.sort((a, b) => {
    const a1 = a as ContactResponseWithDistance;
    const b1 = b as ContactResponseWithDistance;
    return a1.distance > b1.distance ? 1 : -1;
  });

  return results;
};

const stringContains = (v: string, query: string) => {
  function clean(v: string) {
    return v.replace(' ', '').toLowerCase();
  }

  return clean(v).includes(clean(query));
};
