import { API_BASE_URL } from '../env-consts';
import { CommentResponse } from '../shared/types/comments';
import { PaginatedResult } from '../shared/types/paginatedResult';
import {
  CreateTaskCommentPayload,
  TaskImageResponse,
  TaskResponse,
} from '../shared/types/tasks';
import ApiClient from './ApiClient';

const client = new ApiClient(`${API_BASE_URL}/projects`);

export const TaskClient = {
  async getAll(projectId: string, page: number) {
    return client
      .get<PaginatedResult<TaskResponse>>(`/${projectId}/tasks`, {
        params: {
          page,
        },
      })
      .then(({ data }) => data);
  },

  async getById(projectId: string, taskId: string) {
    return client
      .get<TaskResponse>(`/${projectId}/tasks/${taskId}`)
      .then(({ data }) => data);
  },

  async completeTask(projectId: string, taskId: string) {
    return client
      .post<TaskResponse>(`/${projectId}/tasks/${taskId}/complete`)
      .then(({ data }) => data);
  },

  async getComments(projectId: string, taskId: string, page: number) {
    return client
      .get<PaginatedResult<CommentResponse>>(
        `/${projectId}/tasks/${taskId}/comments`,
        {
          params: {
            page,
          },
        }
      )
      .then(({ data }) => data);
  },

  async createComment(
    projectId: string,
    taskId: string,
    payload: CreateTaskCommentPayload
  ) {
    return client
      .post<CommentResponse>(`/${projectId}/tasks/${taskId}/comments`, payload)
      .then(({ data }) => data);
  },

  async addImage(projectId: string, taskId: string, file: File) {
    const data = new FormData();
    data.append('file', file, file.name);

    return client
      .post<TaskImageResponse>(`/${projectId}/tasks/${taskId}/image`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(({ data }) => data);
  },

  async deleteImage(projectId: string, taskId: string, imageId: string) {
    return client
      .delete<void>(`/${projectId}/tasks/${taskId}/image/${imageId}`, {})
      .then(({ data }) => data);
  },
};
