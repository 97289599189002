import React from 'react';
import { Image, View } from 'react-native';

import tw from '../../config/tailwind';
import Col from '../shared/Col';
import ContentWrapper from '../shared/ContentWrapper';
import H2 from '../shared/H2';
import P from '../shared/P';
import Row from '../shared/Row';
import RequestARigForm from './RequestARigForm';

interface RequestARigProps {}

const RequestARig: React.FC<RequestARigProps> = ({}) => {
  return (
    <>
      <ContentWrapper style={tw`mb-5`}>
        <Row>
          <Col style={tw`w-full lg:w-1/2`}>
            <H2>The Zentia Training Rig</H2>
            <P>What is the Zentia training rig?</P>
            <P>
              The Zentia training rig is a metal structure that can be shipped
              to a location of your choice so that your Pinnacle Supervisors can
              begin training your employees on Zentia products. The rig can be
              used to train your team on grid, tile, and floating ceiling
              installations.
            </P>
            <P>
              At the end of your training session, simply disassemble the rig
              and ship it back to Zentia.
            </P>
          </Col>
          <Col style={tw`w-full lg:w-1/2`}>
            <View
              style={tw`w-full aspect-1 bg-background-100 border border-background-600`}
            >
              <Image
                source={{
                  uri: 'https://www.zentia.com/en-gb/wp-content/uploads/sites/18/2022/08/Request-a-rig-scaled.jpg',
                }}
                resizeMode='cover'
                style={{ width: '100%', height: '100%' }}
              />
            </View>
          </Col>
        </Row>
      </ContentWrapper>
      <RequestARigForm />
    </>
  );
};

export default RequestARig;
