import { API_BASE_URL } from '../env-consts';
import { CommentResponse } from '../shared/types/comments';
import {
  CreateLeadCommentPayload,
  CreateLeadPayload,
  LeadResponse,
  LeadStat,
  UpdateLeadPayload,
} from '../shared/types/leads';
import { PaginatedResult } from '../shared/types/paginatedResult';
import ApiClient from './ApiClient';

const client = new ApiClient(`${API_BASE_URL}/leads`);

export const LeadClient = {
  async create(payload: CreateLeadPayload) {
    return client.post<LeadResponse>(`/`, payload).then(({ data }) => data);
  },

  async getAll(page: number) {
    return client
      .get<PaginatedResult<LeadResponse>>(`/`, {
        params: { page },
      })
      .then(({ data }) => data);
  },

  async getStats() {
    return client.get<LeadStat[]>(`/stats`).then(({ data }) => data);
  },

  async getById(leadId: string) {
    return client.get<LeadResponse>(`/${leadId}`).then(({ data }) => data);
  },

  async update(leadId: string, payload: UpdateLeadPayload) {
    return client
      .patch<LeadResponse>(`/${leadId}`, payload)
      .then(({ data }) => data);
  },

  async getComments(leadId: string, page: number) {
    return client
      .get<PaginatedResult<CommentResponse>>(`/${leadId}/comments`, {
        params: {
          page,
        },
      })
      .then(({ data }) => data);
  },

  async createComment(leadId: string, payload: CreateLeadCommentPayload) {
    return client
      .post<CommentResponse>(`/${leadId}/comments`, payload)
      .then(({ data }) => data);
  },
};
