import AsyncStorage from '@react-native-async-storage/async-storage';

interface StorageItem<T> {
  value: T;
  expiresAt: number;
}

export const LocalStorage = {
  async get<T>(key: string): Promise<T | undefined> {
    const item = await AsyncStorage.getItem(key);
    if (!item) return undefined;
    const json = JSON.parse(item) as StorageItem<T>;
    if (json.expiresAt < Date.now()) return undefined;
    return json.value;
  },

  async set<T>(
    key: string,
    value: T,
    /** Time to live in seconds */
    ttl = 60 * 10 * 1000 // 10 Minutes
  ) {
    const storageItem: StorageItem<T> = {
      value,
      expiresAt: Date.now() + Math.max(0, ttl) * 1000,
    };
    await AsyncStorage.setItem(key, JSON.stringify(storageItem));
  },
};
