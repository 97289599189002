import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { FC } from 'react';

import ForgotPasswordScreen from '../views/UnauthenticatedStack/ForgotPasswordScreen';
import InvitationScreen from '../views/UnauthenticatedStack/InvitationScreen';
import LoginScreen from '../views/UnauthenticatedStack/LoginScreen';
import { UnauthenticatedStackNavigatorParamList } from './types';

const Stack =
  createNativeStackNavigator<UnauthenticatedStackNavigatorParamList>();

const UnauthenticatedStackNavigator: FC = () => {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen
        name='login'
        component={LoginScreen}
        options={{ title: 'Login' }}
      />
      <Stack.Screen
        name='forgotPassword'
        component={ForgotPasswordScreen}
        options={{ title: 'Forgot your password' }}
      />
      <Stack.Screen
        name='acceptInviteUser'
        component={InvitationScreen}
        options={{ title: 'Accept an invitiation' }}
      />
      <Stack.Screen
        name='acceptInviteCompany'
        component={InvitationScreen}
        options={{ title: 'Accept an invitiation' }}
      />
    </Stack.Navigator>
  );
};

export default UnauthenticatedStackNavigator;
