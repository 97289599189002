import { FC, useEffect } from 'react';
import { ClassInput } from 'twrnc/dist/esm/types';

import tw from '../../config/tailwind';
import Text from './Text';

type Props = {
  children: string;
  style?: ClassInput;
  absolute?: boolean;
};

const InputValidationMessage: FC<Props> = ({ children, style, absolute }) => {

  if (!children) return <></>;

  return (
    <Text
      style={tw.style(
        `text-red text-xs font-medium mb-4 -mt-3`,
        absolute && '-mt-4 mb-0',
        style
      )}
    >
      {children}
    </Text>
  );
};

export default InputValidationMessage;
