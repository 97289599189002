import { StackActions, useFocusEffect } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React, { useState } from 'react';
import { ActivityIndicator, Pressable, View } from 'react-native';
import { WpClient, ProjectClient } from '../../api';

import Col from '../../components/shared/Col';
import ContentWrapper from '../../components/shared/ContentWrapper';
import FullWidthButton from '../../components/shared/FullWidthButton';
import H1 from '../../components/shared/H1';
import H3 from '../../components/shared/H3';
import ProgressDoughnut from '../../components/shared/ProgressDoughnut';
import Row from '../../components/shared/Row';
import Spacer from '../../components/shared/Spacer';
import Text from '../../components/shared/Text';
import DoubleChevronRightSVG from '../../components/svg/DoubleChevronRightSVG';
import NewsListing from '../../components/wordpress/NewsListing';
import ResourceListing from '../../components/wordpress/ResourceListing';
import tw from '../../config/tailwind';
import { useAppContext } from '../../hooks/useAppContext';
import { useAuthContext } from '../../hooks/useAuthContext';
import DashboardLayout from '../../layout/DashboardLayout';
import { AuthenticatedStackNavigatorParamList } from '../../routes/types';
import { ProjectResponse, ProjectStatus } from '../../shared/types/projects';
import { WpNews, WpResource } from '../../shared/types/wordpress';
import { addLeadingZero } from '../../utils/addLeadingZero';

export type HomeScreenProps =
  NativeStackScreenProps<AuthenticatedStackNavigatorParamList> & {
    //
  };

const HomeScreen: React.FC<HomeScreenProps> = ({ navigation }) => {
  const { user, activeCompany } = useAuthContext();
  const {
    favourites,
    addFavourite,
    checkFavourite,
    isLoading: appLoading,
    projectStats,
  } = useAppContext();
  const [recentProjects, setRecentProjects] = useState<ProjectResponse[]>([]);
  const [recentNews, setRecentNews] = useState<WpNews[]>([]);
  const [recentResources, setRecentResources] = useState<WpResource[]>([]);
  const [loading, setLoading] = useState(true);

  useFocusEffect(
    React.useCallback(() => {
      if (appLoading) return;
      if (!loading || !activeCompany) return;

      let isActive = true;

      WpClient.getNews(1).then((res) => {
        if (isActive) {
          setRecentNews(res.items.slice(0, 3));
        }
      });

      WpClient.getResources(1).then(async (res) => {
        if (isActive) {
          const items = res.items.slice(0, 3);

          await Promise.allSettled(
            items.map((item) => checkFavourite(item.id))
          );

          setRecentResources(items);
        }
      });

      Promise.all([
        ProjectClient.getAll(
          activeCompany?.id as string,
          1,
          ProjectStatus.Live
        ).then((res) => {
          if (isActive) {
            setRecentProjects(res.items.slice(0, 3));
          }
        }),
      ]).then(() => {
        if (isActive) {
          setLoading(false);
        }
      });

      return () => {
        isActive = false;
      };
    }, [activeCompany, appLoading])
  );

  if (appLoading || loading) {
    return <DashboardLayout>{/* <ActivityIndicator /> */}</DashboardLayout>;
  }

  return (
    <DashboardLayout>
      <H1 xl style='font-ubuntu-medium'>
        Hello, {user?.firstName}
      </H1>

      <Row style={tw`mb-5`}>
        <Col style={tw`w-full lg:w-1/2 mb-6 lg:mb-0`}>
          <ContentWrapper style={tw`flex-1`}>
            <Row style={tw`mb-5`}>
              <Col style={tw`w-full`}>
                <H3 style={tw`mb-0`}>Your projects</H3>
              </Col>
            </Row>
            <Row style={tw`mb-5 mx-0 items-center flex-col sm:flex-row`}>
              <View style={tw`sm:mr-10 xl:mr-4 items-center w-full sm:w-auto`}>
                <ProgressDoughnut
                  progress={
                    projectStats?.liveCount > 0
                      ? projectStats.liveCount /
                        (projectStats.liveCount + projectStats.completedCount)
                      : 0
                  }
                />
              </View>
              <View
                style={tw`flex-1 flex-row flex-wrap justify-between sm:justify-start w-full sm:w-auto max-w-[263px] sm:max-w-none`}
              >
                <View
                  style={tw`flex justify-center sm:mr-6 flex-auto sm:flex-initial`}
                >
                  <H1
                    xl
                    style={tw`mb-0 text-green text-center sm:text-left font-ubuntu-medium`}
                  >
                    {addLeadingZero(projectStats.liveCount)}
                  </H1>
                  <Text
                    style={tw`font-bold text-green font-ubuntu-bold text-center sm:text-left`}
                  >
                    Live projects
                  </Text>
                </View>
                <View style={tw`flex justify-center flex-auto sm:flex-initial`}>
                  <H1
                    xl
                    style={tw`mb-0 text-center sm:text-left font-ubuntu-medium`}
                  >
                    {addLeadingZero(projectStats.completedCount)}
                  </H1>
                  <Text
                    style={tw`font-bold font-ubuntu-bold text-center sm:text-left`}
                  >
                    Completed projects
                  </Text>
                </View>
              </View>
            </Row>

            {recentProjects.length > 0 && (
              <Row style={tw`mx-0 mb-3`}>
                {recentProjects.map((project, i) => (
                  <Col
                    key={i}
                    style={tw.style(
                      `w-full  px-0 py-3 text-blue`,
                      i < recentProjects.length && 'border-b  border-grey-100',
                      i === 0 && `border-t`
                    )}
                  >
                    <Pressable
                      onPress={() =>
                        navigation.dispatch(
                          StackActions.push('projectStack', {
                            screen: 'project',
                            params: {
                              id: project.id,
                            },
                          })
                        )
                      }
                    >
                      <Text style={tw`font-bold font-ubuntu-bold`}>
                        {project.name}
                      </Text>
                    </Pressable>
                  </Col>
                ))}
              </Row>
            )}
            <View style={tw`flex flex-row flex-start`}>
              <FullWidthButton
                variant='transparent'
                buttonStyle={tw`py-2 mt-auto`}
                underline
                onPress={() =>
                  navigation.dispatch(StackActions.push('projectsOverview'))
                }
              >
                <View style={tw`flex-row`}>
                  <DoubleChevronRightSVG />
                  <Text style={tw`text-base font-bold font-ubuntu-bold`}>
                    View all projects
                  </Text>
                </View>
              </FullWidthButton>
            </View>
          </ContentWrapper>
        </Col>
        <Col style={tw`w-full lg:w-1/2`}>
          <Row style={tw`flex-1`}>
            {(recentNews?.length > 0 ? recentNews : Array(3).fill(false)).map(
              (newsPost, i, items) => (
                <Col
                  key={i}
                  style={tw.style(
                    `w-full`,
                    i < items.length - 1 && `mb-3 lg:mb-3`,
                    {
                      minHeight: '120px',
                    }
                  )}
                >
                  <NewsListing post={newsPost} skeleton={newsPost === false} />
                </Col>
              )
            )}
          </Row>
        </Col>
      </Row>

      <Row>
        <Col>
          <H3>Latest Resources</H3>
        </Col>
        <Spacer />
        <Col style={tw`w-auto`}>
          <FullWidthButton
            variant='transparent'
            buttonStyle={tw`py-2`}
            underline
            onPress={() => navigation.dispatch(StackActions.push('resources'))}
          >
            <View style={tw`flex-row`}>
              <DoubleChevronRightSVG />
              <Text style={tw`text-base font-ubuntu-bold font-bold`}>
                All resources
              </Text>
            </View>
          </FullWidthButton>
        </Col>
      </Row>

      <Row>
        {recentResources.map((resource, i) => (
          <Col key={i} style={tw`w-1/3 w-full lg:w-1/3 mb-3 lg:mb-0`}>
            <ResourceListing resource={resource} skeleton={!resource} />
          </Col>
        ))}
      </Row>
    </DashboardLayout>
  );
};

export default HomeScreen;
