import axios from 'axios';
import { Formik, FormikHelpers } from 'formik';
import React, { useContext, useState } from 'react';
import { View } from 'react-native';
import * as Yup from 'yup';

import { AuthClient } from '../../api';
import tw from '../../config/tailwind';
import { AuthContext } from '../../context/AuthContextProvider';
import { useToasts } from '../../hooks/useToasts';
import { LoginResponseType } from '../../shared/types/auth';
import Button from '../shared/Button';
import InputCheckbox from '../shared/InputCheckbox';
import InputPassword from '../shared/InputPassword';
import InputText from '../shared/InputText';
import InputValidationMessage from '../shared/InputValidationMessage';
import P from '../shared/P';
import Text from '../shared/Text';

type LoginValues = {
  email: string;
  password: string;
  rememberMe: boolean;
  otpCode: string;
};

interface LoginFormProps {
  onLogin?: () => void;
}

const LoginForm: React.FC<LoginFormProps> = ({ onLogin = () => {} }) => {
  const { login } = useContext(AuthContext);
  const { addUnhandledErrorToast } = useToasts();

  const [isTfa, setIsTfa] = useState(false);
  const [partialTel, setPartialTel] = useState('');

  const initialValues: LoginValues = {
    email: '',
    password: '',
    rememberMe: false,
    otpCode: '',
  };

  const schema: Yup.SchemaOf<LoginValues> = Yup.object().shape({
    email: Yup.string()
      .min(2)
      .max(50)
      .required()
      .email()
      .label('Email address'),
    password: Yup.string().required().label('Password'),
    rememberMe: Yup.bool().required(),
    otpCode: Yup.string()
      .default('')
      .test('otpCodeTest', 'Please enter your 2FA code', (value) => {
        if (!isTfa) return true;
        return !!value && value.length > 0;
      })
      .test('otpCodeTest', 'Code must be 6 characters', (value) => {
        if (!isTfa) return true;
        return !!value && value.length === 6;
      }),
  });

  const onSubmit = async (
    credentials: LoginValues,
    { setErrors }: FormikHelpers<LoginValues>
  ) => {
    try {
      const res = await AuthClient.login({
        email: credentials.email,
        password: credentials.password,
        otpCode: credentials.otpCode || undefined,
      });

      if (res.type === LoginResponseType.Verify) {
        setIsTfa(true);
        setPartialTel(res.partialTel);
      } else {
        login(res.accessToken);
        onLogin();
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 401) {
        setErrors({
          email: 'Username or password is incorrect',
          password: ' ',
        });
      } else {
        addUnhandledErrorToast(error);
      }
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={schema}
    >
      {({
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        values,
        errors,
      }) => (
        <View>
          {isTfa ? (
            <>
              <P>
                A verification code has been sent to your mobile device ending
                in <Text style={tw`font-bold`}>{partialTel}</Text>. This code
                will be valid for <Text style={tw`font-bold`}>10 minutes</Text>.
              </P>
              <InputText
                value={values.otpCode}
                setValue={(v) => {
                  handleChange('otpCode')(v.replace(/[^0-9]/g, ''));
                }}
                placeholder='Enter your 2FA code'
                hasError={!!errors.otpCode}
                label='Code'
                required
                keyboardType='numeric'
              />
              {errors.otpCode && (
                <InputValidationMessage>
                  {errors.otpCode}
                </InputValidationMessage>
              )}
            </>
          ) : (
            <>
              <InputText
                value={values.email}
                setValue={handleChange('email')}
                onBlur={handleBlur('email')}
                placeholder='Enter your email address'
                hasError={!!errors.email}
                label='Email address'
                required
              />
              {errors.email && (
                <InputValidationMessage>{errors.email}</InputValidationMessage>
              )}

              <InputPassword
                value={values.password}
                setValue={handleChange('password')}
                onBlur={handleBlur('password')}
                placeholder='Enter your password'
                hasError={!!errors.password}
                label='Password'
                required
              />
              {errors.password && (
                <InputValidationMessage>
                  {errors.password}
                </InputValidationMessage>
              )}
              <InputCheckbox
                value={values.rememberMe}
                setValue={(value) => setFieldValue('rememberMe', value)}
                label={
                  "Remember me on this device. Don't do this if you're on a public device."
                }
                hasError={!!errors.rememberMe}
              />
              {errors.rememberMe && (
                <InputValidationMessage>
                  {errors.rememberMe}
                </InputValidationMessage>
              )}
            </>
          )}

          <Button
            onPress={handleSubmit}
            style={tw.style(`mb-5 mt-4 self-stretch text-center`, {
              maxWidth: 192,
            })}
          >
            Log in
          </Button>
        </View>
      )}
    </Formik>
  );
};

export default LoginForm;
