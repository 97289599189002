import { Picker } from '@react-native-picker/picker';
import axios from 'axios';
import { Formik } from 'formik';
import React from 'react';
import { ActivityIndicator, View } from 'react-native';
import * as Yup from 'yup';

import { UserClient } from '../../../api';
import tw from '../../../config/tailwind';
import { useToasts } from '../../../hooks/useToasts';
import { CompanyUserRole } from '../../../shared/types/companies';
import {
  CreateUserPayload,
  UserResponse,
  UserRole,
  UserTier
} from '../../../shared/types/users';
import Button from '../../shared/Button';
import Col from '../../shared/Col';
import DefaultModal from '../../shared/DefaultModal';
import InputText from '../../shared/InputText';
import InputValidationMessage from '../../shared/InputValidationMessage';
import Row from '../../shared/Row';
import Select from '../../shared/Select';

interface CreateAdminUserModalProps {
  visible: boolean;
  setVisible: (v: boolean) => void;
  onUserAdded: (user: UserResponse) => void;
}

const CreateAdminUserModal: React.FC<CreateAdminUserModalProps> = ({
  visible,
  setVisible,
  onUserAdded,
}) => {
  const { addToast, addUnhandledErrorToast } = useToasts();

  const initialValues: CreateUserPayload = {
    email: '',
    businessName: 'Zentia',
    firstName: '',
    lastName: '',
    role: UserRole.Admin,
    tier: UserTier.ONE
  };

  const schema: Yup.SchemaOf<CreateUserPayload> = Yup.object().shape({
    email: Yup.string().required().email(),
    businessName: Yup.string().required(), //.required().min(3),
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    role: Yup.mixed().required().oneOf(Object.values(UserRole)),
    tier: Yup.mixed().required().oneOf(Object.values(UserTier)),
  });

  const onSubmit = async (values: CreateUserPayload) => {
    try {
      const newUser = await UserClient.createUser(values);

      addToast({
        title: 'User created',
        description: `We've created an account for ${values.email}`,
        type: 'success',
      });

      onUserAdded(newUser);
      setVisible(false);
    } catch (e) {
      if (axios.isAxiosError(e) && e.response?.status === 409) {
        addToast({
          title: 'Invite failed: Duplicate user',
          description: 'You have already invited this user.',
          type: 'error',
        });
      } else {
        addUnhandledErrorToast(e);
      }
    }
  };

  return (
    <DefaultModal
      setVisible={setVisible}
      visible={visible}
      title='Create a new user'
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={schema}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          values,
          errors,
          isSubmitting,
        }) => (
          <View style={tw`mt-4`}>
            <Row>
              <Col style={tw`w-1/2`}>
                <InputText
                  value={values.firstName}
                  setValue={handleChange('firstName')}
                  onBlur={handleBlur('firstName')}
                  placeholder='First Name'
                  hasError={!!errors.firstName}
                  label='First Name'
                  required
                />
                {errors.firstName && (
                  <InputValidationMessage>
                    {errors.firstName}
                  </InputValidationMessage>
                )}
              </Col>
              <Col style={tw`w-1/2`}>
                <InputText
                  value={values.lastName}
                  setValue={handleChange('lastName')}
                  onBlur={handleBlur('lastName')}
                  placeholder='Last Name'
                  hasError={!!errors.lastName}
                  label='Last Name'
                  required
                />
                {errors.lastName && (
                  <InputValidationMessage>
                    {errors.lastName}
                  </InputValidationMessage>
                )}
              </Col>
              <Col style={tw`w-1/2`}>
                <InputText
                  value={values.email}
                  setValue={handleChange('email')}
                  onBlur={handleBlur('email')}
                  placeholder='Email Address'
                  hasError={!!errors.email}
                  label='Email Address'
                  required
                />
                {errors.email && (
                  <InputValidationMessage>
                    {errors.email}
                  </InputValidationMessage>
                )}
              </Col>
              <Col style={tw`w-1/2`}>
                <Select
                  selectedValue={values.tier as number}
                  onValueChange={(iValue, iIndex) =>
                    setFieldValue('tier', iValue)
                  }
                  label='Select a tier'
                  required
                  hasError={!!errors.tier}
                  options={Object.values(UserTier)
                    .filter((x) => isNaN(parseInt(x as string)))
                    .map((item, i) => ({
                      label: item.toString(),
                      value: i + 1,
                    }))}
                />
                {errors.tier && (
                  <InputValidationMessage>
                    {errors.tier}
                  </InputValidationMessage>
                )}
              </Col>
            </Row>
            <View style={tw`flex flex-row items-center justify-end mt-4`}>
              {isSubmitting && <ActivityIndicator style={tw`mr-4`} />}
              <Button onPress={handleSubmit} disabled={isSubmitting}>
                Invite user
              </Button>
            </View>
          </View>
        )}
      </Formik>
    </DefaultModal>
  );
};

export default CreateAdminUserModal;
