const defaultTheme = require('tailwindcss/defaultTheme');

module.exports = {
  content: ['./src/**/*.{ts,tsx,js}'],
  theme: {
    fontFamily: {
      sans: ['Ubuntu', ...defaultTheme.fontFamily.sans],
      serif: ['Ubuntu', ...defaultTheme.fontFamily.serif],
      'ubuntu-bold': ['"Ubuntu-Bold"'],
      'ubuntu-medium': ['"Ubuntu-Medium"'],
    },
    fontSize: {
      /** font-size: 10px,  line-height: 16px */
      xs: ['.625rem', '1rem'],
      /** font-size: 12px,  line-height: 13px */
      sm: ['0.75rem', '0.8125rem'],
      /** font-size: 15px,  line-height: 17px */
      base: ['.9375rem', '1.0625rem'],
      /** font-size: 22px,  line-height: 20px */
      md: ['1.25rem', '1.375rem'],
      /** font-size: 25px,  line-height: 27px */
      lg: ['1.5625rem', '1.6875rem'],
      /** font-size: 28px,  line-height: 32px */
      xl: ['1.75rem', '2.0125rem'],
      /** font-size: 35px,  line-height: 40px */
      '2xl': ['2.1875rem', '2.5156rem'],
      /** font-size: 45px,  line-height: 49px */
      '3xl': ['2.8125rem', '3.0625rem'],
    },
    extend: {
      colors: {
        blue: {
          DEFAULT: '#101820',
        },
        green: {
          DEFAULT: '#3CDBC0',
        },
        grey: {
          DEFAULT: '#D1D1D1',
          100: '#F1F1F1',
          200: '#E8E8E8',
          900: '#5E5E5E',
        },
        //
        //
        red: {
          DEFAULT: '#DC5656',
        },
        background: {
          DEFAULT: '#ececec', // TODO: Deprecate
          600: '#CCCCCC', // TODO: Deprecate
          300: '#F6F6F6',
          200: '#F7F7F7', // TODO: Deprecate
          100: '#fafafa', // TODO: Deprecate
        },
        border: {
          DEFAULT: '#979797', // TODO: Deprecate
        },
        dark: {
          300: '#5C5C5C', // TODO: Deprecate
          400: '#5e5e5e', // DEPRECATED
          DEFAULT: '#464646', // DEPRECATED
        },
      },
    },
  },
};
