import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import * as Yup from 'yup';
import { UserClient } from '../api';

import Button from '../components/shared/Button';
import H1 from '../components/shared/H1';
import InputPassword from '../components/shared/InputPassword';
import InputValidationMessage from '../components/shared/InputValidationMessage';
import P from '../components/shared/P';
import tw from '../config/tailwind';
import { useAuthContext } from '../hooks/useAuthContext';
import { useToasts } from '../hooks/useToasts';
import LoginLayout from '../layout/LoginLayout';
import { RootStackParamList } from '../routes/types';
import { ResetPasswordPayload, UserRole } from '../shared/types/users';

type ResetPasswordScreenProps = NativeStackScreenProps<
  RootStackParamList,
  'resetPassword'
>;

const ResetPasswordScreen: React.FC<ResetPasswordScreenProps> = ({
  route: {
    params: { userId, token },
  },
  navigation,
}) => {
  const [loading, setLoading] = useState(true);
  const { loading: authLoading, isAuthenticated, user } = useAuthContext();
  const { addToast } = useToasts();

  const redirectUser = () => {
    if (isAuthenticated) {
      if (user?.role === UserRole.Admin) {
        navigation.replace('authenticatedStackNavigator', {
          screen: 'globalAdminStack',
          params: {
            screen: 'admin',
          },
        });
      } else {
        navigation.replace('authenticatedStackNavigator', {
          screen: 'home',
        });
      }
    } else {
      navigation.replace('unauthenticatedStackNavigator', {
        screen: 'login',
      });
    }
  };

  const onError = (message?: string, description?: string) => {
    addToast({
      title: message ?? 'Invalid link',
      description: description ?? ``,
      type: 'error',
    });

    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      redirectUser();
    }
  };

  useEffect(() => {
    if (authLoading) return;

    let isActive = true;

    if (isAuthenticated && userId !== user?.id) {
      return onError(
        '403: Forbidden',
        'You are not authorised to visit this link'
      );
    }

    UserClient.verifyResetToken(userId, token)
      .then((res) => {
        if (isActive) {
          setLoading(false);
        }
      })
      .catch(() => onError());

    return () => {
      isActive = false;
    };
  }, [authLoading]);

  const initialValues: ResetPasswordPayload = {
    password: '',
    confirmPassword: '',
  };

  const schema: Yup.SchemaOf<typeof initialValues> = Yup.object().shape({
    password: Yup.string().required().label('Password').min(8).max(24),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required()
      .label('Confirm password'),
  });

  const submitForm = async (payload: ResetPasswordPayload) => {
    await UserClient.resetPassword(userId, token, payload);
    addToast({
      title: 'Password updated',
      description: '',
      type: 'success',
    });
    redirectUser();
  };

  return (
    <LoginLayout>
      <H1 style={tw`text-xl font-ubuntu-bold font-bold`}>Reset Password</H1>
      <P>
        Set the new password for your account so you can login and access all
        the features.
      </P>

      <Formik
        initialValues={initialValues}
        onSubmit={submitForm}
        validationSchema={schema}
      >
        {({ handleChange, handleBlur, handleSubmit, values, errors }) => (
          <View>
            <InputPassword
              value={values.password}
              setValue={handleChange('password')}
              onBlur={handleBlur('password')}
              placeholder='Enter a password'
              hasError={!!errors.password}
              label='New password'
              required
            />
            {errors.password && (
              <InputValidationMessage>{errors.password}</InputValidationMessage>
            )}
            <InputPassword
              value={values.confirmPassword}
              setValue={handleChange('confirmPassword')}
              onBlur={handleBlur('confirmPassword')}
              placeholder='Confirm your password'
              hasError={!!errors.confirmPassword}
              label='Confirm Password'
              required
            />
            {errors.confirmPassword && (
              <InputValidationMessage>
                {errors.confirmPassword}
              </InputValidationMessage>
            )}

            <Button onPress={handleSubmit} style={tw`mb-4`}>
              Reset password
            </Button>
          </View>
        )}
      </Formik>
    </LoginLayout>
  );
};

export default ResetPasswordScreen;
