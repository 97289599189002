import { NavigationProp, useNavigation } from '@react-navigation/native';
import React, { useContext, useState } from 'react';
import { ActivityIndicator, View } from 'react-native';

import DoubleChevronRightSvg from '../../assets/svg/double-chevron-right.svg';
import InfoBox from '../../components/InfoBox';
import InvoiceListTable from '../../components/invoices/InvoiceListTable';
import TfaModal from '../../components/my-account/EnableTfaModal';
import MarketingPreferencesForm from '../../components/my-account/MarketingPreferencesForm';
import UserDetailsForm from '../../components/my-account/UserDetailsForm';
import Tabs from '../../components/navigation/Tabs';
import Button from '../../components/shared/Button';
import Col from '../../components/shared/Col';
import FullWidthButton from '../../components/shared/FullWidthButton';
import H1 from '../../components/shared/H1';
import Row from '../../components/shared/Row';
import Text from '../../components/shared/Text';
import tw from '../../config/tailwind';
import { AuthContext } from '../../context/AuthContextProvider';
import DashboardLayout from '../../layout/DashboardLayout';
import { AuthenticatedStackNavigatorParamList } from '../../routes/types';

interface MyAccountScreenProps {
  landingTab?: Tab;
}

const tabs = [
  'My details',
  'Marketing preferences',
  // 'My Invoices',
  // 'My companies'
] as const;
export type Tab = typeof tabs[number];

const MyAccountScreen: React.FC<MyAccountScreenProps> = ({landingTab}) => {
  const [activeTab, setActiveTab] = useState<Tab>(landingTab || tabs[0]);
  const [showTfaModal, setShowTfaModal] = useState(false);

  const { user, updateUser } = useContext(AuthContext);

  const navigation =
    useNavigation<NavigationProp<AuthenticatedStackNavigatorParamList>>();

  if (!user) return <ActivityIndicator />;

  return (
    <DashboardLayout>
      <H1 xl>My Account</H1>
      <Tabs
        activeTab={activeTab}
        tabs={[...tabs]}
        emitActiveTab={(tabName: string) => setActiveTab(tabName as Tab)}
      />

      <Text style={tw`text-lg mb-4 font-ubuntu-bold font-bold text-blue`}>
        {activeTab}
      </Text>

      {activeTab === 'My details' && (
        <>
          <Row>
            <Col style={tw`w-full md:w-2/3`}>
              <UserDetailsForm user={user} updateUser={updateUser} />
              <View>
                {/* <Text
                  style={tw`text-lg mb-4 font-ubuntu-bold font-bold text-blue`}
                >
                  Business Details
                </Text>
                <BusinessDetailsForm user={user} updateUser={updateUser} /> */}
              </View>
            </Col>
            <Col style={tw`w-full md:w-1/3`}>
              <InfoBox
                content='Should you need to make any changes to your account, please
                  contact your Partner Manager.'
                style={tw`md:-mt-11`}
              >
                <View style={tw`mr-8`}>
                  <FullWidthButton
                    variant='transparent'
                    buttonStyle={tw`py-1`}
                    underline
                    onPress={() => navigation.navigate('contacts')}
                  >
                    <View style={tw`flex-row`}>
                      <DoubleChevronRightSvg style={tw`mr-1`} />
                      <Text style={tw`font-ubuntu-bold font-bold`}>
                        Contact
                      </Text>
                    </View>
                  </FullWidthButton>
                </View>
              </InfoBox>

              <Button
                style={tw`self-stretch mt-4`}
                onPress={() => setShowTfaModal(true)}
              >
                {user.tfaEnabled
                  ? 'Disable Two Factor Authentication'
                  : 'Enable Two Factor Authentication'}
              </Button>
            </Col>
          </Row>
        </>
      )}

      {activeTab === 'Marketing preferences' && ( //
        <MarketingPreferencesForm />
      )}

      {/* {
        activeTab === 'My Invoices' && (
          <InvoiceListTable/>
        )
      } */}

      {showTfaModal && (
        <TfaModal setVisible={setShowTfaModal} visible={showTfaModal} />
      )}
    </DashboardLayout>
  );
};

export default MyAccountScreen;
