import { Rebate } from "../../../shared/types/rebates";
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';

dayjs.extend(advancedFormat);
dayjs.extend(quarterOfYear);

// remarks: This is reverted to use "Q1 = Jan" format, which render this function... useless, but for clean code sake will keep it
export const displayQuarter = (date: Date = new Date()) => {
  let quarterShortform = parseInt(dayjs(date).startOf('quarter').format('Q'));
  let year = dayjs(date).year();
  
  return `${'Q' + quarterShortform + ' ' + year} (${dayjs(date)
    .startOf('quarter')
    .format('MMM-')}${dayjs(date).endOf('quarter').format('MMM')})`;
};

export const getFullQuarters = (
    rebates: Rebate[] = [],
    pattern: 'exclude' | 'include' | 'since' = 'exclude',
    rebateBackdate: boolean = false,
    year: number = 2021,
  ) => {
    let fullQuaters: { label: string; value: Date }[] = [];
    // loop to create the quarters options for the current quarter and the previous 1 / or 4 quarter
    const previousCount = rebateBackdate ? 4 : 2;
    if (pattern === 'exclude') {
      for (let i = 0; i < previousCount; i++) {
        const date = dayjs().startOf('quarter').subtract(i, 'quarters');
        const rebateExist = rebates.find(
          (x) =>
            dayjs(x.rebateDate).startOf('quarter').valueOf() === date.valueOf()
        );
        // push the date option regradless if rebate for that quarter already exist
        // if (!rebateExist) {
        if (true) {
          fullQuaters.push({
            label: `${displayQuarter(date.toDate())}`,
            value: date.toDate(),
          });
        }
      }
    }else if (pattern === 'include'){
      rebates.forEach(x => {
        const date = dayjs(x.rebateDate);
        if(!fullQuaters.find(item => item.value.valueOf() === date.valueOf())) {
          fullQuaters.push({
            label: `${displayQuarter(date.toDate())}`,
            value: date.toDate(),
          });
        }
      });
    }else if (pattern === 'since'){
      for (let i = 0; i <= (dayjs().year() - year) * 4; i++) {
        const date = dayjs().startOf('quarter').subtract(i, 'quarters');
        // console.log('q date', date.format('YYYY-MM-DD'))
        fullQuaters.push({
          label: `${displayQuarter(date.toDate())}`,
          value: date.toDate(),
        });
      }
    }
    return fullQuaters;
  };

export function addIndexSuffix(rebates: Rebate[], currentRebate: Rebate){
  const date = dayjs(currentRebate.rebateDate).toDate()
  const rebatesWithDate = rebates.filter(r => {
    const sameDate = dayjs(r.rebateDate).valueOf() === date.valueOf()
    const sameCompany = r.company.id === currentRebate.company.id;
    return sameDate && sameCompany
  });
  const currentIndex = rebatesWithDate.findIndex(r => r.id === currentRebate.id)
  return currentIndex <= 0 ? "" : ` (${String(currentIndex)})`
}