import { useLinkProps } from '@react-navigation/native';
import { FC } from 'react';
import { Linking } from 'react-native';
import { ClassInput } from 'twrnc/dist/esm/types';

import tw from '../../config/tailwind';
import { isWeb } from '../../constants';
import Text from './Text';

type Props = {
  children: string;
  onPress?: () => void;
  style?: ClassInput;
  linkProps?: ReturnType<typeof useLinkProps>;
  url?: string;
};

const A: FC<Props> = ({ children, onPress, style, linkProps, url }) => {
  const clicked = () => {
    if (url) return Linking.openURL(url);
    else return linkProps ? linkProps.onPress() : onPress && onPress();
  };

  return (
    <Text
      style={tw.style(`text-green underline mb-4 self-start`, style)}
      onPress={clicked}
      {...linkProps}
    >
      {children}
    </Text>
  );
};

export default A;
