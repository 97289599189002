import { Link, useFocusEffect } from '@react-navigation/native';
import { format } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';
import { ActivityIndicator, Pressable, Text } from 'react-native';

import { CompanyClient, ContactClient, UserClient } from '../../api';
import tw from '../../config/tailwind';
import { useToasts } from '../../hooks/useToasts';
import { ContactResponse } from '../../shared/types/contacts';
import { PaginatedResult } from '../../shared/types/paginatedResult';
import { UserResponse } from '../../shared/types/users';
import Button from '../shared/Button';
import GenericTable from '../shared/GenericTable';
import GenericTableAction from '../shared/GenericTableAction';
import LI from '../shared/LI';
import ViewAction from '../shared/ViewAction';
import AdminContactModal from './modals/AdminContactModal';
import AssignContactModal from './modals/AssignContactModal';
import CreateAdminUserModal from './modals/CreateAdminUserModal';

interface AdminCompanyContactsTableProps {
  companyId: string;
}

const AdminCompanyContactsTable: React.FC<AdminCompanyContactsTableProps> = ({
  companyId,
}) => {
  const [contacts, setContacts] = useState<PaginatedResult<ContactResponse>>();
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState('');
  const [refresh, setRefresh] = useState(0);
  const [contactModalActive, setContactModalActive] = useState(false);
  const { addToast, addUnhandledErrorToast } = useToasts();
  const [editContactId, setEditContactId] = useState<string>();
  const [assignContactModalActive, setAssignContactModalActive] =
    useState(false);

  useEffect(() => {
    if (refresh === 0) return;

    setContacts(undefined);
    ContactClient.findAll(query, page, companyId).then((results) => {
      setContacts(results);
    });
  }, [page, query, refresh]);

  useFocusEffect(
    useCallback(() => {
      setRefresh((old) => old + 1);
      return () => {
        setContacts(undefined);
      };
    }, [])
  );

  const onRemoveContact = async (contactId: string) => {
    try {
      await ContactClient.remove({ companyId, contactId });
      addToast({
        title: 'Contact removed',
        description: 'Contact was removed from the company successfully',
        type: 'success',
      });
      setRefresh((x) => x + 1);
    } catch (error) {
      addUnhandledErrorToast(error);
    }
  };

  return (
    <>
      {contactModalActive && (
        <AdminContactModal
          onUpdated={() => setRefresh((x) => x + 1)}
          setVisible={setContactModalActive}
          contact={
            editContactId
              ? contacts?.items.find((c) => c.id === editContactId)
              : undefined
          }
        />
      )}
      {assignContactModalActive && (
        <AssignContactModal
          companyId={companyId}
          assignedContactIds={contacts?.items.map((c) => c.id) || []}
          onUpdated={() => setRefresh((x) => x + 1)}
          setVisible={setAssignContactModalActive}
        />
      )}

      <Button
        style={tw`ml-auto mb-4`}
        onPress={() => {
          setEditContactId(undefined);
          setAssignContactModalActive(true);
        }}
      >
        Assign contact
      </Button>

      <GenericTable
        paginatedResult={contacts}
        searchPlaceholder='Search contacts'
        headers={{
          name: {
            title: 'Name',
            width: '200px',
          },
          role: {
            title: 'Role',
            width: '200px',
          },
        }}
        actions={(contact) => {
          return [
            <Pressable
              onPress={() => {
                setEditContactId(contact.id);
                setContactModalActive(true);
              }}
            >
              <GenericTableAction label='Edit' />
            </Pressable>,
            <Pressable onPress={() => onRemoveContact(contact.id)}>
              <GenericTableAction label='Remove' />
            </Pressable>,
          ];
        }}
        setPage={setPage}
        setQueryInput={setQuery}
      />
    </>
  );
};

export default AdminCompanyContactsTable;
