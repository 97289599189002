import { useGoogleMap } from '@react-google-maps/api';
import React, { useRef, useEffect } from 'react';
import tw from '../../config/tailwind';

interface MapControlProps {
  position: keyof typeof google.maps.ControlPosition;
}

const MapWebLegends = (props: React.PropsWithChildren<MapControlProps>) => {
  const map = useGoogleMap();
  const ref = useRef<HTMLElement>(null);

  useEffect(() => {
    if (map && ref && ref.current) {
      map.controls[window.google.maps.ControlPosition[props.position]].push(
        ref.current
      );
    }
  }, [map, ref]);

  return (
    <div ref={ref as any}>
      <div
        style={tw`w-fit bg-white py-1 px-4 font-sans m-2.5 text-[16px] flex flex-row shadow-sm items-center align-center`}
      >
        <div style={tw`font-sans font-bold`} >Keys:</div>
        <div style={tw`flex items-center justify-between mr-2`}>
          <img
            style={tw`w-10 h-10`}
            src='https://www.zentia.com/en-gb/wp-content/themes/mw-zentia/assets/dist/images/icons/pinpoint.svg'
            alt='distributor icon'
          />
          <p>Distributors</p>
        </div>
        <div style={tw`flex items-center justify-between`}>
          <img
            style={{...tw`w-6 h-6 px-2`, filter: 'drop-shadow(0px 1px 1px #aaa)'}}
            src='/svg/map-contact-marker.svg'
            alt='distributor icon'
          />
          <p>Contacts</p>
        </div>
      </div>
    </div>
  );
};

export default MapWebLegends;
