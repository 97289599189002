import { StackActions } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React, { useEffect, useState } from 'react';
import { Linking, useWindowDimensions, View } from 'react-native';

import { DocumentClient } from '../../../api';
import DocumentModal from '../../../components/project/modals/DocumentModal';
import MarkupEditor from '../../../components/project/modals/MarkupEditor';
import BackButton from '../../../components/shared/BackButton';
import Button from '../../../components/shared/Button';
import FullWidthButton from '../../../components/shared/FullWidthButton';
import H1 from '../../../components/shared/H1';
import P from '../../../components/shared/P';
import PencilSVG from '../../../components/svg/PencilSVG';
import tw from '../../../config/tailwind';
import { isWeb } from '../../../constants';
import DashboardLayout from '../../../layout/DashboardLayout';
import { ProjectStackNavigatorParamList } from '../../../routes/types';
import { DocumentResponse } from '../../../shared/types/documents';
import { isDocumentImage } from '../../../utils/isDocumentImage';

type ProjectDocumentScreenProps = NativeStackScreenProps<
  ProjectStackNavigatorParamList,
  'document'
> & {
  //
};

const ProjectDocumentScreen: React.FC<ProjectDocumentScreenProps> = ({
  route,
  navigation,
}) => {
  const [document, setDocument] = useState<DocumentResponse>();
  const window = useWindowDimensions();
  const [showEditModal, setShowEditModal] = useState(false);

  useEffect(() => {
    if (document?.id === route.params.documentId) return;

    let isActive = true;

    DocumentClient.getById(route.params.documentId).then(
      (res) => isActive && setDocument(res)
    );

    return () => {
      isActive = false;
    };
  }, [route.params.documentId]);

  const onBackButtonPressed = () =>
    navigation.canGoBack()
      ? navigation.goBack()
      : navigation.dispatch(
          StackActions.push('authenticatedStackNavigator', {
            screen: 'projectStack',
            params: {
              screen: 'project',
              params: {
                id: route.params.projectId,
              },
            },
          })
        );

  if (!document) {
    return (
      <DashboardLayout>
        {/*  */}
        {/*  */}
      </DashboardLayout>
    );
  }

  const downloadButton = (onPress?: () => void) => (
    <FullWidthButton
      variant='transparent'
      buttonStyle={tw`py-2 pr-4 min-w-48`}
      underline
      onPress={onPress}
    >
      Download file
    </FullWidthButton>
  );

  return (
    <DashboardLayout>
      <View style={tw`flex flex-row justify-between flex-wrap -mb-6`}>
        <BackButton onPress={onBackButtonPressed}>Back to project</BackButton>
        <Button
          onPress={() => setShowEditModal((state) => !state)}
          style={tw`mb-12 w-full sm:w-auto`}
        >
          <View style={tw`flex flex-row items-center`}>
            <PencilSVG style={tw`mr-1`} />
            Edit document
          </View>
        </Button>
      </View>
      <H1>{document.title}</H1>
      <P>{document.description}</P>

      <View style={tw`flex flex-row justify-between`}>
        {isWeb ? (
          <a
            href={document.publicUrl}
            download
            target='_blank'
            style={tw`no-underline flex`}
          >
            {downloadButton()}
          </a>
        ) : (
          downloadButton(() => Linking.openURL(document.filename))
        )}
      </View>

      {/* <P /> */}

      {isDocumentImage(document) && (
        <MarkupEditor
          image={document.publicUrl}
          markers={document.markup}
          preview
          row={window.width > 992}
        />
      )}

      {showEditModal && (
        <DocumentModal
          projectId={route.params.projectId}
          onDocumentAdded={setDocument}
          document={document}
          setVisible={setShowEditModal}
          visible
        />
      )}
    </DashboardLayout>
  );
};

export default ProjectDocumentScreen;
