import { AxiosError, AxiosRequestConfig } from 'axios';

import { AuthClient } from '../api';
import { axiosClient } from '../api/ApiClient';

type KeepAliveAxiosRequestConfig = AxiosRequestConfig & { _retry?: boolean };

const excludedPaths = [
  '/auth/refresh-token',
  '/auth/login', //
];

export const axiosSessionKeepAlive = async (
  error: AxiosError
): Promise<AxiosRequestConfig> => {
  const originalRequest = error.config as KeepAliveAxiosRequestConfig;

  if (excludedPaths.includes(new URL(originalRequest.url || '').pathname)) {
    throw error;
  }

  if (error.response?.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;
    const access_token = await AuthClient.refreshToken();
    axiosClient.defaults.headers.common['Authorization'] =
      'Bearer ' + access_token;
    originalRequest.headers = {
      ...originalRequest.headers,
      Authorization: 'Bearer ' + access_token,
    };
    return axiosClient(originalRequest);
  }

  throw error;
};
