import React, { useState } from 'react';
import { TaskClient } from '../../../api';

import { useToasts } from '../../../hooks/useToasts';
import { TaskResponse } from '../../../shared/types/tasks';
import ConfirmationModal from '../../shared/ConfirmationModal';

interface TaskCompletionModalProps {
  task: TaskResponse;
  setTask: (task: TaskResponse) => void;
  visible: boolean;
  setVisible: (v: boolean) => void;
}

const TaskCompletionModal: React.FC<TaskCompletionModalProps> = ({
  task,
  setTask,
  visible,
  setVisible,
}) => {
  const [loading, setLoading] = useState(false);
  const { addUnhandledErrorToast, addToast } = useToasts();

  const onSubmit = async (didConfirm?: boolean) => {
    if (!didConfirm) {
      setVisible(false);
      return;
    }

    try {
      setLoading(true);
      const updatedTask = await TaskClient.completeTask(
        task.project.id,
        task.id
      );
      setVisible(false);
      setTask({ ...updatedTask, taskImages: task.taskImages });
      addToast({
        title: 'Task completed',
        description: 'The task was completed successfully.',
        type: 'success',
      });
      setLoading(false);
    } catch (e) {
      setLoading(false);
      addUnhandledErrorToast(e);
    }
  };

  return (
    <ConfirmationModal
      visible={visible}
      title='Complete task'
      prompt={"Are you sure you'd like to mark this task as complete?"}
      onPromptEnd={onSubmit}
      loading={loading}
    />
  );
};

export default TaskCompletionModal;
