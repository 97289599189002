import React from 'react';

import NewsArticles from '../../../components/news/NewsArticles';
import H1 from '../../../components/shared/H1';
import P from '../../../components/shared/P';
import DashboardLayout from '../../../layout/DashboardLayout';
interface NewsScreenProps {}

const NewsScreen: React.FC<NewsScreenProps> = ({}) => {
  return (
    <DashboardLayout>
      <H1 xl>Latest News</H1>
      <P>
        Stay up to date with the latest news from Zentia. Get updates on
        Pinnacle by Zentia, new product announcements, thought leadership and
        more.
      </P>
      <NewsArticles />
    </DashboardLayout>
  );
};

export default NewsScreen;
