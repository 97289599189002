import { Formik } from 'formik';
import React, { useState } from 'react';
import { View } from 'react-native';
import { RadioButtonProps, RadioGroup } from 'react-native-radio-buttons-group';
import * as Yup from 'yup';
import { FormClient } from '../../api';

import tw from '../../config/tailwind';
import {
  postcodeRegExp,
  TRAINING_NUMBER_OF_ATTENDEE_OPTIONS,
  TRAINING_SESSIONS,
} from '../../constants';
import { TrainingBookingPayload } from '../../shared/types/forms';
import { TrainingLocation } from '../../shared/types/training';
import Button from '../shared/Button';
import Col from '../shared/Col';
import ContentWrapper from '../shared/ContentWrapper';
import H2 from '../shared/H2';
import H3 from '../shared/H3';
import InputLabel from '../shared/InputLabel';
import InputValidationMessage from '../shared/InputValidationMessage';
import LoqateAddressFormFields from '../shared/LoqateAddressFormFields';
import LoqateAddressLookup from '../shared/LoqateAddressLookup';
import MultiDatePicker from '../shared/MultiDatePicker';
import P from '../shared/P';
import Row from '../shared/Row';
import Select from '../shared/Select';

interface BookTrainingProps {}

enum PreviousTrainingOptions {
  Yes = 'Yes',
  No = 'No',
}

export type BookingTrainingFormValues = {
  numberOfAttendees: number;
  preferredDates?: Date[];
  trainingLocation: string;
  previousTraining: string;
  addressLine1: string;
  addressLine2: string;
  county: string;
  postcode: string;
};

const BookTraining: React.FC<BookTrainingProps> = ({}) => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const initialValues: BookingTrainingFormValues = {
    numberOfAttendees: 0,
    preferredDates: [],
    trainingLocation: '',
    previousTraining: '',
    addressLine1: '',
    addressLine2: '',
    county: '',
    postcode: '',
  };

  const [loqateAddress, setLoqateAddress] = useState<string>();

  const radioButtons: RadioButtonProps[] = [
    {
      id: '0',
      value: TrainingLocation.ZENTIA,
      label: 'At Zentia',
    },
    {
      id: '1',
      value: TrainingLocation.ON_SITE,
      label: 'At my business',
    },

    {
      id: '2',
      value: TrainingLocation.OTHER,
      label: 'Somewhere else',
    },
  ];

  const schema: Yup.SchemaOf<typeof initialValues> = Yup.object().shape({
    numberOfAttendees: Yup.number()
      .required()
      .label('Number of attendees')
      .moreThan(0),
    preferredDates: Yup.array().max(5).required().label('Preferred dates'),
    trainingLocation: Yup.string().required().label('Training location'),
    previousTraining: Yup.mixed()
      .oneOf(Object.values(PreviousTrainingOptions))
      .required()
      .label('Training required'),
    addressLine1: Yup.string().required().label('Address Line 1'),
    addressLine2: Yup.string().optional().label('Address Line 2').default(''),
    county: Yup.string().required().label('County'),
    postcode: Yup.string()
      .required()
      .matches(postcodeRegExp, 'Please provide a valid postcode')
      .label('Postcode'),
  });

  const onSubmit = async ({
    addressLine1,
    addressLine2,
    county,
    postcode,
    ...formValues
  }: typeof initialValues) => {
    await FormClient.bookTraining({
      ...(formValues as TrainingBookingPayload),
      address: [addressLine1, addressLine2, county].join(', '),
      postcode,
      trainingRequired: [TRAINING_SESSIONS[0].name],
    });
    setFormSubmitted(true);
  };

  if (formSubmitted) {
    return (
      <ContentWrapper>
        <H3>Thank you for your request</H3>
        <P>A member of the Zentia team will be in touch</P>
      </ContentWrapper>
    );
  }

  return (
    <ContentWrapper>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={schema}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          values,
          errors,
        }) => (
          <View>
            <H2>Book a training session</H2>
            <P>
              Choose your desired training session and tell us a little bit
              about your attendees, then submit the form. We'll get back to you
              with confirmation as soon as possible.
            </P>

            <H3 style={tw`mb-5`}>Tell us about the attendees</H3>

            <Row style={tw`mb-5 w-full lg:w-10/12`}>
              <Col style={tw`w-full lg:w-1/2`}>
                <Select
                  label='How many attendees will there be?'
                  labelSize='md'
                  onValueChange={(v) => {
                    const value = parseInt(v, 10);
                    setFieldValue(
                      'numberOfAttendees',
                      isNaN(value) ? 0 : value
                    );
                  }}
                  selectedValue={
                    values.numberOfAttendees > 0
                      ? values.numberOfAttendees.toString()
                      : undefined
                  }
                  options={TRAINING_NUMBER_OF_ATTENDEE_OPTIONS.map((item) => ({
                    label: item,
                    value: item,
                  }))}
                />
                {errors.numberOfAttendees && (
                  <InputValidationMessage>
                    {errors.numberOfAttendees}
                  </InputValidationMessage>
                )}
              </Col>
              <Col style={tw`w-full lg:w-1/2`}>
                <Select
                  label='Have they had training previously?'
                  labelSize='md'
                  onValueChange={(v) => setFieldValue('previousTraining', v)}
                  selectedValue={values.previousTraining || undefined}
                  options={Object.values(PreviousTrainingOptions).map(
                    (item) => ({
                      label: item,
                      value: item,
                    })
                  )}
                />
                {errors.previousTraining && (
                  <InputValidationMessage>
                    {errors.previousTraining}
                  </InputValidationMessage>
                )}
              </Col>
            </Row>

            <H3>Where will the training take place?</H3>

            <RadioGroup
              layout='row'
              radioButtons={radioButtons}
              containerStyle={tw.style(`mb-5 flex-wrap`, {
                marginLeft: '-10px',
              })}
              onPress={(radioButtonsArray) => {
                const selectedOption = radioButtonsArray.find(
                  (option) => option.selected
                );
                setFieldValue('trainingLocation', selectedOption?.value || '');
              }}
            />
            {errors.trainingLocation && (
              <InputValidationMessage>
                {errors.trainingLocation}
              </InputValidationMessage>
            )}

            <Row style={tw`mb-5 w-full lg:w-10/12`}>
              <Col style={tw`w-full md:w-1/2`}>
                <InputLabel label='Postcode lookup' />
                <LoqateAddressLookup
                  onAddressFound={({
                    BuildingName,
                    Line1,
                    Line2,
                    City,
                    PostalCode,
                  }) => {
                    const addressLine1 = [BuildingName, Line1].join(' ').trim();
                    setLoqateAddress(
                      [addressLine1, Line2, City, PostalCode].join(',')
                    );
                  }}
                />
                {!loqateAddress &&
                  (errors.addressLine1 ||
                    errors.addressLine2 ||
                    errors.county ||
                    errors.postcode) && (
                    <InputValidationMessage>
                      Address is a required field.
                    </InputValidationMessage>
                  )}
              </Col>
              {loqateAddress && (
                <Col style={tw`w-full`}>
                  <LoqateAddressFormFields
                    key={loqateAddress}
                    address={loqateAddress}
                  />
                </Col>
              )}
            </Row>
            <H3>Preferred dates</H3>
            <Row style={tw`mb-5`}>
              <Col style={tw`w-full lg:w-1/2`}>
                <MultiDatePicker
                  value={values.preferredDates || []}
                  setValue={(dates) => setFieldValue('preferredDates', dates)}
                  min={new Date()}
                />
                {errors.preferredDates && (
                  <InputValidationMessage>
                    {Array.isArray(errors.preferredDates)
                      ? errors.preferredDates.join(', ')
                      : errors.preferredDates}
                  </InputValidationMessage>
                )}
              </Col>
            </Row>

            <Button onPress={handleSubmit} style={tw`mb-4`}>
              Submit enquiry
            </Button>
          </View>
        )}
      </Formik>
    </ContentWrapper>
  );
};

export default BookTraining;
