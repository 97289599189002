import { API_BASE_URL } from '../env-consts';
import {
  CreateFeedbackPayload,
  FeedbackResponse,
  FeedbackType,
} from '../shared/types/feedback';
import { PaginatedResult } from '../shared/types/paginatedResult';
import { UserLoginStatResponse } from '../shared/types/users';
import ApiClient from './ApiClient';

const client = new ApiClient(`${API_BASE_URL}/user-stats`);

export const UserStatsClient = {
  async getLoginStats(
    page: number,
    desc: boolean,
    perPage: number,
    userId?: string
  ) {
    const url = userId ? `/logins/${userId}` : '/logins';

    return client
      .get<PaginatedResult<UserLoginStatResponse>>(url, {
        params: {
          page,
          descending: desc,
          perPage,
        },
      })
      .then((res) => res.data);
  },
};
