import React, { useMemo, useRef, useState } from 'react';
import { Dimensions } from 'react-native';
import MapView, {
  AnimatedRegion,
  MapMarkerProps,
  Marker,
  Region,
} from 'react-native-maps';

import H1 from '../shared/H1';

interface MapNativeProps {
  children: React.ReactNode;
  markers: MapMarkerProps[];
  onMarkerPress: (id: string | number) => void;
  height?: number;
}

// TODO: This component requires more work once native issues are fixed.
// This will also require further setup for android/ios - https://github.com/react-native-maps/react-native-maps/blob/master/docs/installation.md
const MapNative: React.FC<MapNativeProps> = ({
  children,
  markers,
  onMarkerPress,
  height,
}) => {
  const ref = useRef(null);

  const region = useMemo(() => {
    let latitudeRange = [0, 0];
    let longitudeRange = [0, 0];

    markers.forEach((marker, i) => {
      const { latitude, longitude } = marker.coordinate;

      if (i === 0) {
        latitudeRange = [latitude, latitude];
        longitudeRange = [longitude, longitude];
        return;
      }

      if (latitude < latitudeRange[0]) {
        latitudeRange[0] = latitude;
      }
      if (latitude < latitudeRange[1]) {
        latitudeRange[1] = latitude;
      }

      if (longitude < longitudeRange[0]) {
        latitudeRange[0] = longitude;
      }
      if (longitude < longitudeRange[1]) {
        longitudeRange[1] = longitude;
      }
    });

    const [minLatitude, maxLatitude] = latitudeRange;
    const [minLongitude, maxLongitude] = longitudeRange;

    return {
      latitude: maxLatitude,
      latitudeDelta: 18.622584880289395,
      longitude: minLongitude,
      longitudeDelta: 16.479492522776127,
    };
  }, [markers.length]);

  return (
    <MapView
      style={{ height, width: Dimensions.get('window').width }}
      ref={ref}
      region={region}
      provider='google'
      onRegionChange={(region) => {
        console.log({ region });
      }}
    >
      {markers.map((markerProps, i) => (
        <Marker
          onPress={() => {
            console.log('onPress', markerProps);
            onMarkerPress(markerProps.id);
          }}
          key={i}
          {...markerProps}
        />
      ))}
      {children}
    </MapView>
  );
};

export default MapNative;
