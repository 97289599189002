import * as DocumentPicker from 'expo-document-picker';
import { FC, useState } from 'react';
import { ActivityIndicator, Pressable, View } from 'react-native';

import tw from '../../config/tailwind';
import { useToasts } from '../../hooks/useToasts';
import CrossSVG from '../svg/CrossSVG';
import Button from './Button';
import Text from './Text';

type Props = {
  setValue?: (v?: File) => void;
  label?: string;
  multiple?: boolean;
  type: '*/*' | 'image/*' | 'application/vnd.ms-excel' | string[];
  loading?: boolean;
  hasError?: boolean;
  uploadOnly?: boolean;
};

const FilePicker: FC<Props> = ({
  setValue,
  label,
  multiple,
  type,
  loading,
  hasError,
  uploadOnly
}) => {
  const [file, setFile] = useState<DocumentPicker.DocumentResult>();
  const { addUnhandledErrorToast } = useToasts();

  const openDocumentPicker = () => {
    DocumentPicker.getDocumentAsync({
      type,
      multiple,
    })
      .then((x) => {
        if (x.type === 'success' && x.file) {
          setFile(x);
          setValue && setValue(x.file);
        }
      })
      .catch((e) => {
        addUnhandledErrorToast(e);
      });
  };

  const removeFile = () => {
    setFile(undefined);
    setValue && setValue(undefined);
  };

  return (
    <View
      style={tw.style(
        `border-2 border-border border-dashed px-8 py-16 my-4 flex justify-center items-center`,
        hasError && 'border-red'
      )}
    >
      {loading ? (
        <ActivityIndicator />
      ) : uploadOnly ? (
        <Button outlined style={tw`self-center`} onPress={openDocumentPicker}>
          {label || 'Select document'}
        </Button>
      ) : file && file.type === 'success' ? (
        <Text style={tw`flex items-center justify-center`}>
          {file.name}{' '}
          <Pressable onPress={removeFile}>
            <CrossSVG
              width={24}
              height={24}
              color='currentColor'
              style={tw`ml-2 text-red`}
            />
          </Pressable>
        </Text>
      ) : (
        <Button outlined style={tw`self-center`} onPress={openDocumentPicker}>
          {label || 'Select document'}
        </Button>
      )}
    </View>
  );
};

export default FilePicker;
