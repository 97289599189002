import { MarkerProps } from '@react-google-maps/api';
import React from 'react';
import { Platform } from 'react-native';

export interface CustomMarkerProps extends MarkerProps {
  id?: string | number;
  markerType?: string;
}

interface MapProps {
  children?: React.ReactNode;
  // markers: (MarkerProps & { id: string | number })[];
  markers: (CustomMarkerProps)[];
  onMarkerPress: (id: string | number) => void;
  height?: number;
}

const Component = Platform.select({
  native: () => require('./MapNative')?.default,
  default: () => require('./MapWeb')?.default,
})();

const Map: React.FC<MapProps> = ({
  children,
  markers,
  onMarkerPress,
  height = 700,
}) => {
  if (!Component) return null;

  if (Platform.OS === 'web') {
    return (
      <Component
        markers={markers}
        height={height}
        onMarkerPress={onMarkerPress}
      >
        {children}
      </Component>
    );
  }

  return (
    <Component
      markers={markers
        .filter((marker) => marker.visible)
        .map((marker) => {
          return {
            id: marker.id ?? '',
            coordinate: {
              latitude: marker.position.lat as number,
              longitude: marker.position.lng as number,
            },
          }
        })}
      height={height}
      onMarkerPress={onMarkerPress}
    >
      {children}
    </Component>
  );
};

export default Map;
