import { useFocusEffect } from '@react-navigation/native';
import React, { useCallback, useEffect, useState } from 'react';
import { Linking, Pressable, View } from 'react-native';

import { InvoiceClient } from '../../api/InvoiceClient';
import tw from '../../config/tailwind';
import { isWeb } from '../../constants';
import { useAuthContext } from '../../hooks/useAuthContext';
import { InvoiceResponse } from '../../shared/types/invoices';
import { PaginatedResult } from '../../shared/types/paginatedResult';
import DownloadAction from '../shared/DownloadAction';
import FullWidthButton from '../shared/FullWidthButton';
import GenericTable from '../shared/GenericTable';
import EyeSVG from '../svg/EyeSVG';
import InvoiceNewModal from './modals/InvoiceModal';

interface InvoiceListTableProps {
  onlyMyInvoices?: boolean;
}
const InvoiceListTable: React.FC<InvoiceListTableProps> = ({ onlyMyInvoices }) => {
  const [page, setPage] = useState(1);
  const [refresh, setRefresh] = useState(0);
  const [showNewInvoiceModal, setShowNewInvoiceModal] = useState(false);

  const { user, activeCompany } = useAuthContext();

  const [paginatedResult, setPaginatedResult] = useState<PaginatedResult<InvoiceResponse>>();
  const [editInvoice, setEditInvoice] = useState<InvoiceResponse | undefined>(undefined);

  // function to load results
  const loadInvoices = useCallback(async (page) => {
    const res = await InvoiceClient.getList(page, activeCompany?.id);
    setPaginatedResult(res);
  }, [activeCompany]);

  useEffect(() => {
    loadInvoices(1)
  }, [activeCompany, refresh]);

  useFocusEffect(
    useCallback(() => {
      setRefresh((old) => old + 1);
    }, [])
  );

  return (
    <View style={tw`bg-white p-8 mb-6`}>
      <GenericTable
        paginatedResult={paginatedResult}
        headers={{
          title: {
            title: 'Title',
            width: '150px',
            bold: true,
          },
          desc: {
            title: 'Description',
            width: '250px'
          },
        }}
        actions={(invoice) => [
          <div
            className='round-button hover:text-blue'
            style={tw.style(
              `w-10 h-10 rounded-full bg-background-100 flex justify-center items-center mx-2`, {cursor: 'pointer'}
            )}
            onClick={() => {
              setEditInvoice(invoice);
              setShowNewInvoiceModal(true);
            }}
          >
            <EyeSVG
              width={18}
              height={10}
              style={tw.style(`text-green`)}
            />
          </div>,
          isWeb ? (
            <a href={invoice.publicUrl} download>
              <DownloadAction />
            </a>
          ) : (
            <Pressable onPress={() => Linking.openURL(invoice.publicUrl)}>
              <DownloadAction />
            </Pressable>
          ),
        ]}
        setPage={setPage}
      />

      {
        (user?.tier || 3) > 1 ? null :
          <View style={tw`flex flex-row justify-between`}>
            <FullWidthButton
              variant='transparent'
              buttonStyle={tw`py-2 pr-4 w-48`}
              underline
              onPress={() => setShowNewInvoiceModal(true)}
            >
              + Upload Invoice
            </FullWidthButton>
          </View>
      }

      <InvoiceNewModal
        visible={showNewInvoiceModal}
        setVisible={setShowNewInvoiceModal}
        onDismiss={() => setEditInvoice(undefined)}
        onInvoiceAdded={() => setRefresh((x) => x + 1)}
        onInvoiceDelete={() => setRefresh((x) => x + 1)}
        initInvoice={editInvoice}
      />
    </View>
  );
};

export default InvoiceListTable;
